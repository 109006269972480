import { useSelector } from 'dva';
import React from 'react';
import { IGlobal } from '../../Model/GlobalModel';
import './Foot.scss';
/**
 * 底部logo医院名称等等
 */
function Foot() {
  const globalData = useSelector(({ global }: { global: IGlobal }) => global);
  return (
    <div className="footer">
      <div className="footer_d">
        <img src={globalData.companyInfo.logoUrl} alt="" className="logoIcon" />
        <div className="companyName">{globalData.companyInfo.name}</div>
      </div>
    </div>
  );
}
export default Foot;
