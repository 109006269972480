import React from 'react';

// const filePath = window.screen.width > 500 ? "img" : "moblieImg";
// tslint:disable-next-line:no-any

class XAET20190001 extends React.Component<{}> {
  public render() {
    return (
      <div id="SHCZ20190001">
        <div className="explain_box">
          <h1 className="firstCode">一、 项目介绍</h1>
          <h4> 1. 项目简介</h4>
          <div className="thirdCode">
            该项目由西安儿童医院发起，用来记录本院就诊的异物患者。医生通过本量表系统记录患者基本信息、研究数据收集、麻醉、治疗方案、出院随访等信息，以备后续科研项目进行需要的数据收集工作。我们将继续收集上述患者信息直至项目研究结束或患者永久性退出。
          </div>
          <h4> 2. 纳入标准</h4>
          <div className="thirdCode">患者必须为异物患者。</div>
          <h4>3. 随访时间</h4>
          <div className="thirdCode">患者在纳入项目后，医生需按照项目要求及时填写相应的访视问卷。</div>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">二、填写指南：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span>本量表系统内所记录的信息主要包括：基本信息、研究数据收集、麻醉、治疗方案、出院随访等。</span>
            </li>
            <li>
              <span>
                病例在系统内首次创建后，系统将为患者进行编号，该编号为患者的唯一识别信息。根据此编号可在系统内查询患者病例信息。
              </span>
            </li>
            <li>
              <span>
                请注意准确填写问卷信息，仔细查看问题内容及说明。问题涉及数值单位时，请根据问题标注的单位填写。
              </span>
            </li>
            <li>
              <span> 问卷需要由专业医生进行收集填写。</span>
            </li>
          </ul>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">三、 注意事项：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span> 我们会严格保守患者任何个人信息秘密。</span>
            </li>
            <li>
              <span>医生必须确保填写内容正确无误后提交。</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default XAET20190001;
