/**
 * 目前该项目大部分地方 都是 直接根据 引用地址 改变值，导致 整体数据特别混乱，
 * 有时间 统一修改
 * 目前发现的地方
 * 1 复合组件 直接修改了state的gaugeData，比如新增和删除都是直接修改了，导致后边的逻辑获取到了新值
 */

import { message } from 'antd';
import Schema from 'async-validator';
import { Model } from 'dva';
import { routerRedux } from 'dva/router';
import SurveyModelUtil from '../utils/Survey/SurveyModelUtil';
import { GaugeSurvey } from './../api';
import { IAllGauge } from './../assets/js/DataJson';
// import SHCZ20190001 from './../assets/js/SHCZ20190001';
message.config({
  top: 150,
});
// tslint:disable-next-line:no-any
let timer: any = null;
const isPc = window.screen.width > 500;
export interface ISurvey {
  gaugeData: IAllGauge[];
  menuIndex: number;
  menuIChild: number;
  doms: HTMLCollectionOf<Element> | [];
  answer: {
    // tslint:disable-next-line:no-any
    [key: string]: any;
  };
  countData: {
    // tslint:disable-next-line:no-any
    [key: string]: any;
  };
  // tslint:disable-next-line:no-any
  schema: { [key: string]: any };
  visitNumber: number;
  answerId: string;
  isCache: boolean;
  userId: undefined | number;
  writeType: string; // 当前页面的填写方式
  currentCatchIf: boolean; // 当前页面是否需要缓存
  patientId: string;
  questionId: string;
  ossInfo: IOssInfo;
  code: string;
  originCode: string;
  roleType: 'patient' | 'doctor';
  modalShow: boolean;
}
export interface IOssInfo {
  accessKeyId?: string;
  accessKeySecret?: string;
  expiration?: string;
  host?: string;
  securityToken?: string;
  bucket?: string;
}
const needCache = ['surveyQrcodeUpdate', 'surveyQrcodeUpdate'];
export default {
  namespace: 'survey',
  state: {
    originCode: '', // 组织code
    schema: {},
    gaugeData: [], // 所有量表数据
    menuIndex: -1, // 1级菜单下标
    menuIChild: -1, // 2级菜单下标
    answer: {}, // 答案
    countData: {}, // 算分使用
    currentAnswer: {}, // 当前访视的答案
    doms: [],
    visitNumber: 0, // 访视次数
    patientId: '', // 患者id
    answerId: '', // 问卷id
    isCache: false, // 是否缓存
    userId: undefined,
    writeType: '',
    currentCatchIf: false,
    questionId: '', // 新建问卷id
    ossInfo: {},
    code: '',
    roleAndUrl: '',
    roleType: 'doctor', // 填写人权限
    modalShow: false,
  } as ISurvey,
  effects: {
    // 提交表单
    *submitFrom({ payload }, { call, put, select, take }) {
      const state = yield select(({ survey }: { survey: ISurvey }) => survey);
      const validator = new Schema(state.schema);
      const surveyModelUtil = new SurveyModelUtil();
      try {
        if (true) {
          yield validator.validate(state.answer);
        }

        if (state.originCode === 'SHCZ20200002' && !payload && state.roleType === 'patient') {
          yield put({ type: 'allSave', payload: { modalShow: true, isCache: false } });
          return;
        }
        if (state.originCode === 'HJ90520200001' && !payload && state.roleType === 'patient') {
          yield put({ type: 'allSave', payload: { modalShow: true, isCache: false } });
          return;
        }

        delete state.answer.beforeModuleNames;
        const result = yield surveyModelUtil.submitSurvey({
          answerJson: JSON.stringify(state.answer),
          questionAnswerJson: JSON.stringify(state.gaugeData),
          writeType: state.writeType,
          id: state.answerId,
          visitNumber: state.answer.visitNumber,
          userId: state.userId,
          patientId: state.patientId,
          questionId: state.questionId,
          code: state.code,
        });

        yield put({ type: 'allSave', payload: { isCache: false } });
        message.info('提交成功');
        // 如果是患者填写的并且是对应的一个组织的 并且把 对应需要展示的答案传过去
        if (state.roleType === 'patient') {
          // 长征
          if (state.originCode === 'SHCZ20200002') {
            const Q01818 = state.answer.Q01818 !== '是' ? '否' : '是';
            yield put(
              routerRedux.replace(
                `/HatVirus?Q01816=${state.countData.Q01816}&Q01817=${state.countData.Q01817}&Q01818=${Q01818}&Q01819=${state.answer.Q01819}`
              )
            );
            return;
          }
          // 海军905
          if (state.originCode === 'HJ90520200001') {
            const Q01818 = Number(state.answer.Q01818) ? '是' : '否';
            let Q01830 = state.answer.Q01830 ? state.answer.Q01830 : [];
            if (Q01830) {
              // tslint:disable-next-line: no-any
              Q01830 = Q01830.map((item: any, index: string | number) => {
                return item + (state.countData.Q01830[index] ? state.countData.Q01830[index] : '-2');
              });
            }
            let Q01840 = state.answer.Q01840 ? state.answer.Q01840 : [];
            if (Q01840) {
              // tslint:disable-next-line: no-any
              Q01840 = Q01840.map((item: any, index: string | number) => {
                return item + (state.countData.Q01840[index] ? state.countData.Q01840[index] : '-2');
              });
            }
            const Q01845 = state.answer.Q01845 ? state.answer.Q01845 : '';
            yield put(
              routerRedux.replace(
                `/HJ90520200001?Q01816=${state.countData.Q01816}&Q01817=${
                  state.countData.Q01817
                }&Q01818=${Q01818}&Q01819=${state.countData.Q01819}&Q01829=${
                  state.countData.Q01829
                }&Q01830=${JSON.stringify(Q01830)}&Q01840=${JSON.stringify(Q01840)}&Q01845=${JSON.stringify(Q01845)}`
              )
            );
            return;
          }
          if (state.originCode === 'JNL20200001') {
            // const Q01818 = state.answer.Q01818 !== '是' ? '否' : '是';
            yield put(
              routerRedux.replace(
                `/JNL20200001?Q01816=${state.countData.Q01816}&Q01817=${state.countData.Q01817}&Q01818=${state.answer.Q01818}&Q01819=${state.answer.Q01819}`
              )
            );
            return;
          }
        }
        if (result.code) {
          if (window.location.href.includes('Reservation')) {
            // 如果是预约页面直接跳转至公众号
            const urlStr = decodeURIComponent(window.location.href.split('roleAndUrl=')[1])
              .split(',')
              .join('/');
            window.location.href = `${window.location.origin}/wechat/#/${urlStr}`;
            return;
          }
          yield put(
            routerRedux.replace(
              `/SubmitCase/${result.code}/${result.name}/${result.sampleSequence}?roleAndUrl=${state.roleAndUrl}`
            )
          );
          return;
        }
        window.history.back();
      } catch ({ errors }) {
        const gaugeData = surveyModelUtil.catchErrValidator(state.gaugeData, errors);
        yield put({ type: 'allSave', payload: { gaugeData: [...gaugeData], isCache: state.currentCatchIf } });
      }
    },
    /**
     * 初始化数据
     */
    *gaugeInit({ callback, payload }, { put, select }) {
      const surveyModelUtil = new SurveyModelUtil();
      // 判断是医生还是病人，再判断是否有资格填写
      const roleType = Boolean(payload && payload.channel) ? payload.channel.toLowerCase() : 'doctor';
      // 获取答案之类的东西
      const { gaugeData, surveyInfo } = yield surveyModelUtil.getSurveyData(payload);
      const answerKey = surveyInfo.answerCacheJson ? 'answerCacheJson' : 'answerJson';
      const surveyAnswer = surveyInfo[answerKey] ? JSON.parse(surveyInfo[answerKey]) : {};
      payload.visitNumber = surveyInfo.visitNumber;
      // const staticData = new SHCZ20190001().data;
      const surveyInitData = surveyModelUtil.surveyInit(
        JSON.parse(gaugeData.jsonDesc),
        // staticData,
        surveyAnswer,
        roleType,
        payload
      );

      // 访视要放到答案里，因为解析器是根据答案进行解析的
      surveyInitData.answer.visitNumber = surveyInfo.visitNumber;
      if (surveyInitData.answer.patient_sex === null || surveyInitData.answer.patient_sex === undefined) {
        surveyInitData.answer.patient_sex = surveyInfo.gender;
        surveyInitData.countData.patient_sex = surveyInfo.gender === '女' ? 0 : 1;
      }
      const { result } = yield GaugeSurvey.getToken();
      // 拿到默认的算分题的传值
      const data = surveyModelUtil.visibleIfChange(
        surveyInitData.gaugeData,
        surveyInitData.countData,
        surveyInitData.answer,
        surveyInitData.schema
      );
      // return;
      // 复合组件拿算分的值
      const groupData = surveyModelUtil.visibleIfGroupChange(data.gaugeData, data.answer, data.schema, data.countData);
      const SurveyModelState = {
        ossInfo: result,
        ...surveyInitData,
        ...groupData,
        visitNumber: surveyInfo.visitNumber,
        userId: surveyInfo.userId,
        originCode: surveyInfo.originCode,
        answerId: payload.answerId,
        patientId: payload.patientId,
        questionId: gaugeData.id,
        isCache: surveyInitData.currentCatchIf,
        code: payload.code,
        roleAndUrl: payload.roleAndUrl,
        roleType,
      };
      yield put({
        type: 'allSave',
        payload: SurveyModelState,
      });

      if (callback) {
        callback();
      }
    },

    /**
     * form表单变化
     * @param { field } 字段名
     * @param { val } 返回值
     */
    *changeForm({ payload }, { put, select, call }) {
      const state: ISurvey = yield select(({ survey }: { survey: ISurvey }) => survey);
      const errMsgDom = document.getElementById(
        `${isPc ? 'pc' : 'm'}_errMsg_${payload.props.formObj.subjectData.field}`
      );
      if (errMsgDom) {
        errMsgDom.innerHTML = '';
      }

      if (
        JSON.stringify(state.answer[payload.props.formObj.subjectData.field]) !== JSON.stringify(payload.val) ||
        payload.props.formObj.subjectData.subjectType === 'FormGroup'
      ) {
        const surveyModelUtil = new SurveyModelUtil();
        // 判断是复合组件还是普通组件
        const isGroup = payload.props.formObj.subjectData.parentPosition;
        // yield call(surveyModelUtil.delay, 200);
        const surveyData = surveyModelUtil[isGroup ? 'changeGroupSurvey' : 'changeSurvey'](payload, state);
        if (surveyData) {
          yield put({
            type: 'allSave',
            payload: {
              ...surveyData,
              isCache: state.currentCatchIf,
            },
          });
        }
      }
    },
    /**
     * 选择菜单
     * param 直接记录payload里面的参数
     * @param menuIndex 1级菜单下标
     * @param menuIChild 2级菜单下标
     */
    *selectMenu({ payload }, { put, select, call }) {
      const state = yield select(({ survey }: { survey: ISurvey }) => survey);
      const surveyModelUtil = new SurveyModelUtil();
      if (payload.menuIndex !== state.menuIndex) {
        yield put({ type: 'LoadModel/directSave', payload: { isLoading: true } });
        yield call(surveyModelUtil.delay, 0);
        yield put({
          type: `allSave`,
          payload,
        });
        return;
      }
      surveyModelUtil.scrollToWindow({ ...state, ...payload });
    },
    /**
     * 重置state
     */
    *restState({}, { put, select }) {
      const surveyModelUtil = new SurveyModelUtil();
      const state = surveyModelUtil.restState();
      yield put({
        type: 'allSave',
        payload: { ...state, schema: {}, answer: {}, isCache: false },
      });
    },

    /**
     * 回显上次方式修改
     */
    *lastSaveSurvey({ payload }, { put, select }) {
      const state = yield select(({ survey }: { survey: ISurvey }) => survey);
      const surveyModelUtil = new SurveyModelUtil();
      // 获取上次访视数据
      const { lastAnswer, lastGaugeData } = yield surveyModelUtil.lastSaveSurvey(
        state.visitNumber,
        state.patientId,
        state.gaugeData,
        state.answer,
        payload
      );
      const payloadNew = { writeType: state.writeType, visitNumber: state.visitNumber };
      // 初始化
      const surveyInitData = yield surveyModelUtil.surveyInit(
        lastGaugeData,
        lastAnswer,
        state.roleType,
        payloadNew,
        true
      );
      // 条件隐藏
      const data = yield surveyModelUtil.visibleIfChange(
        surveyInitData.gaugeData,
        surveyInitData.countData,
        surveyInitData.answer,
        surveyInitData.schema
      );
      const groupData = surveyModelUtil.visibleIfGroupChange(data.gaugeData, data.answer, data.schema, data.countData);
      yield put({
        type: 'allSave',
        payload: { ...groupData, isCache: true, restLoad: true },
      });
    },

    /**
     * 直接保存传入的值
     */
    *directSave({ payload, callback }, { put }) {
      yield put({
        type: 'allSave',
        payload,
      });
      if (callback) {
        callback();
      }
    },
    /**
     * 关闭弹框
     * @param param0
     * @param param1
     */
    *closeModal({ payload, callback }, { put }) {
      yield put({
        type: 'allSave',
        payload: {
          modalShow: false,
          isCache: false,
        },
      });
    },
  },

  reducers: {
    // 全部存储方法
    // tslint:disable-next-line:no-any
    allSave(state: ISurvey, action: any) {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      if (action.payload.isCache) {
        timer = setTimeout(async () => {
          // 扫码进入
          if (needCache.includes(state.writeType)) {
            await GaugeSurvey.catchQrcode({
              answerCacheJson: JSON.stringify(state.answer),
              code: state.code || action.payload.code,
            });
          } else {
            if (JSON.stringify(state.answer) !== '{}') {
              // 普通
              await GaugeSurvey.createCacheGauge({
                answerCacheJson: JSON.stringify(state.answer),
                patientId: state.patientId || action.payload.patientId,
                answerId: state.answerId || action.payload.answerId,
                questionId: state.questionId || action.payload.questionId,
              });
            }
          }
        }, 300);
      }

      return {
        ...state,
        ...action.payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (pathname === '/GaugeSurvey') {
          dispatch({
            type: 'restState',
          });
        }
      });
    },
  },
} as Model;
