import { Select } from 'antd';
import { useDispatch } from 'dva';
const { Option } = Select;
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormSelectTag.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
// tslint:disable-next-line: no-any
let topValue: any;
// tslint:disable-next-line: no-any
let st: any;
// tslint:disable-next-line: no-any
let topSt: any;
function FormSelect(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  // 控制显示的最大条目数
  const maxData = 50;
  // 初始化的时候也只显示最大条目数，不论原始数据有多少都是，只显示最大条目数
  const dOpts = formObj.selectOpts
    ? formObj.selectOpts.filter((item, i) => {
        if (i < maxData) {
          return item;
        }
      })
    : [];
  const [seleVal, setSeleVal] = useState(formObj.defaultValue ? formObj.defaultValue : []);
  const [opts, setOpts] = useState(dOpts);
  const dispatch = useDispatch();
  const className = 'form_input_' + new Date().getTime().toString();
  const endName = 'form_input ' + className;

  function inputChange(str: string) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: str,
      },
    });
    setSeleVal(str);
  }
  // tslint:disable
  /**
   * 优化当数据量很大的时候，使用卡顿的问题
   * 当符合条件的数据超过50条，只显示最前面的50条。否则有多少显示多好啊
   *
   */
  function search(e: any) {
    setSTop();
    if (st) {
      clearTimeout(st);
    }
    st = setTimeout(() => {
      loadOption(e);
    }, 300);
  }
  function loadOption(val: any) {
    let num = 0;
    const newopts = formObj.selectOpts
      ? formObj.selectOpts.filter(item => {
          num++;
          if (item.value.toString().lastIndexOf(val) !== -1) {
            return item;
          } else {
            if (num < maxData) {
              return item;
            }
          }
        })
      : [];
    setOpts(newopts);
  }
  //让iphone当中input聚焦的时候，下面的键盘会挡住输入框
  function setSTop() {
    var u = navigator.userAgent;
    if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
      window.scrollTo({ behavior: 'smooth', top: topValue });
    }
    if (topSt) {
      clearTimeout(topSt);
    }
    topSt = setTimeout(() => {
      setSTop();
    }, 20);
  }
  //聚焦方法
  function inputFocus() {
    setTimeout(() => {
      topValue = Number(document.documentElement.scrollTop || document.body.scrollTop);
    }, 300);
  }
  //失焦方法
  function inputBlur() {
    topValue = null;
    if (topSt) {
      clearTimeout(topSt);
    }
  }
  return (
    <div id="FormSelectTag">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.errMsg ? 'cl star' : 'star'}
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <Select
          className={endName}
          placeholder={formObj.placeholder}
          defaultValue={formObj.defaultValue}
          value={seleVal}
          onChange={inputChange}
          mode={'tags'}
          allowClear={false}
          showSearch={true}
          optionFilterProp="children"
          // tslint:disable-next-line:jsx-no-multiline-js
          // filterOption={true}
          getPopupContainer={() => document.getElementById('scrl_box')!}
          onSearch={e => {
            search(e);
          }}
          onFocus={() => {
            inputFocus();
          }}
          onBlur={() => {
            inputBlur();
          }}
        >
          {// tslint:disable-next-line:jsx-no-multiline-js
          opts.map(item => (
            <Option key={item.name} value={item.value}>
              {item.name || item.label}
            </Option>
          ))}
        </Select>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormSelect);
