import React from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormImage.scss';

/**
 *  图片组件
 */
function FormImage(props: ISurveyHook) {
  // const { isShow } = useFormActions(props);

  return (
    <div className="FormImage">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={props.formObj.subjectData.errMsg ? 'cl star' : 'star'}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{props.formObj.subjectData.subjectName}</div>
        </div>
        <img className="img_box" src={props.formObj.subjectData.imgUrl} />
      </div>
    </div>
  );
}
export default FormImage;
