import { Col, Icon, Row } from 'antd';
import React, { Fragment } from 'react';
import { IDoctorMsg } from '../../../../../api/WxDoctorApi';

interface IVerifyPatientCodeContentProps {
  patientMsg: IDoctorMsg;
  sampleSequence: string;
  // tslint:disable-next-line:no-any
  verifySampleSequence: any;
}
/*
 * 根据患者编号返回出用户是展示的ui
 *
 * */
function VerifyPatientCodeContent(props: IVerifyPatientCodeContentProps) {
  const { patientMsg, sampleSequence, verifySampleSequence } = props;
  return (
    <Fragment>
      <Row gutter={[16, 32]}>
        <Col span={12}>
          <div className={'patient'}>
            <h1>当前患者</h1>
            <div className={'info'}>
              <h2>{patientMsg.name}</h2>
              <span>
                （{patientMsg.gender ? '男' : '女'} {patientMsg.age}岁）
              </span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={'patient'}>
            <h1> 就诊编号是 {sampleSequence}的患者</h1>
            <div className={`info ${verifySampleSequence.unlike && 'diverse'}`}>
              <h2>{verifySampleSequence.name}</h2>
              <span>
                （{verifySampleSequence.gender ? '男' : '女'} {verifySampleSequence.age}岁）
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        {// tslint:disable-next-line:jsx-no-multiline-js
        !verifySampleSequence.unlike ? (
          <Col span={24}>
            <Icon type="info-circle" style={{ color: '#63BE00', paddingRight: '12px' }} theme="filled" />
            就诊编号与需要发送问卷的患者信息相符
          </Col>
        ) : (
          <Col span={24}>
            <Icon type="info-circle" style={{ color: '#F5A623', paddingRight: '12px' }} theme="filled" />
            就诊编号与需要发送问卷的患者信息不相符
          </Col>
        )}
      </Row>
    </Fragment>
  );
}

export default VerifyPatientCodeContent;
