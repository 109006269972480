import { Input } from 'antd';
import React from 'react';
import './MobileSearch.scss';

const { Search } = Input;
// 同意搜索组件
interface IMobileSearchProps {
  onSearch: (value?: string) => void;
  onChange?: (value: string) => void;
  placeholder: string;
  searchText?: string;
}

function MobileSearch(props: IMobileSearchProps) {
  const { onSearch, onChange, placeholder, searchText = true } = props;

  function updateInputText(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <div id={'mobileSearch'}>
      <Search
        placeholder={placeholder}
        onChange={event1 => updateInputText(event1.target.value)}
        onSearch={onSearch}
        enterButton={searchText}
      />
    </div>
  );
}

export default MobileSearch;
