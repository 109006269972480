import { Checkbox, Spin } from 'antd';
import { DatePicker } from 'antd-mobile';
import { Link, RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { Component } from 'react';
import WxDoctorApi, { IDoctorMsg, INotiItem, INotiListReq } from '../../../api/WxDoctorApi';
import { MobileAddBlockContent, MobileHeader, MobileNoDatas, MobileTag } from '../../../components/index';
import { MobilePagination, UpdateTitle } from '../../../utils';
import './PatientMsg.scss';
interface IMyState {
  startDate: Date | undefined;
  endDate: Date | undefined;
  curType: number;
  patientMsg?: IDoctorMsg;
  pageData: { pageSize: number; total: number; current: number };
  notifyData?: INotiItem[];
}
interface IMyProps extends RouteComponentProps<{ id: string }> {}
class PatientMsgClass extends Component<IMyProps, IMyState> {
  constructor(props: Readonly<IMyProps>) {
    super(props);
    this.state = {
      startDate: undefined,
      endDate: undefined,
      curType: 1,
      pageData: { pageSize: 10, total: 0, current: 1 },
      notifyData: [],
    };
    this.scrollE = this.scrollE.bind(this);
  }
  public componentDidMount(): void {
    this.getPatientMsg();
    this.getNotifyList();
    UpdateTitle('患者信息');
    window.addEventListener('scroll', this.scrollE, true);
  }
  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.scrollE, true);
  }

  public scrollE() {
    const { pageData } = this.state;
    MobilePagination('PatientNotifyMsg', pageData.current, pageData.pageSize, pageData.total).then(pageNum => {
      if (pageNum) {
        this.setState(
          (prevState: IMyState) => {
            return { pageData: { ...prevState.pageData, current: Number(pageNum) } };
          },
          () => {
            this.getNotifyList(true);
          }
        );
      }
    });
  }
  // 获取病人信息
  public async getPatientMsg() {
    const { result } = await WxDoctorApi.getPatientMsg(this.props.match.params.id);
    if (result) {
      this.setState({ patientMsg: result });
    }
  }
  // 通知列表 合并
  public async getNotifyList(isMerge?: boolean) {
    const { pageData, curType, startDate, endDate } = this.state;
    const typeKey = ['', 'FOLLOW_UP_MSG', 'FOLLOW_UP_QUESTIONNAIRE'];
    const notifyReq: INotiListReq = {
      receiveUserId: this.props.match.params.id,
      pageSize: pageData.pageSize,
      pageNum: pageData.current,
      sysMsgType: typeKey[curType - 1],
    };
    if (startDate && endDate) {
      notifyReq.startTime = moment(startDate)
        .startOf('day')
        .valueOf();
      notifyReq.endTime = moment(endDate)
        .endOf('day')
        .valueOf();
    }
    const { result } = await WxDoctorApi.getPatientNotis(notifyReq);
    if (result) {
      this.setState((prevState: IMyState) => {
        // tslint:disable-next-line:no-any
        let state: any = {};
        if (prevState.notifyData && prevState.notifyData.length && isMerge) {
          state = { ...state, notifyData: [...prevState.notifyData, ...result.records] };
        } else {
          state = { ...state, notifyData: result.records };
        }
        state = { ...state, pageData: { current: result.current, total: result.total, pageSize: result.size } };
        return state;
      });
    }
  }

  // 随访 修改就诊状态
  public async updateItemTreateStatus(item: INotiItem, visitResult: number) {
    const { result } = await WxDoctorApi.updateNotiTreateStatus({
      id: item.msgId,
      visitResult: item.visitResult === visitResult ? 0 : visitResult,
    });
    if (result) {
      this.getNotifyList();
    }
  }
  public notifyTypeClick(type: number) {
    this.setState(
      { curType: type, pageData: { current: 1, pageSize: 10, total: 0 }, notifyData: [] },
      this.getNotifyList
    );
  }

  public render() {
    const { history, match } = this.props;
    const { patientMsg, curType, pageData, startDate, endDate, notifyData } = this.state;
    return (
      <div className="PatientMsg" id={'PatientNotifyMsg'}>
        <MobileHeader backUrl={'/Doctor/MyPatient'} />
        {//  tslint:disable-next-line:jsx-no-multiline-js
        patientMsg ? (
          <div className="patientMsgCard">
            <img src={patientMsg.headimgurl} className="portrait" />
            <div className="pmsg">
              <div className="msgRow">
                <div className="nameRow">
                  <span className="name"> {patientMsg.name} </span>
                  <span className="sex"> {patientMsg.gender ? '男' : '女'} </span> <span>{patientMsg.age} 岁 </span>
                </div>
                <Link to={`/Doctor/EditPatient/${match.params.id}`}>
                  <div className="editAction">编辑 </div>
                </Link>
              </div>
              <div className="codeContainer">
                {// tslint:disable-next-line:jsx-no-multiline-js
                patientMsg.sampleSequence ? (
                  <div className="codeRow">
                    就诊编号: <span className="number"> {patientMsg.sampleSequence}</span>
                  </div>
                ) : (
                  <div className="codeRow">未填写就诊编号</div>
                )}
              </div>
              <div className="discription" hidden={true}>
                此处是备注信息,有则显示,没有不显示.默认是没有的哦
              </div>
              <div className="taskMarkIcons">
                {//  tslint:disable-next-line:jsx-no-multiline-js
                !!patientMsg &&
                  !!patientMsg.groupNames &&
                  !!patientMsg.groupNames.length &&
                  patientMsg.groupNames.map((value, index) => (
                    <div key={index} className="groupTag">
                      {value}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <Spin />
        )}

        <div className="notiTypeContainer">
          <div style={{ display: 'flex', flex: 3 }}>
            <div onClick={() => this.notifyTypeClick(1)} className={curType === 1 ? 'notiTypeSel' : 'notiType'}>
              通知
            </div>
            <div onClick={() => this.notifyTypeClick(2)} className={curType === 2 ? 'notiTypeSel' : 'notiType'}>
              复诊/随访
            </div>
            <div
              style={{ margin: '0' }}
              onClick={() => this.notifyTypeClick(3)}
              className={curType === 3 ? 'notiTypeSel' : 'notiType'}
            >
              问卷
            </div>
          </div>
          <div className={'total'}> {pageData.total}个通知 </div>
        </div>
        <div>
          <div className="timerPickerContainer">
            <div>
              <DatePicker
                mode="date"
                className="timePicker450pxCenter"
                value={startDate}
                onChange={date => this.setState({ startDate: date }, this.getNotifyList)}
              >
                <div className="timerShow">
                  {startDate ? moment(startDate).format('YYYY-MM-DD') : '开始时间'}
                  <i className={'triangle'} />
                </div>
              </DatePicker>
            </div>
            <div className="spaceLiner">~</div>
            <div>
              <DatePicker
                mode="date"
                value={endDate}
                className="timePicker450pxCenter"
                onChange={date => this.setState({ endDate: date }, this.getNotifyList)}
              >
                <div className="timerShow">
                  {endDate ? moment(endDate).format('YYYY-MM-DD') : '结束时间'}
                  <i className={'triangle'} />
                </div>
              </DatePicker>
            </div>
          </div>
        </div>
        <MobileAddBlockContent backUrl={`/Doctor/AddNotifyType/${match.params.id}`}>添加通知</MobileAddBlockContent>
        {//  tslint:disable-next-line:jsx-no-multiline-js
        notifyData && notifyData.length ? (
          <div className={'notifyList'}>
            {//  tslint:disable-next-line:jsx-no-multiline-js
            curType === 2
              ? notifyData.map((item, index) => {
                  // const actionTitle = ['患者未操作', '患者已接受', '患者拒绝'];
                  // const cssTitle = ['noAction', 'accept', 'reject'];
                  // const notiActionStatus = item.status === 3 ? item.result : 0; // 消息-发送结果 0 未推送 1 未读 2 已读 3 已处理 当处理后就应当 判断处理状态
                  const notiStatusTitle = ['未推送', '患者未读', '', ''];
                  return (
                    <div key={index} className="notiItemContainer">
                      <div className="msgContent" onClick={() => history.push(`/Doctor/NotiDetail/${item.id}`)}>
                        <div className="titleRow">
                          <div className="titleMsg"> {item.title} </div>
                          <div className="time"> {moment(item.sendTime).format('YYYY-MM-DD')}</div>
                        </div>
                        <div className="typesRow">
                          <div className="typeLabel">
                            <MobileTag text={item.sysMsgType === 1 ? '复诊通知' : '随访通知'} />
                            {item.status !== 3 && <MobileTag text={'患者未操作'} />}
                            {item.status === 3 && item.result === 1 && <MobileTag text={'患者已接受'} />}
                            {item.status === 3 && item.result === 2 && <MobileTag text={'患者已拒绝'} />}

                            {/*<div className={cssTitle[notiActionStatus]}> </div>*/}
                          </div>
                        </div>
                        <div className="visitReQtiemRow">
                          <div>
                            {item.sysMsgType === 1 && '复诊'}
                            {item.sysMsgType === 2 && '随访'}时间: {item.treatmentTime}
                          </div>
                          <div className="iconRow">
                            <span className="notReaded">{notiStatusTitle[item.status]}</span>
                            <img src={require('../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
                          </div>
                        </div>
                      </div>
                      <div hidden={item.status === 0}>
                        <Checkbox.Group value={[item.visitResult]} className="radioGroup">
                          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <Checkbox
                              value={1}
                              onChange={
                                //  tslint:disable-next-line:jsx-no-multiline-js
                                e => {
                                  this.updateItemTreateStatus(item, e.target.value);
                                }
                              }
                            >
                              已就诊
                            </Checkbox>
                          </div>
                          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <Checkbox
                              value={2} // tslint:disable-next-line:jsx-no-multiline-js no-shadowed-variable
                              onChange={e => {
                                this.updateItemTreateStatus(item, e.target.value);
                              }}
                            >
                              未就诊
                            </Checkbox>
                          </div>
                        </Checkbox.Group>
                      </div>
                    </div>
                  );
                })
              : notifyData.map((e, index) => {
                  // const notiType = ['复诊通知', '随访通知', ' 随访问卷'];
                  // const notiTypeCss = ['notiReVisiting', 'notiVisit', 'questionnaire'];
                  // const actionTitle = ['患者未操作', '患者已接受', '患者拒绝'];
                  // const cssTitle = ['noAction', 'accept', 'reject'];
                  // const notiActionStatus = e.status === 3 ? e.result : 0; // 消息-发送结果 0 未推送 1 未读 2 已读 3 已处理 当处理后就应当 判断处理状态
                  const notiStatusTitle = ['未推送', '患者未读', '', ''];
                  return (
                    <div
                      key={index}
                      className="notiItemContainer"
                      onClick={() => history.push(`/Doctor/NotiDetail/${e.id}`)}
                    >
                      <div className="titleRow">
                        <div className="titleMsg">{e.title}</div>
                        <div className="time"> {moment(e.sendTime).format('YYYY-MM-DD')}</div>
                      </div>
                      <div className="typesRow">
                        <div className="typeLabel">
                          {e.sysMsgType === 1 && <MobileTag text={'复诊通知'} />}
                          {e.sysMsgType === 2 && <MobileTag text={'随访通知'} />}
                          {e.sysMsgType === 3 && <MobileTag text={'随访问卷'} />}
                          {e.status !== 3 && e.answerResult !== 1 && <MobileTag text={'患者未操作'} />}
                          {e.status === 3 && e.result === 1 && <MobileTag text={'患者已接受'} />}
                          {e.status === 3 && e.result === 2 && <MobileTag text={'患者已拒绝'} />}
                          {e.sysMsgType === 3 && e.answerResult === 1 && <MobileTag text={'患者已填写'} />}
                        </div>
                        <div className="iconRow">
                          <span className="notReaded">{notiStatusTitle[e.status]}</span>
                          <img src={require('../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        ) : (
          <MobileNoDatas imgurl={'img_notice_null.svg'} show={true} />
        )}
      </div>
    );
  }
}

export default PatientMsgClass;
