import { RouteComponentProps, withRouter } from 'dva/router';
import React from 'react';
import './MobileAddBlockContent.scss';
// 应设计要求，专门为
interface IMobileAddBlockContentProps extends RouteComponentProps {
  backUrl?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

function MobileAddBlockContent(props: IMobileAddBlockContentProps) {
  // const { text } = props;
  function back() {
    if (props.disabled) {
      return;
    }
    if (props.backUrl) {
      props.history.push(props.backUrl);
    } else if (props.onClick) {
      props.onClick();
    }
  }
  return (
    <div id={'mobileAddBlockContent'} onClick={back}>
      <img src={require('../../assets/moblieImg/btn_add.svg')} className="img" />
      {props.children}
    </div>
  );
}

export default withRouter(MobileAddBlockContent);
