import { useDispatch, useSelector } from 'dva';
import { Link, RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { WxDoctorApi } from '../../../api';
import { IDoctorMsg } from '../../../api/WxDoctorApi';
import { DoctorMsQrModal } from '../../../components/QRModalCmp/QRModalCmp';
import { IGlobal } from './../../../Model/GlobalModel';
import { Storage, UpdateTitle } from './../../../utils';
import { IItemInfo } from './CodeType';
import './DoctorInfo.scss';
const itemData: IItemInfo[] = [
  { icon: 'pic_patient@2x.png', title: '我的患者', unit: '人', url: '/Doctor/MyPatient' },
  { icon: 'pic_group@2x.png', title: '我的分组', unit: '组', url: '/Doctor/DoctorGroup' },
];

// const qrCodeUrl = 'https://medata-storage-test.oss-cn-shanghai.aliyuncs.com/hulk/wx_qrcode/1205328254047620928.jpg';
interface IExpertInfoProps extends RouteComponentProps {
  id?: string;
}

export function ExpertInfo(props: IExpertInfoProps) {
  const [docMsg, setDocMsg] = useState({} as IDoctorMsg);
  const [mobileQRVisible, setMobileQRVisible] = useState(false);
  const globalState = useSelector(({ global }: { global: IGlobal }) => global);
  const dispatch = useDispatch();
  useEffect(() => {
    UpdateTitle('专家首页');
    let n = 1;
    const timer = setInterval(() => {
      const storage = new Storage();
      const global = JSON.parse(storage.getStore('global') || '{}');
      n++;
      if (n === 2000) {
        clearInterval(timer);
      }
      if (global.token || globalState.token) {
        clearInterval(timer);
        getDoctorMsg();
      }
    }, 200);
  }, []);
  /**
   * 获取医生信息
   */
  async function getDoctorMsg() {
    const { result } = await WxDoctorApi.getUserMsg();
    if (result) {
      setDocMsg(result);
    }
  }
  const items = itemData.map((item, index) => {
    const nums = [docMsg.patientCount, docMsg.groupCount];
    return (
      <Link to={item.url} key={item.icon}>
        <div className="gridItemCard" key={item.icon}>
          <img src={require(`../../../assets/moblieImg/${item.icon}`)} style={{ width: '100%' }} alt="" />
          <div className="msgContainerFont">
            <div className="itemTitle">{item.title}</div>
            <div>{`${nums[index] || 0}${item.unit}`}</div>
          </div>
        </div>
      </Link>
    );
  });
  /**
   * 点击跳转病例总览
   */
  const linkDiseasePreview = () => {
    if (globalState.companyInfo && globalState.companyInfo.code) {
      props.history.push('/DiseasePreview');
    } else {
      dispatch({
        type: 'global/wxInfoSave',
        payload: {
          companyInfo: globalState.hulkUserInfoBackLoginVo.organization,
          sysConfig: globalState.hulkUserInfoBackLoginVo.configInfo,
          token: globalState.hulkUserInfoBackLoginVo.token,
        },
        callback: () => {
          props.history.push('/DiseasePreview');
        },
      });
    }
  };

  return (
    <div className="expertInfo">
      {/* <MobileHeader history={props.history} title="医生首页" /> */}
      <img src={require('../../../assets/moblieImg/img_personal_bg.svg')} className={'backColor'} />
      <div className="docCardContainer">
        <img src={require('../../../assets/moblieImg/img_personal.svg')} style={{ width: '100%', height: '100%' }} />
        <div className="expertCardMsg">
          <div className="">
            <img
              src={require(`../../../assets/moblieImg/${docMsg.gender ? 'img_male' : 'img_female'}.svg`)}
              className="expertImg"
            />
          </div>
          <div className="expertMsg">
            <div className="nameContainer">
              <div className="expertName"> {docMsg && docMsg.expertName}</div>
              <div className="expertPosition">{docMsg && docMsg.organizationName} </div>
            </div>
            <div style={{ fontWeight: 500 }}>
              {docMsg && docMsg.title ? `${docMsg.department}｜${docMsg.title}` : docMsg.department}
              <img
                src={require('../../../assets/img/icon_qr_code_selected.svg')}
                className="qrImg"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  setMobileQRVisible(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="gridContainer"> {items} </div>

      <div className="wx_nav_box_b" onClick={linkDiseasePreview}>
        <img className="my_nav_bottom" src={require('../../../assets/img/pic_patient_overview@2x.png')} alt="" />
        <div className="name_nav_box">
          <div className="name_nav_box1">病例总览</div>
          <div className="name_nav_box2">可查看所有患者的访视情况</div>
        </div>
      </div>
      <DoctorMsQrModal
        url={docMsg.qrCodeUrl}
        visible={mobileQRVisible}
        callback={() => setMobileQRVisible(false)}
        discription=""
      />
    </div>
  );
}
