import { useDispatch } from 'dva';
import React from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormInput.scss';
const isPc = window.screen.width > 500;
/**
 * 输入封装
 * 在subjectData里面type为FormInput
 * @param props
 */
function FormInput(props: ISurveyHook) {
  const dispatch = useDispatch();
  function formValueChange(str: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: str.target.value,
      },
    });
  }
  return (
    <div id="FormInput">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={props.formObj.subjectData.errMsg ? 'cl star' : 'star'}
              // tslint:disable-next-line:jsx-no-multiline-js
              id={
                props.formObj.subjectData.parentPosition &&
                `errLogo_${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
              }
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{props.formObj.subjectData.subjectName}</div>
        </div>
        <AuthCompount isShow={!!props.formObj.subjectData.explain}>
          <div className="explain">（说明：{props.formObj.subjectData.explain}）</div>
        </AuthCompount>
        <input
          className={`${props.formObj.subjectData.size} form_input`}
          defaultValue={props.formObj.subjectData.defaultValue}
          onChange={formValueChange}
          id={props.formObj.subjectData.field}
          placeholder={props.formObj.subjectData.placeholder}
        />
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition
                ? `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
                : `pc_errMsg_${props.formObj.subjectData.field}`
            }
          >
            {props.formObj.subjectData.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          // tslint:disable-next-line:jsx-no-multiline-js
          id={
            props.formObj.subjectData.parentPosition
              ? `${props.formObj.subjectData.field}_${props.formObj.subjectData.groupIndex}`
              : `m_errMsg_${props.formObj.subjectData.field}`
          }
        >
          {props.formObj.subjectData.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormInput);
