import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'dva';
import React from 'react';
import { IGauge } from './../../assets/js/DataJson';
import { AuthCompount } from './../../components';
import { IGlobal } from './../../Model/GlobalModel';
import { ISurvey } from './../../Model/SurveyModel';
import './ModuleTitle.scss';
interface IProps {
  data: IGauge;
  children?: React.ReactNode;
  pId: string;
  positions: number[];
}
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 小模块标题组件
 */
const isPc = window.screen.width > 500;

function ModuleTitle(props: IProps) {
  const answer = useSelector(({ survey }: { survey: ISurvey }) => survey.answer);
  const visitNumber = useSelector(({ survey }: { survey: ISurvey }) => survey.visitNumber);
  const initVisitNumber = useSelector(({ global }: { global: IGlobal }) => global.sysConfig.initVisitNumber);
  const dispatch = useDispatch();
  // 显示上次内容
  const checkboxChange = async (e: CheckboxChangeEvent) => {
    dispatch({
      type: 'survey/lastSaveSurvey',
      payload: { props, checked: e.target.checked },
    });
  };
  const explain = props.data.explain ? <div dangerouslySetInnerHTML={{ __html: props.data.explain }} /> : <div />;
  return (
    <div id={props.pId}>
      <div id="ModuleTitle">
        <div className="sm_tit sp">
          <div className="sm_tit">
            <div className="bor" />
            <div className="tit_bor">
              <div>{props.data.moduleName}</div>
              <AuthCompount
                // tslint:disable-next-line:jsx-no-multiline-js
                isShow={
                  Number(visitNumber) !== 1 &&
                  Number(visitNumber) !== Number(initVisitNumber) &&
                  !!props.data.isReadLastSurvey &&
                  isPc
                }
              >
                <Checkbox
                  checked={answer.beforeModuleNames && answer.beforeModuleNames.includes(props.data.moduleName)}
                  // tslint:disable-next-line:jsx-no-multiline-js
                  defaultChecked={answer.beforeModuleNames && answer.beforeModuleNames.includes(props.data.moduleName)}
                  onChange={checkboxChange}
                >
                  读取上次访视内容
                </Checkbox>
              </AuthCompount>
            </div>
          </div>
          <AuthCompount isShow={false}>
            <div className="mus">* 代表必填项</div>
          </AuthCompount>
          <AuthCompount
            // tslint:disable-next-line:jsx-no-multiline-js
            isShow={
              Number(visitNumber) !== 1 &&
              !isPc &&
              Number(visitNumber) !== Number(initVisitNumber) &&
              !!props.data.isReadLastSurvey
            }
          >
            <Checkbox
              checked={answer.beforeModuleNames && answer.beforeModuleNames.includes(props.data.moduleName)}
              // tslint:disable-next-line:jsx-no-multiline-js
              defaultChecked={answer.beforeModuleNames && answer.beforeModuleNames.includes(props.data.moduleName)}
              onChange={checkboxChange}
              className="check_margin"
            >
              读取上次访视内容
            </Checkbox>
          </AuthCompount>
        </div>

        <AuthCompount isShow={!!props.data.explain}>
          <div className="exp">
            <span /> {explain}
          </div>
        </AuthCompount>
        {props.children}
      </div>
    </div>
  );
}
export default ModuleTitle;
