import { useDispatch, useSelector } from 'dva';
import { useEffect } from 'react';
import Config from '../config/Config';
import { SignInApi } from './../api';
import { IGlobal } from './../Model/GlobalModel';
// import { WeChatIf } from './../utils';

/**
 * 获取微信登录后的相关信息的layout
 */
// tslint:disable-next-line:no-any
function WxConnect({ children }: any) {
  const state = {
    role: window.location.href.includes('Doctor'), // true 为医生 false 为患者
  };
  const globalState = useSelector(({ global }: { global: IGlobal }) => global);
  const dispatch = useDispatch();

  useEffect(() => {
    const isWechat = window.location.href.includes('Doctor/') || window.location.href.includes('Patient/');
    if (isWechat) {
      const key = `${state.role ? 'hulkUserInfoBackLoginVo' : 'hulkUserInfoFrontLoginVo'}` as
        | 'hulkUserInfoBackLoginVo'
        | 'hulkUserInfoFrontLoginVo';
      const token = globalState[key].token;
      if (window.location.href.includes('code=')) {
        const code = window.location.href.split('code=')[1].split('&state')[0];
        getCodeUserInfo(code);
        return;
      }

      if (state.role) {
        if (
          (!token || !globalState.hulkUserInfoBackLoginVo.qrCodeUrl) &&
          !window.location.href.includes('/CetificationMsg')
        ) {
          getWxCode();
        }
      }

      if (!state.role) {
        if (
          (!token || !globalState.hulkUserInfoFrontLoginVo.birthday) &&
          !window.location.href.includes('/PatientInfo') &&
          !window.location.href.includes('/PatientPhone')
        ) {
          getWxCode();
        }
      }
    }
  }, []);
  /**
   * 通过code获取用户信息
   */
  const getCodeUserInfo = async (code: string) => {
    // return;
    const { result } = await SignInApi.getCodeUserInfo({
      code,
      systemCode: `${state.role ? 'HULK_BACK' : 'HULK_FRONT'}`,
    });
    dispatchUserinfo(result);
  };

  /**
   * 获取微信code
   */
  const getWxCode = async () => {
    const { result } = await SignInApi.getWxCode(
      `${Config.domainUrl.REACT_APP_DOMAIN}/#/${state.role ? 'Doctor/Certificate' : 'Patient/PatientNew'}`
    );
    window.location.replace(result);
  };

  // tslint:disable-next-line: no-any
  const dispatchUserinfo = (result: any) => {
    let token = '';
    if (state.role) {
      token = result.hulkUserInfoBackLoginVo.token
        ? result.hulkUserInfoBackLoginVo.token
        : result.hulkUserInfoFrontLoginVo.token;
    } else {
      token = result.hulkUserInfoFrontLoginVo.token;
    }
    const userInfo = {
      token,
      wechatUserInfoVo: result.wechatUserInfoVo,
      hulkUserInfoBackLoginVo: result.hulkUserInfoBackLoginVo, // 医生
      hulkUserInfoFrontLoginVo: result.hulkUserInfoFrontLoginVo, // 患者
    };
    dispatch({
      type: 'global/wxInfoSave',
      payload: userInfo,
      callback: () => {
        if (state.role && result.hulkUserInfoBackLoginVo.qrCodeUrl) {
          window.location.replace(`${Config.domainUrl}/#/Doctor/DoctorInfo`);
        }
        if (!state.role && result.hulkUserInfoFrontLoginVo.birthday) {
          window.location.replace(`${Config.domainUrl}/#/Patient/PatientHomePage`);
        }
      },
    });
  };
  return children;
}
export default WxConnect;
