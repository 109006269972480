import { Button, Modal } from 'antd';
import { RouteComponentProps } from 'dva/router';
import React from 'react';
import SurveyModelUtil from '../../../utils/Survey/SurveyModelUtil';
import './LandingPage.scss';
interface IProps extends RouteComponentProps {
  code: string;
}
interface IState {
  companyName: string;
  companyImg: string;
  companyCode: string;
  modalShow: boolean;
  companyOriginCode: string;
}
class LandingPage extends React.Component<IProps> {
  public state = {
    companyName: '',
    companyImg: '',
    companyCode: '',
    modalShow: false,
    companyOriginCode: '',
  } as IState;
  public async componentWillMount() {
    // 初始化准备各种数据
    const ids = window.location.href;
    const parparm = ids.split('?')[1];
    const code = parparm.replace('code=', '');
    const surveyModelUtil = new SurveyModelUtil();
    const { result } = await surveyModelUtil.getQRCodeMsg(code);
    this.setState({
      companyCode: code,
      companyName: result.organizationVo.name,
      companyImg: result.organizationVo.logoUrl,
      companyOriginCode: result.organizationVo.code,
    });
  }
  public render() {
    // console.log(this.state, 'render');
    const qielength = 8;
    let companyName = this.state.companyName;
    const nameArray = this.state.companyName.split('');
    // tslint:disable
    if (this.state.companyName.length > qielength) {
      let newArray: any = [];
      const half = Math.floor(nameArray.length / 2);
      newArray[0] = nameArray[0];
      for (let i = 1; i < nameArray.length; i++) {
        if (i >= 2 * half) {
          newArray.push(nameArray[i]);
        } else {
          if (i < half) {
            newArray[i * 2] = nameArray[i];
          } else {
            newArray[(i % half) * 2 + 1] = nameArray[i];
          }
        }
      }
      companyName = newArray.toString().replace(/,/g, '');
    }
    // console.log(companyName);
    const hosClassName = this.state.companyName.length > qielength ? 'double' : 'single';
    const modalBtn = [
      // tslint:disable-next-line:jsx-wrap-multiline
      <Button key="back" onClick={e => this.closeModal()}>
        不同意
      </Button>,
      // tslint:disable-next-line:jsx-wrap-multiline
      <Button key="submit" type="primary" onClick={e => this.linkVirus()}>
        同意
      </Button>,
    ];
    return (
      <div id="LandingPage">
        <img className="bg" src={require(`../../../assets/img/landingpage/code.jpg`)} />
        <div className="left-pos line-width" />
        <div className="left-pos line-height" />
        <p className="small-word left-word-pos">
          众志成城
          <br />
          ，抗击疫情
        </p>
        <img className="title" src={require(`../../../assets/img/landingpage/title.png`)} />
        <div className="logo">
          <img src={this.state.companyImg} />
          <p className={hosClassName}>{companyName}</p>
        </div>
        <p className="more-word">
          您好！为做好新型冠状病毒感染肺炎防控工作，切实保障患者、家属及医院员工的身体健康和生命安全，根据《中华人民共和国传染病防治法》、《上海市突发公共卫生事件应急预案》等法律法规要求，结合目前防控形势及我院实际请您配合我们填写。
        </p>

        <div className="right-pos line-width" />
        <div className="right-pos line-height" />
        <p className="small-word right-word-pos">
          武汉加油
          <br />
          ，中国加油
        </p>
        <p className="other-word">
          应对疫情守则，48字需牢记：
          <br />
          须警惕，不轻视&nbsp;&nbsp;&nbsp;少出门，少集聚 <br /> 勤洗手，勤通风&nbsp;&nbsp;&nbsp;戴口罩，讲卫生 <br />
          打喷嚏，捂口鼻&nbsp;&nbsp;&nbsp;喷嚏后，慎揉眼 <br /> 有症状，早就医&nbsp;&nbsp;&nbsp;不恐慌，不传谣
        </p>
        <div className="button" onClick={e => this.submitFrom()}>
          <img src={require(`../../../assets/img/landingpage/btn.png`)} />
        </div>
        <Modal closable={false} visible={this.state.modalShow} title="隐私条款" footer={modalBtn}>
          <div className="p_t">
            本信息填报系统依据新冠状病毒指南第五版，国家卫生健康委办公厅关于印发新型冠状病毒肺炎防控方案（第四版）等指导文件汇总编制，在填报中所涉及个人身份证号码、住址、行程等隐私信息，均在本院存档备案作为疾病管控资料，以备有关部门调用。该数据不移作其它任何用途，特此告知。
          </div>
        </Modal>
      </div>
    );
  }
  private closeModal = () => {
    this.setState({
      modalShow: false,
    });
  };
  private linkVirus = () => {
    const ids = window.location.href;
    const parparm = ids.split('?')[1];
    const code = parparm.replace('code=', '');
    if (code) {
      this.props.history.push(`/GaugeSurvey?code=${code}&roleAndUrl=undefined&writeType=surveyQrcodeCreate`);
    } else {
      // alert('非法进入');
      return;
    }
  };
  private submitFrom() {
    // console.log(this.state);
    if (this.state.companyOriginCode === 'HJ90520200001') {
      this.setState({
        modalShow: true,
      });
      return;
    }
    this.linkVirus();
  }
}
export default LandingPage;
