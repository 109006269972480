import React from 'react';
import './MobileTag.scss';
// 因设计要求，专门为 标签设计一个组件，只需要传对应的文字即可，
interface IMobileTagProps {
  text?:
    | '随访问卷'
    | '复诊通知'
    | '随访通知'
    | '我已接受'
    | '我已拒绝'
    | '患者已接受'
    | '患者已拒绝'
    | '我已填写'
    | '我未操作'
    | '患者已填写'
    | '患者未操作'
    | '患者未填写';
}

function MobileTag(props: IMobileTagProps) {
  const { text } = props;

  return (
    <span id={'mobileTag'}>
      {text === '随访问卷' && <span className={'blue'}>{text}</span>}
      {text === '复诊通知' && <span className={'yellow'}>{text}</span>}
      {text === '随访通知' && <span className={'green'}>{text}</span>}
      {//  tslint:disable-next-line:jsx-no-multiline-js
      (text === '我已接受' || text === '患者已接受' || text === '我已填写' || text === '患者已填写') && (
        <span className={'green border'}>{text}</span>
      )}
      {(text === '我已拒绝' || text === '患者已拒绝') && <span className={'red border'}>{text}</span>}
      {//  tslint:disable-next-line:jsx-no-multiline-js
      (text === '我未操作' || text === '患者未填写' || text === '患者未操作') && (
        <span className={'yellow border'}>{text}</span>
      )}
    </span>
  );
}

export default MobileTag;
