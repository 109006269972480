import { Tag } from 'antd';
import { connect, SubscriptionAPI } from 'dva';
import React from 'react';
import SurveyModelUtil from '../../../utils/Survey/SurveyModelUtil';
import GaugeSurveyApi from './../../../api/GaugeSurvey';

import { AuthCompount } from '../../../components';
import { IGlobal } from './../../../Model/GlobalModel';
import { ISurvey } from './../../../Model/SurveyModel';
import './PdfResult.scss';

interface IProps extends SubscriptionAPI {
  survey: ISurvey;
  global: IGlobal;
  // tslint:disable-next-line:no-any
  dispatch: any;
}
interface IAttentionItem {
  moduleName: string;
  subDataList: string[];
  expansion: boolean;
}
interface IState {
  data: {
    user: string;
  };
  clickIf: boolean;
  patient_code: string;
  patient_name: string;
  created: string;
  isSpinning: boolean;
  isQrcode: boolean;
  surveyQrcodes: string[];
  isShowAttention: boolean;
  attentions?: IAttentionItem[];
}
// const isPc = window.screen.width > 500;

class GaugeSurvey extends React.Component<IProps> {
  public state = {
    data: {},
    clickIf: false,
    patient_code: '',
    patient_name: '',
    created: '',
    isSpinning: false,
    isQrcode: false,
    surveyQrcodes: ['surveyQrcodeCreate', 'surveyQrcodeUpdate'],
    isShowAttention: false,
  } as IState;
  // 提前准备数据渲染
  public async componentWillMount() {
    // 初始化准备各种数据
    const ids = this.props.history.location.search.split('?');
    const urlPrams = await this.processUrl(ids[1]);
    this.gaugeInit(urlPrams);
    if (this.props.global.sysConfig.noteMessageJson) {
      this.getAttention(urlPrams);
    }
  }

  public componentDidUpdate(p: IProps) {
    // console.log(this.state, this.props);
  }
  // tslint:disable
  public donAaaay(dataV: any) {
    dataV.sort((a: any, b: any) => {
      const top = a.top;
      const bottom = b.top;
      return Number(top) - Number(bottom);
    });
  }
  public render() {
    const survey = this.props.survey;
    if (!survey.gaugeData[survey.menuIndex]) {
      return <div />;
    }
    const companyInfo = this.props.global.companyInfo;
    const gaugeData = survey.gaugeData[0];
    let peopleInfo: any = [];
    gaugeData.moduleChildrens[0].subjects.map(item => {
      if (
        item.subjectData.field !== 'Q01824' &&
        item.subjectData.field !== 'Q01820' &&
        item.subjectData.field !== 'Q01832' &&
        item.subjectData.field !== 'Q01843' &&
        item.subjectData.field !== 'Q01844'
      ) {
        peopleInfo.push(
          item.subjectData.subjectName.split('、')[1] +
            '：' +
            (item.subjectData.defaultValue ? item.subjectData.defaultValue : '')
        );
      }
      if (item.subjectData.field === 'Q01824') {
        item.subjectData.defaultValue.map((dData: any) => {
          if (dData.Q01825) {
            peopleInfo.push('陪同人姓名:' + dData.Q01825);
            peopleInfo.push('陪同人手机:' + dData.Q01826);
          }
        });
      }
    });
    // console.log(survey.answer.Q01832)
    survey.answer.Q01832 = survey.answer.Q01832 ? survey.answer.Q01832 : [];
    peopleInfo.push('返沪原因：' + (survey.answer.Q01832.length !== 0 ? survey.answer.Q01832.toString() : '无'));
    peopleInfo.push('既往基础病：' + (survey.answer.Q01843 ? survey.answer.Q01843 : '无'));
    peopleInfo.push('1年内是否接种过流感疫苗：' + (survey.answer.Q01844 ? survey.answer.Q01844 : '无'));
    survey.answer.Q01816 = survey.answer.Q01816 ? survey.answer.Q01816 : [];
    const zz = survey.answer.Q01816.map((item: any) => {
      if (
        item === '结膜炎' ||
        item === '心慌、胸闷' ||
        item === '持续头痛' ||
        item === '身体有其他特别不适，但不是以上这几种' ||
        item.lastIndexOf('气促，总觉得喘不过气') !== -1
      ) {
        return (item = { value: item, top: 1 });
      } else {
        return (item = { value: item, top: 2 });
      }
    });
    survey.answer.Q01818 = survey.answer.Q01818 ? survey.answer.Q01818 : [];
    const trafStr =
      survey.answer.Q01818.length === 0
        ? '您前两周未乘坐过高危交通工具'
        : '您前两周乘坐过<span class="redp">' + survey.answer.Q01818 + '</span>次高危交通工具';
    survey.answer.Q01830 = survey.answer.Q01830 ? survey.answer.Q01830 : [];
    const jt = survey.answer.Q01830.map((item: any, index: any) => {
      return {
        value: item + (survey.countData.Q01830[index] ? '(有确诊或疑似病例同程)' : ''),
        top: survey.countData.Q01830[index] ? 1 : 2,
      };
    });

    survey.answer.Q01840 = survey.answer.Q01840 ? survey.answer.Q01840 : [];
    const areaStr = survey.answer.Q01840.length === 0 ? '您未居住在有患者确诊或者疑似的小区中' : '您居住在';
    survey.answer.Q01840 = survey.answer.Q01840 ? survey.answer.Q01840 : [];
    const ja = survey.answer.Q01840.map((item: any, index: any) => {
      return {
        value: item + (survey.countData.Q01840[index] ? '(此小区有疑似或者确诊患者)' : ''),
        top: survey.countData.Q01840[index] ? 1 : 2,
      };
    });

    let jc: any = [];
    if (gaugeData.moduleChildrens[1].subjects[5]) {
      survey.countData.Q01819 = survey.countData.Q01819 ? survey.countData.Q01819 : [];
      const CQ01819 = survey.countData.Q01819.toString();
      const CQ01819Count = gaugeData.moduleChildrens[1].subjects[5].subjectData.countNumData;
      for (let i in CQ01819Count) {
        const cd = CQ01819Count[i];
        jc.push({
          value: cd + (CQ01819.lastIndexOf(cd) !== -1 ? '(有)' : '(无)'),
          top: CQ01819.lastIndexOf(cd) !== -1 ? 1 : 2,
        });
      }
    }
    survey.countData.Q01829 = survey.countData.Q01829 ? survey.countData.Q01829 : [];
    const yw = survey.countData.Q01829.map((item: any) => {
      return { value: item.split('-')[0], top: Number(item.split('-')[1]) };
    });
    survey.countData.Q01817 = survey.countData.Q01817 ? survey.countData.Q01817 : [];
    const cs = survey.countData.Q01817.map((item: any) => {
      return { value: item.split('-')[0], top: Number(item.split('-')[1]) };
    });
    const mark = survey.answer.Q01845;
    this.donAaaay(zz);
    this.donAaaay(jt);
    this.donAaaay(ja);
    this.donAaaay(jc);
    this.donAaaay(yw);
    this.donAaaay(cs);
    return (
      <div id="PdfResult">
        <div id="LayoutComponent" className="layoutContainer">
          <div className="layoutContent">
            <div className="bg">
              <img src={require(`../../../assets/img/pdf_bg_frontpage.png`)} />
            </div>
            <div className="head">
              <img src={companyInfo.logoUrl} />
              <p>{companyInfo.systemName}</p>
              <p>{companyInfo.name}</p>
            </div>
            <div className="content">
              <p className="titleName">
                <img className="" src={require(`../../../assets/img/pinfo.svg`)} />
                个人信息
              </p>
              <div className="con">
                {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                peopleInfo.map((sub: any) => (
                  <div
                    className="pInfo"
                    key={sub + Math.random().toString()}
                    style={sub.lastIndexOf('居住地址：') !== -1 ? { width: '100%' } : { width: '48%' }}
                  >
                    {sub}
                  </div>
                ))}
                <div className="clearF" />
              </div>
            </div>
            <div className="content">
              <p className="titleName">
                <img className="" src={require(`../../../assets/img/report.svg`)} />
                自我评估
              </p>
              <div className="con">
                <div className="childCon">
                  <p className="cTitle">你的症状</p>
                  {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                  zz.map((sub: any) => (
                    <p className={sub.top === 1 ? 'redp pcon' : 'op pcon'} key={sub.value + Math.random().toString()}>
                      {sub.value}
                    </p>
                  ))}
                  <AuthCompount isShow={zz.length === 0}>
                    <p color="bp">无</p>
                  </AuthCompount>
                </div>
                <div className="childCon">
                  <p className="cTitle">前两周您居住、出差或旅行的省份</p>
                  <div className="tag_box">
                    {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                    cs.map((sub: any, i: any) => (
                      <div key={i}>
                        <AuthCompount isShow={sub.top === 1}>
                          <Tag className="tag_itam" color="red">
                            {sub.value}
                          </Tag>
                        </AuthCompount>
                        <AuthCompount isShow={sub.top === 2}>
                          <Tag className="tag_itam" color="orange">
                            {sub.value}
                          </Tag>
                        </AuthCompount>
                        <AuthCompount isShow={sub.top === 3}>
                          <Tag className="tag_itam" color="blue">
                            {sub.value}
                          </Tag>
                        </AuthCompount>
                      </div>
                    ))}
                    <AuthCompount isShow={cs.length === 0}>
                      <p color="bp">无</p>
                    </AuthCompount>
                  </div>
                </div>
                <div className="childCon">
                  <p className="cTitle" dangerouslySetInnerHTML={{ __html: areaStr }} />
                  {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                  ja.map((sub: any) => (
                    <p className={sub.top === 1 ? 'redp pcon' : 'bp pcon'} key={sub.value + Math.random().toString()}>
                      {sub.value}
                    </p>
                  ))}
                </div>

                <div className="childCon">
                  <p className="cTitle" dangerouslySetInnerHTML={{ __html: trafStr }} />
                  {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                  jt.map((sub: any) => (
                    <p className={sub.top === 1 ? 'redp pcon' : 'bp pcon'} key={sub.value + Math.random().toString()}>
                      {sub.value}
                    </p>
                  ))}
                </div>
                <div className="childCon">
                  <p className="cTitle">你两周内曾接触过</p>
                  {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                  jc.map((sub: any) => (
                    <p className={sub.top === 1 ? 'redp pcon' : 'bp pcon'} key={sub.value + Math.random().toString()}>
                      {sub.value}
                    </p>
                  ))}
                </div>
                <div className="childCon">
                  <p className="cTitle">最近两周使用药物情况</p>
                  {// tslint:disable-next-line:jsx-no-multiline-js  no-any
                  yw.map((sub: any) => (
                    <p className={sub.top === 1 ? 'redp pcon' : 'op pcon'} key={sub.value + Math.random().toString()}>
                      {sub.value}
                    </p>
                  ))}
                  <AuthCompount isShow={yw.length === 0}>
                    <p color="bp">无</p>
                  </AuthCompount>
                </div>
                <div className="childCon">
                  <p className="cTitle">{mark === '是' ? '14天内去过农贸市场' : '14天内未去过农贸市场'}</p>
                </div>
                <div className="childCon">
                  <p className="cTitle">医生记录</p>
                  <textarea className="form_input" value={survey.answer.Q01842 ? survey.answer.Q01842 : ''} />
                </div>
                <div className="dcs">
                  <p>填表人签名：____________________</p>
                  <p>填表日期：{this.state.created}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  /**
   * 获取病人的详情
   */
  private getPatientDetail = async (id: string) => {
    const { result } = await GaugeSurveyApi.getPatientDetail({ id });
    this.setState({
      patient_code: result.customCode,
      patient_name: result.name,
      created: result.created,
    });
  };

  // tslint:disable-next-line: no-any
  private getAttention = async (payload: any) => {
    if (payload.answerId || payload.patientId) {
      const json = {
        answerId: payload.answerId,
        patientId: payload.patientId,
      };
      if (payload.answerId) {
        delete json.patientId;
      }
      const { result } = await GaugeSurveyApi.getNoteMessage(json);
      if (result.noteMessage) {
        result.answerResultList.push({
          moduleName: '要点提示',
          subDataList: result.noteMessage,
        });
      }
      this.setState({ attentions: result.answerResultList });
    }
  };

  /**
   * 处理url上面的传来过的值 以判断 各种情况的出现
   * @return urlPrams
   */
  private processUrl = async (url: string) => {
    // tslint:disable-next-line:no-any
    const urlPrams = {} as { [key: string]: any };
    const strs = url.split('&');
    strs.forEach(item => {
      const keys = item.split('=');
      urlPrams[keys[0]] = keys[1];
    });
    // 查病例缓存 拿到对应的信息展示
    if (urlPrams.patientId) {
      this.getPatientDetail(urlPrams.patientId);
    }
    // 扫码进入隐藏对应的栏目
    if (this.state.surveyQrcodes.includes(urlPrams.writeType)) {
      this.setState({ isQrcode: true });
      const surveyModelUtil = new SurveyModelUtil();
      const { result } = await surveyModelUtil.getQRCodeMsg(urlPrams.code);
      this.props.dispatch({
        type: 'global/qrCodeSaveUserInfo',
        payload: {
          code: result.organizationVo.code,
          systemName: result.organizationVo.systemName,
          name: result.organizationVo.name,
          logoUrl: result.organizationVo.logoUrl,
          visitNumber: result.visitNumber,
        },
      });
      urlPrams.channel = result.channel;
    }
    return urlPrams;
  };

  /**
   * 初始化的dispatch
   */
  // tslint:disable-next-line:no-any
  private gaugeInit = (payload?: any) => {
    this.props.dispatch({
      type: 'survey/gaugeInit',
      payload,
    });
  };
}
export default connect(({ global, survey }: { global: IGlobal; survey: ISurvey }) => ({
  global,
  survey,
}))(GaugeSurvey);
