import { Table } from 'antd';
import React from 'react';

class SHGH20190001 extends React.Component<{}> {
  public render() {
    const columns = [
      {
        title: '数据收集',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        className: 'rowName',
      },
      {
        title: '基线',
        children: [
          {
            title: (
              <span>
                Visit 1<br />
                （0 W）
              </span>
            ),
            dataIndex: 'visit1',
            key: 'visit1',
            width: '15%',
          },
        ],
      },
      {
        title: '26周随访',
        children: [
          {
            title: (
              <span>
                Visit 2<br />
                （4 W）
              </span>
            ),
            dataIndex: 'visit2',
            key: 'visit2',
            width: '15%',
          },
          {
            title: (
              <span>
                Visit 3<br />
                （12 W）
              </span>
            ),
            dataIndex: 'visit3',
            key: 'visit3',
            // width: '20%',
          },
          {
            title: (
              <span>
                Visit 4<br />
                （24 W）
              </span>
            ),
            dataIndex: 'visit4',
            key: 'visit4',
            // width: '20%',
          },
        ],
      },
      {
        title: '52周随访',
        children: [
          {
            title: (
              <span>
                Visit 5<br />
                （48 W）
              </span>
            ),
            dataIndex: 'visit5',
            key: 'visit5',
            // width: '20%',
          },
        ],
      },
    ];
    const data = [
      {
        id: '1',
        name: <h3 className="maintitle">基线记录</h3>,
        visit1: '',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '2',
        name: '人口学信息',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '3',
        name: '疾病史/ 家族史',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '4',
        name: '接受GLM治疗前疾病活动度',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '5',
        name: '既往用药及合并用药情况',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '6',
        name: <h3 className="maintitle">治疗方法及并发症记录</h3>,
        visit1: '',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '7',
        name: '目前治疗药物/ 起始 & 结束时间',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '8',
        name: 'GLM治疗启动/退出的原因',
        visit1: '',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '9',
        name: <h3 className="maintitle">研究数据收集</h3>,
        visit1: '',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '10',
        name: '夜间背痛（VAS），睡眠评分（PSQI）',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '11',
        name: '疲劳 （FACIT-F）、工作能力和活动损害问卷（WPAI）、BASFI',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '12',
        name: 'ASDAS, BASDAI',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '13',
        name: '外周关节的压痛与肿胀计数',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '14',
        name:
          '实验室检查：白细胞计数（WBC）、血红蛋白（Hb）、血小板（PLT）、谷丙转氨酶（ALT）、总胆红素（TBIL）、直接胆红素（DBIL）、血清肌酸酐（CR）、红细胞沉降率（ESR）、C反应蛋白（CRP）、血尿酸 （BUA）',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '15',
        name: '结核病感染筛查（PPD/T-spot）',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '16',
        name: '肝炎病毒感染筛查(HBV-DNA)',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '18',
        name: 'MaastrichtAS肌腱端炎积分（MASES）',
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
      {
        id: '19',
        name: '胸部X线检查',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '20',
        name: '中医辨证分型',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '',
        visit5: '',
      },
      {
        id: '21',
        name: '骶髂关节MRI结果',
        visit1: '×',
        visit2: '',
        visit3: '',
        visit4: '×',
        visit5: '',
      },
      {
        id: '17',
        name: <h3 className="maintitle">不良事件记录</h3>,
        visit1: '×',
        visit2: '×',
        visit3: '×',
        visit4: '×',
        visit5: '×',
      },
    ];
    return (
      <div id="SHGH20190001">
        <div className="SHGH">
          <div className="explainArea">
            <h1 style={{ fontSize: '1.3em' }}> 一、项目介绍</h1>
            <h2 style={{ fontSize: '1.3em' }}> 研究简介： </h2>
            <ul>
              <li style={{ listStyle: 'none' }}>
                这项前瞻性队列研究将登记 100 例接受戈利木单抗治疗的 AS
                患者。医生通过随访记录患者报告结局、临床症状、合并用药、不良事件、实验室检查以及影像学结果，直至患者退出临床访视。无论患者是否中断或停用戈利木单抗，医生将继续收集上述信息直至研究结束或患者永久性退出。
              </li>
            </ul>
            <h2 style={{ marginTop: '20px', fontSize: '1.3em' }}> 主要纳入标准：</h2>
            <ul>
              <li>患者必须年满 18 岁 </li>
              <li>诊断符合 1984 年纽约强直性脊柱炎分类标准</li>
              <li>Bath 强直性脊柱炎活动度评分(BASDAI ) ≥4 </li>
              <li>患者能理解并完成自我评价问卷； </li>
            </ul>
            <h2 style={{ marginTop: '20px', fontSize: '1.3em' }}> 主要排除标准：</h2>
            <ul>
              <li> 有戈利木单抗禁忌症患者 </li>
              <li> 既往接受过bDMARDs 治疗的患者</li>
            </ul>
            <h2 style={{ marginTop: '20px', fontSize: '1.3em' }}> 随访时间:</h2>
            <ul>
              <li style={{ listStyle: 'none' }}>
                采取动态入组的方式招募病人。随访平均时长为48周。除基线调查/评估外，随访期间一共进行4次调查/评估，分别在入组后的第4、12、24和48周。
              </li>
            </ul>
            <h1 style={{ marginTop: '20px', fontSize: '1.3em' }}> 二、填写指南</h1>
            <h2 style={{ marginTop: '20px', fontSize: '1.3em' }}> 数据收集：</h2>
            <span> 系统会根据被访视者不同的访视次数展示不同的问卷信息，具体收集数据如下：</span>
          </div>
          <div style={{ marginTop: '5px' }}>
            {/* <img style={{ width: '70%' }} src={require('./../../resource/table-image.png')} /> */}
            <Table columns={columns} dataSource={data} bordered={true} size="middle" pagination={false} rowKey="id" />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h4 style={{ marginTop: '10px', marginBottom: '10px' }}> 说明 ： </h4>
            <ol>
              <li> 访视窗：治疗期±3天，治疗结束后随访期±5天。</li>
              <li> 结核筛查在第24周根据临床医生的评估来决定是否行PPD和T-Spot检查。 </li>
              <li>
                从用研究药物前2周内开始到研究结束期间所有除研究用药以外的其他用药均需记录。不良事件从签署知情同意书开始，一直到最后一次用药后的30天，以先到者为准。最后一次用药后30天之后的不良事件，只记录那些与研究药物相关的不良事件。
              </li>
            </ol>

            <h3 style={{ marginTop: '20px' }}> 注意事项： </h3>
            <ol className="notice">
              <li>该问卷内所信息需要由专业医生进行收集。 </li>
              <li>
                在为患者填写访视1阶段问卷时需要输入患者的个人信息内容，并为患者进行编号，该编号为患者的唯一识别信息。
              </li>
              <li>在为被访视者填写访视2-5阶段信息时，需要输入患者编号确定患者身份。系统自动展示对应阶段的问卷信息。</li>
              <li>请仔细查看问题内容，问题涉及数值单位时，请根据问题标注的单位填写</li>
              <li>请注意准确填写问卷所有信息，所有信息均为必填内容。</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
export default SHGH20190001;
