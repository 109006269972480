import { Link } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WeChatPatient from '../../../api/WeChatPatient';
import WxDoctorApi from '../../../api/WxDoctorApi';
import { MobilePagination } from '../../../utils';
import UpdateTitle from '../../../utils/UpdateTitle';
import { Storage } from './../../../utils';
import { IDoctor, IPatientInfo } from './interface';
import './PatientHomePage.scss';
export default () => {
  const [patientinfo, setPatientInfo] = useState<IPatientInfo>({
    name: '',
    age: 0,
    gender: 1,
    headimgurl: '',
    morphUserUuid: 1,
  });

  const [doctors, setDoctor] = useState<IDoctor[]>([]);
  let page = { pageSize: 10, total: 0, current: 1 };
  // tslint:disable-next-line:no-any
  let doctorsData: any[] | never[] = [];

  useEffect(() => {
    let n = 1;
    const timer = setInterval(() => {
      const storage = new Storage();
      const global = JSON.parse(storage.getStore('global') || '{}');
      n++;
      if (n === 2000) {
        clearInterval(timer);
      }
      if (global.token) {
        clearInterval(timer);
        getUserInfo();
        getDoctors();
        UpdateTitle('个人信息');
        window.addEventListener('scroll', scrollE, true);
      }
    }, 200);
    return () => {
      window.removeEventListener('scroll', scrollE, true);
    };
  }, []);
  let listContent;
  if (!doctors || doctors.length <= 0) {
    listContent = (
      <div className="noDoctor">
        <img src={require('./../../../assets/img/img_doctor_null.svg')} />
        <p>暂无医生</p>
      </div>
    );
  } else {
    listContent = (
      <div className="list" id="list">
        <p className="titlePatient">我的医生</p>
        <ul className={'doctor'}>
          {// tslint:disable-next-line: jsx-no-multiline-js
          doctors.map(
            ({ id, msgCount, expertName, department, title, organizationName, attentionTime, gender }, index) => {
              return (
                <li key={id} className={`doctorItem ${index % 2 === 1 ? 'hiddenMargin' : ''}`}>
                  <Link to={`/Patient/PatientNotifyList/${id}/${expertName}`}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js */}
                    {!!msgCount && <span className="unreadNum">{msgCount < 100 ? msgCount : '99+'}</span>}
                    <img
                      src={
                        // tslint:disable-next-line:jsx-no-multiline-js
                        gender
                          ? require('./../../../assets/moblieImg/img_male.svg')
                          : require('./../../../assets/moblieImg/img_female.svg')
                      }
                    />
                    <p className="name">{expertName}</p>
                    <p className="department">
                      <span>{department.length < 6 ? department : department.slice(0, 5) + '...'}</span>
                      <span>{title.length < 5 ? title : title.slice(0, 4) + '...'}</span>
                    </p>
                    <p className="hospital">{organizationName}</p>
                    <p className="time">关注时间：{moment(attentionTime).format('YYYY-MM-DD')}</p>
                  </Link>
                </li>
              );
            }
          )}
        </ul>
      </div>
    );
  }
  return (
    <div id="Patient-PatientHomePage">
      <img className="bgImg" src={require('./../../../assets/img/img_personal_bg.svg')} />
      <div className="personInfo">
        <img
          src={patientinfo.headimgurl ? patientinfo.headimgurl : require('./../../../assets/img/img_doctor_null.svg')}
          className="photo"
        />
        <div className="basicInfo ">
          <div className="name">{patientinfo.name}</div>
          <div>
            <span>
              <label>{patientinfo.gender === 1 ? '男' : '女'}</label>
            </span>
            <span className={'age'}>
              <label>{patientinfo.age}岁</label>
            </span>
          </div>
        </div>
        <img className="cardBg" src={require('./../../../assets/img/img_personal.svg')} />
      </div>
      <div className="doctorList">{listContent}</div>
    </div>
  );
  async function getDoctors() {
    const { result } = await WeChatPatient.doctorPage({ pageNum: page.current, pageSize: page.pageSize });
    setDoctor([...doctorsData, ...result.records]);
    doctorsData = [...doctorsData, ...result.records];
    page = { current: result.current, total: result.total, pageSize: result.size };
  }
  async function getUserInfo() {
    const {
      result: { name, age, gender, headimgurl },
    } = await WxDoctorApi.getUserMsg();
    setPatientInfo({ name, age, gender, headimgurl });
  }

  function scrollE() {
    MobilePagination('Patient-PatientHomePage', page.current, page.pageSize, page.total).then(pageNum => {
      if (pageNum) {
        page.current = pageNum;
        getDoctors();
      }
    });
  }
};
