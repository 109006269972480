import React from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
// import Parser from './../../../utils/Parser';
import './FormCount.scss';

/**
 *  计算分数组件
 */
export default function FormCount(props: ISurveyHook) {
  return (
    <div id="FormCount">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={props.formObj.subjectData.errMsg ? 'cl star' : 'star'}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">
            <div dangerouslySetInnerHTML={{ __html: props.formObj.subjectData.subjectName }} />
          </div>
          {/* 只需要对算分的进行dom直接算分，所以这里只对复合组件里面的算分题目id进行赋值 */}
          <div
            // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition
                ? `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
                : ''
            }
          >
            {props.formObj.subjectData.defaultValue}
          </div>
        </div>
      </div>
      <AuthCompount isShow={!!props.formObj.subjectData.explain}>
        <div className="explain">（说明：{props.formObj.subjectData.explain}）</div>
      </AuthCompount>
    </div>
  );
}
