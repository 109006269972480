import { Button, Table, Tooltip } from 'antd';
import { Pagination } from 'antd';
import { Toast } from 'antd-mobile';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'dva';
import { Link, RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React from 'react';
import { GaugeSurvey } from '../../../api';
import DiseaseManage from '../../../api/DiseaseManage';
import downFileApi from '../../../api/Downfile';
import QRActionApi from '../../../api/QRActionApi';
import { ImgModalCmp } from '../../../components/QRModalCmp/QRModalCmp';
import { IconFont } from '../../../config/IconConfig';
import { MobilePagination } from '../../../utils';
import PageInfoCompount from './../../../components/PageInfoCompount/PageInfoCompount';
import LayoutComponent from './../../../layout/LayoutComponent';
import { IGlobal } from './../../../Model/GlobalModel';
import { CaseDropdrow } from './CaseDropdrow/CaseDropdrow';
import './SeeDiseaseOverview.scss';
const isMobile = window.screen.width < 750;
const filePath = window.screen.width > 500 ? 'img' : 'moblieImg';
interface IProps
  extends FormComponentProps,
    RouteComponentProps<{
      patientId: string;
      patientName: string;
      customCode: string;
    }> {
  global: IGlobal;
}
interface IState {
  data: IListResult;
  pagination: IPagination;
  patientId: string;
  param: IListParam;
  info: IPatientInfo;
  dropVisible: boolean;
  QRUrl?: string;
  pageNum: number;
  pageSize: number;
  reqData: IListParam;
  filter: { pageNum: number };
  mobileQRVisible: boolean;
  dataItems: IListItem[];
  hiddenNewVisit: boolean;
  newVisitDisable: boolean;
  onloading: boolean;
}
interface IPatientInfo {
  customCode: string;
  expertName: string;
  gender: number;
  id: number;
  lastVisitTime: string;
  name: string;
  status: number;
  visitNumber: number;
}
interface IListParam {
  patientId: string;
  pageNum: number;
  pageSize: number;
}

export interface IListItem {
  created: string;
  id: number;
  isSubmit: boolean;
  patientId: number;
  questionAnswerJson: string;
  userId: number;
  userName: string;
  visitNumber: number;
  expertName: string;
  status: number;
  // qrcodeStatus: number;
  editStatus: boolean;
  isRemind: boolean;
  maxVisitNumber: number;
}

interface IListResult {
  current: number;
  pages: number;
  records: IListItem[];
  size: number;
  total: number;
}

export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
}

/**
 * 继续填写的问卷 URL
 */

const actionsUrl = (record: IListItem) => {
  // 操作状态1：正常2：继续填写 3：锁定4:不显示 // 不管是编辑还是继续填写本质都是在修改当前这份问卷这边是没有新增的问卷的
  if (record.isSubmit) {
    // 已经提交过的问卷继续填写就是update
    return `/GaugeSurvey?writeType=surveyUpdate&answerId=${record.id}&patientId=${record.patientId}`;
  } else {
    // 未提交过的问卷就是新增
    return `/GaugeSurvey?writeType=surveyCreate&patientId=${record.patientId}`;
  }
};

const isPc = window.screen.width > 500;

class DiseaseOverview extends React.Component<IProps, IState> {
  public state = {
    data: { pages: 1 },
    // pagination: { total: 0, pageSize: 10, current: 0 },
    param: {
      patientId: this.props.match.params.patientId,
      pageNum: 1,
      pageSize: 10,
    },
    info: { customCode: '', name: '' },
    dropVisible: false,
    mobileQRVisible: false,
    hiddenNewVisit: false,
    newVisitDisable: false,
    onloading: false,
  } as IState;

  public columns = [
    {
      title: '问卷名称',
      dataIndex: 'age',
      key: 'age',
      width: '22%',
      render: (text: string, record: IListItem) => {
        return (
          <div>
            {this.props.global.companyInfo.name + '问卷'}
            <Tooltip placement="bottomLeft" title="该患者需要填写问卷">
              <img
                src={require('./../../../assets/img/icon_tips_red.svg')}
                className="icon_tips"
                hidden={!record.isRemind}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: '访视次数',
      dataIndex: 'visitNumber',
      key: 'visitNumber',
      width: '15%',
      render: (text: string, record: IListItem) => {
        return record.isSubmit ? record.visitNumber : record.visitNumber + 1;
      },
    },
    {
      title: '问卷时间',
      dataIndex: 'created',
      key: 'created',
      width: '18%',
      render: (text: string, record: IListItem) => {
        return moment(record.created).format('YYYY.MM.DD');
      },
    },
    {
      title: '填写状态',
      dataIndex: 'isSubmit',
      key: 'isSubmit',
      width: '16%',
      render: (text: string, record: IListItem) => {
        return record.status === 1 ? <div className="fin">已提交</div> : <div className="con">正在填写</div>;
      },
    },
    {
      title: '填写医生',
      dataIndex: 'expertName',
      width: '20%',
      key: 'expertName',
    },
    {
      title: '操作',
      dataIndex: 'studyDate',
      render: (text: string, record: IListItem) => {
        // 1：正常 2：继续填写 3：锁定
        const actionTitle = ['编辑问卷', '继续填写', '编辑问卷'];
        const isDetil = record.status !== 2;
        const isPdf = Boolean(this.props.global.sysConfig.pdfExport);
        const isQR = Boolean(this.props.global.sysConfig.qrCodeEdit && record.status === 1);
        const recordPDF = this.props.global.companyInfo.code === 'HJ90520200001' ? true : false;
        const authoritys: boolean[] = [isDetil, isPdf, isQR, recordPDF];

        return (
          <div className="rowAction">
            <Button
              onClick={() => this.tableRowAction(record, 0)}
              disabled={record.status === 3}
              title={record.status === 3 ? '其他用户正在填写,无法操作' : undefined}
            >
              {actionTitle[record.status - 1]}
            </Button>
            {// tslint:disable-next-line: jsx-no-multiline-js
            record.status !== 1 ? (
              ''
            ) : (
              <div>
                <CaseDropdrow authoritys={authoritys} item={record} rowAction={this.tableRowAction} />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  // tslint:disable-next-line:no-any
  public scroll: any;

  public componentDidMount() {
    this.getInfo();
    this.setState(
      {
        patientId: this.props.match.params.patientId,
        pageNum: 1,
        pageSize: 10,
      },
      () => {
        this.getList();
      }
    );
    this.isVisitAvailable();

    window.addEventListener('click', () => {
      this.setState({
        dropVisible: false,
      });
    });
    this.scrollE = this.scrollE.bind(this);
    window.addEventListener('scroll', this.scrollE, true);
  }
  public render() {
    const pcList = (
      <div className="pc">
        <Table
          className="table"
          columns={this.columns}
          dataSource={this.state.data.records}
          pagination={false}
          rowKey="id"
        />
        <div className="pa">
          <Pagination defaultCurrent={1} total={this.state.data.total} onChange={this.pageChange} />
        </div>
      </div>
    );
    const moList = this.state.data.records ? this.state.data.records.map(item => this.createListItem(item)) : '';
    return (
      <div className="SeeDisContainer" id="SeeDisContainer">
        <LayoutComponent isBack={true} name="查看病例问卷">
          <PageInfoCompount isShowLeft={false} mode={1} />
          <div className="patientInfo">
            <div className="patienCode" title={this.state.info.customCode}>
              病例编号：{this.state.info.customCode}
            </div>
            <div className="patienName" title={this.state.info.name}>
              病例名称：{this.state.info.name}
            </div>
            <div className="addOneCaseContainer">
              <Link
                // tslint:disable-next-line:jsx-no-multiline-js
                to={
                  this.state.newVisitDisable
                    ? '#'
                    : `/GaugeSurvey?writeType=surveyCreate&patientId=${this.state.info.id}`
                }
                className={this.state.newVisitDisable ? 'addOneCase addOneCaseDisabel' : 'addOneCase'}
                hidden={this.state.hiddenNewVisit}
                title={this.state.newVisitDisable ? '其他用户正在填写,无法操作' : undefined}
              >
                新增问卷
              </Link>
            </div>
          </div>
          <div className="listContainer">
            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {filePath === 'img' ? (
              pcList
            ) : (
              <div className="moList" id="dataList">
                {moList}
              </div>
            )}
          </div>
          {/* <MobileFooter url={this.props.global.companyInfo.logoUrl} name={this.props.global.companyInfo.name} /> */}
          <ImgModalCmp
            url={this.state.QRUrl}
            visible={this.state.mobileQRVisible}
            callback={() => this.setState({ mobileQRVisible: false })}
            discription="展示此二维码给患者编辑问卷"
          />
        </LayoutComponent>
        <div className={'mobileAddVisit'} hidden={this.state.hiddenNewVisit}>
          <Link
            // tslint:disable-next-line:jsx-no-multiline-js
            to={
              this.state.newVisitDisable ? '#' : `/GaugeSurvey?writeType=surveyCreate&patientId=${this.state.info.id}`
            }
            className={this.state.newVisitDisable ? 'addOneCase addOneCaseDis' : 'addOneCase'}
          >
            新增问卷
          </Link>
        </div>
      </div>
    );
  }
  public scrollE() {
    if (!isMobile) {
      return;
    }
    const { data, onloading } = this.state;
    MobilePagination('SeeDisContainer', data.current, data.size, data.total).then(pageNum => {
      if (pageNum && !onloading) {
        this.setState(
          (prevState: IState) => {
            return { data: { ...prevState.data, current: Number(pageNum) }, onloading: true };
          },
          () => {
            this.scollGetItemsview();
          }
        );
      }
    });
  }

  /**
   * 列表行点击事件的处理
   * @param actionIndex 0 '继续填写' 或者 '编辑问卷' 1'查看病例问卷' 2 '下载PDF'3 '二维码'
   */
  private tableRowAction = (item: IListItem, actionIndex: number) => {
    const { patientId } = this.props.match.params;
    this.setState({ dropVisible: true });
    switch (actionIndex) {
      case 0: {
        // 匿名问卷继续填写,或者普通问卷的继续填写,或者带上方式次数的继续填写问卷
        const url = actionsUrl(item);
        this.props.history.push(url);
        break;
      }
      case 1: {
        // 查看病例问卷
        const url = `/GaugeSurvey?writeType=surveyDetail&answerId=${item.id}&patientId=${patientId}`;
        this.props.history.push(url);
        break;
      }
      case 2: {
        this.downPDF(item);
        break;
      }
      case 4: {
        const url = `/PdfResult?writeType=surveyDetail&answerId=${item.id}&patientId=${patientId}`;
        this.props.history.push(url);
        break;
      }
    }
  };

  /*
   * 搜索
   * 滚动获取
   */
  private scollGetItemsview = async () => {
    //
    const { data } = this.state;
    const json = {
      patientId: this.state.param.patientId,
      pageNum: data.current,
      pageSize: data.size,
    };
    const { result } = await DiseaseManage.geAnswerList<IListResult, IListParam>(json);
    this.setState({
      data: {
        records: [...data.records, ...result.records],
        current: result.current,
        pages: result.pages,
        size: result.size,
        total: result.total,
      },
      onloading: false,
    });
  };
  /**
   * 确定是否 显示新增问卷
   */
  private isVisitAvailable = async () => {
    const { maxVisitNumber } = this.props.global.sysConfig;
    const { result } = await GaugeSurvey.getCacheGauge({ patientId: this.props.match.params.patientId });
    if (result.id) {
      this.setState({
        newVisitDisable: true,
      });
    } else {
      if (maxVisitNumber && result.visitNumber && maxVisitNumber > 0 && result.visitNumber >= maxVisitNumber) {
        this.setState({
          hiddenNewVisit: true,
        });
      }
    }
  };
  /**
   * 根据Id获取用户信息
   */
  private getInfo = async () => {
    const { result } = await DiseaseManage.getInfoById<IPatientInfo, { id: string }>({
      id: this.props.match.params.patientId,
    });
    this.setState({
      info: { customCode: result.customCode, name: result.name, id: result.id } as IPatientInfo,
    });
  };
  /**
   * 搜索
   * 获取数据异步请求
   */
  private getList = async () => {
    const { result } = await DiseaseManage.geAnswerList<IListResult, IListParam>(this.state.param);

    this.setState({
      data: {
        records: this.state.data.records && !isPc ? [...this.state.data.records, ...result.records] : result.records,
        current: result.current,
        pages: result.pages,
        size: result.size,
        total: result.total,
      },
    });
  };

  /* 翻页 */

  private pageChange = async (page: number, pageSize?: number) => {
    if (page) {
      const reqData: IListParam = this.state.param;
      reqData.pageNum = page;
      this.setState({ reqData }, () => this.getList());
    }
  };
  /* 手机列表单元 */
  private createListItem(param: IListItem) {
    const { patientId } = this.props.match.params;
    const isQR = this.props.global.sysConfig.qrCodeEdit;
    // const widthNum = isQR ? 8 : 12; // 懒得重写 ,做个兼容
    return (
      <div className="mo-dataList" key={param.id}>
        <div className="upRow">
          <div className="doctorName">填写医生：{param.expertName}</div>
          <div className="qrtime">
            问卷时间：
            {moment(param.created).format('YYYY.MM.DD')}
          </div>
          <div className="statusIcon">
            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {param.status === 1 ? (
              <div className="anticonIcon">
                <IconFont type="iconicon_finish" />
                已提交
              </div>
            ) : (
              <div className="anticonIcon">
                <IconFont type="iconicon_editing" />
                正在填写
              </div>
            )}
          </div>
        </div>
        <div className="downRow">
          <div className="qrName">
            问卷名称：
            {this.props.global.companyInfo.name}
            <img
              src={require('./../../../assets/img/icon_tips_red.svg')}
              className="icon_tips"
              hidden={!param.isRemind}
              // tslint:disable-next-line: jsx-no-multiline-js
              onClick={() => {
                Toast.info('建议在此问卷收集患者信息', 1);
              }}
            />
          </div>
          <div className="visitTime">
            访视次数：
            {// tslint:disable-next-line:jsx-no-multiline-js
            param.isSubmit ? param.visitNumber : param.visitNumber + 1}
            次
          </div>
        </div>
        <div className="btnGP">
          <div className={param.status === 1 || (isQR && param.isSubmit) ? 'actionBtn' : 'actionBtn'}>
            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {param.status === 1 || param.status === 3 ? (
              <Link
                className={
                  /* tslint:disable-next-line:jsx-no-multiline-js */
                  param.status !== 3 ? 'actionBtn ' : 'actionBtn disabledColor'
                }
                title={
                  /* tslint:disable-next-line:jsx-no-multiline-js */
                  param.status === 3 ? '其他用户正在填写，无法操作' : ''
                }
                // tslint:disable-next-line:jsx-no-multiline-js
                to={param.status !== 3 ? actionsUrl(param) : '#'}
              >
                编辑问卷
              </Link>
            ) : (
              <div className="actionBtn">
                <Link
                  className="actionBtn"
                  // tslint:disable-next-line:jsx-no-multiline-js
                  to={actionsUrl(param)}
                >
                  继续填写
                </Link>
              </div>
            )}
          </div>
          {param.status === 1 ? (
            <div className={isQR ? 'actionBtn leftLine' : ''}>
              {/* tslint:disable-next-line:jsx-no-multiline-js */}
              <Link
                className="table-row-action center "
                // tslint:disable-next-line:jsx-no-multiline-js
                to={`/GaugeSurvey?writeType=surveyDetail&answerId=${param.id}&patientId=${patientId}`}
              >
                查看病例问卷
              </Link>
            </div>
          ) : (
            ''
          )}
          {isQR && param.isSubmit ? (
            <div className="actionBtn leftLine">
              {/* tslint:disable-next-line:jsx-no-multiline-js */}
              <Link
                className="table-row-action center action"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => this.mobileQrAction(param)}
                to={`#`}
              >
                二维码
                {// tslint:disable-next-line:jsx-no-multiline-js
                param.editStatus ? (
                  <img src={require(`../../../assets/img/icon_code_finish.svg`)} alt="" className="scanActionIcon" />
                ) : null}
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  /**
   * 手机列表 二维码点击
   */

  private mobileQrAction = (item: IListItem) => {
    this.setState({ mobileQRVisible: true });
    this.getQRUrl(item.id);
  };

  /**
   * 下载PDF
   */
  private downPDF = async (record: IListItem) => {
    // const content = await QRActionApi.getPDFStream(record.id);
    // exportFile(content, '病例.pdf');

    downFileApi.get(`/logined/pdf/download`, { params: { id: record.id } });
  };

  /**
   * 根据 id 去获取 二维码
   */
  private getQRUrl = async (id: number) => {
    const { result } = await QRActionApi.getSurveyQRImgUrl(id);
    if (result) {
      this.setState({ QRUrl: result });
    }
  };
}
export default connect(global => global)(DiseaseOverview);
