import { Button, Form } from 'antd';
import { InputItem, TextareaItem } from 'antd-mobile';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { WeChatDoctor } from '../../../api';
import UpdateTitle from '../../../utils/UpdateTitle';

import { MobileHeader } from '../../../components';
import './DoctorAddNotify.scss';
interface INewNotisProps
  extends FormComponentProps,
    RouteComponentProps<{
      periodId: string;
      type: string;
      notifyId: string;
      groupId: string;
    }> {
  id?: string;
}
interface IState {
  content: string;
  sysMsgType?: number;
  title: string;
  dataSource: string;
  nextFollowUpDays: string;
  pushCycleId: string;
  type: number;
}
function NewNotisPage(props: INewNotisProps) {
  const periodId = props.match.params.periodId;
  const notifyId = props.match.params.notifyId;
  const groupId = props.match.params.groupId;
  const type = props.match.params.type;
  const [state, setState] = useState<IState>({
    title: '',
    content: '',
    dataSource: '2',
    nextFollowUpDays: '',
    pushCycleId: periodId,
    type: 2,
  });
  useEffect(() => {
    if (type === 'EDIT') {
      UpdateTitle('编辑通知');
      getNotify();
    } else {
      UpdateTitle('添加通知');
    }
  }, []);
  const { getFieldDecorator } = props.form;
  function changeEvent(inputType: string, data?: string | number) {
    setState({ ...state, [inputType]: data });
  }
  async function save(e: React.MouseEvent<HTMLSpanElement>) {
    if (!e.currentTarget.className.includes('enable')) {
      let result;
      const dto = { ...state, nextFollowUpDays: state.nextFollowUpDays ? state.nextFollowUpDays : 0 };
      if (type === 'EDIT') {
        result = await WeChatDoctor.editNotify({ dto, id: notifyId });
      } else {
        result = await WeChatDoctor.addNotify(dto);
      }
      if (result.code === 0) {
        props.history.push(`/Doctor/DoctorNotifyList/${periodId}/${groupId}`);
      }
    }
  }
  async function getNotify() {
    const { result } = await WeChatDoctor.getNotify({ id: notifyId });
    setState({ ...result });
  }
  return (
    <div className="DoctorAddNotify">
      <MobileHeader backUrl={`/Doctor/DoctorNotifyList/${periodId}/${groupId}`}>
        <Button
          type={'link'}
          disabled={!(state.content && state.title && (state.sysMsgType === 3 ? true : state.nextFollowUpDays))}
          onClick={save}
        >
          保存
        </Button>
      </MobileHeader>
      <div className="notiTitle"> 通知标题 </div>
      <Form.Item colon={false}>
        {/* tslint:disable-next-line:jsx-no-multiline-js*/}
        {getFieldDecorator('notiTitle', {
          initialValue: state.title,
        })(
          <TextareaItem
            placeholder="标题"
            className="notiTitleTextInput"
            autoHeight={true}
            rows={2}
            onChange={value => changeEvent('title', value)}
          />
        )}
      </Form.Item>
      <div className="notiTypeTitle">通知类型 </div>
      <div className="notiTypeContainer">
        <div className="notiReVisiting" onClick={() => changeEvent('sysMsgType', 1)}>
          复诊通知
          {state.sysMsgType === 1 ? <img src={require('../../../assets/img/icon_group_checkbox_select.svg')} /> : ''}
        </div>
        <div className="notiVisit" onClick={() => changeEvent('sysMsgType', 2)}>
          随访通知
          {state.sysMsgType === 2 ? <img src={require('../../../assets/img/icon_group_checkbox_select.svg')} /> : ''}
        </div>
        <div className="notiQuestionnaire" onClick={() => changeEvent('sysMsgType', 3)}>
          随访问卷
          {state.sysMsgType === 3 ? <img src={require('../../../assets/img/icon_group_checkbox_select.svg')} /> : ''}
        </div>
      </div>
      {(state.sysMsgType === 1 || state.sysMsgType === 2) && (
        <div className="notiSendTimeContainer">
          <div className="notiTypeTitle">通知时间</div>
          <div className="timeSet">
            {state.sysMsgType === 1 ? '复诊' : '随访'}前
            <Form.Item colon={false}>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('dayAfter', {
                initialValue: state.nextFollowUpDays,
              })(
                <InputItem
                  placeholder="0"
                  className="dayAfter"
                  type={'number'}
                  onChange={value => changeEvent('nextFollowUpDays', value)}
                />
              )}
            </Form.Item>
            天发送
          </div>
        </div>
      )}

      <div className="notiContentMsgTitle">通知内容 </div>
      <Form.Item colon={false}>
        {/* tslint:disable-next-line:jsx-no-multiline-js*/}
        {getFieldDecorator('notiContentMsg', {
          initialValue: state.content,
        })(
          <TextareaItem
            placeholder="内容"
            className="notiContentMsgInput"
            autoHeight={true}
            rows={3}
            onChange={value => changeEvent('content', value)}
          />
        )}
      </Form.Item>
    </div>
  );
}
const DoctorAddNotify = Form.create()(NewNotisPage);
export default DoctorAddNotify;
