import { ICacheParam, ICacheResult } from './../Model/Model';
import axios from './RequestApi';
/**
 * @class 量表相关的api
 */

export default class GaugeSurvey {
  /**
   * 获取量表暂存信息
   */
  public static getCacheGauge(data: { patientId: string; answerId?: string }) {
    return axios.get<ICacheResult, { patientId: string; answerId?: string }>(`/logined/answer/cache/info`, data);
  }
  /**
   * 获取匿名缓存文件信息，用于第一次调用
   */
  public static getUnknownCacheGauge() {
    return axios.get<ICacheResult, {}>(`/logined/answer/cache/anonymousInfo`);
  }
  /**
   * 暂存问卷
   */
  public static createCacheGauge(data: ICacheParam) {
    return axios.post<ICacheResult, ICacheParam>(`/logined/answer/cache/save`, data);
  }
  /**
   * 提交问卷
   */
  public static createGauge(data: ICreateGauge) {
    return axios.post<{}, ICreateGauge>(`/logined/answer/save`, data);
  }

  /**
   * 获取问卷信息
   */
  public static getGaugeDetail(data: { id: string }) {
    return axios.get<ICacheResult, { id: string }>(`/logined/answer/info`, data);
  }

  /**
   * 通过病例id 获取病例信息
   */
  public static getPatientDetail<T, D>(data: D) {
    return axios.get<T, D>(`/logined/patient/infoById`, data);
  }
  /**
   * 通过病例id 获取注意事项
   */
  public static getNoteMessage<T, D>(data: D) {
    return axios.get<T, D>(`/logined/answer/noteMessage`, data);
  }
  /**
   * 提交编辑问卷
   */
  public static updateGauge(data: IUpdateGaugeParams) {
    return axios.post<{}, IUpdateGaugeParams>(`/logined/answer/edit`, data);
  }
  /**
   * 获取二维码
   */
  public static getQRCode() {
    return axios.get<string, {}>('/logined/qrcode');
  }

  /**
   * 扫码提交问卷
   */
  public static createQrcode(data: ICreateQrcode) {
    return axios.post<ICreateQrcodeResp, ICreateQrcode>(`/qrcode/answer/save`, data);
  }

  /**
   * 扫码编辑提交问卷
   */
  public static updateQrcode(data: ICreateQrcode) {
    return axios.post<ICreateQrcodeResp, ICreateQrcode>(`/qrcode/answer/edit`, data);
  }

  /**
   * 扫码编辑提缓存
   */
  public static catchQrcode(data: { answerCacheJson: string; code: string }) {
    return axios.post<{}, { answerCacheJson: string; code: string }>(`/qrcode/cache/save`, data);
  }
  /**
   * 扫码获取问卷答案或者缓存
   * @param data
   */
  public static getQrCodeAnswerOrCache(data: { code: string }) {
    return axios.get<IQrCodeAnswerOrCache, { code: string }>(`/qrcode/cache/info`, data);
  }

  /**
   * 获取问卷问题
   */
  public static getSurvey(data: { questionId?: string }) {
    return axios.get<IGetSurvey, { questionId?: string }>(`/logined/question/getQuestionInfo`, data);
  }

  /**
   * 医生二维码
   */
  public static getDoctorCode(data: { id?: string }) {
    return axios.get<string, { id?: string }>(`/qrcode/doctor_channel/answer`, data);
  }

  /**
   * 获取oss token
   */
  public static getToken() {
    return axios.get<IGetToken, {}>(`/oss/token`);
  }

  /**
   * 获取上次方式结果
   */
  public static answerLastInfo(data: IAnswerLastInfoReq) {
    return axios.get<{ answerJson: string }, IAnswerLastInfoReq>(`/logined/answer/lastInfo`, data);
  }

  /**
   * 上传文件
   */
  // tslint:disable-next-line:no-any
  public static uploadExcelTemplate(data: any) {
    return axios.upload(`/logined/excel/template/upload`, data);
  }
}

interface IAnswerLastInfoReq {
  visitNumber: number;
  patientId: string;
}
export interface IUploadExcelTempReq {
  file: File;
}

export interface IGetToken {
  securityToken: string;
  host: string;
}

export interface IGetSurvey {
  appVersion?: string;
  id?: number;
  jsonDesc: string;
  visitNumber?: number;
}
export interface IUpdateGaugeParams {
  answerJson: string;
  id?: string;
  questionId: string;
}

export interface ICreateQrcode {
  answerJson: string;
  code?: string;
}
export interface ICreateQrcodeResp {
  code: string;
  customCode: string;
  expertName: string;
  gender: number;
  id: number;
  lastVisitTime: string;
  maxVisitNumber: number;
  name: string;
  sampleSequence: number;
  showVisitNumber: number;
  status: number;
  visitNumber: number;
}

export interface ICreateGauge {
  answerJson: string;
  visitNumber?: number;
  patientId?: string;
  questionId: string;
}
export interface IQrCodeAnswerOrCache {
  answerCacheJson: string;
  answerJson: string;
}
