import { Button, Form, message } from 'antd';
import { InputItem } from 'antd-mobile';
import { FormComponentProps } from 'antd/lib/form';
import { SubscriptionAPI } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { WeChatDoctor } from '../../../api';
import { MobileHeader } from '../../../components/index';
import UpdateTitle from '../../../utils/UpdateTitle';
import { IGlobal } from './../../../Model/GlobalModel';
import './DoctorAddPeriod.scss';
interface IProps
  extends SubscriptionAPI,
    FormComponentProps,
    RouteComponentProps<{
      periodId: string;
      type: string;
      number: string;
      days: string;
      isN: string;
      groupId?: string;
    }> {
  global: IGlobal;
}
interface IState {
  days?: string;
  number?: string;
  id?: string;
}
interface IValidateStatusType {
  validateStatus: 'success' | 'warning' | 'error' | 'validating' | '';
  errorMsg: string;
}
export default Form.create()((props: IProps) => {
  // tslint:disable-next-line: radix
  const groupId = props.match.params.groupId;
  const type = props.match.params.type;
  const id = props.match.params.periodId;
  const isN = Number(props.match.params.isN);
  // tslint:disable-next-line: variable-name
  const number = props.match.params.number;
  const days = props.match.params.days;
  const [state, setstate] = useState<IState>({});
  const [verifyMobile, setVerifyMobile] = useState<IValidateStatusType>({
    validateStatus: 'success',
    errorMsg: '',
  });
  const { getFieldDecorator } = props.form;
  useEffect(() => {
    if (type === 'EDIT') {
      UpdateTitle('编辑周期');
      setstate({ ...state, id, number, days });
    } else {
      UpdateTitle('添加周期');
    }
  }, []);
  // 保存
  async function save() {
    props.form.validateFields(async (err, values) => {
      let result;
      if (!err) {
        if (type === 'EDIT') {
          result = await WeChatDoctor.editPeriods({ dto: state });
        } else {
          result = await WeChatDoctor.addPeriods({ dto: state, groupId });
        }
        if (result.code === 0) {
          props.history.push(`/Doctor/DoctorSetPeriod/${groupId}`);
        } else {
          message.info(result.message);
        }
      }
    });
  }
  // 输入校验
  function changeEvent(value: string, inputType: string) {
    switch (inputType) {
      case 'days':
        if (/^[1-9]\d*$/.test(value)) {
          setstate({ ...state, days: value });
        } else {
          delete state.days;
          setstate({ ...state });
        }
        break;
      case 'number':
        if (/^[1-9]+$|^[nN]$/g.test(value)) {
          setstate({ ...state, number: value.toLowerCase() === 'n' ? '-1' : value });
          if (/^[nN]$/g.test(value) && isN === 1) {
            setVerifyMobile({
              validateStatus: 'error',
              errorMsg: '请输入正整数，已存在次数N的周期，次数N的周期必须在最后一个',
            });
          } else {
            setVerifyMobile({
              validateStatus: 'success',
              errorMsg: '',
            });
          }
        } else {
          delete state.number;
          setstate({ ...state });
          setVerifyMobile({
            validateStatus: 'error',
            errorMsg: isN === 0 ? '请输入正整数或N!' : '请输入正整数!',
          });
        }
        break;
    }
  }
  return (
    <div id="Doctor-DoctorAddPeriod">
      {/* <NavBar mode="light" leftContent={[<img src={require('./../../../assets/img/btn_back.svg')} />, '返回']}></NavBar> */}
      <div className="contentArea">
        <div className="topMenu">
          <MobileHeader backUrl={`/Doctor/DoctorSetPeriod/${groupId}`}>
            <Button
              type={'link'}
              disabled={!(state.days && state.number && (isN === 1 ? Number(state.number) !== -1 : true))}
              onClick={save}
            >
              保存
            </Button>
          </MobileHeader>
        </div>

        <div className="formArea">
          <h1>天数</h1>
          <Form.Item>
            {// tslint:disable-next-line: jsx-no-multiline-js
            getFieldDecorator('days', {
              rules: [
                {
                  pattern: /^[1-9]\d*$/,
                  message: '请输入正整数!',
                },
                {
                  required: true,
                  message: '请输入正整数!',
                },
              ],
              initialValue: state.days,
            })(
              <InputItem placeholder="输入数字" className="interval" onChange={value => changeEvent(value, 'days')} />
            )}
          </Form.Item>
          <h1>次数</h1>
          <Form.Item validateStatus={verifyMobile.validateStatus} help={verifyMobile.errorMsg}>
            <InputItem
              placeholder="输入数字或n"
              value={Number(state.number) === -1 ? 'N' : state.number}
              className="times"
              onChange={value => changeEvent(value, 'number')}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
});
