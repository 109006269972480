import { Button, Form } from 'antd';
import { InputItem, TextareaItem } from 'antd-mobile';
import { useDispatch, useSelector } from 'dva';
import React, { useEffect, useState } from 'react';
import WeChatDoctor, { IGroup, IPatientItem } from '../../../api/WeChatDoctor';
import { MobileHeader } from '../../../components/index';
import UpdateTitle from '../../../utils/UpdateTitle';
import './DoctorAddGroup.scss';
import { IProps, IState } from './interface';
export default Form.create()((props: IProps) => {
  // const [pagnation, setPagnation] = useState({ pageSize: 100000, total: 0, pageNum: 1 });
  // const [list, setList] = useState<IPatientItem[]>([]);
  const [choosedList, setChoosedList] = useState([] as IPatientItem[]);
  const dispatch = useDispatch();
  const globalState = useSelector(({ groupInfo }: { groupInfo: IGroup }) => groupInfo);
  // 分组信息
  const [state, setState] = useState<IState>({
    name: '',
    description: '',
    type: 2,
    refreshUserIds: [],
    wechatUserRole: 1,
    hulkUserInfoList: [],
  });
  const [isDeleting, setIsDeleting] = useState(false); // 是否是删除状态
  const [selectedId, setSelectedId] = useState(''); // 点击用户头像出现弹窗的id
  const { getFieldDecorator } = props.form;
  const type = props.match.params.type;
  const groupId = props.match.params.groupId;
  useEffect(() => {
    if (type === 'EDIT') {
      getGroupDetail();
      UpdateTitle('编辑分组');
    } else {
      setTimeout(() => {
        if (globalState.hulkUserInfoList.length > 0) {
          setState({
            ...state,
            name: globalState.name,
            description: globalState.description,
            hulkUserInfoList: globalState.hulkUserInfoList,
          });
          setChoosedList(globalState.hulkUserInfoList);
        }
      }, 100);
      UpdateTitle('添加分组');
    }
  }, []);
  useEffect(() => {
    const idArr: string[] = [];
    choosedList.forEach(item => {
      idArr.push(item.id);
    });
    setState({ ...state, refreshUserIds: idArr, hulkUserInfoList: choosedList });
  }, [choosedList]);
  // 分组详情
  async function getGroupDetail() {
    // tslint:disable-next-line: radix
    const { result } = await WeChatDoctor.groupDetail(parseInt(groupId));
    if (globalState.hulkUserInfoList.length <= 0) {
      setState({
        ...state,
        name: result.name,
        description: result.description,
        hulkUserInfoList: result.hulkUserInfoList,
      });
      setChoosedList(result.hulkUserInfoList);
    } else {
      setState({
        ...state,
        name: globalState.name,
        description: globalState.description,
        hulkUserInfoList: globalState.hulkUserInfoList,
      });
      setChoosedList(globalState.hulkUserInfoList);
    }
  }
  function addPatient() {
    dispatch({
      type: 'groupInfo/saveInfo',
      payload: state,
    });
    props.history.push(`/Doctor/AddGroupMember/${type}/${groupId}`);
  }
  /*  function back() {
    if (type === 'EDIT') {
      props.history.push(`/Doctor/DoctorGroupDetail/${groupId}`);
    } else {
      props.history.push('/Doctor/DoctorGroup');
    }
  }*/
  async function save() {
    let res;
    if (type === 'EDIT') {
      // tslint:disable-next-line: radix
      res = await WeChatDoctor.editGroup({ id: parseInt(groupId), dto: state });
    } else {
      state.joinUserIdList = state.refreshUserIds;
      delete state.refreshUserIds;
      res = await WeChatDoctor.addGroup(state);
    }
    if (res.code === 0) {
      dispatch({
        type: 'groupInfo/clearInfo',
      });
      props.history.push('/Doctor/DoctorGroup');
    } else {
      alert('失败');
    }
  }
  // 确认删除
  function deleteSure() {
    const tempArr = choosedList.filter(item => {
      return !item.isDeleting;
    });
    setChoosedList(tempArr);
    setIsDeleting(false);
  }
  let choosedPatientHtml;
  if (choosedList.length > 0) {
    choosedPatientHtml = choosedList.map((item, index) => {
      return (
        <div
          key={item.id}
          className="patientItem"
          // tslint:disable-next-line: jsx-no-multiline-js
          onClick={() => {
            if (isDeleting) {
              item.isDeleting = !item.isDeleting;
              setChoosedList([...choosedList]);
            } else {
              if (item.id === selectedId) {
                setSelectedId('');
              } else {
                setSelectedId(item.id);
              }
            }
          }}
        >
          <img src={item.headimgurl} />
          {// tslint:disable-next-line: jsx-no-multiline-js
          isDeleting ? (
            <img
              className="selectIcon"
              // tslint:disable-next-line: jsx-no-multiline-js
              src={
                item.isDeleting
                  ? require('../../../assets/img/icon_delete_patient_select.svg')
                  : require('../../../assets/img/icon_delete_patient.svg')
              }
            />
          ) : (
            ''
          )}
          {// tslint:disable-next-line: jsx-wrap-multiline  jsx-no-multiline-js
          selectedId === item.id ? (
            <div className="detail">
              <div className="firstLine">
                <span className="name">{item.name}</span>
                <span>{item.gender === 1 ? '男' : '女'}</span>
                <span>{item.age}岁</span>
              </div>
              {// tslint:disable-next-line: jsx-no-multiline-js
              item.sampleSequence ? (
                <div>
                  就诊编号：<span>{item.sampleSequence}</span>
                </div>
              ) : null}
              {// tslint:disable-next-line: jsx-no-multiline-js
              item.joinTime ? (
                <div>
                  绑定时间：<span>{item.joinTime ? item.joinTime.slice(0, 10) : ''}</span>
                </div>
              ) : null}
            </div>
          ) : (
            ''
          )}
        </div>
      );
    });
  }

  return (
    <div id="Doctor-DoctorAddGroup">
      <div className="contentArea">
        <MobileHeader backUrl={type === 'EDIT' ? `/Doctor/DoctorGroupDetail/${groupId}` : '/Doctor/DoctorGroup'}>
          <Button type={'link'} disabled={!state.name} onClick={save}>
            保存
          </Button>
        </MobileHeader>
        <div className="formArea">
          <h1>分组名称</h1>
          <Form.Item>
            {// tslint:disable-next-line: jsx-no-multiline-js
            getFieldDecorator('days', {
              rules: [
                {
                  required: true,
                  message: '请输入名称',
                },
              ],
              initialValue: state.name,
              // tslint:disable-next-line: no-shadowed-variable
            })(<InputItem placeholder="名称" className="name" onChange={name => setState({ ...state, name })} />)}
          </Form.Item>
          <h1>分组备注</h1>
          <Form.Item>
            {// tslint:disable-next-line: jsx-no-multiline-js
            getFieldDecorator('remark', {
              rules: [],
              initialValue: state.description,
            })(
              <TextareaItem
                placeholder="备注"
                className="remark"
                onChange={description => setState({ ...state, description })}
              />
            )}
          </Form.Item>
          <h1>选择患者</h1>
          <div className="patientArea">
            <img className="addBtn" src={require('../../../assets/img/btn_add_patient.svg')} onClick={addPatient} />
            {// tslint:disable-next-line: jsx-no-multiline-js
            choosedList.length > 0 && !isDeleting ? (
              <img
                className="deleteBtn"
                src={require('../../../assets/img/btn_delete_patient.svg')}
                onClick={() => setIsDeleting(true)}
              />
            ) : (
              ''
            )}
            {// tslint:disable-next-line: jsx-no-multiline-js
            isDeleting ? (
              <img
                className="deleteBtn"
                src={require('../../../assets/img/btn_delete_patient_done.svg')}
                onClick={deleteSure}
              />
            ) : (
              ''
            )}
            {// tslint:disable-next-line: jsx-no-multiline-js
            isDeleting ? (
              <span
                className="cancel"
                // tslint:disable-next-line: jsx-no-multiline-js
                onClick={() => {
                  setIsDeleting(false);
                }}
              >
                取消
              </span>
            ) : (
              ''
            )}
            <div className="addedPatientList">{choosedPatientHtml}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
