import { Button, message, Spin } from 'antd';
import { Modal } from 'antd-mobile';
import { SubscriptionAPI } from 'dva';
import { RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import QRActionApi from '../../../api/QRActionApi';
import WeChatPatient from '../../../api/WeChatPatient';
import { MobileHeader, MobileTag } from '../../../components';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import { IMessageType } from '../PatientNotifyList/interface';
import './PatientNotifyDetail.scss';
export interface IProps
  extends SubscriptionAPI,
    RouteComponentProps<{
      notifyId: string;
    }> {
  global: IGlobal;
}
export interface IState {
  title: string;
  sysMsgType: number;
  sendUserInfo: {
    name: string;
    department: string;
    title: string;
    id?: string | number;
    expertName?: string;
  };
  sendTime: string;
  treatmentTime: string;
  answerCode?: string;
  result: number;
  status?: number;
  buttonStatus?: number | null;
  content: string;
}
export default (props: IProps) => {
  const statusImg = [
    0,
    require('./../../../assets/img/img_accept.svg'),
    require('./../../../assets/img/img_refuse.svg'),
  ];
  const [isShowModal, setIsShowModal] = useState(false);
  const [writeType, setWriteType] = useState();
  const [code, setCode] = useState();
  const [state, setState] = useState<IState>({
    title: '',
    sysMsgType: 1,
    sendUserInfo: {
      name: '',
      department: '',
      title: '',
    },
    content: '',
    sendTime: '',
    treatmentTime: '',
    result: 1,
  });
  const messageType: IMessageType = {
    1: { name: '复诊通知', class: 'orange' },
    2: { name: '随访通知', class: 'green' },
    3: { name: '随访问卷', class: 'blue' },
  };
  useEffect(() => {
    getNotifyDetail(props.match.params.notifyId);
    UpdateTitle('我的通知');
  }, []);
  async function getType(answerCode: string) {
    const { result } = await QRActionApi.getQRCodeMsg({ code: answerCode });
    setWriteType(result.type === 0 ? 'surveyQrcodeCreate' : 'surveyQrcodeUpdate');
  }
  return (
    <div id="PatientNotifyDetail">
      <MobileHeader />

      {state.title ? (
        <Fragment>
          <div className="titleArea">
            <p className="titleNotify">{state.title}</p>
            <p>
              {state.sysMsgType === 1 && <MobileTag text={'复诊通知'} />}
              {state.sysMsgType === 2 && <MobileTag text={'随访通知'} />}
              {state.sysMsgType === 3 && <MobileTag text={'随访问卷'} />}
            </p>
            <p>
              发送人：
              <span className={'sendName'}>
                {`${state.sendUserInfo.name}（${state.sendUserInfo.department}｜${state.sendUserInfo.title}）`}
              </span>
            </p>
            {state.sendTime && <p>发送时间：{moment(state.sendTime).format('YYYY-MM-DD HH:mm')}</p>}

            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {state.sysMsgType !== 3 && state.treatmentTime && (
              <p>
                {messageType[state.sysMsgType].name.slice(0, 2)}时间：
                {moment(state.treatmentTime).format('YYYY-MM-DD HH:mm')}
              </p>
            )}

            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {state.result && <img className="operationStatus" src={statusImg[state.result]} />}
            {//  tslint:disable-next-line:jsx-no-multiline-js
            state.sysMsgType === 3 && state.buttonStatus === 3 && (
              <img className="operationStatus" src={require('./../../../assets/img/img_finish.svg')} />
            )}
          </div>
          <div className="contentArea">{state.content}</div>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {state.sysMsgType === 3 && state.buttonStatus !== 3 && (
            <div className="buttonArea" style={{ textAlign: 'center' }}>
              <Button
                className="accept"
                onClick={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () =>
                    props.history.push(
                      `/GaugeSurvey?code=${code}&writeType=${writeType}&roleAndUrl=${encodeURI(
                        ['Patient', 'PatientNotifyList', state.sendUserInfo.id, state.sendUserInfo.expertName].join()
                      )}`
                    )
                }
              >
                {state.buttonStatus === 2 ? '继续填写' : '填写问卷'}
              </Button>
            </div>
          )}
          {!state.result && state.sysMsgType !== 3 && (
            <div className="buttonArea">
              <Button className="refuse" onClick={() => setIsShowModal(true)}>
                拒绝
              </Button>
              <Button className="accept" style={{ float: 'right' }} onClick={() => changeStatus(1)}>
                接受
              </Button>
              <Modal
                className="refuseModal patientNotifyModal"
                visible={isShowModal}
                transparent={true}
                maskClosable={false}
                // tslint:disable-next-line: jsx-no-multiline-js
                footer={[
                  {
                    text: '残忍拒绝',
                    onPress: () => changeStatus(2),
                  },
                  {
                    text: '我再想想',
                    onPress: () => setIsShowModal(false),
                  },
                ]}
              >
                {/* tslint:disable-next-line:jsx-no-multiline-js */}
                <div className={'tipName'}>{`确定拒绝  ${state.sendUserInfo.name}的${messageType[
                  state.sysMsgType
                ].name.slice(0, 2)}通知  吗？`}</div>
              </Modal>
            </div>
          )}
        </Fragment>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spin tip="正在加载请稍等..." />
        </div>
      )}
    </div>
  );
  async function getNotifyDetail(id: string) {
    const { result } = await WeChatPatient.getNotifyDetail({ id });
    setState({ ...state, ...result });
    setCode(result.answerCode);
    if (result.status === 1) {
      setReaded(props.match.params.notifyId);
    }
    if (result.sysMsgType === 3 && result.answerCode && result.buttonStatus !== 3) {
      getType(result.answerCode);
    }
  }
  function setReaded(id: string) {
    WeChatPatient.setReaded({ id });
  }
  function back() {
    props.history.goBack();
  }
  async function changeStatus(num: number) {
    const result = await WeChatPatient.changeStatus({ id: props.match.params.notifyId, result: num });
    if (result.code) {
      message.error(result.message);
    } else {
      back();
    }
  }
};
