import oss from 'ali-oss';
import { Carousel, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'dva';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IconFont } from '../../../config/IconConfig';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import { ISurvey } from './../../../Model/SurveyModel';
import './FormUploadImg.scss';
const isPc = window.screen.width > 500;
/**
 * 上传图片
 * 在subjectData里面type为FormUploadImg
 */
function FormUploadImg(props: ISurveyHook) {
  const ossInfo = useSelector(({ survey }: { survey: ISurvey }) => survey.ossInfo);
  // tslint:disable-next-line: no-any
  let slider: any;
  // tslint:disable-next-line: no-any
  let fileInput: any;
  const formObj = props.formObj.subjectData;
  // const { isShow } = useFormActions(props);
  const [previewVisible, setpreviewVisible] = useState(false); // 预览窗口是否可见
  const [defaultValue, setDefaultValue] = useState(props.formObj.subjectData.defaultValue || []);
  const [chooseIndex, setChooseIndex] = useState(0); // 预览图片的下标
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: defaultValue,
      },
    });
  }, [defaultValue]);
  useEffect(() => {
    if (previewVisible) {
      if (isPc) {
        setTimeout(() => {
          slider.slick.innerSlider.slickGoTo(chooseIndex, true);
        }, 8);
      } else {
        setTimeout(() => {
          document.getElementById(`scrollArea${props.formObj.subjectData.field}`)!.scrollLeft = chooseIndex * 285;
        }, 8);
      }
    }
  }, [previewVisible]);
  // 上传至oss
  const UploadToOss = async (targetFiles: FileList | null) => {
    // tslint:disable-next-line: no-any
    let imageUrls = [] as any[];
    // tslint:disable-next-line: no-any
    const clientOss: any = new oss({
      accessKeyId: ossInfo.accessKeyId!,
      accessKeySecret: ossInfo.accessKeySecret!,
      region: 'oss-cn-shanghai', //
      bucket: ossInfo.bucket!, //
      stsToken: ossInfo.securityToken!,
    });
    if (targetFiles) {
      imageUrls = await Promise.all(
        Array.from(targetFiles).map(async item => {
          const isLegal = item.type === 'image/jpeg' || item.type === 'image/png';
          if (!isLegal) {
            message.error('请上传JPG/PNG图片!');
            return false;
          }
          return await clientOss.put(
            `hulk_user_upload_images/${moment().format('YYYYMMDD')}/${item.name.split('.')[0]}-${item.lastModified}.${
              item.type.split('/')[1]
            }`,
            item
          );
        })
      );
      return imageUrls;
    }
  };
  async function inputChange(info: React.ChangeEvent<HTMLInputElement>) {
    const result = await UploadToOss(info.currentTarget.files);
    if (result) {
      const imgUrls = result.map(item => item.url);
      setDefaultValue([...defaultValue, ...imgUrls]);
    }
  }
  function deleImg(i: number) {
    props.formObj.subjectData.defaultValue.splice(i, 1);
    setDefaultValue([...props.formObj.subjectData.defaultValue]);
  }
  function preview(index: number) {
    setChooseIndex(index);
    setpreviewVisible(true);
  }
  function handleCancel() {
    setpreviewVisible(false);
  }
  function prev() {
    slider.slick.slickNext();
  }
  function next() {
    slider.slick.slickPrev();
  }
  function upload() {
    fileInput.click();
  }
  return (
    <div id="FormUploadImg">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
              className={formObj.errMsg ? 'cl star' : 'star'}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>

        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
      <div className="clearfix">
        <AuthCompount isShow={!isPc}>
          <p className="tips">支持jpg/png格式；图片大小建议不超过2M</p>
        </AuthCompount>
        <div className="uploadArea">
          <div className="upload" onClick={upload}>
            <IconFont className="pc_icon_add" type="iconicon_add1" />
            <p>点击添加图片</p>
            <AuthCompount isShow={isPc}>
              <p className="tips">支持jpg/png格式</p>
              <p className="tips">图片大小建议不超过2M</p>
            </AuthCompount>
            <input
              type="file"
              multiple={true}
              name="file"
              id="file"
              ref={el => (fileInput = el)}
              onChange={inputChange}
            />
          </div>
          {// tslint:disable-next-line: jsx-no-multiline-js
          defaultValue.map((item: string, i: number) => (
            <div key={i}>
              <IconFont className="icon_close" type="iconicon_close2" onClick={() => deleImg(i)} />
              <img src={item} alt="" onClick={() => preview(i)} />
              <p className="tips_hover">查看大图</p>
            </div>
          ))}
        </div>

        <Modal visible={previewVisible} footer={null} onCancel={handleCancel} className="previewModal">
          <AuthCompount isShow={isPc}>
            <Carousel ref={el => (slider = el)} className="image_swiper">
              {// tslint:disable-next-line: jsx-no-multiline-js
              defaultValue.map((item: string, i: number) => (
                <div key={i}>
                  <img alt="example" style={{ width: '100%' }} src={item} />
                </div>
              ))}
            </Carousel>
            <img
              src={require('../../../assets/img/pc_icon_left_normal.svg')}
              alt=""
              className="FormUploadImg_prev"
              onClick={prev}
            />
            <img
              src={require('../../../assets/img/pc_icon_right_normal.svg')}
              alt=""
              className="FormUploadImg_next"
              onClick={next}
            />
          </AuthCompount>
          <AuthCompount isShow={!isPc}>
            <div className="imgContainer">
              <img
                className="icon_close closeModal"
                src={require('../.././../assets/img/icon_close_popup.svg')}
                onClick={() => setpreviewVisible(false)}
              />
              <div className="scrollArea" id={`scrollArea${props.formObj.subjectData.field}`}>
                {// tslint:disable-next-line: jsx-no-multiline-js
                defaultValue.map((item: string, i: number) => (
                  <div key={i}>
                    <img alt="example" style={{ width: '100%' }} src={item} />
                  </div>
                ))}
              </div>
            </div>
          </AuthCompount>
        </Modal>
      </div>
    </div>
  );
}
export default FormUploadImg;
