import { List } from 'antd-mobile';
import { Link, RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WxDoctorApi, { IMyPatientReq, IPatientItem } from '../../../api/WxDoctorApi';
import { MobileHeader, MobileNoDatas, MobileSearch } from '../../../components';
import { MobilePagination, UpdateTitle } from '../../../utils';
// import { useMobliePagination } from '../../../utils/UtilHook';
import './MyPatient.scss';
const Item = List.Item;
interface IMyPatientProps extends RouteComponentProps {
  ID?: string;
}

export function MyPatient(props: IMyPatientProps) {
  const [searchKey, setSearchKey] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [patients, setPatient] = useState([] as IPatientItem[]);
  let listPagination = { pageSize: 10, total: 0, current: 1 };
  // tslint:disable-next-line:no-any
  let patientsData: any[] | never[] = [];

  useEffect(() => {
    UpdateTitle('我的患者');
    window.addEventListener('scroll', scrollE, true);
    searchClick();
    return () => {
      window.removeEventListener('scroll', scrollE, true);
    };
  }, []);

  /**
   * 搜索的暂时无数据不同
   */
  useEffect(() => {
    if (!searchKey) {
      // setNoMsg({ imgurl: '', msg: '' });
    }
  }, [searchKey]);

  /**
   * 分页
   */
  function scrollE() {
    MobilePagination('MyPatient', listPagination.current, listPagination.pageSize, listPagination.total).then(
      pageNum => {
        if (pageNum) {
          listPagination.current = pageNum;
          searchClick();
        }
      }
    );
  }

  /**
   * 病例搜索
   */
  async function searchClick() {
    const searchReq: IMyPatientReq = {
      pageNum: listPagination.current,
      pageSize: listPagination.pageSize,
      name: searchKey,
    };
    const { result } = await WxDoctorApi.getMyPatients(searchReq);
    if (result) {
      listPagination = { current: result.current, total: result.total, pageSize: result.size };
    }
    const data = [...patientsData, ...result.records];
    setPatient(data);
    patientsData = data;
    if (searchKey) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }

  const patientItems =
    patients &&
    patients.map((e: IPatientItem, index: number) => {
      return (
        <Item key={index}>
          <Link to={`/Doctor/PatientMsg/${e.id}`}>
            <div className="itemContainer">
              <div className="imgRow">
                <img src={e.headimgurl} className="imgPortrait" />
                <span className="name"> {e.name} </span>
                <span className="sex"> {e.gender ? '男' : '女'} </span> <span>{e.age} 岁 </span>
              </div>
              <div className="timeRow">绑定时间: {moment(e.attentionTime).format('YYYY-MM-DD')} </div>
            </div>
            <div className="codeContainer">
              {// tslint:disable-next-line:jsx-no-multiline-js
              e.sampleSequence ? (
                <div className="codeRow">
                  就诊编号: <span className="number"> {e.sampleSequence}</span>
                </div>
              ) : (
                <div className="codeRow">未填写就诊编号</div>
              )}
              <img src={require('../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
            </div>
          </Link>
        </Item>
      );
    });
  return (
    <div className="MyPatient" id={'MyPatient'}>
      <MobileHeader backUrl={'/Doctor/DoctorInfo'} />
      <div className={'search'}>
        <MobileSearch
          placeholder={'搜索患者姓名或者就诊编号'}
          searchText={'搜索'}
          onChange={setSearchKey}
          onSearch={searchClick}
        />
      </div>

      <div className="listContainer" id="listContainerId">
        <List className="patientList"> {patientItems} </List>
      </div>
      {// tslint:disable-next-line:jsx-no-multiline-js
      !patients.length && isSearch && (
        <MobileNoDatas imgurl="img_search_null.svg" msg="没有更多的搜索结果" show={true} />
      )}
      {// tslint:disable-next-line:jsx-no-multiline-js
      !patients.length && !isSearch && <MobileNoDatas imgurl="img_patient_null.svg" msg="暂无患者" show={true} />}
    </div>
  );
}
