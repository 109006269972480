// import { Input } from "antd";
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { useDispatch } from 'dva';
import moment from 'moment';
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormSectionsDate.scss';

const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 日期范围选择
 * 在subjectData里面type为FormSectionsDate
 * 具体用法参照 ant design
 */
function FormSectionsDate(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  const [val, setVal] = useState(props.formObj.subjectData.defaultValue);
  const dispatch = useDispatch();
  function inputChange(dates: RangePickerValue, dateStrings: [string, string]) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: dateStrings[0] ? dateStrings : [],
      },
    });
    setVal(dateStrings[0] ? dateStrings : []);
  }
  return (
    <div id="FormSectionsDate">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.errMsg ? 'cl star' : 'star'}
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <DatePicker.RangePicker
          className={`form_input`}
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={
            formObj.defaultValue
              ? [moment(formObj.defaultValue[0], 'YYYY-MM-DD'), moment(formObj.defaultValue[1], 'YYYY-MM-DD')]
              : []
          }
          value={val ? [moment(val[0], 'YYYY-MM-DD'), moment(val[1], 'YYYY-MM-DD')] : []}
          onChange={inputChange}
          getCalendarContainer={() => document.getElementById('scrl_box')!}
        />
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormSectionsDate);
