import { FormComponentProps } from 'antd/lib/form';
import { SubscriptionAPI } from 'dva';
import { IAnony, IDiseaseItem } from '../../../api/DiseaseManage';
import { IChartProps } from '../../../components/Charts/Charts';
import { IGlobal } from '../../../Model/GlobalModel';
// const isMobile = window.screen.width < 500;
export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
  pages?: number;
}

export interface IProps extends FormComponentProps, SubscriptionAPI {
  global: IGlobal;
}

export const CurvedData = [
  {
    item: '1991',
    count: 3,
  },
  {
    item: '1992',
    count: 4,
  },
  {
    item: '1993',
    count: 3.5,
  },
  {
    item: '1994',
    count: 5,
  },
  {
    item: '1995',
    count: 4.9,
  },
  {
    item: '1996',
    count: 6,
  },
  {
    item: '1997',
    count: 7,
  },
  {
    item: '1998',
    count: 9,
  },
  {
    item: '1999',
    count: 13,
  },
];
/**
 * 重构后的 数据结构定义
 */

export interface IDisPreviewProps extends FormComponentProps, SubscriptionAPI {
  // tslint:disable-next-line:no-any
  survey: any;
  global: IGlobal;
}

export interface IDisPreviewState {
  pagination: IPagination;
  onloading: boolean;
  // listMap: Map<number, IListItem[]>; // 缓存已经请求的数据
  listItem: IDiseaseItem[];
  statisticMsg?: IChartProps[];
  anonyResult?: IAnony; // 存放匿名问卷
  // listType: number; // 1 是普通列表 2 卡片列表
  mobileShowEfollow: boolean;
  qrIconActive: boolean;
  efollowActive: boolean;
}

export const initState: IDisPreviewState = {
  pagination: { total: 0, pageSize: 10, current: 1 },
  onloading: false,
  // listMap: new Map(),
  listItem: [] as IDiseaseItem[],
  mobileShowEfollow: false,
  qrIconActive: false,
  efollowActive: false,
};

export const searchMsg = [
  { title: '病例编号', code: 'customCode', type: 'Input' },
  { title: '就诊序号', code: 'code', type: 'Input' },
  { title: '访视次数', code: 'visitNumber', type: 'InputNumber' },
  { title: '病例名称', code: 'name', type: 'Input' },
];

export interface IListItem {
  customCode: string;
  id: number;
  lastVisitTime: string;
  name: string;
  visitNumber: number;
  status: number;
  expertName: string;
  code: string;
  maxVisitNumber: number;
  showVisitNumber: number;
  sampleSequence: string;
}
export interface IState {
  data: IListResult;
  pagination: IPagination;
  filter: IListParam;
  anonyResult: IAnony;
  onloading: boolean;
  search: boolean;
  continue: boolean;
  statisticMsg?: IChartProps[];
  // qrIconActive: boolean;
}

export interface IListParam {
  code?: string; // 系统编号
  pageNum: number;
  pageSize: number;
  customCode?: string; // 患者编号
  visitNumber: number; // 访视次数
}
export interface IListResult {
  records: IListItem[];
  current: number;
  pages: number;
  size: number;
  total: number;
}
