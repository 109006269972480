import { Button, Input, message, Modal, Tooltip } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { SignInApi } from '../../../api';
import { IconFont } from '../../../config/IconConfig';
import LayoutComponent from '../../../layout/LayoutComponent';
import { useModalVisible, useWindowUpdateWidth } from '../../../utils/UtilHook';
import './UpdatePsd.scss';
const psdRep = /[0-9A-z]{8,20}/;
let lastsecond = 5;
const isMobile = window.screen.width < 750;
interface IUpdatePsdProps extends FormComponentProps, RouteComponentProps<{ name: string; code: string }> {}
function UpdatePsdPage(props: IUpdatePsdProps) {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [pswUpdateSuc, setPswUpdateSuc] = useState(false); // 密码更新状态
  const [discSecon, setDiscSecon] = useState(lastsecond); // 计时
  const [oldPsdAvi, setOldPsdAvi] = useState(true); // 原始密码错误
  // const [setConfirmPsdAvi] = useState(true); // 确认密码状态
  const [confirmBtnAvi, setConfirmBtnAvi] = useState(false);
  const { visible, colseModal, setVisible } = useModalVisible(false);
  useEffect(() => {
    if (pswUpdateSuc) {
      const siv = setInterval(() => {
        lastsecond--;
        setDiscSecon(lastsecond);
        if (lastsecond === 0) {
          props.history.push(`/SignIn/${props.match.params.code}`);
          clearInterval(siv);
        }
      }, 1000);
    }
  }, [pswUpdateSuc]);
  if (pswUpdateSuc) {
    return (
      <div className="UpdatePsdSuccess">
        <IconFont className="successIcon" type="iconpc_icon_finish" />
        <div className="suctitle"> 密码修改成功 </div>
        <div className="switchDis"> {`${discSecon}s后自动返回「登录」页面，请重新登录…`}</div>
      </div>
    );
  }

  /**
   * 点击确认成功后
   */
  async function updatepsdConfirm() {
    const password = getFieldValue('oldPsd');
    const newPassword = getFieldValue('newPsd');
    const confirmPsd = getFieldValue('newPsdConfirm');
    const availablePsd = psdRep.test(newPassword);

    if (!availablePsd) {
      // 密码格式错误
      message.error('密码格式错误');
      return;
    }
    if (newPassword !== confirmPsd) {
      // 确认密码错误
      message.error('确认密码与新密码不一致');
      // setConfirmPsdAvi(false);
      return;
    }
    const { result } = await SignInApi.updatePassword({ newPassword, password });

    if (result === 2) {
      setOldPsdAvi(false);
      return;
    }
    props.history.replace(`/PsdUpdateSuc/${props.match.params.code}`);
    setPswUpdateSuc(true);
  }
  /**
   * 校验元素
   */
  async function valiadatePsd() {
    //
    const password = getFieldValue('oldPsd');
    const newPassword = getFieldValue('newPsd');
    const confirmPsd = getFieldValue('newPsdConfirm');

    if (password && newPassword && confirmPsd) {
      setConfirmBtnAvi(true);
    }
  }

  /**
   * 手机
   */
  if (isMobile) {
    return (
      <div className="mobieContainer">
        亲爱的<span className="nameTitle">{props.match.params.name}</span>医生，您正在修改密码
        <div className="changeDis"> 修改完成后需重新登录系统 </div>
        <div className="orginalTitle">
          <div>原始密码</div>
          <div className="errorMsg" hidden={oldPsdAvi}>
            原始密码错误
          </div>
        </div>
        <Form.Item colon={false}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('oldPsd', {
            rules: [{ required: false, message: '点击输入' }],
          })(
            <Input.Password
              placeholder="原始密码"
              // tslint:disable-next-line:jsx-no-multiline-js
              onFocus={() => {
                setOldPsdAvi(true);
              }}
              onBlur={valiadatePsd}
            />
          )}
        </Form.Item>
        <div
          className="forgetpsd"
          // tslint:disable-next-line:jsx-no-multiline-js
        >
          <span
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              setVisible(true);
            }}
          >
            忘记原始密码
          </span>
        </div>
        <div className="newTitle">新密码</div>
        <Form.Item colon={false}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('newPsd', {
            rules: [
              {
                required: false,
                message: (
                  <div>
                    <div>需包含英文大写/小写/数字/标点符号至少两种</div> <div> 密码长度需要 8~20 位 </div>
                  </div>
                ),
                pattern: /[0-9A-z]{8,20}/,
              },
            ],
            //   validateTrigger: 'falsy',
          })(<Input.Password placeholder="新密码(密码长度8 ~ 20位)" className="" onBlur={valiadatePsd} />)}
        </Form.Item>
        <div className="confirmTitle"> 确认新密码 </div>
        <Form.Item colon={false}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('newPsdConfirm', {
            rules: [
              {
                required: true,
                message: '请输入确认密码',
              },
            ],
          })(
            <Input.Password
              placeholder="确认新密码"
              // tslint:disable-next-line:jsx-no-multiline-js
              onChange={valiadatePsd}
            />
          )}
        </Form.Item>
        <div className="btnContainer">
          <Button className="confirmbtn" onClick={updatepsdConfirm} disabled={!confirmBtnAvi}>
            确定
          </Button>
          <Button onClick={props.history.goBack} className="returnBtn">
            返回
          </Button>
        </div>
        <Modal
          visible={visible}
          onCancel={colseModal}
          width={useWindowUpdateWidth(540)}
          footer={undefined}
          className="normalModalCmp"
          centered={true}
          closable={false}
        >
          <div className="container">
            <div className="titlessModal"> 请联系我们 </div>
            <div className="phoneNum"> ‭(021) 6167 6990‬</div>
            <div
              className="konwBtn"
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                setVisible(false);
              }}
            >
              我知道了
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="updatePsdContainer">
      <LayoutComponent isBack={true} name="修改密码">
        <div className="updatePsdContent">
          <div className="UpdatePsd">
            <div className="headerContainer">
              <div className="headerTitle">
                亲爱的<span className="nameTitle">{props.match.params.name}</span>医生，您正在修改密码
              </div>
              <div className="headerDis"> {`修改完成后需重新登录系统`} </div>
            </div>
            <div className="stepContainer">
              <div className="stepFirstContainer">
                <div className="stepIconContainer">
                  <div className="stepIcon">1</div> <div className="stepLine" />
                </div>
                <div className="stepMsgContainer">
                  <div className="steptitle">校验身份 </div>
                  <div className="psdTitleErr">
                    <div className="psdTitle"> 原始密码 </div>
                    <div className="errorMsg" hidden={oldPsdAvi}>
                      原始密码输入错误
                    </div>
                  </div>
                  <Form.Item colon={false}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                    {getFieldDecorator('oldPsd', {
                      rules: [{ required: true, message: '点击输入' }],
                    })(
                      <Input.Password
                        placeholder="原始密码"
                        onBlur={valiadatePsd}
                        onFocus={
                          // tslint:disable-next-line:jsx-no-multiline-js
                          () => {
                            setOldPsdAvi(true);
                          }
                        }
                      />
                    )}
                  </Form.Item>
                  <div className="forgetPsd">
                    <Tooltip placement="bottomLeft" title={'请联系我们：400-123543'}>
                      忘记原始密码?
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="stepFirstContainer">
                <div className="stepIconContainer">
                  <div className={getFieldValue('oldPsd') ? 'stepIcon' : 'stepIconDisable'}>2</div>
                </div>
                <div className="stepMsgContainer">
                  <div className="steptitle">重设密码</div>
                  <div className="psdTitle"> 新密码 </div>
                  <Form.Item colon={false}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                    {getFieldDecorator('newPsd', {
                      rules: [
                        {
                          required: true,
                          message: (
                            <div>
                              <div>需包含英文大写/小写/数字/标点符号至少两种</div> <div> 密码长度需要 8~20 位 </div>
                            </div>
                          ),
                          pattern: /[0-9A-z]{8,20}/,
                        },
                      ],
                      //   validateTrigger: 'falsy',
                    })(<Input.Password placeholder="新密码(密码长度8 ~ 20位)" className="" onBlur={valiadatePsd} />)}
                  </Form.Item>
                  <div className="psdTitle">确认新密码 </div>
                  <Form.Item colon={false}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                    {getFieldDecorator('newPsdConfirm', {
                      rules: [
                        {
                          required: true,
                          message: '请输入确认密码',
                        },
                      ],
                    })(
                      <Input.Password
                        placeholder="确认新密码"
                        // tslint:disable-next-line:jsx-no-multiline-js
                        onChange={valiadatePsd}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="btnContainer">
              <Button className="confirmbtn" onClick={updatepsdConfirm} disabled={!confirmBtnAvi}>
                确认
              </Button>
              <div
                className="returnBtn"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  props.history.goBack();
                }}
              >
                返回
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
}

const UpdatePsd = Form.create()(UpdatePsdPage);
export default UpdatePsd;
