import React from 'react';

// const filePath = window.screen.width > 500 ? "img" : "moblie-img";

class TEST001 extends React.Component<{}> {
  public render() {
    return (
      <div id="TEST001">
        <div className="explain_box">
          <h1 className="firstCode">一、量表系统简介：</h1>
          <div className="thirdCode">
            本量表系统用来登记本院就诊的脊柱关节炎患者。医生将通过本系统记录患者初诊以及每次复诊或随访的动态信息，包括患者的基本信息、临床症状、实验室检查结果、影像学检查结果、用药情况、疾病活动程度评价以及患者报告结果，医生将持续收集上述信息直至患者终止在本院就诊。
          </div>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">二、填写指南：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span>
                本量表系统内所登记的信息需要由专业医生或医生助理进行收集，其中“患者报告结果”部分相关问卷由医生指导患者完成。
              </span>
            </li>
            <li>
              <span>
                本量表系统内所登记的信息主要包括：人口学信息、一般病史、体格检查、生命体征、诊断建议、实验室检查、疾病活动程度评价、患者报告结果、目前治疗等。
              </span>
            </li>
            <li>
              <span>
                在为患者首次填写该问卷时需要登记患者的“人口学信息”和“一般病史”（该部分内容仅出现在患者首次登记时，为必填项目），系统将为患者进行编号，该编号为患者的唯一识别信息。
              </span>
            </li>
            <li>
              <span>
                除“人口学信息”和“一般病史”外，其它部分内容在患者每一次纪录时均会出现（为非必填项目），将由医生根据信息掌握情况选择填写。
              </span>
            </li>
            <li>
              <span>请注意准确填写问卷信息，仔细查看问题内容，问题涉及数值单位时，请根据问题标注的单位填写。</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default TEST001;
