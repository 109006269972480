import { Button } from 'antd-mobile';
import { connect } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect } from 'react';

import Config from '../../../config/Config';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import './PatientNew.scss';
interface IProps extends RouteComponentProps {
  global: IGlobal;
}
export default connect(({ global: { wechatPatientInfo } }: { global: IGlobal }) => ({
  wechatPatientInfo,
}))((props: IProps) => {
  useEffect(() => {
    UpdateTitle('E-Follow');
  }, []);
  return (
    <div id="Patient-PatientNew">
      <p className="titlePatient">欢迎使用E-Follow系统</p>
      <p className="introduction">
        E-Follow系统由上海基诺联生物科技有限公司开发，旨在使患者能及时收到医生的随访相关信息，提高患者的就医满意度，最终让患者获得高质量的随访。{' '}
      </p>
      <img src={require(`./../../../assets/img/img_welcome-_bg_patient.png`)} className="welcomeImg" />
      <Button
        className="patientInfo"
        onClick={() => window.location.replace(`${Config.domainUrl}/#/Patient/PatientInfo`)}
        type="primary"
      >
        填写个人信息
      </Button>
    </div>
  );
});
