import React from 'react';

// const filePath = window.screen.width > 500 ? "img" : "moblie-img";

class SHRJ20190001 extends React.Component<{}> {
  public render() {
    return (
      <div id="SHRJ20190001">
        <div className="explain_box">
          <h1 className="firstCode">一、 项目介绍</h1>
          <h4> 1. 项目简介</h4>
          <div className="thirdCode">
            该项目由仁济医院风湿科发起，为了进行特发性炎性肌病的研究。风湿科医生或研究生通过本量表系统记录患者人口学信息、一般病史、体格检查、生命体征、诊断建议、实验室检查、疾病活动程度评价、患者报告结果、目前治疗等信息，以备后续科研项目进行需要的数据收集工作。我们将继续收集上述患者信息直至项目研究结束或患者永久性退出。
          </div>
          <h4>2. 纳入标准</h4>
          <div className="thirdCode">
            患者必须为确诊为特发性炎性肌病的患者，符合诊断条件；
            <br />
            患者能理解并完成问卷患者填写部分题目。
          </div>
          <h4>3. 随访时间</h4>
          <div className="thirdCode">
            项目随访持续时间为1年。患者在纳入项目后，医生或患者需按照项目要求及时填写相应的访视问卷。
          </div>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">二、填写指南：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span>
                本量表系统内所记录的信息主要包括：人口学信息、一般病史、体格检查、生命体征、诊断建议、实验室检查、疾病活动程度评价、患者报告结果、目前治疗等。
              </span>
            </li>
            <li>
              <span>
                病例在系统内首次创建后，系统将为患者进行编号，该编号为患者的唯一识别信息。根据此编号可在系统内查询患者病例信息。
              </span>
            </li>
            <li>
              <span>
                请注意准确填写问卷信息，仔细查看问题内容及说明。问题涉及数值单位时，请根据问题标注的单位填写。
              </span>
            </li>
            <li>
              <span> 问卷医生填写部分，需要由专业医生进行收集填写。</span>
            </li>
            <li>
              <span> 问卷患者填写部分，需要医生协助患者在能理解题目的基础上完成填写。</span>
            </li>
          </ul>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">三、 注意事项：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span> 我们会严格保守患者任何个人信息秘密。</span>
            </li>
            <li>
              <span>医生必须确保填写内容正确无误后提交。</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default SHRJ20190001;
