import { Button, Col, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import Parser from '../../utils/Parser';

export default function Test() {
  const [str, setStr] = useState('{{a}}*{{b}}');
  const [objStr, setObjStr] = useState('{a: 1,b: 2}');
  const [result, setResult] = useState('');
  /*
   *  提取输入的公式和对象，将字符串对象解析为对象，传入Parser 运行，修改返回或alert
   * */
  function test() {
    const obj = objStr
      .slice(1, -1)
      .split(',')
      .map(val => val.split(':'))
      .reduce((total, [key, val]) => Object.assign(total, { [key]: val }), {});
    try {
      // tslint:disable-next-line:no-eval
      setResult(eval(Parser(str, obj)) + '');
    } catch (e) {
      setResult('输入错误');
      alert('输入错误');
    }
  }

  return (
    <div>
      <Row>
        <Col span={8}>
          <TextArea
            allowClear={true}
            autoSize={{ minRows: 16 }}
            value={str}
            onChange={value => setStr(value.target.value)}
          />
        </Col>
        <Col span={8}>
          <TextArea
            allowClear={true}
            autoSize={{ minRows: 16 }}
            value={objStr}
            onChange={value => setObjStr(value.target.value)}
          />
        </Col>
        <Col span={8}>
          <Button type={'primary'} onClick={test}>
            运行
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <h1 style={{ textAlign: 'center', marginTop: '24px' }}>结果：{result}</h1>
        </Col>
      </Row>
    </div>
  );
}
