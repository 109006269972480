import { Dropdown, Icon, Menu } from 'antd';
import { connect } from 'dva';
import { Link } from 'dva/router';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Foot from '../components/Foot/Foot';
import AuthCompount from './../components/AuthCompount/AuthCompount';
import { IGlobal } from './../Model/GlobalModel';
import './LayoutComponent.scss';
interface IProps extends RouteComponentProps {
  isBack: boolean;
  name?: string;
  background?: string;
  global?: IGlobal;
  // tslint:disable-next-line:no-any
  dispatch?: any;
  isQrcode?: boolean;
}
const isPc = window.screen.width > 500;

class LayoutComponent extends React.Component<IProps> {
  public render() {
    if (!this.props.global) {
      return <div />;
    }
    return (
      <div id="LayoutComponent" className="layoutContainer">
        <AuthCompount isShow={!this.props.isQrcode}>
          <div className="header">
            <div className="headerLeft">
              <AuthCompount isShow={this.props.isBack}>
                <div className="back" onClick={this.back}>
                  返回
                </div>
                <AuthCompount isShow={!isPc}>
                  <div className="mb_name">
                    <Icon type="left" className="mobile-back" onClick={this.back} />
                    <div>{this.props.name}</div>
                  </div>
                </AuthCompount>
              </AuthCompount>
            </div>
            <AuthCompount isShow={isPc}>
              <div className="header_name">{this.props.name}</div>
            </AuthCompount>
            <div className="userName-dropdown headerRight">
              <Dropdown overlay={this.menuRender()} trigger={['click']} className="headerDrop">
                <div className="ant-dropdown-link">
                  {this.props.global.userName} <Icon type="down" />
                </div>
              </Dropdown>
            </div>
          </div>
        </AuthCompount>
        <div
          // tslint:disable-next-line:jsx-no-multiline-js
          className={this.props.background === 'gray' ? 'layoutContent' : 'white layoutContent'}
        >
          {this.props.children}
        </div>
        <Foot />
      </div>
    );
  }
  private back = () => {
    this.props.history!.goBack();
  };
  private menuRender = () => {
    if (!this.props.global) {
      return <div />;
    }
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to={`/SignIn/${this.props.global.companyInfo.code}`}>退出登录</Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to={`/UpdatePsd/${this.props.global.userName}/${this.props.global.companyInfo.code}`}>修改密码</Link>
        </Menu.Item>
      </Menu>
    );
  };
}
// tslint:disable-next-line:no-any
export default withRouter(connect((global: any) => global)(LayoutComponent));
