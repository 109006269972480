import { RouteComponentProps, withRouter } from 'dva/router';
import React from 'react';
import './MobileHeader.scss';
interface IMobileHeaderProps extends RouteComponentProps {
  title?: string; // 中间标题？？
  backUrl?: string;
  children?: React.ReactNode;
}
// 手机端，顶部返回组件

export default withRouter(function MobileHeader(props: IMobileHeaderProps) {
  function back() {
    if (props.backUrl) {
      props.history.push(props.backUrl);
    } else {
      props.history.goBack();
    }
  }
  return (
    <div className="mobileHeader">
      <div className="leftContiner">
        <span onClick={back}>
          <img src={require('../../assets/moblieImg/btn_back.svg')} className="iconBack" />
          返回
        </span>
      </div>
      <div className="titleContainer">{props.title || ''} </div>
      <div className="rigthtContiner">{props.children}</div>
    </div>
  );
});
