import { connect } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect } from 'react';
// import Config from '../../../config/Config';
import { IGlobal } from '../../../Model/GlobalModel';
import { UpdateTitle } from '../../../utils';
import './DocStartCert.scss';

interface IDoctorCertProps extends RouteComponentProps {
  ID?: string;
  global: IGlobal;
  // tslint:disable-next-line:no-any
  dispatch: any;
}
export default connect(({ global: { wechatPatientInfo } }: { global: IGlobal }) => ({
  wechatPatientInfo,
}))((props: IDoctorCertProps) => {
  useEffect(() => {
    UpdateTitle('E-Follow');
    // setTimeout(() => {
    // if (props.global && props.global.hulkUserInfoBackLoginVo.token) {
    //   window.location.replace(`${Config.domainUrl}/#/Doctor/DoctorInfo`);
    // } else {
    // if (window.location.href.includes('code=')) {
    //   window.location.replace(`${Config.domainUrl}/#/Doctor/Certificate`);
    // }
    // }
    // }, 300);
  }, []);
  /**
   * 点击开始认证
   */
  function cetificateOnClick() {
    // window.location.href = `${Config.domainUrl}/#/Doctor/CetificationMsg`;
    props.history.replace('/Doctor/CetificationMsg');
  }
  return (
    <div className="DocStartCert">
      <div className="container">
        <div className="headerTitle"> 经过认证的医生才能使用E-Follow </div>
        <div className="contactUs">联系我们：400 231 232</div>
      </div>
      <img src={require('../../../assets/moblieImg/img_welcome_bg@2x.png')} className="welcomeImg" />
      <div className="container">
        <div className="cetificationAction" onClick={cetificateOnClick}>
          开始认证
        </div>
      </div>
    </div>
  );
});
