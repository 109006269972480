import { message, Modal } from 'antd';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { QRActionApi } from '../../api';
import { IconFont } from '../../config/IconConfig';
import { useModalVisible, useWindowUpdateWidth } from '../../utils/UtilHook';
import './QRModalCmp.scss';
interface IModalCmpProps {
  visible: boolean;
  title?: string;
  closable?: boolean;
  children?: React.ReactElement;
  footerView?: React.ReactElement;
  colseModal?: () => void;
  okModal?: () => void;
}

interface IImgModalCmp {
  url?: string;
  visible: boolean;
  discription: string;
  callback: () => void;
}

interface IQRImgeProps {
  url: string;
  width: number;
}
interface IMobileQrProps {
  url: string;
  width: number;
  visible: boolean;
  discription: string;
  callback: () => void;
}
export function ScanImgModalCmp(props: IModalCmpProps) {
  const { visible, colseModal, setVisible } = useModalVisible(props.visible);
  const [qRUrl, setQRUrl] = useState();
  const qrWidth = useWindowUpdateWidth(400);
  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      getImg();
    }
  }, [props.visible]);

  useEffect(() => {
    if (visible && props.okModal) {
      props.okModal();
    }
    if (!visible && props.colseModal) {
      props.colseModal();
    }
  }, [visible]);

  /**
   * 获取 QRImg
   */

  async function getImg() {
    const { result } = await QRActionApi.getDoctorQRImgUrl();
    setQRUrl(result);
  }

  const footer = <div className="scan_footer" />;
  const modal = (
    <Modal
      visible={visible}
      onCancel={colseModal}
      width={useWindowUpdateWidth(690)}
      footer={footer}
      className="searchGaugeModal"
      centered={true}
      title={props.title ? props.title : ''}
      closable={false}
    >
      <div className="qrModalContainer">
        <div className="closeBtn bottom" onClick={colseModal}>
          <IconFont type="iconicon_close2" />
        </div>
        <div className="scan_code_content">
          <div className="scan_code_img">{qRUrl && <QRCode value={qRUrl} size={qrWidth} />}</div>
        </div>
      </div>
    </Modal>
  );
  return modal;
}

export function ImgModalCmp(props: IImgModalCmp) {
  const footer = <div className="scan_footer" />;
  const { visible, setVisible } = useModalVisible(false);
  useEffect(() => {
    if (props.visible && props.url) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.visible, props.url]);
  const qrWidth = useWindowUpdateWidth(400);
  function closeModal() {
    setVisible(false);
    props.callback();
  }
  /**
   * 点击复制
   */
  function handleCopy() {
    if (copy(props.url || '')) {
      message.success('复制成功');
    } else {
      message.error('复制失败,请重新复制');
    }
  }
  const modal = (
    <Modal
      visible={visible}
      onCancel={closeModal}
      width={useWindowUpdateWidth(690)}
      footer={footer}
      className="normalModalCmp"
      centered={true}
      title={''}
      closable={false}
    >
      <div className="qrModalContainer">
        <div className="closeBtn" onClick={closeModal}>
          <img className="icon_close closeModal" src={require('../../assets/img/icon_close_popup.svg')} />
        </div>
        <div className="scan_code_content">
          <div className="scan_code_img">{props.url && <QRCode value={props.url} size={qrWidth} />}</div>
        </div>
        <div className="scan_qr_discription_url" onClick={handleCopy}>
          点击复制链接
        </div>
        <div className="scan_qr_discription">
          <img src={require(`../../assets/img/icon_explain.svg`)} alt="" className="imgIcon" />
          <div style={{ paddingLeft: '16px' }}> {props.discription} </div>
        </div>
      </div>
    </Modal>
  );
  return modal;
}

export function DoctorMsQrModal(props: IImgModalCmp) {
  const footer = <div className="scan_footer" />;
  const { visible, setVisible } = useModalVisible(false);
  useEffect(() => {
    if (props.visible && props.url) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.visible, props.url]);
  const qrWidth = useWindowUpdateWidth(560);
  function closeModal() {
    setVisible(false);
    props.callback();
  }

  const modal = (
    <Modal
      visible={visible}
      onCancel={closeModal}
      width={useWindowUpdateWidth(600)}
      footer={footer}
      className="doctorModalCmp"
      centered={true}
      title={''}
      closable={false}
    >
      <div className="qrModalContainer">
        <div className="scan_code_content">
          <img src={props.url} style={{ height: qrWidth, width: qrWidth }} className="scan_code_img" />
        </div>
        <div className="closeBtn" onClick={closeModal}>
          <IconFont type="iconicon_close2" />
        </div>
      </div>
    </Modal>
  );
  return modal;
}

export function QRImge(props: IQRImgeProps) {
  const width = useWindowUpdateWidth(props.width);
  return <QRCode value={props.url} size={width} />;
}

export function MobileQRModal(props: IMobileQrProps) {
  const footer = <div className="scan_footer" />;
  const { visible, setVisible } = useModalVisible(false);
  useEffect(() => {
    if (props.visible && props.url) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.visible, props.url]);
  function closeModal() {
    setVisible(false);
    props.callback();
  }
  const modal = (
    <Modal
      visible={visible}
      onCancel={closeModal}
      width={useWindowUpdateWidth(560)}
      footer={footer}
      className="mobileQrModalCmp"
      centered={true}
      title={''}
      closable={false}
    >
      <div className="qrModalContainer">
        <div className="scan_code_content">
          <QRImge url={props.url} width={props.width} />
        </div>
        <div className="mobileQRDis">{props.discription} </div>
        <div className="closeBtn" onClick={closeModal}>
          <IconFont type="iconicon_close2" />
        </div>
      </div>
    </Modal>
  );
  return modal;
}

export function MobileEfollowQrModal(props: IImgModalCmp) {
  const footer = <div className="scan_footer" />;
  const { visible, setVisible } = useModalVisible(false);
  useEffect(() => {
    if (props.visible && props.url) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [props.visible, props.url]);
  const qrWidth = useWindowUpdateWidth(440);
  function closeModal() {
    setVisible(false);
    props.callback();
  }

  const modal = (
    <Modal
      visible={visible}
      onCancel={closeModal}
      width={useWindowUpdateWidth(480)}
      footer={footer}
      className="moibleEfollowModalCmp"
      centered={true}
      title={''}
      closable={false}
    >
      <div className="qrModalContainer">
        <div className="scan_code_content">
          <img src={props.url} style={{ height: qrWidth, width: qrWidth }} className="scan_code_img" />
        </div>
        <div className="mobileQRDis"> 扫一扫进入医患交流平台E-Follow</div>
        <div className="closeBtn" onClick={closeModal}>
          <IconFont type="iconicon_close2" />
        </div>
      </div>
    </Modal>
  );
  return modal;
}
