import { Button, Checkbox, Form, Input, Radio } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { WxDoctorApi } from '../../../api';
import { IDoctorMsg, IPatientEditMsg, IUpdatePMsgReq } from '../../../api/WxDoctorApi';
import { MobileHeader } from '../../../components/index';
import UpdateTitle from '../../../utils/UpdateTitle';
import VerifyPatientCodeModal from './components/VerifyPatientCodeModal/VerifyPatientCodeModal';
import './EditPatient.scss';
interface IEditPatientProps extends FormComponentProps, RouteComponentProps<{ id: string }> {}

function EditPatientPage(props: IEditPatientProps) {
  const { id } = props.match.params;
  const [pmsg, setPmsg] = useState({} as IPatientEditMsg);
  const [selGroups, setSelGroups] = useState();
  const [patientType, setPatientType] = useState();
  const [remark, setRemark] = useState('');
  const [sampleSequence, setSampleSequence] = useState('');
  const [patientMsg, setPatientMsg] = useState({} as IDoctorMsg);
  const [showVerify, setShowVerify] = useState(false);

  // 可以操控输入框 颜色，以及提示内容 后期估计会用到
  const codeValidate: Array<['validating' | 'error' | 'success' | 'warning', string]> = [
    ['validating', ''],
    ['error', '此就诊编号已经绑定患者'],
    ['error', '此就诊编号无患者'],
  ];

  useEffect(() => {
    getMsg();
    getPatientMsg();
    UpdateTitle('患者信息');
  }, []);
  /**
   * 获取病人信息
   */
  async function getPatientMsg() {
    const { result } = await WxDoctorApi.getPatientMsg(id);
    if (result) {
      setPatientMsg(result);
    }
  }

  /**
   * 获取患者分组备注信息
   */
  async function getMsg() {
    const { result } = await WxDoctorApi.getPatientEditMsg({ patientId: id });
    if (result) {
      setPatientType(result.type);
      setSampleSequence(result.sampleSequence);
      setRemark(result.remark);
      setPmsg(result);
      setSelGroups(
        result.groupInfoVo
          .filter(e => {
            return e.exist;
          })
          .map(val => val.id)
      );
    }
  }

  /**
   * 保存更新信息
   */
  async function updataMsg() {
    const req: IUpdatePMsgReq = {
      groupIds: selGroups,
      type: patientType,
      remark,
      sampleSequence,
      patientId: id,
    };
    const { result } = await WxDoctorApi.updatePatientEditMsg(req);
    if (result) {
      props.history.push(`/Doctor/PatientMsg/${id}`);
    }
  }

  /**
   * 组 内容
   */
  const groupTag =
    pmsg &&
    pmsg.groupInfoVo &&
    pmsg.groupInfoVo.map((e, index) => {
      return (
        <div className="groupItem" key={e.id}>
          <Checkbox value={e.id} className="groupCheckbox" checked={e.exist} />
          <div className="msgCheckContainer">
            <div className="groupItemTitle">{e.name} </div>
            <div className="discription"> {e.description} </div>
          </div>
        </div>
      );
    });
  /**
   * 分组选择变化
   */
  function checkGroupsOnChange(e: CheckboxValueType[]) {
    //
    setSelGroups([...e]);
  }
  function actionVerify(reset?: boolean) {
    if (!sampleSequence || sampleSequence === pmsg.sampleSequence) {
      return;
    }
    setShowVerify(!showVerify);
    if (reset) {
      setSampleSequence('');
    }
  }
  function isSend() {
    if (patientType === 'new_patient') {
      return false;
    } else if (patientType === 'old_patient' && sampleSequence) {
      return false;
    }
    return true;
  }
  return (
    <div className="EditPatient">
      <MobileHeader backUrl={`/Doctor/PatientMsg/${id}`}>
        <Button type="link" disabled={isSend()} onClick={updataMsg}>
          保存
        </Button>
      </MobileHeader>
      <div className={'patientType'}>
        <h1>患者类型</h1>
        <Radio.Group onChange={e => setPatientType(e.target.value)} value={patientType}>
          <Radio value={'new_patient'} style={{ marginRight: '80px' }}>
            新患者
          </Radio>
          <Radio value={'old_patient'}>老患者</Radio>
        </Radio.Group>
      </div>
      {/* tslint:disable-next-line:jsx-no-multiline-js */}
      {patientType === 'old_patient' && (
        <div className={'patientType'}>
          <h1> 就诊编号 </h1>
          <Form.Item validateStatus={codeValidate[0][0]} help={codeValidate[0][1]}>
            <Input
              value={sampleSequence}
              placeholder={'请输入就诊编号'}
              onChange={e => setSampleSequence(e.target.value)}
              onBlur={() => actionVerify()}
            />
          </Form.Item>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {showVerify && (
            <VerifyPatientCodeModal
              onClose={actionVerify}
              show={showVerify}
              patientMsg={patientMsg}
              sampleSequence={sampleSequence}
            />
          )}
        </div>
      )}

      <Checkbox.Group
        value={selGroups}
        // tslint:disable-next-line:jsx-no-multiline-js
        onChange={e => {
          checkGroupsOnChange([...e]);
        }}
      >
        <div>
          <div className="groupTitle">分组 </div>
          <div className="groupContiner">
            {groupTag && groupTag.length ? groupTag : <span style={{ opacity: '0.5' }}>您还未进行分组</span>}
          </div>
        </div>
      </Checkbox.Group>
      <div className="remarkContentMsgTitle">备注 </div>
      <TextArea
        placeholder="请输入备注"
        value={remark}
        onChange={e => setRemark(e.target.value)}
        className="remarkContentMsgInput"
      />
    </div>
  );
}
const EditPatient = Form.create()(EditPatientPage);
export default EditPatient;
