import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useDispatch } from 'dva';
import React, { useEffect, useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormCheckbox.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 多选框 FormCheckbox
 */
function FormCheckbox(props: ISurveyHook) {
  if (!props.formObj.subjectData.checkboxs) {
    return <div />;
  }
  const checkboxVals = props.formObj.subjectData.checkboxs.map(item => {
    return item.value;
  });
  let wordLength = 0;
  const dispatch = useDispatch();
  // tslint:disable-next-line:no-any
  const [val, setVal] = useState<any[]>(props.formObj.subjectData.defaultValue || []);
  useEffect(() => {
    if (val) {
      const checkboxDatas = JSON.parse(JSON.stringify(val));
      const originVal = JSON.parse(JSON.stringify(val));
      diffOther().then(res => {
        const otherInputIndex = checkboxDatas.indexOf(res);
        if (otherInputIndex !== -1) {
          const otherIndex = checkboxDatas.indexOf(props.formObj.subjectData.otherInput);
          checkboxDatas.splice(otherIndex, 1);
          checkboxDatas.splice(otherInputIndex, 1);
          checkboxDatas.push(originVal[otherIndex]);
          checkboxDatas.push(originVal[otherInputIndex]);
        }
        dispatch({
          type: 'survey/changeForm',
          payload: {
            props,
            val: checkboxDatas,
          },
        });
      });
    }
  }, [val]);

  props.formObj.subjectData.checkboxs.map(item => {
    wordLength = String(item.label).length > 6 ? 1 : 0;
  });
  const className = wordLength === 1 ? 'red bigword' : 'red';
  async function checkboxChange(arr: CheckboxValueType[]) {
    if (props.formObj.subjectData.otherInput && arr.includes(props.formObj.subjectData.otherInput)) {
      const result = await diffOther();
      setVal([result, ...arr]);
      return;
    }
    setVal(arr);
  }

  // 其他输入框监听
  async function otherInput(str: React.ChangeEvent<HTMLInputElement>) {
    const input = str.currentTarget.value;
    await diffOther();
    setVal([input, ...val]);
  }

  //  获取其他输入的默认值 otherVal
  function getDefaultValue() {
    const defaultValue = props.formObj.subjectData.defaultValue;
    if (defaultValue) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < defaultValue.length; i++) {
        if (!checkboxVals.includes(defaultValue[i])) {
          return defaultValue[i];
        }
      }
    }
  }

  /**
   * diff 原来的数组找出扩展输入
   * @return 拓展输入的值
   */
  async function diffOther() {
    if (val) {
      let srt = '';
      val.forEach((item, i) => {
        if (!checkboxVals.includes(item)) {
          val.splice(i, 1);
          srt = item;
        }
      });
      return srt;
    }
  }
  /**
   * 用户点击回显上次方式的监听
   * 直接触发当前组件的渲染
   */
  // tslint:disable-next-line:no-any
  window[`onlastSurvey_${props.formObj.subjectData.field}`] = (value: any, field: string) => {
    setVal(value);
  };
  return (
    <div id="FormCheckbox">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={props.formObj.subjectData.errMsg ? 'cl star' : 'star'}
              // tslint:disable-next-line:jsx-no-multiline-js
              id={
                props.formObj.subjectData.parentPosition &&
                `errLogo_${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
              }
            >
              *
            </div>
          </AuthCompount>
          <div>{props.formObj.subjectData.subjectName}</div>
        </div>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg" // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition &&
              `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
            }
          >
            {props.formObj.subjectData.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!!props.formObj.subjectData.explain}>
        <div className="explain">（说明：{props.formObj.subjectData.explain}）</div>
      </AuthCompount>

      <Checkbox.Group
        className={className}
        options={props.formObj.subjectData.checkboxs}
        defaultValue={props.formObj.subjectData.defaultValue}
        value={val}
        onChange={checkboxChange}
      />
      <AuthCompount
        // tslint:disable-next-line:jsx-no-multiline-js
        isShow={val && val.includes(props.formObj.subjectData.otherInput)}
      >
        <input
          className="form_input"
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={getDefaultValue()}
          onChange={otherInput}
        />
      </AuthCompount>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg" // tslint:disable-next-line:jsx-no-multiline-js
          id={
            props.formObj.subjectData.parentPosition &&
            `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
          }
        >
          {props.formObj.subjectData.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}

export default FormCheckbox;
