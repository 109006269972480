import { Form, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { SubscriptionAPI, useDispatch, useSelector } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import WeChatPatient from '../../../api/WeChatPatient';
import WxDoctorApi from '../../../api/WxDoctorApi';
import Config from '../../../config/Config';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import './DoctorCert.scss';
interface IDoctorCertProps extends FormComponentProps, RouteComponentProps, SubscriptionAPI {
  ID?: string;
}
function DoctorCertPage(props: IDoctorCertProps) {
  const { getFieldDecorator, getFieldValue } = props.form;
  const dispatch = useDispatch();
  const codeTime = useSelector(({ global }: { global: IGlobal }) =>
    global.wechatDoctorCert ? global.wechatDoctorCert.codeTime : 60
  );
  const [showFocus, setShowFocus] = useState({
    mobile: false,
    inviteCode: false,
    validationCode: false,
  });
  const globalState = useSelector(({ global }: { global: IGlobal }) => global);
  const [state, setState] = useState({
    mobile: '',
    inviteCode: '',
    validationCode: '',
  });
  useEffect(() => {
    UpdateTitle('E-Follow');
    if (codeTime !== 60) {
      countDown(codeTime);
    }
  }, []);
  /**
   * 发送验证码
   */
  async function sendCode() {
    const mobile = getFieldValue('mobile');
    if (!/^1[3456789]\d{9}$/.test(mobile)) {
      message.error('请输入正确的手机号码！');
      return;
    }
    const { code, result } = await WeChatPatient.sendSms({ mobile: Number(mobile), systemCode: 'HULK_BACK' });
    if (code === 0) {
      countDown(codeTime);
    }
    message.success(result);
  }
  // 倒计时
  function countDown(num: number) {
    const timer = setInterval(() => {
      num--;
      if (num === 0) {
        clearInterval(timer);
      }
      dispatch({
        type: 'global/wxInfoSave',
        payload: {
          wechatDoctorCert: {
            codeTime: num === 0 ? 60 : num,
          },
        },
      });
    }, 1000);
  }
  /**
   * 点击认证
   */
  async function cetificateOnClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!e.currentTarget.className.includes('disable')) {
      const mobile = getFieldValue('mobile');
      const inviteCode = getFieldValue('inviteCode');
      const validationCode = getFieldValue('validationCode');
      const { result } = await WxDoctorApi.doctorCetification({ mobile, inviteCode, validationCode });
      if (result) {
        const global = JSON.parse(JSON.stringify(globalState));
        global.token = result;
        global.hulkUserInfoBackLoginVo.token = result;
        global.wechatDoctorCert.codeTime = 60;
        dispatch({
          type: 'global/wxInfoSave',
          payload: global,
        });
        // 请求成功
        window.location.href = `${Config.domainUrl}/#/Doctor/DoctorInfo`;
        // props.history.push('/Doctor/DoctorInfo');
      }
    }
  }
  return (
    <div className="doctorCert">
      <div className="headerTitle"> 医生认证 </div>

      <Form.Item colon={false}>
        {/* tslint:disable-next-line:jsx-no-multiline-js*/}
        {getFieldDecorator('mobile', {
          rules: [
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号码!',
            },
            { required: true, message: '点击输入' },
          ],
        })(
          <div className={'containerInput'}>
            <div className={`labelInput ${state.mobile || showFocus.mobile ? 'showLabel' : ''}`}>手机号</div>
            <Input
              placeholder="手机号"
              onFocus={() => setShowFocus({ ...showFocus, mobile: !showFocus.mobile })}
              onBlur={() => setShowFocus({ ...showFocus, mobile: !showFocus.mobile })}
              onChange={e => setState({ ...state, mobile: e.target.value })}
            />
          </div>
        )}
      </Form.Item>
      <div className="codeContiner">
        <Form.Item colon={false} className="codeInput">
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('validationCode', {
            rules: [{ required: true, message: '点击输入' }],
          })(
            <div className={'containerInput'}>
              <div className={`labelInput ${state.validationCode || showFocus.validationCode ? 'showLabel' : ''}`}>
                验证码
              </div>
              <Input
                placeholder="验证码"
                onFocus={() => setShowFocus({ ...showFocus, validationCode: !showFocus.validationCode })}
                onBlur={() => setShowFocus({ ...showFocus, validationCode: !showFocus.validationCode })}
                onChange={e => setState({ ...state, validationCode: e.target.value })}
              />
            </div>
          )}
        </Form.Item>
        <button className={codeTime === 60 ? 'action' : 'action enable'} disabled={codeTime !== 60} onClick={sendCode}>
          {codeTime === 60 ? `发送验证码` : `${codeTime}秒后重发`}
        </button>
      </div>
      <div className="inviteCode">
        <Form.Item colon={false}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('inviteCode', {
            rules: [{ required: true, message: '点击输入' }],
          })(
            <div className={'containerInput'}>
              <div className={`labelInput ${state.inviteCode || showFocus.inviteCode ? 'showLabel' : ''}`}>邀请码</div>
              <Input
                placeholder="邀请码"
                onFocus={() => setShowFocus({ ...showFocus, inviteCode: !showFocus.inviteCode })}
                onBlur={() => setShowFocus({ ...showFocus, inviteCode: !showFocus.inviteCode })}
                onChange={e => setState({ ...state, inviteCode: e.target.value })}
              />
            </div>
          )}
        </Form.Item>
      </div>
      <div className="discription">
        <div> 注意事项： </div>
        <div> 认证时需填写我们给您的唯一邀请码。如邀请码遗忘或填写错误请联系我们，联系电话：400-006-8822 </div>
      </div>
      <div
        // tslint:disable-next-line: jsx-no-multiline-js
        className={
          state.mobile && state.inviteCode && state.validationCode ? 'cetificationAction' : 'cetificationAction disable'
        }
        onClick={cetificateOnClick}
      >
        认证
      </div>
    </div>
  );
}
/*

const NewNotis = connect(({ global }: { global: IGlobal }) => ({
  global,
}))(Form.create()(DoctorCertPage));
*/
const NewNotis = Form.create()(DoctorCertPage);
export default NewNotis;
