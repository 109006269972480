import React from 'react';
interface IProps {
  isShow: boolean;
}
/**
 * 控制是否显示的组件，类似于v-if
 */ class AuthCompount extends React.Component<IProps> {
  public render() {
    return this.props.isShow ? this.props.children : null;
  }
}
export default AuthCompount;
