// import { Link } from 'dva/router';
import { Button } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import WeChatDoctor, { INotify } from '../../../api/WeChatDoctor';
import { MobileAddBlockContent, MobileHeader, MobileNoDatas, MobileTag } from '../../../components/index';
import { MobilePagination, UpdateTitle } from '../../../utils';
import './DoctorNotifyList.scss';
import { IDeleteState, IMessageType, IProps } from './interface';

export default (props: IProps) => {
  // tslint:disable-next-line: radix
  const periodId = parseInt(props.match.params.periodId);
  // tslint:disable-next-line: radix
  const groupId = parseInt(props.match.params.groupId);
  const [pagnation, setPagnation] = useState({ pageSize: 10, total: 0, pageNum: 1 });
  const [notifies, setNotifies] = useState<INotify[]>([]);
  const [deleteState, setDeleteState] = useState<IDeleteState>({ isDeleting: false, deleteArr: [] });
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const messageType: IMessageType = {
    1: { name: '复诊通知', class: 'orange', type: '复诊' },
    2: { name: '随访通知', class: 'green', type: '随访' },
    3: { name: '随访问卷', class: 'blue', type: '问卷' },
  };
  useEffect(() => {
    UpdateTitle('通知列表');
    getNotifies();
  }, []);
  useEffect(() => {
    if (deleteSuccess) {
      getNotifies();
      setDeleteSuccess(false);
    }
  }, [deleteSuccess]);
  // 滚动加载下一页
  async function scrollEvent() {
    const pageNum = await MobilePagination('notifyList', pagnation.pageNum, pagnation.pageSize, pagnation.total);
    if (pageNum) {
      pagnation.pageNum = pageNum;
      getNotifies();
    }
  }
  async function getNotifies() {
    const searchRequest = {
      pageNum: pagnation.pageNum,
      pageSize: pagnation.pageSize,
      pushCycleId: periodId,
    };
    const { result } = await WeChatDoctor.getNotifies(searchRequest);
    setNotifies([...notifies, ...result.records]);
    setPagnation({ ...pagnation, total: result.total });
  }
  function itemClick(id: number) {
    if (deleteState.isDeleting) {
      let deleteArr = deleteState.deleteArr;
      if (deleteArr.lastIndexOf(id) !== -1) {
        deleteArr = deleteArr.filter(v => {
          return v !== id;
        });
      } else {
        deleteArr.push(id);
      }
      setDeleteState({ ...deleteState, deleteArr });
    } else {
      props.history.push(`/Doctor/DoctorAddNotify/${periodId}/${id}/EDIT/${groupId}`);
    }
  }
  async function deleteSure() {
    if (deleteState.deleteArr.length > 0) {
      const result = await WeChatDoctor.deleteNotify({ data: { ids: deleteState.deleteArr, pushCycleId: periodId } });
      if (result.code === 0) {
        setDeleteState({ isDeleting: false, deleteArr: [] });
        pagnation.pageNum = 1;
        setNotifies([]);
        setDeleteSuccess(true);
      }
    } else {
      setDeleteState({ isDeleting: false, deleteArr: [] });
    }
  }
  let listContent;
  if (notifies.length <= 0) {
    listContent = <MobileNoDatas imgurl={'img_notice_null.svg'} msg={'暂无通知'} show={true} />;
  } else {
    listContent = notifies.map(item => {
      return (
        <div key={item.id} className="notifyItem" onClick={() => itemClick(item.id)}>
          <div className="topArea">
            {// tslint:disable-next-line: jsx-no-multiline-js
            deleteState.isDeleting && deleteState.deleteArr.lastIndexOf(item.id!) === -1 ? (
              <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time.svg')} />
            ) : (
              ''
            )}
            {// tslint:disable-next-line: jsx-no-multiline-js
            deleteState.isDeleting && deleteState.deleteArr.lastIndexOf(item.id!) !== -1 ? (
              <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time_select.svg')} />
            ) : (
              ''
            )}
            <h1>{item.title}</h1>
          </div>
          <p>
            {item.sysMsgType === 1 && <MobileTag text={'复诊通知'} />}
            {item.sysMsgType === 2 && <MobileTag text={'随访通知'} />}
            {item.sysMsgType === 3 && <MobileTag text={'随访问卷'} />}
          </p>
          <div className="timeArea">
            {// tslint:disable-next-line: jsx-no-multiline-js
            item.sysMsgType !== 3 && (
              <span className="time">
                发送后第{item.nextFollowUpDays}天{item.sysMsgType ? messageType[item.sysMsgType].type : ''}
              </span>
            )}

            <img className="icon_back" src={require('../../../assets/moblieImg/icon_more2.svg')} />
          </div>
        </div>
      );
    });
  }
  return (
    <div id="Doctor-DoctorNotifyList">
      <div className="topMenu">
        <MobileHeader backUrl={`/Doctor/DoctorSetPeriod/${groupId}`}>
          {// tslint:disable-next-line: jsx-no-multiline-js
          deleteState.isDeleting ? (
            <Fragment>
              <Button type={'link'} onClick={() => setDeleteState({ ...deleteState, isDeleting: false })}>
                取消
              </Button>
              <Button
                type={'link'}
                disabled={deleteState.deleteArr.length === 0}
                className="deleteSure"
                onClick={deleteSure}
              >
                确定删除
              </Button>
            </Fragment>
          ) : (
            <Button
              type={'link'}
              disabled={notifies.length === 0}
              onClick={() => setDeleteState({ ...deleteState, isDeleting: true })}
            >
              删除通知
            </Button>
          )}
        </MobileHeader>
      </div>

      <div className="personInfo">
        <MobileAddBlockContent backUrl={`/Doctor/DoctorAddNotify/${periodId}/0/ADD/${groupId}`}>
          添加通知
        </MobileAddBlockContent>
      </div>
      <div className="notifyList" id="notifyList" onScroll={scrollEvent}>
        {listContent}
      </div>
    </div>
  );
};
