import { connect } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React from 'react';
import QRActionApi from '../../../api/QRActionApi';
import { IGlobal } from '../../../Model/GlobalModel';
interface IProps extends RouteComponentProps<{ code: string }> {
  global: IGlobal;
}
class QRLogin extends React.Component<IProps, {}> {
  public componentDidMount() {
    this.getQRCodeMsg();
  }
  public render() {
    return <div />;
  }

  /**
   * 扫码后请求 需要的信息
   */

  private getQRCodeMsg = async () => {
    const code = this.getQueryVariable('code');
    const roleAndUrl = this.getQueryVariable('roleAndUrl');
    const isAdd = this.getQueryVariable('isAdd');

    const { result } = await QRActionApi.getQRCodeMsg({ code });

    let url = `/GaugeSurvey?`;
    // 将需要的 参数传入 obj 对象，下面会自动解析成 key=value&key=value  格式，然后拼接url
    // tslint:disable-next-line:no-any
    const obj: any = { code, roleAndUrl, writeType: result.type === 0 ? 'surveyQrcodeCreate' : 'surveyQrcodeUpdate' };
    if (isAdd) {
      obj.writeType = isAdd;
    }
    url += Object.keys(obj)
      .map(val => `${val}=${obj[val]}`)
      .join('&');

    this.props.history.push(url);
  };

  /**
   * 获取 url 参数
   */
  private getQueryVariable = (urlKey: string) => {
    const url = window.location.href;
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg);
    const obj = new Map();
    if (arr) {
      arr.forEach(item => {
        const tempArr = item.substring(1).split('=');
        const key = decodeURIComponent(tempArr[0]);
        const val = decodeURIComponent(tempArr[1]);
        obj.set(key, val);
      });
    }
    return obj.get(urlKey);
  };
}

export default connect()(QRLogin);
