import { useSelector } from 'dva';
import React from 'react';
import './Loading.scss';
/**
 * 全局的loading
 */
export default () => {
  // tslint:disable-next-line:no-any
  const isLoading = useSelector(({ LoadModel }: { LoadModel: any }) => LoadModel.isLoading);
  if (isLoading) {
    return (
      <div className="loading-self">
        <div className="ant-spin ant-spin-spinning">
          <span className="ant-spin-dot ant-spin-dot-spin">
            <i className="ant-spin-dot-item" />
            <i className="ant-spin-dot-item" />
            <i className="ant-spin-dot-item" />
            <i className="ant-spin-dot-item" />
          </span>
        </div>
      </div>
    );
  }
  return null;
};
