import axios from './RequestApi';
import { IListResult, IMyPatientReq } from './WxDoctorApi';

export default class WeChatPatient {
  // 发送验证码
  public static sendSms(data: ISms) {
    return axios.post<string, {}>(`/sms/send`, { ...data, smsSceneEnum: 'WX_REG' });
  }
  // 医生列表
  public static doctorPage(data: IMyPatientReq) {
    return axios.get<IListResult<IDoctor>, IMyPatientReq>(`/logined/hulkUserInfo/wechat/doctor/page`, data);
  }
  // 通知列表
  public static getNotifies<T, D>(data: D) {
    return axios.get<IListResult<INotify>, D>(`/logined/wechatNotifyMsg/front/page`, data);
  }
  // 通知详情
  public static getNotifyDetail<T, D>(data: ID) {
    return axios.get<INotify, ID>(`/logined/wechatNotifyMsg/${data.id}`, data);
  }
  // 通知标为已读
  public static setReaded<T, D>(data: ID) {
    return axios.get<IListResult<INotify>, ID>(`/logined/wechatNotifyMsg/${data.id}/read`, data);
  }
  // 拒绝或接受
  public static changeStatus<T, D>(data: D) {
    return axios.post<T, D>(`/logined/wechatNotifyMsg/deal`, data);
  }
  // 保存患者信息
  public static savePatientInfo(data: IWechatPatientInfo) {
    return axios.post<string, IWechatPatientInfo>('/logined/hulkUserInfo/savePatient', data);
  }
}

interface ID {
  id: string;
}

interface ISms {
  mobile: number;
  systemCode: 'HULK_BACK' | 'HULK_FRONT'; // 医生 | 患者
}

interface IDoctor {
  id: string;
  msgCount: number;
  headimgurl: string;
  name: string;
  expertName: string;
  department: string;
  title: string;
  attentionTime: string;
  organizationName: string;
  gender: number;
}

export interface INotify {
  id: number;
  title: string;
  sysMsgType: number;
  treatmentTime: string;
  buttonStatus: number;
  sendTime: string;
  answerCode: string;
  status: number;
  result: number;
}
export interface IWechatPatientInfo {
  name: string;
  birthday: string;
  gender: number;
  mobile: number | string;
  smsCode: string;
}
