import { Button, Col, Row } from 'antd';
import { Link } from 'dva/router';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import AuthCompount from './../AuthCompount/AuthCompount';
import './PageInfoCompount.scss';
interface IProps {
  isShowLeft: boolean; // 是否显示左边的东西
  lookNum?: string; // 方式次数
  diseaseCode?: string; // 病例编号
  diseaseName?: string; // 病例名称
  mode: number; // 显示模式 1不显示按钮 2显示提交按钮 3显示新增病例按钮
  submit?: () => void; // 提交
  changeNav?: () => void; // 显示导航栏
  submitShow?: boolean;
}

const isPc = window.screen.width > 500;
/**
 * 页面头部信息
 */
class PageInfoCompount extends React.Component<IProps> {
  public render() {
    return (
      <div className="PageInfoCompount">
        <div className="pageInfoCompount">
          <Row className="left_box">
            <AuthCompount isShow={isPc && this.props.isShowLeft}>
              <Col className="deNu">
                <span className="detail lengthControl" title={this.props.lookNum}>
                  访视{this.props.lookNum}
                </span>
              </Col>
              <AuthCompount isShow={this.props.submitShow !== false}>
                <Col className="deC">
                  <span className="detail lengthControl" title={this.props.diseaseCode}>
                    病例编号: {this.props.diseaseCode}
                  </span>
                </Col>
                <Col className="deN">
                  <span className="detail lengthControl" title={this.props.diseaseName}>
                    病例名称: {this.props.diseaseName}
                  </span>
                </Col>
              </AuthCompount>
            </AuthCompount>
            <AuthCompount isShow={!isPc && this.props.isShowLeft}>
              <div className="mb_look">
                <img src={require('./../../assets/moblieImg/icon_more.svg')} alt="" onClick={this.props.changeNav} />
                <div>访视{this.props.lookNum}</div>
              </div>
            </AuthCompount>
          </Row>
          <AuthCompount isShow={this.props.mode !== 1}>
            <AuthCompount
              // tslint:disable-next-line:jsx-no-multiline-js
              isShow={this.props.mode !== 2 && !this.props.submitShow}
            >
              <Button type="primary" onClick={this.props.submit}>
                提交
              </Button>
            </AuthCompount>
            <AuthCompount isShow={this.props.mode !== 3}>
              <div className="bu_na">
                <Link to="/GaugeSurvey">
                  <IconFont type="iconicon_add_patient1" /> 新增病例
                </Link>
              </div>
            </AuthCompount>
          </AuthCompount>
        </div>
      </div>
    );
  }
}
export default PageInfoCompount;
