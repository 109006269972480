import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import QRActionApi from '../../../../api/QRActionApi';
import { useWindowUpdateWidth } from '../../../../utils/UtilHook';
import './PCaseCard.scss';

export interface IPCaseCardProps {
  title: string;
  discription: string;
  img: string;
  showQR?: boolean; // 用来点击显示二维码
  onClick?: () => void; // 整个 card click Action
}
export function PCaseCard(props: IPCaseCardProps) {
  const [visible, setVisible] = useState(false);
  const [qrUrl, setQrUrl] = useState();
  const qrWidth = useWindowUpdateWidth(120);

  /**
   *  处理点击事件
   */
  function cardClick() {
    showQrImgAction();
    if (props.onClick) {
      props.onClick();
    }
  }

  /**
   * 获取 QRImg
   */

  async function getImg() {
    const { result } = await QRActionApi.getDoctorQRImgUrl();
    setQrUrl(result);
    setVisible(true);
  }
  function showQrImgAction() {
    if (props.showQR) {
      getImg();
    }
  }

  return (
    <div className="cardContainer" onClick={cardClick}>
      <div className="headerContent">
        <div className="titMsg">
          <div className="title"> {props.title} </div>
          <div className="discription" title={props.discription}>
            {props.discription}
          </div>
        </div>
        <div className="imgIcon">
          <img src={require(`../../../../assets/img/${props.img}`)} alt="" className="imgIcon" />
        </div>
      </div>
      <div hidden={!visible} className="QRContainer">
        <div className="scan_code_img">{qrUrl && <QRCode value={qrUrl} size={qrWidth} />}</div>
        <div
          // tslint:disable-next-line:jsx-no-multiline-js
          onClick={e => {
            setVisible(false);
            e.stopPropagation();
          }}
          className="hideQRCode"
        >
          收起
        </div>
      </div>
    </div>
  );
}
