import { Button } from 'antd';
import { InputItem, Modal } from 'antd-mobile';
import { SubscriptionAPI, useDispatch, useSelector } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import WeChatDoctor, { IGroup, IPatientItem, IUpdateSampleSequenceItem } from '../../../api/WeChatDoctor';
import { MobileHeader, MobileSearch } from '../../../components';
import { UpdateTitle } from '../../../utils';
import './AddGroupMember.scss';
export interface IProps
  extends SubscriptionAPI,
    RouteComponentProps<{
      groupId: string;
      type: string;
    }> {}
interface IVerifyResult {
  age: number;
  gender: number;
  id: string;
  isBind?: boolean;
  name: string;
  sampleSequence?: string;
  patientId?: string;
  patientName?: string;
  patientGender?: number;
  patientAge?: number;
  result?: string;
}
let isAddMember = false; // 当将患者添加至组需要将滚动条固定在右边
export default (props: IProps) => {
  const [request, setRequest] = useState({ pageSize: 100000, total: 0, pageNum: 1, type: 'old_patient', name: '' }); // 请求条件
  const [list, setList] = useState<IPatientItem[]>([]); // 查询患者列表list
  const [choosedList, setChoosedList] = useState<IPatientItem[]>([]); // 选中的新患者list
  const [choosedOldPatientList, setChoosedOldPatientList] = useState<IPatientItem[]>([]); // 选中的老患者list
  const [checkedPatientIds, setCheckedPatientIds] = useState(','); // 选中的所有患者id集合（逗号拼接的字符串），切换时不清除
  const [showTips, setShowTips] = useState(false); // 是否显示提示
  const [modalShow, setModalShow] = useState({
    // 填写就诊编号弹窗、添加至组确认框、点击完成时提示框
    isShowModal: false,
    addToGroupComfirm: false,
    memberNotInList: false,
  });
  const [hasVerifyResult, setHasVerifyResult] = useState({ hasError: false, hasResult: false, allRight: true }); // 是否有校验结果 校验结果是否有错误 如果有不可提交至服务器 如全部正确时不可返回至填写页面
  const [verifyResult, setVerifyResult] = useState<IVerifyResult[]>([]); // 校验结果
  const dispatch = useDispatch();
  const globalState = useSelector(({ groupInfo }: { groupInfo: IGroup }) => groupInfo);
  const type = props.match.params.type; // EDIT|ADD
  const groupId = props.match.params.groupId;
  useEffect(() => {
    UpdateTitle('选择患者');
    searchPatient();
  }, []);
  useEffect(() => {
    // 当将患者添加至组时需要将滚动条固定在右边
    if (isAddMember) {
      const scrollWidth = document.getElementById('choosedPatient')!.scrollWidth;
      document.getElementById('choosedPatient')!.scrollLeft = scrollWidth;
      isAddMember = false;
    }
  }, [choosedList, choosedOldPatientList]);
  // 查询患者
  async function searchPatient() {
    const req = {
      pageNum: request.pageNum,
      pageSize: request.pageSize,
      name: request.name,
      type: request.type,
    };
    const { result } = await WeChatDoctor.getPatients(req);
    setRequest({ ...request, total: result.total });
    removeChoosedPatient(result.records);
  }
  // 移除已存在组中的成员且将已勾选的患者打勾
  function removeChoosedPatient(needFilterList: IPatientItem[]) {
    let tempStr = ',';
    globalState.hulkUserInfoList.map(item => {
      tempStr += item.id + ',';
    });
    let waitAddListStr = ',';
    if (request.type === 'new_patient') {
      choosedList.forEach(v => {
        waitAddListStr += v.id + ',';
      });
    } else {
      choosedOldPatientList.forEach(v => {
        waitAddListStr += v.id + ',';
      });
    }
    waitAddListStr += checkedPatientIds;
    if (needFilterList) {
      needFilterList = needFilterList.filter(item => {
        if (waitAddListStr.includes(item.id)) {
          item.isChoosed = true;
        }
        if (!tempStr.includes(item.id)) {
          return item;
        }
      });
      setList([...needFilterList]);
    }
  }
  // 切换新、老患者
  function switchTab(patientType: string) {
    request.type = patientType;
    setRequest({ ...request });
    searchPatient();
  }
  // 勾选患者
  function choosePatient(id: string, index: number) {
    if (list) {
      list[index].isChoosed = !list[index].isChoosed;
      if (list[index].isChoosed) {
        setCheckedPatientIds(checkedPatientIds + ',' + id + ',');
      } else {
        setCheckedPatientIds(checkedPatientIds.replace(',' + id + ',', ''));
        if (request.type === 'new_patient') {
          const tempArr = choosedList.filter(item => {
            return item.id !== id;
          });
          setChoosedList([...tempArr]);
        } else {
          const tempArr = choosedOldPatientList.filter(item => {
            return item.id !== id;
          });
          setChoosedOldPatientList([...tempArr]);
        }
      }
      setList([...list]);
    }
  }
  // 点击批量填写就诊编号显示弹窗
  function batchWrite(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      setModalShow({ ...modalShow, isShowModal: true });
    }
  }
  // 填写就诊编号
  function writeSampleSequence(value: string, index: number) {
    list[index].writeSampleSequence = value;
    setList([...list]);
  }
  // 点击校验
  async function verify(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      const newArr: string[] = [];
      list.forEach(item => {
        if (item.writeSampleSequence && item.isChoosed) {
          newArr.push(item.writeSampleSequence);
          const vefiryInfo = {
            age: item.age,
            gender: item.gender,
            name: item.name,
            id: item.id,
            sampleSequence: item.writeSampleSequence,
          };
          verifyResult.push(vefiryInfo);
        }
      });
      setVerifyResult([...verifyResult]);
      const result = await WeChatDoctor.verifySampleSequence(newArr);
      if (result.code === 0) {
        loopGetResult(result.result);
      }
    }
  }
  // 循环校验列表得到校验结果
  function loopGetResult(result: IVerifyResult[]) {
    const tempObj = {
      hasError: false,
      allRight: true,
    };
    verifyResult.forEach(item => {
      let isExist = false;
      result.forEach(element => {
        if (item.sampleSequence === element.sampleSequence) {
          isExist = true;
          if (element.isBind) {
            item.result = 'isBand';
            tempObj.hasError = true;
            tempObj.allRight = false;
            return;
          }
          if (item.age === element.age && item.gender === element.gender && item.name === element.name) {
            item.result = 'match';
          } else {
            item.result = 'notMatch';
            tempObj.allRight = false;
          }
          item.patientAge = element.age;
          item.patientGender = element.gender;
          item.patientName = element.name;
          item.patientId = element.id;
        }
      });
      if (!isExist) {
        item.result = 'notExist';
        tempObj.hasError = true;
        tempObj.allRight = false;
      }
      setHasVerifyResult({ ...hasVerifyResult, ...tempObj, hasResult: true });
    });
  }
  // 校验结果有错误时返回填写页面
  function backToWrite(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      setVerifyResult([]);
      setHasVerifyResult({ hasResult: false, hasError: false, allRight: true });
    }
  }
  // 校验完成并提交就诊编号
  async function verifySure(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      const newArr: IUpdateSampleSequenceItem[] = [];
      verifyResult.forEach(item => {
        const obj: IUpdateSampleSequenceItem = {
          patientId: item.patientId!,
          userId: item.id,
        };
        newArr.push(obj);
      });
      const result = await WeChatDoctor.updateSampleSequence(newArr);
      if (result.code === 0) {
        setModalShow({ ...modalShow, isShowModal: false });
        setVerifyResult([]);
        searchPatient();
        let tempStr = ',';
        verifyResult.forEach(item => {
          tempStr += item.id + ',';
        });
        // tslint:disable-next-line: no-shadowed-variable
        const newArr: IPatientItem[] = [];
        choosedList.forEach(v => {
          if (tempStr.includes(v.id)) {
            choosedOldPatientList.push(v);
          } else {
            newArr.push(v);
          }
        });
        setChoosedList(newArr);
        setChoosedOldPatientList([...choosedOldPatientList]);
      }
    }
  }
  // 点击完成
  function addSure(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      let tempStr = ',';
      choosedList.forEach(item => {
        tempStr += item.id + ',';
      });
      choosedOldPatientList.forEach(item => {
        tempStr += item.id + ',';
      });
      if (list.some(item => item.isChoosed === true && !tempStr.includes(item.id))) {
        setModalShow({ ...modalShow, memberNotInList: true });
      } else {
        saveAndJump();
      }
    }
  }
  // 将所选患者保存至dva以及跳转
  function saveAndJump() {
    dispatch({
      type: 'groupInfo/saveInfo',
      payload: {
        hulkUserInfoList: [...globalState.hulkUserInfoList, ...choosedList, ...choosedOldPatientList],
      },
    });
    props.history.push(`/Doctor/DoctorAddGroup/${type}/${groupId}`);
  }
  // 添加至组
  function addToGroup(e: React.MouseEvent<HTMLSpanElement>) {
    if (e.currentTarget.className.indexOf('disable') === -1) {
      if (request.type === 'new_patient') {
        setModalShow({ ...modalShow, addToGroupComfirm: true });
      } else {
        addToGroupSure();
      }
    }
  }
  // 从已选患者中移除
  function cancelToGroup(index: number, item: IPatientItem) {
    if (request.type === 'new_patient') {
      choosedList.splice(index, 1);
      setChoosedList([...choosedList]);
    } else {
      choosedOldPatientList.splice(index, 1);
      setChoosedOldPatientList([...choosedOldPatientList]);
    }
    setCheckedPatientIds(checkedPatientIds.replace(',' + item.id + ',', ''));
    list.forEach(listItem => {
      if (listItem.id === item.id) {
        listItem.isAddToGroup = false;
        listItem.isChoosed = false;
      }
    });
    setList([...list]);
  }
  // 弹窗确认后添加至已选列表
  function addToGroupSure() {
    setModalShow({ ...modalShow, addToGroupComfirm: false });
    let tempStr = ',';
    if (request.type === 'new_patient') {
      choosedList.forEach(v => {
        tempStr += v.id + ',';
      });
    } else {
      choosedOldPatientList.forEach(v => {
        tempStr += v.id + ',';
      });
    }
    const newArr = list.filter(item => {
      if (item.isChoosed && !tempStr.includes(item.id)) {
        item.isAddToGroup = true;
        return item.isChoosed;
      }
    });
    isAddMember = true;
    if (request.type === 'new_patient') {
      setChoosedList([...choosedList, ...newArr]);
    } else {
      setChoosedOldPatientList([...choosedOldPatientList, ...newArr]);
    }
  }
  let listHtml; // 患者列表
  if (list.length <= 0) {
    listHtml = request.name ? (
      <div className="noGroup">
        <img src={require('./../../../assets/moblieImg/img_search_null.svg')} />
        <p>没有更多的搜索结果</p>
      </div>
    ) : (
      <div className="noGroup">
        <img src={require('./../../../assets/moblieImg/img_patient_null.svg')} />
        <p>暂无患者</p>
      </div>
    );
  } else {
    listHtml = list.map((item, index) => {
      if (!item.isHide) {
        return (
          <div className="patientItem" key={item.id}>
            <img
              className="selectIcon"
              // tslint:disable-next-line: jsx-no-multiline-js
              src={
                item.isChoosed
                  ? require('../../../assets/img/icon_add_patient_checkbox_select.svg')
                  : require('../../../assets/img/icon_add_patient_checkbox.svg')
              }
              onClick={() => choosePatient(item.id, index)}
            />
            <img src={item.headimgurl} className="photo" />
            <div className="infoArea">
              <p>{item.name}</p>
              {// tslint:disable-next-line: jsx-no-multiline-js
              item.sampleSequence ? (
                <p>{`就诊编号：${item.sampleSequence}`}</p>
              ) : (
                <p className={'colorNull'}>未填写就诊编号</p>
              )}
            </div>
          </div>
        );
      }
    });
  }
  const choosedPatientHtml =
    request.type === 'new_patient'
      ? choosedList.map((item, index) => {
          return <img src={item.headimgurl} key={item.id} onClick={() => cancelToGroup(index, item)} />;
        })
      : choosedOldPatientList.map((item, index) => {
          return <img src={item.headimgurl} key={item.id} onClick={() => cancelToGroup(index, item)} />;
        });
  // 校验结果
  const verifyResultHtml = verifyResult.map(item => {
    let detailHtml;
    switch (item.result) {
      case 'match':
        detailHtml = (
          <div className={'details ' + item.result}>
            <div>就诊编号是{item.sampleSequence}的患者信息：</div>
            <div className="patientName">
              <span className="name">{item.patientName}</span>
              <span>{`（${item.gender === 1 ? '男' : '女'} ${item.age}岁）`} </span>
            </div>
            <div>需要添加进组的患者与此就诊编号相符</div>
          </div>
        );
        break;
      case 'notMatch':
        detailHtml = (
          <div className={'details ' + item.result}>
            <div>
              就诊编号是{item.sampleSequence}的患者信息：
              <img src={require('../../../assets/img/icon_inconformity.svg')} />
            </div>
            <div className="patientName">
              <span className="name">{item.patientName}</span>
              <span>{`（${item.gender === 1 ? '男' : '女'} ${item.age}岁）`} </span>
            </div>
            <div>需要添加进组的患者与此就诊编号不相符</div>
          </div>
        );
        break;
      case 'notExist':
        detailHtml = (
          <div className={'details ' + item.result}>
            <div>
              暂无就诊编号是{item.sampleSequence}的患者
              <img src={require('../../../assets/img/icon_inconformity.svg')} />
            </div>
            <div>请检查就诊编号输入是否有误</div>
          </div>
        );
        break;
      case 'isBand':
        detailHtml = (
          <div className={'details ' + item.result}>
            <div>
              {item.sampleSequence}的就诊编号已被其他患者绑定
              <img src={require('../../../assets/img/icon_inconformity.svg')} />
            </div>
            <div>请检查就诊编号输入是否有误</div>
          </div>
        );
        break;
    }
    return (
      <div className="verifyResult" key={item.id}>
        <div className="titleArea">
          <div className="leftArea">
            <span className="name">{item.name}</span>
            <span>{`（${item.gender === 1 ? '男' : '女'} ${item.age}岁）`} </span>
          </div>
          <span className="sampleSequence">{item.sampleSequence}</span>
        </div>
        {detailHtml}
      </div>
    );
  });
  // 填写就诊编号列表
  const inputHtml = list.map((item, index) => {
    if (item.isChoosed) {
      return (
        <InputItem
          placeholder="输入就诊编号"
          className="numberInput"
          key={item.id}
          onChange={value => writeSampleSequence(value, index)}
          defaultValue={item.writeSampleSequence}
        >
          <span className="name">{item.name}</span>
          <span>{`（${item.gender === 1 ? '男' : '女'} ${item.age}岁）`} </span>
        </InputItem>
      );
    }
  });
  return (
    <div id="Doctor-AddGroupMember">
      <div className={'addGroupMemberHeader'}>
        <MobileHeader backUrl={`/Doctor/DoctorAddGroup/${type}/${groupId}`}>
          <Button type={'link'} disabled={!(choosedList.length || choosedOldPatientList.length)} onClick={addSure}>
            完成
          </Button>
        </MobileHeader>
      </div>

      <div className="searchArea">
        <MobileSearch
          placeholder={'搜索患者姓名或就诊编号'}
          searchText={'搜索'}
          onChange={
            // tslint:disable-next-line: jsx-no-multiline-js
            value => {
              setRequest({ ...request, name: value });
              setList([...list]);
            }
          }
          onSearch={searchPatient}
        />
      </div>
      <div className="tabArea">
        <div onClick={() => switchTab('old_patient')}>
          <p>老患者</p>
          {request.type === 'old_patient' ? <div className="choosed" /> : ''}
        </div>
        <div onClick={() => switchTab('new_patient')}>
          <p>新患者</p>
          {request.type === 'new_patient' ? <div className="choosed" /> : ''}
        </div>
        {// tslint:disable-next-line: jsx-no-multiline-js
        showTips ? (
          <span onClick={() => setShowTips(!showTips)}>收起注释</span>
        ) : (
          <img src={require('../../../assets/img/icon_tips.svg')} onClick={() => setShowTips(!showTips)} />
        )}
      </div>
      {// tslint:disable-next-line: jsx-no-multiline-js
      showTips ? (
        <div className="tips">
          <p>老患者：已有就诊编号的患者（首次访视已完成）</p>
          <p>新患者：没有就诊编号的患者或者还未填写就诊编号的患者（首次访视未完成）</p>
        </div>
      ) : (
        ''
      )}

      <div className="choosedList">
        <span>已选患者</span>
        <div className="choosedPatient" id="choosedPatient">
          {choosedPatientHtml}
        </div>
      </div>
      <div className="patientList">{listHtml}</div>
      <div className={`bottomArea ${list.some(value => value.isChoosed === true) ? 'showBottomArea' : ''} `}>
        {// tslint:disable-next-line: jsx-no-multiline-js
        request.type === 'new_patient' ? (
          <span className={list.some(v => v.isChoosed === true) ? '' : 'disable'} onClick={batchWrite}>
            批量填写就诊编号
          </span>
        ) : (
          ''
        )}
        <span className={list.some(v => v.isChoosed === true) ? 'rightArea' : 'rightArea disable'} onClick={addToGroup}>
          添加至组
        </span>
      </div>
      <Modal
        popup={true}
        visible={modalShow.isShowModal}
        animationType="slide-up"
        className="DoctorAddMemberModal"
        maskClosable={true}
        onClose={() => setModalShow({ ...modalShow, isShowModal: false })}
      >
        <div className="modalHeader">
          <span className="modalTitle">批量填写就诊编号</span>
          {// tslint:disable-next-line: jsx-no-multiline-js
          hasVerifyResult.hasResult ? (
            <div>
              <span className={hasVerifyResult.allRight ? 'sure disable' : 'sure'} onClick={backToWrite}>
                继续校验
              </span>
              <span className={hasVerifyResult.hasError ? 'sure disable' : 'sure'} onClick={verifySure}>
                我已校验
              </span>
            </div>
          ) : (
            <span
              className={
                // tslint:disable-next-line: jsx-no-multiline-js
                list.some(item => {
                  return item.isChoosed === true && item.writeSampleSequence !== undefined;
                })
                  ? 'sure'
                  : 'sure disable'
              }
              onClick={verify}
            >
              校验
            </span>
          )}
        </div>
        {// tslint:disable-next-line: jsx-no-multiline-js
        hasVerifyResult.hasResult ? (
          <div className="verifyResultContainer">{verifyResultHtml}</div>
        ) : (
          <div className="numberInputContainer">{inputHtml}</div>
        )}
      </Modal>
      <Modal visible={modalShow.addToGroupComfirm} transparent={true} className="addToGroupComfirm">
        <div className="content">
          <img src={require('../../../assets/img/icon_inconformity.svg')} />
          <div className="firstLine">未填写就诊编号的患者会以新患者身份加入组</div>
          <div>在量表系统内会产生新的病例数据，请注意检查</div>
        </div>
        <div className="buttonContainer">
          <span className="cancel" onClick={() => setModalShow({ ...modalShow, addToGroupComfirm: false })}>
            取消
          </span>
          <span className="sure" onClick={addToGroupSure}>
            确定添加
          </span>
        </div>
      </Modal>
      <Modal visible={modalShow.memberNotInList} transparent={true} className="memberNotInList">
        <div className="content">
          <img src={require('../../../assets/img/icon_inconformity.svg')} />
          <div className="firstLine">您还有已勾选的患者未添加至组</div>
          <div>请注意检查</div>
        </div>
        <div className="buttonContainer">
          <span className="cancel" onClick={() => setModalShow({ ...modalShow, memberNotInList: false })}>
            我再想想
          </span>
          <span className="sure" onClick={saveAndJump}>
            确定完成
          </span>
        </div>
      </Modal>
    </div>
  );
};
