import * as Sentry from '@sentry/browser';
import Config from './config/Config';
import './index.scss';
Sentry.init({ dsn: Config.activeConfig.SENTRY_DSN, environment: Config.activeConfig.SENTRY_ENVIRONMENT });
Sentry.forceLoad();
// tslint:disable-next-line:ordered-imports
import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';
import './Model/Model';
import Storage from './utils/Storage';
if (!window.location.href.includes('/Doctor') && !window.location.href.includes('/Patient')) {
  getPageTitle();
}
function getPageTitle() {
  let num = 1;
  const inter = setInterval(() => {
    const storage = new Storage();
    const global = JSON.parse(storage.getStore('global'));
    num++;
    if ((document.getElementById('title') && global.companyInfo.name) || num === 100) {
      clearInterval(inter);
      document.getElementById('title')!.innerHTML = global.companyInfo.name;
    }
  }, 500);
}
// tslint:disable-next-line:no-console
console.log('welcome come  🚀');
