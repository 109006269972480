import { Menu, Tooltip } from 'antd';
import { SelectParam } from 'antd/lib/menu';
import { useDispatch, useSelector } from 'dva';
import React, { useState } from 'react';
const { SubMenu } = Menu;
import { IAllGauge } from '../../assets/js/DataJson';
import { ISurvey } from '../../Model/SurveyModel';
import SurveyModelUtil from './../../utils/Survey/SurveyModelUtil';
// import AuthCompount from './../AuthCompount/AuthCompount';
import './FormNavigation.scss';
interface IProps {
  changeGauge: () => void;
}
/**
 *  表单侧边导航栏
 *  直接从dva里面拿数据
 */
export default (props: IProps) => {
  const dispatch = useDispatch();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState('');
  const gaugeData = useSelector(({ survey }: { survey: ISurvey }) => survey.gaugeData);
  // const menuIndex = useSelector(({ survey }: { survey: ISurvey }) => survey.menuIndex);
  /**
   * 点击导航栏
   */
  const changeGauge = (selectedKeys: SelectParam) => {
    const menuIndexKey = Number(selectedKeys.key.split('+')[0]);
    const menuIChild = Number(selectedKeys.key.split('+')[1]);
    // 直接把选择的父子下标存到dva里
    dispatch({
      type: 'survey/selectMenu',
      payload: {
        menuIndex: menuIndexKey,
        menuIChild,
      },
    });
    setDefaultSelectedKeys(selectedKeys.key);
    props.changeGauge();
  };
  /**
   * 更改tip挂载
   */
  const elemtCurrent = () => {
    return document.getElementById('menu') ? document.getElementById('menu')! : document.body;
  };

  const surveyModelUtil = new SurveyModelUtil();
  return (
    <Menu
      id="menu"
      mode="inline"
      selectedKeys={[defaultSelectedKeys]}
      // tslint:disable-next-line:jsx-no-multiline-js
      defaultSelectedKeys={[defaultSelectedKeys]}
      // tslint:disable-next-line:no-any
      defaultOpenKeys={[gaugeData.filter((item: any) => item.isShow)[0].moduleName]}
      onSelect={changeGauge}
    >
      {// tslint:disable-next-line:jsx-no-multiline-js
      gaugeData.map((item: IAllGauge, index: number) => {
        return item.isShow ? (
          <SubMenu
            key={item.moduleName}
            // tslint:disable-next-line:jsx-no-multiline-js
            title={
              // tslint:disable-next-line:jsx-wrap-multiline
              <span className={surveyModelUtil.errStyle(item, index)}>{item.moduleName}</span>
            }
          >
            {// tslint:disable-next-line:jsx-no-multiline-js
            item.moduleChildrens.map((itemChild, iChild) => {
              return itemChild.isShow ? (
                <Menu.Item
                  key={`${index}+${iChild}`}
                  // tslint:disable-next-line:jsx-no-multiline-js
                  className={surveyModelUtil.errStyle(item, index, iChild)}
                >
                  <Tooltip
                    placement="topLeft"
                    autoAdjustOverflow={true}
                    title={itemChild.moduleName}
                    // tslint:disable-next-line:jsx-no-multiline-js
                    overlayStyle={{
                      zIndex: 999999999,
                    }}
                    // tslint:disable-next-line:jsx-no-multiline-js
                    getPopupContainer={elemtCurrent}
                  >
                    {itemChild.moduleName}
                  </Tooltip>
                </Menu.Item>
              ) : null;
            })}
          </SubMenu>
        ) : null;
      })}
      <div style={{ height: '253px' }} />
    </Menu>
  );
};
