import Config from './../config/Config';
import GlobalModel from './../Model/GlobalModel';
import GroupModel from './../Model/GroupModel';

/**
 * 缓存，该项目需要用缓存的地方必须要用这个类，这里会校验缓存版本
 */
class Storage {
  constructor() {
    this.checkVersion();
  }
  public setStore = (key: string, content: string) => {
    localStorage.setItem(key, content);
  };
  public getStore = (key: string) => {
    return localStorage.getItem(key) || JSON.stringify(GlobalModel.state);
  };
  public delStore = (key: string) => {
    localStorage.removeItem(key);
  };
  public clearStore = () => localStorage.clear;

  /**
   * 检查缓存版本
   */
  private checkVersion = () => {
    GlobalModel.state.versionCatch = Config.activeConfig.LOCAL_CACHE_VERSION;
    const catchData = JSON.parse(localStorage.getItem('global') || '{}');
    if (catchData.versionCatch !== Config.activeConfig.LOCAL_CACHE_VERSION) {
      localStorage.setItem('global', JSON.stringify(GlobalModel.state));
      localStorage.setItem('groupInfo', JSON.stringify(GroupModel.state));
    }
  };
}
export default Storage;
