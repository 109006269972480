import { message } from 'antd';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import React from 'react';
import { useWindowUpdateWidth } from '../../utils/UtilHook';
import './QRCmp.scss';
/**
 * 列表二维码
 */
export interface IQrContentProps {
  url?: string;
  width: number;
}
export function QrContent(props: IQrContentProps) {
  if (!props.url) {
    return (
      <div style={{ width: useWindowUpdateWidth(props.width) }} className="QrContainer">
        二维码
      </div>
    );
  }
  /**
   * 点击复制
   */
  function handleCopy() {
    if (copy(props.url || '')) {
      message.success('复制成功');
    } else {
      message.error('复制失败,请重新复制');
    }
  }

  return (
    <div className="QrContainer">
      <QRCode value={props.url} size={useWindowUpdateWidth(props.width)} />
      <div className="url" onClick={handleCopy}>
        点击复制链接
      </div>
      <div className="discription">展示此二维码给患者编辑问卷 </div>
    </div>
  );
}
