import axios from './RequestApi';
/**
 * @class 我的数据，包括节点用户和云端用户
 */
export default class QRActionApi {
  /**
   *
   * @param 获取二维码信息
   */
  public static getQRCodeMsg(data: IQRCodeMsgReq) {
    return axios.get<IQRCodeMsg, IQRCodeMsgReq>(`/qrcode/info`, data);
  }

  /**
   * 获取二维码
   */

  public static getQRImg() {
    return axios.get<string, {}>(`/logined/qrcode/base64`);
  }
  /**
   *
   * @param 新增病例二维码
   */

  public static getDoctorQRImgUrl() {
    return axios.get<string, {}>(`/logined/qrcode`);
  }
  /**
   *
   * @param 编辑问卷二维码
   */

  public static getSurveyQRImgUrl(id: number) {
    return axios.get<string, {}>(`/logined/qrcode/answer`, { id });
  }

  /**
   * 下载 PDF
   */
  public static getPDFStream(id: number) {
    return axios.get<string, {}>(`/logined/pdf/download`, { id }, 'blob');
  }

  /**
   * 下载 Excel模板
   */
  public static getExceltemplate() {
    return axios.get<string, {}>(`/logined/excel/template/downLoad`, {}, 'blob');
  }
}

export interface IQRCodeMsgReq {
  code: string;
}

export interface IQRCodeMsg {
  questionVo: IAnswerInfoVo;
  channel: string;
  name: string;
  organizationVo: IOrganizationVo;
  userId: number;
  visitNumber: number;
  type: number;
  answerInfoVo: {
    answerJson: string;
    gender: number | null;
  };
}

export interface IAnswerInfoVo {
  appVersion: string;
  created: string;
  expertName: string;
  id: number;
  isSubmit: false;
  maxVisitNumber: number;
  patientId: number;
  questionAnswerJson: string;
  status: number;
  userId: number;
  visitNumber: number;
  jsonDesc: string;
}
export interface IOrganizationVo {
  code: string;
  id: number;
  logoUrl: string;
  name: string;
  systemName: string;
}
