import { Button } from 'antd';
import { useSelector } from 'dva';
import { RouteComponentProps } from 'dva/router';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { IconFont } from '../../../config/IconConfig';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import { useWindowUpdateWidth } from '../../../utils/UtilHook';
import { GaugeSurvey } from './../../../api';
import { AuthCompount, Foot } from './../../../components';

import './SubmitCase.scss';
interface IProps
  extends RouteComponentProps<{
    code: string;
    name: string;
    sampleSequence: string;
  }> {}

function SubmitCase(props: IProps) {
  /**
   * 获取 url 参数
   */
  const getQueryVariable = (urlKey: string) => {
    const url = window.location.href;
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg);
    const obj = new Map();
    if (arr) {
      arr.forEach(item => {
        const tempArr = item.substring(1).split('=');
        const key = decodeURIComponent(tempArr[0]);
        const val = decodeURIComponent(tempArr[1]);
        obj.set(key, val);
      });
    }
    return obj.get(urlKey);
  };
  const urlStr: string = getQueryVariable('roleAndUrl') || '';
  const answerId: string = getQueryVariable('answerId') || '';
  const proposal: string = getQueryVariable('proposal') || '';
  const globalData = useSelector(({ global }: { global: IGlobal }) => global);
  const qrWidth = useWindowUpdateWidth(300);
  const [imgUrl, setImgUrl] = useState('');
  useEffect(() => {
    UpdateTitle(globalData.companyInfo.name ? globalData.companyInfo.name : '');
    if (answerId) {
      getDoctorCode();
    }
  }, []);
  async function getDoctorCode() {
    const { result } = await GaugeSurvey.getDoctorCode({ id: answerId });
    setImgUrl(result);
  }
  return (
    <div className="SubmitCase">
      <div className="topArea">
        <div className="header2">病例名称：{props.match.params.name}</div>
      </div>
      <div className="contetArea">
        <div className="contentContainer">
          <IconFont className="fontSize" type="iconicon_finish1" />
          <div className="submit"> 提交成功</div>
          <AuthCompount isShow={!proposal}>
            <div className="codeTitle"> 您的就诊序号</div>
            <div className="codeNum">{props.match.params.sampleSequence}</div>
            <div className="hintTitle"> 请找医生获得预约单后再关闭该页面</div>
            <AuthCompount isShow={!!urlStr && urlStr !== 'undefined'}>
              <div>
                <Button type={'primary'} onClick={() => props.history.push('/' + urlStr.replace(/,/g, '/'))}>
                  返回通知列表
                </Button>
              </div>
            </AuthCompount>
          </AuthCompount>
          <AuthCompount isShow={!!proposal}>
            <div className="mar">{proposal}</div>
            <QRCode value={imgUrl} size={qrWidth} />
            <div className="mar hintTitle"> 请找医生获得预约单后再关闭该页面</div>
          </AuthCompount>
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default SubmitCase;
