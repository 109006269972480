import { Icon } from 'antd';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { IconFont } from '../../../config/IconConfig';
import { AuthCompount, Foot } from './../../../components';
import './JNL20200001.scss';
interface IProps
  extends RouteComponentProps<{
    code: string;
    name: string;
    sampleSequence: string;
  }> {}

function HatVirus(props: IProps) {
  /**
   * 获取 url 参数
   */
  const getQueryVariable = (urlKey: string) => {
    const url = window.location.href;
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg);
    const obj = new Map();
    if (arr) {
      arr.forEach(item => {
        const tempArr = item.substring(1).split('=');
        const key = decodeURIComponent(tempArr[0]);
        const val = decodeURIComponent(tempArr[1]);
        obj.set(key, val);
      });
    }
    return obj.get(urlKey);
  };
  const [Q01816, setQ01816] = useState<string[]>([]);
  const [Q01817, setQ01817] = useState<string[]>([]);
  const [Q01818] = useState(getQueryVariable('Q01818'));
  const [Q01819, setQ01819] = useState<string[]>([]);
  const split = (val: string) => {
    if (val) {
      return val.split(',');
    }
    return [];
  };
  useEffect(() => {
    setQ01816(split(getQueryVariable('Q01816')));
    const region = split(getQueryVariable('Q01817'));
    region.sort((a, b) => {
      const top = a.split('-')[1];
      const bottom = b.split('-')[1];
      return Number(top) - Number(bottom);
    });
    setQ01817(region);
    setQ01819(split(getQueryVariable('Q01819')));
  }, []);

  // tslint:disable-next-line: no-any
  function pneumonia(A: any, B1: any, B2: any, B3: any) {
    let bArea = 0;
    A = A ? rNums(A) : [];
    B2 = B2 ? Number(B2) : 0; // travel
    // B3 = B3 ? B3.length : 0;
    // B1 =areaDone(B1);
    // tslint:disable-next-line:no-any
    B1.forEach((v: any) => {
      bArea += Number(v.split('-')[1]);
    });
    const B = bArea + B2 * 2 + B3 * 2;
    // tslint:disable-next-line:no-console
    console.log(bArea, A, B1, B2, B3);
    const normal = '建议正常生活，做好个人防护，注意个人卫生。';
    const fever =
      '密切观察，如果自觉症状较重或有明显加重，建议佩戴N95或外科口罩，至医院发热门诊就诊。如若条件受限无法就诊，请居家单间隔离，密切观察病情变化。';
    const degree =
      '密切观察，如果自觉症状较重或有明显加重，建议佩戴N95或外科口罩，至医院发热门诊就诊。如若条件受限无法就诊，请居家单间隔离，密切观察病情变化。';
    const specialist =
      '密切观察，如果自觉症状较重或有明显加重，建议佩戴口罩，到医院普通专科门诊或急诊就诊。如若条件受限无法就诊，请密切观察病情变化。';
    const home = '建议居家密切观察，需定期评测。';
    const isolation = '建议居家单间隔离，密切观察，需定期评测。';
    const aCount = A.length;
    // tslint:disable-next-line: no-shadowed-variable
    let msg = '';
    if (aCount === 0 && B < 2) {
      msg = normal;
    } else if (A.indexOf(1) > -1 && intersect(A, [2, 4, 5, 6, 7, 8, 9, 10, 11]).length >= 1) {
      msg = fever;
    } else if (intersect(A, [2, 3, 4, 5, 6, 7]).length >= 2 && B >= 2) {
      msg = degree;
    } else if (intersect(A, [2, 3, 4, 5, 6, 7]).length >= 1 && intersect(A, [8, 9, 10, 11]).length >= 1 && B >= 2) {
      msg = degree;
    } else if ((A.indexOf(6) > -1 || A.indexOf(9) > -1) && B >= 2) {
      msg = degree;
    } else if (intersect(A, [1, 2, 3, 4, 5, 6, 7]).length === 0 && intersect(A, [8, 9, 10, 11]).length >= 1) {
      msg = specialist;
    } else if (intersect(A, [8, 9, 10, 11]).length >= 1 && B < 2) {
      msg = specialist;
    } else if (A.indexOf(6) > -1 && B < 2) {
      msg = specialist;
    } else if (intersect(A, [2, 3, 4, 5, 7]).length === 1) {
      msg = home;
    } else {
      msg = isolation;
    }
    return msg;
  }
  // 计算肺炎 临时代码
  // tslint:disable-next-line:no-any
  function intersect(arr1: any, arr2: any) {
    if (
      Object.prototype.toString.call(arr1) === '[object Array]' &&
      Object.prototype.toString.call(arr2) === '[object Array]'
    ) {
      // tslint:disable-next-line:no-any
      return arr1.filter((v: any) => {
        return arr2.indexOf(v) !== -1;
      });
    }
  }

  /**
   * string[] 转 number[]
   */
  function rNums(arr: string[]) {
    return arr.map(item => {
      return Number(item);
    });
  }
  /**
   * 判断是否有* 如果有则删掉*
   */
  const q19 = Q01819.length !== 0 ? 1 : 0;
  // console.log(String(Q01818))
  const msg = pneumonia(Q01816, Q01817, String(Q01818).lastIndexOf('是') !== -1 ? 1 : 0, q19);
  return (
    <div className="JNL">
      <div className="contetArea">
        <div className="contentContainer">
          <IconFont className="fontSize" type="iconicon_finish1" />
          <div className="submit"> 提交成功</div>
          <AuthCompount isShow={!!msg}>
            <div className="mar">评测结果：{msg}</div>
          </AuthCompount>
        </div>
        <div className="mar2">
          <Icon className="icon_sy" type="exclamation-circle" />
          温馨提示：
          <br />
          本自测表基于国家卫生健康委办公厅印发的新型冠状病毒感染有关指南编制，仅作为疾病自我评估，不具备诊断效力。如出现发热或其它不适症状，请及时前往医院就诊。
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default HatVirus;
