import { useDispatch } from 'dva';
import React from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';

import './FormTextear.scss';
const isPc = window.screen.width > 500;
/**
 * 多行文本
 * 在subjectData里面type为FormTextear
 */
function FormTextear(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  const dispatch = useDispatch();
  function inputChange(str: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: str.currentTarget.value,
      },
    });
  }

  return (
    <div id="FormTextear">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.errMsg ? 'cl star' : 'star'}
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
            >
              *
            </div>
          </AuthCompount>
          <div>{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>

      <textarea
        cols={20}
        className="form_input"
        defaultValue={formObj.defaultValue}
        onChange={inputChange}
        disabled={formObj.disable}
        placeholder={formObj.placeholder}
        id={props.formObj.subjectData.field}
      />
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormTextear);
