import { Icon } from 'antd';
import React from 'react';

interface ISimpleContentProps {
  sampleSequence: string;
  isBind: boolean | undefined;
}
/*
 * 用来显示简单的 校验提示，已绑定，无就诊编号
 *
 * */
function SimpleContent(props: ISimpleContentProps) {
  const { sampleSequence, isBind } = props;

  return (
    <div id={'simpleContent'}>
      <header>
        <Icon type="info-circle" theme="filled" />
      </header>
      <strong>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {isBind ? `${sampleSequence}的就诊编号已被其他患者绑定` : `暂无就诊编号是${sampleSequence}的患者`}
      </strong>
      <span>请检查就诊编号输入是否有误</span>
    </div>
  );
}

export default SimpleContent;
