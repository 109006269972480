function GetHundredYear() {
  const year = new Date().getFullYear();
  const yearData = [];
  for (let i = year - 10; i < year + 10; i++) {
    yearData.push(i.toString());
  }
  return yearData;
}

export default GetHundredYear();
