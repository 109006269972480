// import { NavBar } from 'antd-mobile';
import { Button } from 'antd';
import { DatePicker, Modal } from 'antd-mobile';
import { SubscriptionAPI } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { Fragment, useEffect, useState } from 'react';
import WeChatDoctor, { IGroup } from '../../../api/WeChatDoctor';
import { MobileHeader } from '../../../components/index';
import { UpdateTitle } from '../../../utils';
import './DoctorGroupDetail.scss';
interface IProps
  extends SubscriptionAPI,
    RouteComponentProps<{
      groupId: string;
    }> {}

export default (props: IProps) => {
  // tslint:disable-next-line: radix
  const groupId: number = parseInt(props.match.params.groupId);
  const [state, setState] = useState<IGroup>({
    id: -1,
    name: '',
    description: '',
    hulkUserInfoList: [],
  });
  const [isShowModal, setIsShowModal] = useState({ show: false, name: '', morphUserUuid: '' });
  useEffect(() => {
    UpdateTitle('我的分组');
    groupDetail(groupId);
  }, []);
  function remove(name: string, morphUserUuid: string) {
    setIsShowModal({ show: true, name, morphUserUuid });
  }
  async function deleteSure() {
    const result = await WeChatDoctor.deleteGroupMember({ data: { id: groupId, userId: isShowModal.morphUserUuid } });
    if (result.code === 0) {
      groupDetail(groupId);
      setIsShowModal({ ...isShowModal, show: false });
    }
  }
  // 编辑入组时间
  function editTime(i: number, bool: boolean) {
    state.hulkUserInfoList[i].editing = bool;
    setState({ ...state });
  }
  // 分组详情
  async function groupDetail(id: number) {
    const { result } = await WeChatDoctor.groupDetail(id);
    setState({ ...state, ...result });
  }
  // 编辑入组时间
  async function editGroupJoinTime(id: string, date: Date, index: number) {
    const d = new Date(date);
    const resDate = d.getFullYear() + '-' + p(d.getMonth() + 1) + '-' + p(d.getDate());
    const resTime = p(d.getHours()) + ':' + p(d.getMinutes()) + ':' + p(d.getSeconds());
    const result = await WeChatDoctor.editGroupJoinTime({
      // tslint:disable-next-line: radix
      groupId,
      relationshipUserId: id,
      joinTime: resDate + ' ' + resTime,
    });
    if (result.code === 0) {
      state.hulkUserInfoList[index].editing = false;
      setState({ ...state });
      groupDetail(groupId);
    }
  }
  function p(s: number) {
    return s < 10 ? '0' + s : s;
  }
  let list;
  if (state.hulkUserInfoList.length <= 0) {
    list = (
      <div className="noGroup">
        <img src={require('./../../../assets/img/img_follow_up_null.svg')} />
        <p>该分组下没有患者</p>
      </div>
    );
  } else {
    list = state.hulkUserInfoList.map((v, index) => {
      return (
        <div key={v.id} className="patientItem">
          <p className="titlePatient">
            <img className="photo" src={v.headimgurl} />
            <span className="name">{v.name}</span>
            <span>{v.gender ? '男' : '女'}</span>
            <span>{v.age}岁</span>
            <Button className="remove" onClick={() => remove(v.name, v.id)}>
              移除
            </Button>
          </p>
          <div className={'sampleSequence'}>
            {// tslint:disable-next-line: jsx-no-multiline-js
            v.sampleSequence ? <p>{`就诊编号：${v.sampleSequence}`}</p> : <p className={'colorNull'}>未填写就诊编号</p>}
          </div>
          <p>
            <span className={'joinTime'}>入组时间：{v.joinTime ? v.joinTime.slice(0, 10) : ''}</span>

            <img
              className="edit"
              src={require('../../../assets/img/icon_edit_new.svg')}
              onClick={() => editTime(index, true)}
            />
            <DatePicker
              visible={v.editing}
              mode="date"
              className={'timePicker450pxCenter'}
              title=""
              extra="选择"
              value={new Date(Date.now())}
              onChange={date => editGroupJoinTime(v.id, date, index)}
              onDismiss={() => editTime(index, false)}
            />
          </p>
        </div>
      );
    });
  }
  return (
    <div id="Doctor-DoctorGroupDetail">
      {/* <NavBar mode="light" leftContent={[<img src={require('./../../../assets/img/btn_back.svg')} />, '返回']}></NavBar> */}
      <div className="contentArea">
        <div className="topMenu">
          <MobileHeader backUrl={`/Doctor/DoctorGroup`}>
            <Fragment>
              <Button type={'link'} onClick={() => props.history.push(`/Doctor/DoctorAddGroup/EDIT/${groupId}`)}>
                编辑组
              </Button>
              <Button type={'primary'} onClick={() => props.history.push(`/Doctor/DoctorSetPeriod/${groupId}`)}>
                自动推送通知
              </Button>
            </Fragment>
          </MobileHeader>
        </div>

        <div className="scrollArea">
          <div className="introduction">
            <p className="name">{state.name}</p>
            <p className="remark">{state.description}</p>
          </div>
          <div className="patientList">{list}</div>
        </div>
      </div>
      <Modal
        className="refuseModal"
        visible={isShowModal.show}
        transparent={true}
        maskClosable={false}
        // tslint:disable-next-line: jsx-no-multiline-js
        footer={[
          {
            text: '确定删除',
            onPress: deleteSure,
          },
          {
            text: '我再想想',
            onPress: () => {
              setIsShowModal({ ...isShowModal, show: false });
            },
          },
        ]}
      >
        <div className={'delPatient'}>确定移除 {isShowModal.name} 吗？</div>
      </Modal>
    </div>
  );
};
