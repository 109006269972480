import { ConfigProvider } from 'antd';
import { RouterAPI } from 'dva';
import { Route, Router, Switch } from 'dva/router';
import React, { useEffect } from 'react';
import { Loading } from './components';
import WxConnect from './layout/WxConnect';
import Routes, { IRouteConfigItem } from './pages/Routes';
import WetChatRoutes from './weChat/Routes';
function RouterConfig(api?: RouterAPI) {
  useEffect(() => {
    window.addEventListener('resize', setRem);
    document.documentElement.addEventListener('DOMContentLoaded', setRem, false);
    setInterval(() => {
      setRem();
    }, 200);
    return () => {
      window.removeEventListener('resize', setRem);
    };
  }, []);
  function setRem() {
    const docEl = document.documentElement;
    // tslint:disable
    docEl.style.fontSize = 'inherit';
    const user_webset_font = String(window.getComputedStyle(docEl, null).fontSize);
    // console.log(user_webset_font)
    if (parseFloat(user_webset_font) < 100) {
      const xs = parseFloat(user_webset_font) / 16;
      docEl.style.fontSize = docEl.clientWidth >= 450 ? '450px' : Math.floor(docEl.clientWidth / xs) + 'px';
    }
  }
  if (api) {
    const { history } = api;
    // 换一个循环
    const subRoute = (param: IRouteConfigItem[]) => {
      const routeResults: JSX.Element[] = [];
      const arrRouter: IRouteConfigItem[] = [...param];
      let index = 0;

      while (index < arrRouter.length) {
        if (arrRouter[index].routes && (arrRouter[index].routes as []).length) {
          arrRouter.push(...(arrRouter[index].routes as []));
        } else {
          routeResults.push(
            <Route path={arrRouter[index].path} key={index} exact={true} component={arrRouter[index].component} />
          );
        }
        index++;
      }
      return routeResults;
    };
    setRem();
    const wetChatStyle = {
      margin: '0 auto',
      maxWidth: '450px',
      minHeight: '100%',
      boxShadow: '0px 0px 20px 0px rgba(87, 82, 82, 0.3)',
    };
    const test = false;
    if (test) {
      return (
        <div style={{ height: 'inherit' }}>
          <ConfigProvider autoInsertSpaceInButton={false}>
            <Router history={history}>
              <Switch>
                {subRoute(Routes)}
                <div style={wetChatStyle}>{subRoute(WetChatRoutes)}</div>
              </Switch>
              <Loading />
            </Router>
          </ConfigProvider>
        </div>
      );
    } else {
      return (
        <div style={{ height: 'inherit' }}>
          <WxConnect>
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Router history={history}>
                <Switch>
                  {subRoute(Routes)}
                  <div style={wetChatStyle}>{subRoute(WetChatRoutes)}</div>
                </Switch>
                <Loading />
              </Router>
            </ConfigProvider>
          </WxConnect>
        </div>
      );
    }
  } else {
    return {};
  }
}

export default RouterConfig;
