import { Button, Modal, Spin, Tooltip } from 'antd';
import { connect, SubscriptionAPI } from 'dva';
import React from 'react';
import SurveyModelUtil from '../../../utils/Survey/SurveyModelUtil';
import GaugeSurveyApi from './../../../api/GaugeSurvey';
import { IAllGauge, IGauge, ISubjectV } from './../../../assets/js/DataJson';
import { AuthCompount, FormList, FormNavigation, ModuleTitle } from './../../../components';
import LayoutComponent from './../../../layout/LayoutComponent';
import { IGlobal } from './../../../Model/GlobalModel';
import { ISurvey } from './../../../Model/SurveyModel';
import './GaugeSurvey.scss';
interface IProps extends SubscriptionAPI {
  survey: ISurvey;
  global: IGlobal;
  // tslint:disable-next-line:no-any
  dispatch: any;
}
interface IAttentionItem {
  moduleName: string;
  subDataList: string[];
  expansion: boolean;
}
interface IState {
  data: {
    user: string;
  };
  clickIf: boolean;
  patient_code: string;
  patient_name: string;
  isSpinning: boolean;
  isQrcode: boolean;
  surveyQrcodes: string[];
  isShowAttention: boolean;
  attentions?: IAttentionItem[];
}
const isPc = window.screen.width > 500;

class GaugeSurvey extends React.Component<IProps> {
  public state = {
    data: {},
    clickIf: false,
    patient_code: '',
    patient_name: '',
    isSpinning: false,
    isQrcode: false,
    surveyQrcodes: ['surveyQrcodeCreate', 'surveyQrcodeUpdate'],
    isShowAttention: false,
  } as IState;
  // 提前准备数据渲染
  public async componentWillMount() {
    // 初始化准备各种数据
    const ids = this.props.history.location.search.split('?');
    const urlPrams = await this.processUrl(ids[1]);
    this.gaugeInit(urlPrams);
    if (this.props.global.sysConfig.noteMessageJson) {
      this.getAttention(urlPrams);
    }
  }

  public componentDidUpdate(p: IProps) {
    // console.log(document.querySelectorAll("span").length)
    if (document.querySelectorAll('span').length !== 0) {
      // tslint:disable-next-line: prefer-for-of
      const spanlength = document.querySelectorAll('span').length;
      for (let i = 0; i < spanlength; i++) {
        const dom = document.querySelectorAll('span')[i];
        if (
          dom.innerHTML.lastIndexOf('&lt;') !== -1 ||
          dom.innerHTML.lastIndexOf('&gt;') !== -1 ||
          dom.innerHTML.lastIndexOf('&amp;') !== -1
        ) {
          const domString = dom.innerHTML
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
          dom.innerHTML = domString;
        }
        // console.log(document.querySelectorAll("span")[i])
      }
    }
    if (p.survey.menuIChild !== this.props.survey.menuIChild || p.survey.menuIndex !== this.props.survey.menuIndex) {
      const surveyModelUtil = new SurveyModelUtil();
      surveyModelUtil.scrollToWindow(this.props.survey);
      this.props.dispatch({ type: 'LoadModel/directSave', payload: { isLoading: false } });
    }
  }
  public render() {
    const survey = this.props.survey;
    if (!survey.gaugeData[survey.menuIndex]) {
      return <div />;
    }
    const modalBtn = [
      // tslint:disable-next-line:jsx-wrap-multiline
      <Button key="back" onClick={this.closeModal}>
        返回修改
      </Button>,
      // tslint:disable-next-line:jsx-wrap-multiline
      <Button key="submit" type="primary" onClick={this.linkVirus}>
        继续提交
      </Button>,
    ];
    return (
      <div id="GaugeSurvey">
        <LayoutComponent
          name={this.props.global.companyInfo.systemName}
          isBack={!this.state.isQrcode}
          background={isPc ? 'gray' : 'white'}
          isQrcode={this.state.isQrcode}
        >
          <Spin spinning={this.state.isSpinning}>
            <div className={this.state.isQrcode ? 'hader_tit qrTop' : 'hader_tit'}>
              <AuthCompount isShow={isPc}>
                <div className="header_info">
                  <div>访视{this.props.survey.visitNumber}</div>
                  <AuthCompount
                    isShow={this.props.survey.visitNumber > Number(this.props.global.sysConfig.initVisitNumber)}
                  >
                    <div className="dis_c">
                      病例编号：
                      <Tooltip
                        placement="bottomLeft"
                        title={this.state.patient_code}
                        overlayStyle={{ zIndex: 999999999 }}
                      >
                        {this.state.patient_code}
                      </Tooltip>
                    </div>
                    <div className="dis_c">
                      病例名称：
                      <Tooltip
                        placement="bottomLeft"
                        title={this.state.patient_name}
                        overlayStyle={{ zIndex: 999999999 }}
                      >
                        {this.state.patient_name}
                      </Tooltip>
                    </div>
                  </AuthCompount>
                </div>
                <div>
                  <AuthCompount
                    isShow={
                      // tslint:disable-next-line: jsx-no-multiline-js
                      Boolean(
                        this.state.attentions &&
                          this.props.global.sysConfig.noteMessageJson &&
                          !this.state.surveyQrcodes.includes(this.props.survey.writeType)
                      )
                    }
                  >
                    <span
                      // tslint:disable-next-line: jsx-no-multiline-js
                      className={
                        this.state.attentions && this.state.attentions.length > 0 ? 'attention' : 'attention disable'
                      }
                      onClick={this.showAttention}
                    >
                      {this.state.isShowAttention ? '收起注意事项' : '注意事项'}
                    </span>
                  </AuthCompount>
                  <AuthCompount isShow={this.props.survey.writeType !== 'surveyDetail'}>
                    <Button type="primary" onClick={this.submitFrom} className="submitBtn">
                      提交
                    </Button>
                  </AuthCompount>
                </div>
              </AuthCompount>
              <AuthCompount isShow={!isPc}>
                <div className="mb_look">
                  <img src={require('./../../../assets/moblieImg/icon_more.svg')} alt="" onClick={this.changeNav} />
                  <div className="mb_visitNumber">访视{this.props.survey.visitNumber}</div>
                </div>
                <div className="header_rig">
                  <AuthCompount
                    isShow={
                      // tslint:disable-next-line: jsx-no-multiline-js
                      Boolean(
                        this.state.attentions &&
                          this.props.global.sysConfig.noteMessageJson &&
                          !this.state.surveyQrcodes.includes(this.props.survey.writeType)
                      )
                    }
                  >
                    <span
                      // tslint:disable-next-line: jsx-no-multiline-js
                      className={
                        this.state.attentions && this.state.attentions.length > 0 ? 'attention' : 'attention disable'
                      }
                      onClick={this.showAttention}
                    >
                      {this.state.isShowAttention ? '收起注意事项' : '注意事项'}
                    </span>
                  </AuthCompount>
                  <AuthCompount isShow={this.props.survey.writeType !== 'surveyDetail'}>
                    <Button type="primary" onClick={this.submitFrom} className="submitBtn">
                      提交
                    </Button>
                  </AuthCompount>
                </div>
              </AuthCompount>
            </div>
            <div className="attentionContainer">
              <AuthCompount isShow={this.state.isShowAttention}>{this.getAttentionJSX()}</AuthCompount>
            </div>
            <div className={this.state.isQrcode && isPc ? 'heiGauge qrBodyH' : ''} />
            <AuthCompount isShow={!isPc}>
              <img
                src={require('./../../../assets/moblieImg/btn_more.svg')}
                alt=""
                // tslint:disable-next-line:jsx-no-multiline-js
                className={this.state.clickIf ? 'right mb_icon' : 'left mb_icon'}
                onClick={this.changeNav}
              />
            </AuthCompount>
            <div className={this.state.isQrcode ? 'gauge_box' : 'gauge_box mt120'}>
              <AuthCompount isShow={isPc || this.state.clickIf}>
                <div className="formNavigation">
                  <FormNavigation changeGauge={this.changeGauge} />
                </div>
              </AuthCompount>
              <AuthCompount isShow={!isPc && this.state.clickIf}>
                <div
                  className="mask"
                  // tslint:disable-next-line: jsx-no-multiline-js
                  onClick={this.changeGauge}
                />
              </AuthCompount>
              <div className="subjectContainer">
                <div className="scrl_box" id="scrl_box">
                  <AuthCompount isShow={!isPc}>
                    <div className="big_moduleName">
                      <div>{survey.gaugeData[survey.menuIndex].moduleName}</div>
                      <AuthCompount
                        // tslint:disable-next-line:jsx-no-multiline-js
                        isShow={this.errStyle(survey.gaugeData[survey.menuIndex], survey.menuIndex) === 'err'}
                      >
                        <div className="rea">请输入标红的问题</div>
                      </AuthCompount>
                    </div>
                  </AuthCompount>
                  {survey.gaugeData[survey.menuIndex].moduleChildrens.map((item: IGauge, i: number) => (
                    <AuthCompount key={i} isShow={!!item.isShow}>
                      <div className="gauge_content">
                        <ModuleTitle
                          data={item}
                          pId={'gauge_content' + survey.menuIndex + '_' + i}
                          positions={[survey.menuIndex, i]}
                        >
                          {// tslint:disable-next-line:jsx-no-multiline-js
                          item.subjects.map((sub: ISubjectV, index: number) => (
                            <div key={sub.subjectData.field}>
                              <FormList.FormRender formObj={sub} />
                            </div>
                          ))}
                        </ModuleTitle>
                      </div>
                    </AuthCompount>
                  ))}
                </div>
              </div>
            </div>
          </Spin>
        </LayoutComponent>
        <Modal closable={false} visible={this.props.survey.modalShow} title="重要提示！" footer={modalBtn}>
          <div className="p_t">
            根据国家卫生健康 2020 年 1 月 21 日发布的(2020 年第 1
            号)公告，此次新型冠状病毒感染的肺炎已纳入《传染病防治法》规定的乙类传染病，并采取甲类传染病的预防、控制措施。
          </div>
          <div className="p_t">
            根据《传染病防治法》的规定，对于确诊患者和新型冠状病毒携带者，应当在定点医疗机构进行隔离治疗，隔离期限根据医学检查结果确定。
          </div>
          <div className="p_t">
            瞒报疫区行程、拒绝隔离治疗或者隔离期满擅自脱离隔离治疗的，可以由公安机关协助医疗机构采取强制隔离治疗措施
          </div>
          <div className="p_t re">若因此造成严重后果，涉嫌构成犯罪的将被追究刑事责任！</div>
        </Modal>
      </div>
    );
  }
  /**
   * 获取病人的详情
   */
  private getPatientDetail = async (id: string) => {
    const { result } = await GaugeSurveyApi.getPatientDetail({ id });
    this.setState({
      patient_code: result.customCode,
      patient_name: result.name,
    });
  };

  // 显示注意事项string
  private showAttention = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!e.currentTarget.className.includes('disable')) {
      this.setState({ isShowAttention: !this.state.isShowAttention });
    }
  };
  // 注意事项内容
  private getAttentionJSX = () => {
    if (this.state.attentions) {
      return this.state.attentions.map((item, index) => {
        const expansion =
          item.subDataList.length > 3 ? (
            <span className="expansion" onClick={() => this.spreadToggle(index)}>
              {item.expansion ? '收起' : '展开'}
            </span>
          ) : (
            ''
          );
        const contentJSX = item.subDataList.map((v, i) => {
          const tempArr = v.split('$');
          const len = tempArr.length;
          const valueJSX = tempArr.map((value, valueIndex) => {
            return <span key={valueIndex}>{value}</span>;
          });
          return (
            <div key={i} className={'columns' + len}>
              {valueJSX}
            </div>
          );
        });
        return (
          <div className={item.moduleName === '要点提示' ? 'pointTip' : 'attentionItem'} key={index}>
            <div className="attentionTitle">
              <div>
                <span className="titleTips" />
                {item.moduleName}
              </div>
              {expansion}
            </div>
            <div className={expansion === '' || item.expansion ? 'attentionContent' : 'attentionContent limitHeight'}>
              {contentJSX}
            </div>
          </div>
        );
      });
    }
  };
  // 多于三行的模块展开或隐藏
  private spreadToggle = (index: number) => {
    if (this.state.attentions) {
      this.state.attentions[index].expansion = !this.state.attentions[index].expansion;
      this.setState({ attentions: this.state.attentions });
    }
  };
  // 获取注意事项
  // tslint:disable-next-line: no-any
  private getAttention = async (payload: any) => {
    if (payload.answerId || payload.patientId) {
      const json = {
        answerId: payload.answerId,
        patientId: payload.patientId,
      };
      if (payload.answerId) {
        delete json.patientId;
      }
      const { result } = await GaugeSurveyApi.getNoteMessage(json);
      if (result.noteMessage) {
        result.answerResultList.push({
          moduleName: '要点提示',
          subDataList: result.noteMessage,
        });
      }
      this.setState({ attentions: result.answerResultList });
    }
  };
  /**
   * 导航栏
   */
  private changeGauge = () => {
    if (!isPc) {
      this.setState({
        clickIf: false,
      });
    }
  };

  /**
   * mobile 导航栏显示
   */
  private changeNav = () => {
    this.setState({
      clickIf: !this.state.clickIf,
    });
  };

  /**
   * 判断该模块是否有错误
   * @return 错误样式
   */
  private errStyle = (
    data: IAllGauge,
    index: number,
    iChild?: number
    // tslint:disable-next-line:no-any
  ): any => {
    if (data.isError) {
      if (iChild || iChild === 0) {
        // tslint:disable-next-line:no-any
        const isErr = data.isError.some((item: any) => {
          return item.menuIndex === index && item.menuIChild === iChild;
        });
        return isErr ? 'err' : '';
      } else {
        // tslint:disable-next-line:no-any
        const isErr = data.isError.some((item: any) => {
          return item.menuIndex === index;
        });
        return isErr ? 'err' : '';
      }
    }
    return '';
  };

  /**
   * 处理url上面的传来过的值 以判断 各种情况的出现
   * @return urlPrams
   */
  private processUrl = async (url: string) => {
    // tslint:disable-next-line:no-any
    const urlPrams = {} as { [key: string]: any };
    const strs = url.split('&');
    strs.forEach(item => {
      const keys = item.split('=');
      urlPrams[keys[0]] = keys[1];
    });
    // 查病例缓存 拿到对应的信息展示
    if (urlPrams.patientId) {
      this.getPatientDetail(urlPrams.patientId);
    }
    // 扫码进入隐藏对应的栏目
    if (this.state.surveyQrcodes.includes(urlPrams.writeType)) {
      this.setState({ isQrcode: true });
      const surveyModelUtil = new SurveyModelUtil();
      const { result } = await surveyModelUtil.getQRCodeMsg(urlPrams.code);
      this.props.dispatch({
        type: 'global/qrCodeSaveUserInfo',
        payload: {
          code: result.organizationVo.code,
          systemName: result.organizationVo.systemName,
          name: result.organizationVo.name,
          logoUrl: result.organizationVo.logoUrl,
          visitNumber: result.visitNumber,
        },
      });
      urlPrams.channel = result.channel;
    }
    return urlPrams;
  };

  /**
   * 初始化的dispatch
   */
  // tslint:disable-next-line:no-any
  private gaugeInit = (payload?: any) => {
    this.props.dispatch({
      type: 'survey/gaugeInit',
      payload,
    });
  };
  /**
   * 提交表单
   */
  private submitFrom = () => {
    this.props.dispatch({ type: 'survey/submitFrom' });
  };
  /**
   * 关闭弹框
   */
  private closeModal = () => {
    this.props.dispatch({
      type: 'survey/closeModal',
    });
  };
  private linkVirus = () => {
    this.props.dispatch({
      type: 'survey/submitFrom',
      payload: {
        isSubmit: true,
      },
    });
  };
}
export default connect(({ global, survey }: { global: IGlobal; survey: ISurvey }) => ({
  global,
  survey,
}))(GaugeSurvey);
