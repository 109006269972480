/* import { message } from "antd"; */

/**
 * 滚动分页方法
 * 接受四个参数
 * 滚动的元素 id （也就是overflow属性为sroll 的id）
 * 页码
 * 页数
 * 分页总共的页码
 *
 * 返回一个promise
 * resolve 携带分页参数
 * reject 携带停止分页
 */

export default async (elemId: string, pageIndex: number, pageSize: number, totalCount: number) => {
  const clientHeight = (document.getElementById(elemId) || document.body).clientHeight; // 可见区域高度
  const scrollTop2 = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop; // 滚动条到顶部的距离
  const scrollHeight = document.body.clientHeight; // 整个页面高度
  if (clientHeight - scrollTop2 < scrollHeight + 100 && Number(pageIndex) * Number(pageSize) < Number(totalCount)) {
    return ++pageIndex;
  }
  // 如果分页到底
  if (Number(pageIndex) * Number(pageSize) >= Number(totalCount) && clientHeight - scrollTop2 < scrollHeight + 100) {
    // reject 需要传入Error对象
    return false;
  }
};
