import { Model } from 'dva';

export default {
  namespace: 'LoadModel',
  state: {
    isLoading: false,
  },
  effects: {
    // 直接保存 payload 的值
    *directSave({ payload, callback }, { put }) {
      yield put({
        type: 'allSave',
        payload,
      });
    },
  },
  reducers: {
    // tslint:disable-next-line:no-any
    allSave(state, action: any) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
} as Model;
