import { Button, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from 'dva';
import { Link } from 'dva/router';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import WeChatPatient from '../../../api/WeChatPatient';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import './PatientPhone.scss';
interface IValidateStatusType {
  validateStatus: 'success' | 'warning' | 'error' | 'validating' | '';
  errorMsg: string;
}

const PatientPhone = (props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const globalState = useSelector(({ global }: { global: IGlobal }) => global);
  const wechatPatientInfo = globalState.wechatPatientInfo;
  const codeTime = useSelector(({ global }: { global: IGlobal }) =>
    global.wechatDoctorCert ? global.wechatDoctorCert.codeTime : 60
  );
  const [mobile, setMobile] = useState(wechatPatientInfo.mobile);
  const [verifyMobile, setVerifyMobile] = useState<IValidateStatusType>({
    validateStatus: 'success',
    errorMsg: '',
  });
  const [smsCode, setSmsCode] = useState(wechatPatientInfo.smsCode);
  const [showFocus, setShowFocus] = useState({
    mobile: false,
    inviteCode: false,
  });
  useEffect(() => {
    UpdateTitle('E-Follow');
    if (codeTime !== 60) {
      countDown(codeTime);
    }
  }, []);
  async function sendCode() {
    if (mobile && /^1[3456789]\d{9}$/.test(mobile as string)) {
      const { code, result } = await WeChatPatient.sendSms({ mobile: Number(mobile), systemCode: 'HULK_FRONT' });
      if (code === 0) {
        countDown(codeTime);
      }
      message.success(result);
    } else {
      setVerifyMobile({
        validateStatus: 'error',
        errorMsg: '请输入11位的手机号码！',
      });
    }
  }
  // 倒计时
  function countDown(num: number) {
    const timer = setInterval(() => {
      num--;
      if (num === 0) {
        clearInterval(timer);
      }
      dispatch({
        type: 'global/wxInfoSave',
        payload: {
          wechatDoctorCert: {
            codeTime: num === 0 ? 60 : num,
          },
        },
      });
    }, 1000);
  }
  function savePatient(e: React.MouseEvent) {
    if (e.currentTarget.className.indexOf('enable') === -1) {
      WeChatPatient.savePatientInfo({ ...wechatPatientInfo, mobile, smsCode }).then(({ code, result }) => {
        if (code) {
          message.error(result);
        } else {
          message.success(result);
          history.push('/Patient/PatientHomePage');
          globalState.wechatDoctorCert.codeTime = 60;
          dispatch({
            type: 'global/wxInfoSave',
            payload: global,
          });
        }
      });
    }
  }
  function mobileHelp() {
    setShowFocus({ ...showFocus, mobile: !showFocus.mobile });
    if (mobile && /^1[3456789]\d{9}$/.test(mobile as string)) {
      dispatch({
        type: 'global/wxInfoSave',
        payload: {
          wechatPatientInfo: {
            ...wechatPatientInfo,
            mobile,
          },
        },
      });
      setVerifyMobile({
        validateStatus: 'success',
        errorMsg: '',
      });
    } else {
      setVerifyMobile({
        validateStatus: 'error',
        errorMsg: '请输入11位的手机号码！',
      });
    }
  }
  function saveSmsCode() {
    setShowFocus({ ...showFocus, inviteCode: !showFocus.inviteCode });
    dispatch({
      type: 'global/wxInfoSave',
      payload: {
        wechatPatientInfo: {
          ...wechatPatientInfo,
          smsCode,
        },
      },
    });
  }

  return (
    <div id="Patient-PatientPhone">
      <div className="patientTitle">绑定手机号码</div>
      <Form>
        <Form.Item className={'inputFrom'} validateStatus={verifyMobile.validateStatus} help={verifyMobile.errorMsg}>
          <div className={'containerInput'}>
            <div className={`labelInput ${mobile || showFocus.mobile ? 'showLabel' : ''}`}>手机号码</div>
            <Input
              placeholder="手机号"
              value={mobile}
              onChange={e => setMobile(e.target.value)}
              onFocus={() => setShowFocus({ ...showFocus, mobile: !showFocus.mobile })}
              onBlur={mobileHelp}
            />
          </div>
        </Form.Item>
        <div className="codeContiner">
          <div className={'containerInput codeInput'}>
            <div className={`labelInput ${smsCode || showFocus.inviteCode ? 'showLabel' : ''}`}>验证码</div>
            <Input
              placeholder="验证码"
              onFocus={() => setShowFocus({ ...showFocus, inviteCode: !showFocus.inviteCode })}
              onBlur={saveSmsCode}
              value={smsCode}
              onChange={e => setSmsCode(e.target.value)}
            />
          </div>
          <button
            className={codeTime === 60 ? 'action' : 'action enable'}
            disabled={codeTime !== 60}
            onClick={sendCode}
          >
            {codeTime === 60 ? '发送验证码' : `${codeTime} 秒后重发`}
          </button>
        </div>
        <Link to="PatientInfo" className="backPatient">
          返回上一步
        </Link>
        <Button onClick={savePatient} htmlType="submit" disabled={!(mobile && smsCode)} className="sure">
          确定
        </Button>
      </Form>
    </div>
  );
};
export default PatientPhone;
