import { Checkbox, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormComponentProps } from 'antd/lib/form';
import { connect, SubscriptionAPI } from 'dva';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Foot from '../../components/Foot/Foot';
import { IconFont } from '../../config/IconConfig';
const filePath = window.screen.width > 500 ? 'img' : 'moblieImg';
import './SignIn.scss';
interface IProps extends SubscriptionAPI, FormComponentProps, RouteComponentProps<{ code: string }> {
  // tslint:disable-next-line:no-any
  global: any;
}

interface IState {
  isRem: boolean;
  password: string;
  inputed: boolean;
  footer: boolean;
}

class SignIn extends React.Component<IProps, IState> {
  public state = {
    isRem: false,
    password: '',
    inputed: false,
    footer: true,
  } as IState;
  public componentDidMount() {
    this.props.dispatch({
      type: 'global/getCompanyInfo',
      payload: { code: this.props.match.params },
    });
    if (this.props.global.isRem) {
      this.setState({ password: this.props.global.password });
    }
  }
  public render() {
    return (
      <div id="SignIn">
        <div className="signin_box">
          <img className="bg_img" src={require(`./../../assets/${filePath}/pc_pic_logon_bg.png`)} alt="" />
          <div className="form_box1">{this.pcLoginRender()}</div>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.state.footer ? <Foot /> : ''}
        </div>
      </div>
    );
  }

  /**
   * pc端登录渲染
   */
  private pcLoginRender = () => {
    const { getFieldDecorator } = this.props.form;
    const passwordtype = filePath === 'img' ? 'iconicon_password1' : 'iconicon_password';
    return (
      <div className="SignContent">
        <div className="sysName welcome">欢迎使用</div>
        <div className="sysName">{this.props.global.companyInfo && this.props.global.companyInfo.systemName}</div>
        <div className="for1">
          <div className="for_t">登录</div>
          <Form className="login-form">
            <div className="form-div">
              <Form.Item>
                {// tslint:disable-next-line:jsx-no-multiline-js
                getFieldDecorator('mobile', {
                  initialValue: this.props.global.mobile,
                  rules: [
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(this.inputRender('text', '请输入手机号', filePath === 'img' ? 'iconicon_phone1' : 'iconicon_phone'))}
              </Form.Item>
              <Form.Item>
                {// tslint:disable-next-line:jsx-no-multiline-js
                getFieldDecorator('password', {
                  initialValue: this.props.global.isRem ? this.props.global.password : '',
                  rules: [
                    {
                      required: true,
                      message: '请输入密码',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <Input.Password
                    placeholder="请输入密码"
                    onBlur={this.inputOnBlur}
                    onFocus={this.inputOnFocus}
                    // tslint:disable-next-line:jsx-no-multiline-js
                    prefix={<IconFont className="passwordIcon" type={passwordtype} />}
                    className="passwordInput"
                    // tslint:disable-next-line:jsx-no-multiline-js
                    // defaultValue={this.props.global.isRem ? this.props.global.password : ''}
                    // tslint:disable-next-line:jsx-no-multiline-js
                    onChange={e => {
                      this.passwordTo(e);
                    }}
                    onKeyPress={this.enterLogin}
                  />
                )}
              </Form.Item>
              <div className="remember">
                <div />
                <div>
                  <Checkbox checked={this.props.global.isRem} onChange={this.checkoutChange} />
                  <span className="fon">记住密码</span>
                </div>
              </div>
              <div className="login_btn" onClick={this.login}>
                登录
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  /**
   * 回车登陆
   */

  private enterLogin = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.keyCode === 13) {
      this.login();
    }
  };
  /* 传递密码 */
  // tslint:disable-next-line:no-any
  private passwordTo = (e: any) => {
    if (!this.state.inputed) {
      this.setState({ inputed: true });
    }
    this.setState({ password: e.currentTarget.value });
  };

  /**
   * 登录
   */
  // tslint:disable-next-line:no-any
  private login = async () => {
    // tslint:disable-next-line:no-any
    const res: any = await this.props.form.validateFields();
    if (this.state.inputed) {
      res.password = this.state.password;
    } else {
      res.password = this.state.password || this.props.global.password;
    }
    if (this.state.password) {
      this.props.dispatch({
        type: 'global/login',
        payload: {
          mobile: res.mobile,
          password: res.password,
          orgCode: this.props.match.params.code,
        },
      });
    }
  };

  /**
   * 输入框渲染
   * @param { type } 输入框类型
   * @param { placeholder } placeholder
   * @param { iconType } icon 类型
   */
  private inputRender = (type?: string, placeholder?: string, iconType?: string) => {
    return (
      <div className="input_box">
        <IconFont type={iconType} className="int_icon" />
        <input
          // tslint:disable-next-line:jsx-no-multiline-js
          onBlur={this.inputOnBlur}
          onFocus={this.inputOnFocus}
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={this.props.global[`${type === 'text' ? 'mobile' : 'password'}`]}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
        />
      </div>
    );
  };
  /* 获取焦点事件 */
  private inputOnFocus = () => {
    if (filePath !== 'img') {
      this.setState({ footer: false });
    }
  };
  /* 失去焦点事件 */
  private inputOnBlur = () => {
    this.setState({ footer: true });
  };
  /**
   * 是否记住密码
   */
  private checkoutChange = (e: CheckboxChangeEvent) => {
    this.props.dispatch({
      type: 'global/checkoutChange',
      payload: {
        isRem: e.target.checked,
      },
    });
  };
}
// tslint:disable-next-line:no-any
export default connect((global: any) => global)(Form.create()(SignIn));
