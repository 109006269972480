import { Button, DatePicker, InputItem } from 'antd-mobile';
import { useDispatch, useSelector } from 'dva';
import { RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Config from '../../../config/Config';
import { IGlobal } from '../../../Model/GlobalModel';
import UpdateTitle from '../../../utils/UpdateTitle';
import './PatientInfo.scss';

export default (props: RouteComponentProps) => {
  // const { history } = props;
  const dispatch = useDispatch();
  const globalState = useSelector(({ global }: { global: IGlobal }) => global);
  const wechatPatientInfo = globalState.wechatPatientInfo;

  const [state, setState] = useState(wechatPatientInfo);
  const [showName, setShowName] = useState(false);
  useEffect(() => {
    UpdateTitle('E-Follow');
  }, []);
  function verify() {
    dispatch({
      type: 'global/wxInfoSave',
      payload: {
        wechatPatientInfo: { ...state },
      },
    });
    window.location.href = `${Config.domainUrl}/#/Patient/PatientPhone`;
    // history.push('PatientPhone');
  }
  return (
    <div id="Patient-PatientInfo">
      <p className="patientTitle">填写个人信息</p>
      <div className={'infoName'}>
        <div className={`tips ${state.name || showName ? 'showTips' : ''}`}>姓名</div>
        <InputItem
          value={state.name}
          placeholder="姓名"
          className="name"
          onChange={name => setState({ ...state, name })}
          onFocus={() => setShowName(!showName)}
          onBlur={() => setShowName(!showName)}
        />
      </div>

      <DatePicker
        mode="date"
        className={'timePicker450pxCenter'}
        minDate={moment('1900-01-01 00:00:00').toDate()}
        value={state.birthday ? moment(state.birthday).toDate() : moment('1990-01-01 00:00:00').toDate()}
        onChange={date => setState({ ...state, birthday: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
      >
        <div className="patient-date">
          <div>
            选择出生年月 <img src={require('./../../../assets/img/icon_calendar.svg')} />
          </div>
          <span className="color">{state.birthday ? moment(state.birthday).format('YYYY-MM-DD') : ''}</span>
        </div>
      </DatePicker>

      <p className="sexChoose">选择性别</p>
      <div className="imgArea">
        {['img_male.svg', 'img_female.svg'].map(item => {
          const key = item === 'img_male.svg' ? 1 : 0;
          return (
            <div
              key={item}
              // tslint:disable-next-line: jsx-no-multiline-js
              onClick={() => {
                setState({ ...state, gender: key });
              }}
            >
              <img src={require('./../../../assets/img/' + item)} />
              <p className={state.gender === key ? 'color' : ''}>{key ? '男' : '女'}</p>
              {// tslint:disable-next-line: jsx-no-multiline-js
              state.gender === key ? (
                <img src={require('./../../../assets/img/icon_gender_select.svg')} className="choose" />
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      <Button
        disabled={!state.name || !state.birthday || ![0, 1].includes(state.gender)}
        className="nextStep"
        onClick={verify}
      >
        下一步
      </Button>
    </div>
  );
};
