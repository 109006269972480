import { Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import { useDispatch } from 'dva';
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormSlider.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 滑动组件 FormSlider
 */
function FormSlider(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  // const { isShow } = useFormActions(props);
  const [val, setVal] = useState(formObj.defaultValue);
  const dispatch = useDispatch();
  function inputChange(str: SliderValue) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: Number(str),
      },
    });
    setVal(Number(str));
  }
  function afterChange(str: SliderValue) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: Number(str),
      },
    });
    setVal(Number(str));
  }

  /**
   * 更改tip挂载
   */
  function elemtCurrent() {
    return document.getElementById(formObj.field) ? document.getElementById(formObj.field)! : document.body;
  }

  const open = <div className="sliderHintIcon"> {val} </div>;
  return (
    <div>
      <div id="FormSlider">
        <div id={formObj.field} className="sliderHintIcondev" />
        <div className="form_box">
          <div className="tit_box">
            <AuthCompount isShow={!!props.formObj.validatorData.required}>
              <div
                // tslint:disable-next-line:jsx-no-multiline-js
                className={formObj.errMsg ? 'cl star' : 'star'}
                id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
              >
                *
              </div>
            </AuthCompount>
            <div>
              {formObj.subjectName}：<span className="slidrBanch">{val}分</span>
            </div>
          </div>
          <AuthCompount isShow={isPc}>
            <div
              className="errMsg"
              id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
            >
              {formObj.errMsg}
            </div>
          </AuthCompount>
        </div>
        <div className="explain">{formObj.explain}</div>
        <Slider
          min={Number(formObj.sliderMin)}
          max={Number(formObj.sliderMax)}
          // disabled={formObj.disable}
          defaultValue={Number(formObj.defaultValue)}
          value={Number(val)}
          tooltipVisible={true}
          onChange={inputChange}
          onAfterChange={afterChange}
          // dots={true}
          marks={formObj.marks}
          step={Number(formObj.step)}
          tipFormatter={() => open}
          getTooltipPopupContainer={elemtCurrent}
        />
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormSlider);
