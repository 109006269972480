import axios from './RequestApi';
/**
 * @class 我的数据，包括节点用户和云端用户
 */
export default class SignInApi {
  /**
   * 登录
   * @param data
   */
  public static userLogin(data: ILoginParam) {
    return axios.post<ILoginResult, ILoginParam>(`/user/login/`, data);
  }
  /**
   * 获取组织信息
   * @param data
   */
  public static getOrgName(data: { code: string }) {
    return axios.get<IOrganization, { code: string }>(`/organization/info`, data);
  }
  /**
   * 获取微信的code
   * @param redirectUri
   */
  public static getWxCode(redirectUri: string) {
    return axios.get<string, { redirectUri: string }>(`/wx/redirect/getCodeUrl`, { redirectUri });
  }
  /**
   * 通过code获取用户信息
   * @param data
   */
  public static getCodeUserInfo(data: { code: string; systemCode: string }) {
    return axios.get<IWxUserInfo, { code: string; systemCode: string }>(`/wx/redirect/login`, data);
  }
  /**
   * 获取用户信息
   * @param data
   */
  public static getUserInfo() {
    return axios.get<IWxUserInfo, {}>(`/logined/hulkUserInfo/getHulkUserInfo`);
  }

  /**
   * 修改密码
   */
  public static updatePassword(data: IUpdatePsdReq) {
    return axios.post<number, IUpdatePsdReq>(`/logined/user/change/password`, data);
  }
}

export interface ILoginResult {
  token?: string;
  name?: string; //   用户名
  mobile?: string; // 用户手机号
  id?: number; // 用户id
  configInfo: IConfigInfo;
  organization: IOrganization;
}

export interface ILoginParam {
  mobile: string;
  password: string;
  orgCode: string;
}
export interface IConfigInfo {
  initVisitNumber?: number; // 访视初始值
  maxVisitNumber?: number; // 最大访视次数 -1:为无限访视次数
  maxVisitPatientNumber?: number; // 最大访视人数 -1:为无限访视人数
  pdfExport: boolean; // 是否支持pdf导出
  excelExportStatis?: boolean; // 是否支持excel导出统计
  excelImport: boolean;
  qrCodeAdd?: boolean; // 是否支持二维码新增患者
  qrCodeEdit?: boolean; // 是否支持二维码编辑患者
  noteMessageJson?: string;
}
export interface IOrganization {
  code?: string; // 医院编号
  logoUrl?: string; // logo
  name?: string; // 组织名称
  systemName?: string; // 系统名
}
export interface IWxUserInfo {
  hulkUserInfoBackLoginVo?: IHulkUserInfoBackLoginVo;
  hulkUserInfoFrontLoginVo?: IHulkUserInfoFrontLoginVo;
  wechatUserInfoVo?: IWechatUserInfoVo;
}

export interface IHulkUserInfoFrontLoginVo {
  age: number;
  birthday?: string;
  token: string;
  name: string;
  systemCode: string;
}
export interface IHulkUserInfoBackLoginVo {
  department: string;
  expertName: string;
  groupCount: number;
  mobile: number;
  organizationName: string;
  patientCount: number;
  qrCodeUrl: string;
  token: string;
  wechatUserInfoId: number;
  systemCode: string;
  organization: {
    code: string;
    logoUrl: string;
    name: string;
  };
  // tslint:disable-next-line:no-any
  configInfo: any;
}
export interface IWechatUserInfoVo {
  headimgurl: string;
  nickname: string;
}

export interface IUpdatePsdReq {
  newPassword: string;
  password: string;
}
