import AddGroupMember from './Doctor/AddGroupMember/AddGroupMember';
import AddNotify from './Doctor/AddNotify/AddNotify';
import AddNotifyType from './Doctor/AddNotifyType/AddNotifyType';
import DoctorAddGroup from './Doctor/DoctorAddGroup/DoctorAddGroup';
import DoctorAddNotify from './Doctor/DoctorAddNotify/DoctorAddNotify';
import DoctorAddPeriod from './Doctor/DoctorAddPeriod/DoctorAddPeriod';
import DocStartCert from './Doctor/DoctorCertificate/DocStartCert';
import DoctorCert from './Doctor/DoctorCertificate/DoctorCert';
import DoctorGroupClass from './Doctor/DoctorGroup/DoctorGroupClass';
import DoctorGroupDetail from './Doctor/DoctorGroupDetail/DoctorGroupDetail';
import { ExpertInfo } from './Doctor/DoctorInfo/DoctorInfo';
import DoctorNotifyList from './Doctor/DoctorNotifyList/DoctorNotifyList';
import DoctorSetPeriod from './Doctor/DoctorSetPeriod/DoctorSetPeriod';
import EditPatient from './Doctor/EditPatient/EditPatient';
import { MyPatient } from './Doctor/MyPatient/MyPatient';
// import { PatientMsg } from './Doctor/MyPatient/PatientMsg';
import PatientMsgClass from './Doctor/MyPatient/PatientMsgClass';
import NewNotis from './Doctor/NewNotis/NewNotis';
import NotiDetail from './Doctor/NotiDetail/NotiDetail';
import PatientHomePage from './Patient/PatientHomePage/PatientHomePage';
import PatientInfo from './Patient/PatientInfo/PatientInfo';
import PatientNew from './Patient/PatientNew/PatientNew';
import PatientNotifyDetail from './Patient/PatientNotifyDetail/PatientNotifyDetail';
import PatientNotifyList from './Patient/PatientNotifyList/PatientNotifyList';
import PatientPhone from './Patient/PatientPhone/PatientPhone';

export interface IRouteConfigItem {
  path: string;
  name: string;
  // tslint:disable-next-line:no-any
  component?: React.ComponentType | any;
  icon?: string;
  status?: string;
  routes?: IRouteConfigItem[];
}

const Routes: IRouteConfigItem[] = [
  {
    path: '/Doctor/DoctorInfo',
    name: '专家首页',
    component: ExpertInfo,
  },
  {
    path: '/Doctor/MyPatient',
    name: '我的患者',
    component: MyPatient,
  },
  {
    path: '/Doctor/NewNotis/:id',
    name: '添加通知',
    component: NewNotis,
  },
  {
    path: '/Doctor/AddNotifyType/:id',
    name: '选择通知类型',
    component: AddNotifyType,
  },
  {
    path: '/Doctor/AddNotifyType/:id/:type',
    name: '添加通知',
    component: AddNotify,
  },
  {
    path: '/Doctor/PatientMsg/:id',
    name: '患者信息',
    component: PatientMsgClass,
  },
  {
    path: '/Doctor/EditPatient/:id',
    name: '编辑患者信息',
    component: EditPatient,
  },
  {
    path: '/Doctor/Certificate',
    name: '医生开始认证',
    component: DocStartCert,
  },
  {
    path: '/Doctor/CetificationMsg',
    name: '医生开始认证',
    component: DoctorCert,
  },
  {
    path: '/Doctor/NotiDetail/:id',
    name: '随访详情',
    component: NotiDetail,
  },

  {
    path: '/Patient/PatientInfo',
    name: '患者个人中心',
    component: PatientInfo,
  },
  {
    path: '/Patient/PatientNew',
    name: '患者欢迎页面',
    component: PatientNew,
  },
  {
    path: '/Patient/PatientHomePage',
    name: '个人信息',
    component: PatientHomePage,
  },
  {
    path: '/Patient/PatientNotifyList/:doctorId/:name',
    name: '个人信息',
    component: PatientNotifyList,
  },
  {
    path: '/Patient/PatientNotifyDetail/:notifyId',
    name: '个人信息',
    component: PatientNotifyDetail,
  },
  {
    path: '/Patient/PatientPhone',
    name: '绑定手机号码',
    component: PatientPhone,
  },
  {
    path: '/Doctor/DoctorGroup',
    name: '分组列表',
    component: DoctorGroupClass,
  },
  {
    path: '/Doctor/DoctorSetPeriod/:groupId',
    name: '周期',
    component: DoctorSetPeriod,
  },
  {
    path: '/Doctor/DoctorAddPeriod/:groupId/:periodId/:type/:number/:days/:isN',
    name: '添加周期',
    component: DoctorAddPeriod,
  },
  {
    path: '/Doctor/DoctorAddGroup/:type/:groupId',
    name: '添加分组',
    component: DoctorAddGroup,
  },
  {
    path: '/Doctor/DoctorGroupDetail/:groupId',
    name: '分组详情',
    component: DoctorGroupDetail,
  },
  {
    path: '/Doctor/DoctorNotifyList/:periodId/:groupId',
    name: '通知列表',
    component: DoctorNotifyList,
  },
  {
    path: '/Doctor/DoctorAddNotify/:periodId/:notifyId/:type/:groupId',
    name: '通知详情',
    component: DoctorAddNotify,
  },
  {
    path: '/Doctor/AddGroupMember/:type/:groupId',
    name: '选择患者',
    component: AddGroupMember,
  },
];

export default Routes;
