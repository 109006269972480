import { Model } from 'dva';
import { IGroup } from '../api/WeChatDoctor';
// 医生添加分组页面时缓存分组信息
export default {
  namespace: 'groupInfo',
  state: {
    id: -1,
    name: '',
    description: '',
    hulkUserInfoList: [],
    refreshUserIds: [],
  } as IGroup,
  effects: {
    *saveInfo({ payload, callback }, { put }) {
      yield put({
        type: 'allSave',
        payload,
      });
    },
    *restState({}, { put, select }) {
      yield put({
        type: 'allSave',
        payload: { id: -1, name: '', description: '', hulkUserInfoList: [], refreshUserIds: [] },
      });
    },
  },
  reducers: {
    // tslint:disable-next-line:no-any
    allSave(state: IGroup, action: any) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  subscriptions: {
    // 当不是分组页面时，清除缓存
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (!pathname.includes('/DoctorAddGroup') && !pathname.includes('/AddGroupMember')) {
          dispatch({
            type: 'restState',
          });
        }
      });
    },
  },
} as Model;
