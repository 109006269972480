import { Button } from 'antd';
import { Route, RouteComponentProps, Switch } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { WxDoctorApi } from '../../../api/index';
import { IDoctorMsg, INewNotiReq } from '../../../api/WxDoctorApi';
import { MobileHeader } from '../../../components/index';
import UpdateTitle from '../../../utils/UpdateTitle';
import './AddNotify.scss';
import NotifyContent from './components/NotifyContent/NotifyContent';
import PatientState from './components/PatientState/PatientState';

interface IParams {
  id: string;
  type: string;
}
interface IAddNotifyProps extends RouteComponentProps<IParams> {}
/*
 *
 *  添加通知
 *
 * */
function AddNotify(props: IAddNotifyProps) {
  const { match, history, location } = props;
  const [patientMsg, setPatientMsg] = useState({} as IDoctorMsg);
  const [notifyStartDate, setNotifyStartDate] = useState();
  const [notifyEndDate, setNotifyEndDate] = useState();
  const [notifyTitle, setNotifyTitle] = useState();
  const [notifyContent, setNotifyContent] = useState();
  const [patientType, setPatientType] = useState();
  const [sampleSequence, setSampleSequence] = useState();
  const [visitNumber, setVisitNumber] = useState();
  // const [patientCase, setPatientCase] = useState<IPatientCase>();

  const [isSend, setIsSend] = useState(true);
  let urlMap: Map<string, string> | null = null;
  useEffect(() => {
    if (match.params.type === 'return') {
      UpdateTitle('添加复诊通知');
    } else if (match.params.type === 'follow') {
      UpdateTitle('添加随访通知');
    } else if (match.params.type === 'questionnaire') {
      UpdateTitle('添加随访问卷通知');
      // getPatientCase(); // 获取 医院，问卷 访视阶段 就诊编号
    } else if (match.params.type === 'questionnaireNotify') {
      // getPatientCase(); // 获取 医院，问卷 访视阶段 就诊编号

      // tslint:disable-next-line:no-any
      const urlObj: any = location.search
        .slice(1)
        .split('&')
        .map(val => val.split('='));
      urlMap = new Map(urlObj);

      if (!urlMap.has('patientType') || !urlMap.has('sampleSequence') || !urlMap.has('visitNumber')) {
        history.push(`/Doctor/AddNotifyType/${match.params.id}/questionnaire`);
      }
    }
    getPatientMsg();
  }, []);
  useEffect(() => {
    verifySend();
  }, [notifyStartDate, notifyTitle, notifyContent, patientType, sampleSequence, visitNumber]);

  /**
   * 校验发送
   */
  function verifySend() {
    if (match.params.type === 'questionnaire' && (Number(patientType) === 1 || sampleSequence)) {
      setIsSend(false);
    } else if (notifyStartDate && notifyTitle && notifyContent) {
      setIsSend(false);
    } else {
      setIsSend(true);
    }
  }

  /**
   * 获取病人信息
   */
  async function getPatientMsg() {
    const { result } = await WxDoctorApi.getPatientMsg(match.params.id);
    if (result) {
      setPatientMsg(result);
      setPatientType((urlMap && Number(urlMap.get('patientType'))) || result.type);
      setSampleSequence((urlMap && urlMap.get('sampleSequence')) || result.sampleSequence);
      setVisitNumber((urlMap && urlMap.get('visitNumber')) || result.currentMaxVisitNumber || result.initVisitNumber);
    }
  }
  /**
   * 通过患者ID获取病例信息
   */
  /*  async function getPatientCase() {
    const { result } = await WxDoctorApi.getPatientCase(match.params.id);
    if (result) {
      setPatientCase(result);
      setVisitNumber((urlMap && urlMap.get('visitNumber')) || result.maxVisitNumber || result.initVisitNumber);

      if (result.sampleSequence && match.params.type === 'questionnaire') {
        setIsSend(false);
      }
    }
  }*/
  function onChange(type: string, value: string) {
    if (type === 'notifyStartDate') {
      setNotifyStartDate(value);
      if (notifyEndDate && moment(value).isAfter(moment(notifyEndDate))) {
        setNotifyEndDate(
          moment(value)
            .add(1, 'm')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }
    }
    if (type === 'notifyEndDate') {
      setNotifyEndDate(value);
    }
    if (type === 'notifyTitle') {
      setNotifyTitle(value);
    }
    if (type === 'notifyContent') {
      setNotifyContent(value);
    }
    if (type === 'patientType') {
      setPatientType(value);
    }
    if (type === 'sampleSequence') {
      setSampleSequence(value);
    }
    if (type === 'visitNumber') {
      setVisitNumber(value);
    }
  }
  async function sendNotify() {
    if (match.params.type === 'questionnaire') {
      history.push(
        `/Doctor/AddNotifyType/${match.params.id}/questionnaireNotify?patientType=${patientType}&sampleSequence=${sampleSequence}&visitNumber=${visitNumber}`
      );
      return;
    }
    const notiReq: INewNotiReq = {
      receiveUserId: match.params.id,
      title: notifyTitle,
      content: notifyContent,
      sendTime: moment(notifyStartDate).format('YYYY-MM-DD HH:mm:ss'),
      dataSource: 1,
    };
    if (match.params.type === 'return') {
      notiReq.sysMsgType = 1;
      notiReq.treatmentTime = notifyEndDate;
    } else if (match.params.type === 'follow') {
      notiReq.sysMsgType = 2;
      notiReq.treatmentTime = notifyEndDate;
    } else {
      notiReq.sysMsgType = 3;
      notiReq.visitNumber = Number(visitNumber); // 访视阶段
      if (patientMsg.type !== 2) {
        // 当用户没有角色时
        notiReq.patientType = patientType === 1 ? 'new_patient' : 'old_patient';
      }
      if (sampleSequence && patientType === 2 && patientMsg.type !== 2) {
        // 没有角色 且有 诊断编号
        notiReq.patientRelationship = {
          patientId: match.params.id,
          userId: sampleSequence,
        };
      }
    }
    const { code } = await WxDoctorApi.addOneNotification(notiReq);
    if (code === 0) {
      history.push(`/Doctor/PatientMsg/${match.params.id}`);
    }
  }
  return (
    <div id={'addNotify'}>
      <MobileHeader>
        <Button type={'link'} disabled={isSend} onClick={sendNotify}>
          {match.params.type !== 'questionnaire' ? '发送' : '下一步'}
        </Button>
      </MobileHeader>

      <div className={'addNotifyContent'}>
        <header>
          <img className={'photo'} src={patientMsg.headimgurl} />
          <b>{patientMsg.name}</b>
          <div>
            {patientMsg.gender ? '男' : '女'} <span>{patientMsg.age} 岁 </span>
          </div>
        </header>
        <div>
          {patientMsg.name && (
            <Switch>
              <Route
                path={'/Doctor/AddNotifyType/:id/return'}
                render={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () => (
                    <NotifyContent
                      type={match.params.type}
                      notifyStartDate={notifyStartDate}
                      notifyEndDate={notifyEndDate}
                      notifyTitle={notifyTitle}
                      notifyContent={notifyContent}
                      onChange={onChange}
                    />
                  )
                }
              />
              <Route
                path={'/Doctor/AddNotifyType/:id/follow'}
                render={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () => (
                    <NotifyContent
                      type={match.params.type}
                      notifyStartDate={notifyStartDate}
                      notifyEndDate={notifyEndDate}
                      notifyTitle={notifyTitle}
                      notifyContent={notifyContent}
                      onChange={onChange}
                    />
                  )
                }
              />
              <Route
                path={'/Doctor/AddNotifyType/:id/questionnaireNotify'}
                render={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () => (
                    <NotifyContent
                      type={match.params.type}
                      notifyStartDate={notifyStartDate}
                      notifyEndDate={notifyEndDate}
                      notifyTitle={notifyTitle}
                      notifyContent={notifyContent}
                      onChange={onChange}
                      verifySend={verifySend}
                    />
                  )
                }
              />
              <Route
                path={'/Doctor/AddNotifyType/:id/questionnaire'}
                render={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () => (
                    <PatientState
                      patientMsg={patientMsg}
                      patientType={patientType}
                      sampleSequence={sampleSequence}
                      visitNumber={visitNumber}
                      onChange={onChange}
                      verifySend={verifySend}
                    />
                  )
                }
              />
            </Switch>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddNotify;
