import { IChartDataItem } from '../components/Charts/Charts';
import axios from './RequestApi';
/**
 * @class 我的数据，包括节点用户和云端用户
 */
export default class ListViewApi {
  /**
   * 获取病例总览
   */
  public static getDiseaseOverview(data: IDiseaseListReq) {
    return axios.get<IListResult<IDiseaseItem>, IDiseaseListReq>(`/logined/patient/list`, data);
  }
  /**
   * 问卷列表
   */
  public static geAnswerList<T, D>(data: D) {
    return axios.get<T, D>(`/logined/answer/list`, data);
  }
  /**
   * 患者检索
   */
  public static getPatientByCode<T, D>(data: D) {
    return axios.get<T, D>(`/logined/patient/infoByCode`, data);
  }
  /**
   * 匿名数据
   */
  public static getAnonymousInfo() {
    return axios.get<IAnony, {}>(`/logined/answer/cache/anonymousInfo`);
  }
  /**
   * 根据ID获取用户信息
   */
  public static getInfoById<T, D>(data: D) {
    return axios.get<T, D>(`/logined/patient/infoById`, data);
  }
  /**
   * 获取统计结果
   */
  public static getStatisticMsg() {
    return axios.get<ISatatisticMsg[], {}>('/logined/patient/statis');
  }
  /**
   * 导出统计信息
   *
   */
  public static uploadStatisticMsg(data: IUploadStatisticMsgReq) {
    return axios.get<{}, IUploadStatisticMsgReq>('/logined/excel/template/export/statis', data, 'blob');
  }
}
export interface ISatatisticMsg {
  statisItemVoList: IChartDataItem[];
  type: string;
}

export interface IUploadStatisticMsgReq {
  code?: string;
  customCode?: string;
  name?: string;
  visitNumber?: number;
}

export interface IDiseaseListReq {
  code?: string; // 系统编号
  pageNum: number;
  pageSize: number;
  customCode?: string; // 患者编号
  visitNumber?: number; // 访视次数
}

export interface IDiseaseItem {
  customCode: string;
  id: number;
  lastVisitTime: string;
  name: string;
  visitNumber: number;
  status: number;
  expertName: string;
  code: string;
  maxVisitNumber: number;
  showVisitNumber: number;
  sampleSequence: string;
  isRemind: boolean;
  gender: string;
  createdName: string;
  created: string;
}

export interface IListResult<T> {
  current: number;
  pages: number;
  records: T[];
  size: number;
  total: number;
}

export interface IAnony {
  created: string;
  expertName: string;
  id: number;
  isSubmit: boolean;
  patientId: number;
  questionAnswerJson: string;
  userId: number;
  visitNumber: number;
  answerCacheJson: string;
}
