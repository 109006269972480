import { Model } from 'dva';
import { routerRedux } from 'dva/router';
import { SignInApi } from '../api';
import { IWechatPatientInfo } from '../api/WeChatPatient';
import {
  IConfigInfo,
  IHulkUserInfoBackLoginVo,
  IHulkUserInfoFrontLoginVo,
  IOrganization,
  IWechatUserInfoVo,
} from './../api/SignInApi';
const isMobile = window.screen.width < 500;
export interface IGlobal {
  isLoading: boolean;
  isRem: boolean;
  mobile: string;
  password: string;
  companyInfo: IOrganization;
  sysConfig: IConfigInfo;
  token: string;
  userName: string;
  showQrCodeButton: boolean; // 是否 e-follow显示二维码
  showWxPublicButton: boolean; // 是否显示直接进入公众号
  // tslint:disable-next-line:no-any
  weChat: any; // 这里放微信端的一些信息，等接口出来后完善，但是token 统一放到一个地方
  hulkUserInfoBackLoginVo: IHulkUserInfoBackLoginVo;
  hulkUserInfoFrontLoginVo: IHulkUserInfoFrontLoginVo;
  wechatUserInfoVo: IWechatUserInfoVo;
  wechatPatientInfo: IWechatPatientInfo;
  listType: number;
  wechatDoctorCert: {
    codeTime: number;
  };
}
export default {
  namespace: 'global',
  state: {
    isRem: false,
    mobile: '',
    password: '',
    userName: '',
    showWxPublicButton: false,
    showQrCodeButton: false,
    companyInfo: {}, // 公司信息
    sysConfig: {}, // 配置信息
    token: '',
    weChat: {},
    listType: isMobile ? 2 : 1,
    hulkUserInfoBackLoginVo: {}, // 微信进入后医生信息
    hulkUserInfoFrontLoginVo: {}, // 微信进入后患者信息
    wechatUserInfoVo: {},
    wechatPatientInfo: {
      name: '',
      birthday: '',
      gender: 2,
      smsCode: '',
    },
    wechatDoctorCert: {
      codeTime: 60,
    },
  } as IGlobal,
  effects: {
    // 获取公司信息
    *getCompanyInfo({ payload }, { put }) {
      const { result } = yield SignInApi.getOrgName(payload.code);
      yield put({
        type: 'allSave',
        payload: { companyInfo: result },
      });
    },
    // 保存密码
    *checkoutChange({ payload }, { put }) {
      yield put({
        type: 'allSave',
        payload,
      });
    },
    // 登录
    *login({ payload }, { put, select }) {
      try {
        const { result } = yield SignInApi.userLogin(payload);

        yield put({
          type: 'allSave',
          payload: {
            sysConfig: { ...result.configInfo, currentInitVisitNumber: result.configInfo.initVisitNumber },
            token: result.token,
            userName: result.personalUserInfo.name,
            mobile: payload.mobile,
            password: payload.password,
            showQrCodeButton: result.showQrCodeButton,
            showWxPublicButton: result.showWxPublicButton,
          },
        });
        // tslint:disable-next-line:no-any
        const code = yield select((state: { global: { companyInfo: { code: any } } }) => state.global.companyInfo.code);
        yield put(routerRedux.push(result.isFirstLogin ? `/UpdatePsd/${result.name}/${code}` : '/'));
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e);
      }
    },

    // 扫码保存用户相关信息
    *qrCodeSaveUserInfo({ payload }, { put, select }) {
      const state = yield select(({ global }: { global: IGlobal }) => global);
      yield put({
        type: 'allSave',
        payload: {
          companyInfo: payload,
          sysConfig: {
            ...state.sysConfig,
            initVisitNumber: payload.visitNumber,
          },
        },
      });
    },

    // 直接保存 payload 的值
    *wxInfoSave({ payload, callback }, { put }) {
      yield put({
        type: 'allSave',
        payload,
      });
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    // tslint:disable-next-line:no-any
    allSave(state: IGlobal, action: any) {
      if (!state.isRem) {
        state.password = '';
      }
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  subscriptions: {
    // 当不是分组页面时，清除缓存
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (!pathname.includes('/Doctor') && !pathname.includes('/Patient')) {
          document.getElementById('');
          dispatch({
            type: 'restState',
          });
        }
      });
    },
  },
} as Model;
