import { Dropdown, Menu, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import QRActionApi from '../../../../api/QRActionApi';
import { QrContent } from '../../../../components/QRComponent/QRCmp';
import { IListItem } from '../SeeDiseaseOverview';
import './CaseDropdrow.scss';
export interface IDropdownProps {
  item: IListItem;
  authoritys: boolean[];

  rowAction: (item: IListItem, index: number) => void;
}

export function CaseDropdrow(props: IDropdownProps) {
  const [visible, setVisible] = useState(false);
  const [qRUrl, setQRUrl] = useState('');
  // 检测屏幕点击事件
  useEffect(() => {
    window.addEventListener('click', () => {
      setVisible(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setVisible(false);
      });
    };
  }, []);

  /**
   * 根据 id 去获取 二维码
   */
  async function getQRUrl() {
    const { result } = await QRActionApi.getSurveyQRImgUrl(props.item.id);
    if (result) {
      setQRUrl(result);
    }
  }

  /**
   * 列表行点击事件的处理
   * @param actionIndex 0 '继续填写' 或者 '编辑问卷' 1'查看病例问卷' 2 '下载PDF'3 '二维码'
   */
  function tableRowAction(actionIndex: number) {
    if (actionIndex === 3) {
      getQRUrl();
    }
    props.rowAction(props.item, actionIndex);
  }

  /**
   *  打开更多按钮
   */
  function openDrop(e: React.SyntheticEvent) {
    setVisible(true);
    e.stopPropagation();
  }
  const menuItems: JSX.Element[] = [];
  ['查看病例问卷', '下载PDF', '二维码', '查看报告'].map((item, index) => {
    if (props.authoritys[index]) {
      if (index === 2) {
        menuItems.push(
          <Menu.Item
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={e => {
              tableRowAction(index + 1);
              e.domEvent.stopPropagation();
            }}
            key={index}
          >
            <Tooltip
              placement="rightTop"
              title={<QrContent url={qRUrl} width={120} />}
              trigger="click"
              overlayClassName="toolTipCustom"
              autoAdjustOverflow={true}
              // tslint:disable-next-line:jsx-no-multiline-js
              onVisibleChange={v => {
                setVisible(v);
              }}
              style={{ backgroundColor: 'red' }}
            >
              <div>
                {item}
                <img
                  src={require('../../../../assets/img/icon_code_finish.svg')}
                  style={{ height: '16px', width: '16px', marginLeft: '10px' }}
                  hidden={!props.item.editStatus}
                />
              </div>
            </Tooltip>
          </Menu.Item>
        );
      } else {
        menuItems.push(
          <Menu.Item onClick={() => tableRowAction(index + 1)} key={index}>
            {item}
          </Menu.Item>
        );
      }
    }
  });
  return (
    <Dropdown
      overlay={<Menu>{menuItems} </Menu>}
      trigger={['click']}
      visible={visible}
      key={props.item.id}
      placement="bottomRight"
      className="moreDrop"
    >
      <span
        className="moreAction"
        // tslint:disable-next-line:jsx-no-multiline-js
        onClick={e => {
          openDrop(e);
        }}
      >
        更多
      </span>
    </Dropdown>
  );
}
