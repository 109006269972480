import { RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { WxDoctorApi } from '../../../api';
import { INotiItem } from '../../../api/WxDoctorApi';
import { MobileHeader } from '../../../components';
import './NotiDetail.scss';
interface INotiDetailProps extends RouteComponentProps<{ id: string }> {}
export default function NotiDetail(props: INotiDetailProps) {
  const [notiMsg, setNotiMsg] = useState({} as INotiItem);
  useEffect(() => {
    getNotiMsg();
  }, []);
  /**
   * 获取通知详情
   */
  async function getNotiMsg() {
    const { result } = await WxDoctorApi.getNotificationMsg(props.match.params.id);
    if (result) {
      setNotiMsg(result);
    }
  }
  const notiType = ['复诊通知', '随访通知', ' 随访问卷'];
  const notiTypeCss = ['notiReVisiting', 'notiVisit', 'questionnaire'];
  const timeTitle = ['复诊时间', '随访时间'];
  const sender =
    notiMsg && notiMsg.sendUserInfo
      ? `${notiMsg.sendUserInfo.name}(${notiMsg.sendUserInfo.department} | ${notiMsg.sendUserInfo.title})`
      : '';
  let notiActionStatus = notiMsg.status === 3 ? notiMsg.result : 0; // 消息-发送结果 0 未推送 1 未读 2 已读 3 已处理 当处理后就应当 判断处理状态

  if (notiMsg.sysMsgType === 3) {
    notiActionStatus = notiMsg.answerResult === 1 ? 3 : 0;
  }
  const actonImg = [
    'img_patient_no-action.svg',
    'img_patient_accept.svg',
    'img_patient_refuse.svg',
    'img_patient_finish.svg',
  ][notiActionStatus];
  return (
    <div className="NotiDetail">
      <MobileHeader />
      <div className="headerTitle">{notiMsg && notiMsg.title}</div>
      <div style={{ display: 'flex' }}>
        <div className={notiTypeCss[notiMsg.sysMsgType - 1]}>{notiType[notiMsg.sysMsgType - 1]}</div>
      </div>
      <div className="notiSender">
        <div className="senderMsg">
          <div className="lineSpace">{`发送人: ${sender}`}</div>
          <div className="lineSpace"> 发送时间: {moment(notiMsg.sendTime).format('YYYY-MM-DD HH:mm')} </div>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {notiMsg.sysMsgType !== 3 && (
            <div className="lineSpace">
              {`${timeTitle[notiMsg.sysMsgType - 1]}: ${moment(notiMsg.treatmentTime).format('YYYY-MM-DD HH:mm')}`}{' '}
            </div>
          )}
        </div>

        <img
          // tslint:disable-next-line:jsx-no-multiline-js
          src={require(`../../../assets/moblieImg/${actonImg}`)}
          className="imgContiner"
        />
      </div>
      <div className="notiMsg">{notiMsg.content}</div>
    </div>
  );
}
