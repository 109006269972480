// tslint:disable-next-line:file-name-casing
import { FormComponentProps } from 'antd/lib/form';
import { IGlobal } from '../../../Model/GlobalModel';
import { IPCaseCardProps } from './Component/PCaseCard';

export interface IState {
  data?: IPatientInfo;
  point: string;
  status: number /* 问卷操作状态1：正常，2：继续填写，3：锁定，4：不显示  匿名病例状态  5存在匿名缓存，6：不存在匿名缓存 */;
  QRStatus: boolean; // 二维码隐藏显示
  visible: boolean; // 移动端 显示二维码
  QRUrl: string;
  QRImgBase64: string;
  searchType: number; // 0 病例编号 1 系统编号
}
export interface IPatientInfo {
  customCode: string;
  id: number;
  lastVisitTime: string;
  name: string;
  visitNumber: number;
  status: number;
  expertName: string;
  maxVisitNumber: number;
}
export interface IParam {
  customCode?: string;
  code?: string;
}

export interface IProps extends FormComponentProps {
  global: IGlobal;
  // tslint:disable-next-line:no-any
  history: any;
}

export const PcaseMsg: IPCaseCardProps[] = [
  { title: '二维码新增病例', discription: '展示此二维码给患者新增病例', img: 'pc_icon_qr_code.svg' },
  { title: '新增病例', discription: '新增患者病例', img: 'pc_icon_add_patient.svg' },
  { title: '下载模板', discription: '模板为上传患者信息EXCEL模板', img: 'pc_icon_download.svg' },
  { title: '上传导入', discription: '上传按照模板的患者信息EXCEL', img: 'pc_icon_import.svg' },
];

export const MobileActions = [
  { title: '二维码新增病例', discription: '展示此二维码给患者新增病例', img: 'icon_qr_code.svg' },
  { title: '新增病例', discription: '', img: 'icon_edit.svg' },
];
