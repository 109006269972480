import { Select } from 'antd';
import { useDispatch } from 'dva';
const { Option } = Select;
import React, { useEffect, useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import GetHundredYears from './../../../assets/js/GetHundredYear';
import './FormAloneDate.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
// tslint:disable-next-line:no-any
let timer: any;
/**
 * 可以单选年月日组件
 */
function FormAloneDate(props: ISurveyHook) {
  const dispatch = useDispatch();
  /**
   * 获取年月日
   * @param key
   */
  const getSpecificDate = (key: 'year' | 'month' | 'day') => {
    const date = {
      year: new Date().getFullYear().toString(),
      month: (new Date().getMonth() + 1).toString(),
      day: (new Date().getDate() + 1).toString(),
    };
    return date[key];
  };
  // tslint:disable-next-line:no-any
  const [days, setDays] = useState<any>([]); // 获取这个月的天数
  const [defaultDates, setDefaultDates] = useState<string[]>([]);
  const formObj = props.formObj.subjectData;

  useEffect(() => {
    if (props.formObj.subjectData.defaultValue && props.formObj.subjectData.defaultValue.length) {
      const { year, month } = currentDay(props.formObj.subjectData.defaultValue);
      setDays(getMonthDay(year, month));
      return;
    }
  }, []);

  useEffect(() => {
    // 这里由于defaultDates初始化的时候是由空数组变成带值的数组，所以这里会触发两次，我们省略掉一次没有意义的调用😜
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      let val = '';
      const dateNames = ['年', '月', '日'];
      const confirmed = defaultDates.filter(item => !!item);
      for (let i = 0; i < confirmed.length; i++) {
        // 正则写的可能有点问题所以可能数组里就不止三个 这里直接去三个以内的计算
        if (dateNames[i]) {
          val += confirmed[i] + dateNames[i];
        }
      }
      dispatch({
        type: 'survey/changeForm',
        payload: {
          props,
          val,
        },
      });
    }, 200);
  }, [defaultDates]);

  /**
   * 获取当前日期
   * @param defaultValue 默认值
   */
  const currentDay = (defaultValue?: string) => {
    if (defaultValue) {
      const des = defaultValue.split(/[年|月|日]/);
      setDefaultDates(des.filter(item => !!item));
      return {
        year: des[0] === props.formObj.subjectData.customWordsKey || !des[0] ? getSpecificDate('year') : des[0],
        month: des[1] === props.formObj.subjectData.customWordsKey || !des[1] ? getSpecificDate('month') : des[1],
      };
    }
    setDefaultDates([getSpecificDate('year'), getSpecificDate('month'), getSpecificDate('day')]);
    return {
      year: getSpecificDate('year'),
      month: getSpecificDate('month'),
    };
  };

  /**
   * 获取这个月有多少天
   */
  const getMonthDay = (year: string, month: string) => {
    const dayDatas = [];
    for (let i = 1; i < new Date(Number(year), Number(month), 0).getDate() + 1; i++) {
      dayDatas.push(i.toString());
    }
    dayDatas.push(props.formObj.subjectData.customWordsKey);
    return dayDatas;
  };

  const yearChange = (str: string) => {
    setDefaultDates(str ? [str] : []);
  };

  const monthChange = (str: string) => {
    setDefaultDates(str ? [defaultDates[0], str] : [defaultDates[0]]);
    setDays(getMonthDay(defaultDates[0], str));
  };

  const dayChange = (str: string) => {
    setDefaultDates(str ? [defaultDates[0], defaultDates[1], str] : [defaultDates[0], defaultDates[1]]);
  };
  return (
    <div id="FormAloneDate">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.errMsg ? 'cl star' : 'star'}
              // tslint:disable-next-line:jsx-no-multiline-js
              id={
                props.formObj.subjectData.parentPosition &&
                `errLogo_${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
              }
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <Select
          allowClear={true}
          className={`form_alone_date`}
          placeholder="年份"
          value={defaultDates[0]}
          onChange={yearChange}
          getPopupContainer={() => document.getElementById('scrl_box')!}
        >
          {// tslint:disable-next-line:jsx-no-multiline-js
          GetHundredYears.map((item: string) => {
            return (
              <Option key={item + '年'} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>

        <Select
          allowClear={true}
          className={`form_alone_date`}
          placeholder="月份"
          value={defaultDates[1]}
          onChange={monthChange}
          disabled={defaultDates.length < 1}
          getPopupContainer={() => document.getElementById('scrl_box')!}
        >
          <Option
            key={props.formObj.subjectData.customWordsKey! + '月'}
            value={props.formObj.subjectData.customWordsVal || ''}
          >
            {props.formObj.subjectData.customWordsKey}
          </Option>
          {// tslint:disable-next-line:jsx-no-multiline-js
          ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((item: string) => (
            <Option key={item.toString()} value={item}>
              {item}
            </Option>
          ))}
        </Select>

        <Select
          allowClear={true}
          className={`form_alone_date`}
          placeholder="天"
          value={defaultDates[2]}
          onChange={dayChange}
          disabled={defaultDates.length < 2}
          getPopupContainer={() => document.getElementById('scrl_box')!}
        >
          {// tslint:disable-next-line:jsx-no-multiline-js
          days.map((item: string) => (
            <Option key={item + '日'} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg" // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition &&
              `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
            }
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg" // tslint:disable-next-line:jsx-no-multiline-js
          id={
            props.formObj.subjectData.parentPosition &&
            `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
          }
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormAloneDate);
