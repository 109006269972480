import { Icon } from 'antd';
import { Link } from 'dva/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WeChatPatient, { INotify } from '../../../api/WeChatPatient';
import { MobileHeader, MobileTag } from '../../../components';
import { MobilePagination } from '../../../utils';
import UpdateTitle from '../../../utils/UpdateTitle';
import { IMessageType, IProps } from './interface';
import './PatientNotifyList.scss';

export default (props: IProps) => {
  const [patientNotifys, setPatientNotifys] = useState<INotify[]>([]);

  let page = { pageSize: 10, total: 0, current: 1 };
  // tslint:disable-next-line:no-any
  let patientsData: any[] | never[] = [];
  let loadingList: boolean = true;

  const messageType: IMessageType = {
    1: { name: '复诊通知', class: 'orange' },
    2: { name: '随访通知', class: 'green' },
    3: { name: '随访问卷', class: 'blue' },
  };
  useEffect(() => {
    getNotifies(props.match.params.doctorId, 1);
    UpdateTitle(`${props.match.params.name}的通知`);
    window.addEventListener('scroll', scrollE, true);

    return () => {
      window.removeEventListener('scroll', scrollE, true);
    };
  }, []);
  let listContent;
  if (!patientNotifys || patientNotifys.length <= 0) {
    listContent = (
      <div className="noDoctor">
        <img src={require('./../../../assets/img/img_time_null.svg')} />
        <p>暂无通知</p>
      </div>
    );
  } else {
    listContent = (
      <div className="list">
        {// tslint:disable-next-line: jsx-no-multiline-js
        patientNotifys.map(item => {
          let notifyText = <div />;
          if (item.sysMsgType === 3) {
            if (item.status === 2 || item.status === 3) {
              notifyText = <MobileTag text={item.buttonStatus === 3 ? '我已填写' : '我未操作'} />;
            }
          } else if (item.status === 3) {
            notifyText = <MobileTag text={item.result === 1 ? '我已接受' : '我已拒绝'} />;
          }

          return (
            <div key={item.id} className="notifyItem">
              <Link to={`/Patient/PatientNotifyDetail/${item.id}`}>
                <div className="topAreaTitle">
                  <span>{moment(item.sendTime).format('YYYY-MM-DD')}</span>
                  <h1>{item.title}</h1>
                </div>
                <div className={'topLabel'}>
                  <div>
                    {item.sysMsgType === 1 && <MobileTag text={'复诊通知'} />}
                    {item.sysMsgType === 2 && <MobileTag text={'随访通知'} />}
                    {item.sysMsgType === 3 && <MobileTag text={'随访问卷'} />}

                    {notifyText}
                  </div>
                  {/* tslint:disable-next-line:jsx-no-multiline-js */}
                  {item.sysMsgType !== 3 && item.result === 1 && (
                    <div className={'time'}>
                      {messageType[item.sysMsgType].name.slice(0, 2)}时间：{item.treatmentTime}
                    </div>
                  )}

                  <div className={'icon_back'}>
                    {item.status === 1 && <i className={'redPoint'} />}

                    <Icon className={'icon'} type="right" />
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div id="Patient-PatientNotifyList">
      <MobileHeader backUrl={'/Patient/PatientHomePage'} />
      <div className="notifyList">{listContent}</div>
    </div>
  );
  async function getNotifies(id: string, curr: number) {
    const { result } = await WeChatPatient.getNotifies({
      pageNum: curr,
      pageSize: page.pageSize,
      createId: id,
    });
    setPatientNotifys([...patientsData, ...result.records]);
    patientsData = [...patientsData, ...result.records];
    page = { current: result.current, total: result.total, pageSize: result.size };
    loadingList = false;
  }
  function scrollE() {
    if (!loadingList) {
      MobilePagination('Patient-PatientNotifyList', page.current, page.pageSize, page.total).then(pageNum => {
        if (pageNum && pageNum !== page.current) {
          loadingList = true;
          page.current = pageNum;
          getNotifies(props.match.params.doctorId, pageNum);
        }
      });
    }
  }
};
