import Mustache from 'mustache';
/**
 * 模板解析器
 * @param template 需要解析的字符串 {{需要解析的key}}
 * @param view 解析后对应匹配的对应
 * @param type
 * @return string 返回一段可以eval 的字符串
 */
// tslint:disable-next-line:no-any
function Parser(template: string, view: any, type?: string) {
  // 这里要深拷贝一份 不然会改变原数据
  // 这些注释不要删除
  // view = JSON.parse(JSON.stringify(view));
  // const mark = template.match(/({{.*?}})/g);
  // console.log(template, '我是换出来的');
  // if (mark) {
  //   mark.forEach((item, index) => {
  //     const key = item.replace(/[^\w]/g, '');
  //     // 如果是key的值是数组，目前只支持+，暂时不做解析处理
  //     if (Array.isArray(view[key])) {
  //       view[key] = `[${view[key]}]`;
  //     }
  //     if (view[key] === undefined) {
  //       view[key] = type ? null : 0;
  //     }
  //     template = template.replace(item, view[key]);
  //   });
  // }
  view = JSON.parse(JSON.stringify(view));
  // 日期 天数转换
  view.date_dayParse = () => {
    return (text: string, render: (text: string) => string) => {
      // 单选日期模式
      if (/年|月|日/.test(render(text))) {
        // 获取传入的年月日的数组
        const currentDates = render(text).match(/\d+/g)!;
        for (let i = 0; i < 3; i++) {
          currentDates[i] = currentDates[i] ? currentDates[i] : '1';
        }
        // 这里有一个很有意思的现象 两个日期比如 2019-1-1 和 2019-1-1 一起new Date 时间 一个 0点 一个8点
        return Date.parse(`${currentDates.join('-')} 00:00:00`);
      }
      return Date.parse(`${render(text)} 00:00:00`);
    };
  };
  // 日期 天数计算
  view.date_dayCount = () => {
    return (text: string, render: (text: string) => string) => {
      try {
        // tslint:disable-next-line:no-eval
        const daySecond = Math.abs(eval(render(text)));
        return daySecond / (24 * 3600 * 1000);
      } catch (err) {
        return 0;
      }
    };
  };

  // 日期 月份转换
  view.date_monthParse = () => {
    return (text: string, render: (text: string) => string) => {
      // 单选日期模式
      if (/年|月|日/.test(render(text))) {
        // 获取传入的年月日的数组
        // tslint:disable-next-line:no-any
        const currentDates: any[] = render(text).match(/\d+/g)!;
        for (let i = 0; i < 3; i++) {
          currentDates[i] = currentDates[i] ? currentDates[i] : '1';
        }
        return Number(currentDates[0]) * 12 + Number(currentDates[1]);
      }
      const dates = render(text).split('-');
      return Number(dates[0]) * 12 + Number(dates[1]);
    };
  };
  // 日期 月份计算
  view.date_monthCount = () => {
    return (text: string, render: (text: string) => string) => {
      try {
        // tslint:disable-next-line:no-eval
        return Math.abs(eval(render(text)));
      } catch (err) {
        return 0;
      }
    };
  };

  // 时间 转换成秒统一计算
  view.time_parse = () => {
    return (text: string, render: (text: string) => string) => {
      // tslint:disable-next-line:no-any
      const times: any = render(text).match(/\d+/g);
      return (
        Number(times[0] ? times[0] : `${type ? null : 0}`) * 3600 +
        Number(times[1] ? times[1] : `${type ? null : 0}`) * 60 +
        Number(times[2] ? times[2] : `${type ? null : 0}`)
      );
    };
  };
  // 时间 小时计算
  view.time_hourCount = () => {
    return (text: string, render: (text: string) => string) => {
      try {
        // tslint:disable-next-line:no-eval
        const h = eval(render(text)) / 3600;
        return h > 0 ? h : 24 + h;
      } catch (err) {
        return 0;
      }
    };
  };
  // 时间 分钟计算
  view.time_minuteCount = () => {
    return (text: string, render: (text: string) => string) => {
      try {
        // tslint:disable-next-line:no-eval
        const h = eval(render(text)) / 3600;
        return h > 0 ? h * 60 : (24 + h) * 60;
      } catch (err) {
        return 0;
      }
    };
  };
  // 时间 秒计算
  view.time_secondCount = () => {
    return (text: string, render: (text: string) => string) => {
      try {
        // tslint:disable-next-line:no-eval
        const h = eval(render(text)) / 3600;

        return h > 0 ? h * 3600 : (24 + h) * 3600;
      } catch (err) {
        return 0;
      }
    };
  };

  // 计算年龄
  view.countAge = () => {
    return (text: string, render: (text: string) => string) => {
      return getAge(render(text));
    };
  };
  // 判断是否是数组
  view.turnArray = () => {
    return (text: string, render: (text: string) => string) => {
      return `[${render(text)}]`;
    };
  };

  // 算分题专用
  view.defaultNum = () => {
    return (text: string, render: (text: string) => string) => {
      return render(text) ? render(text) : 0;
    };
  };

  const a = Mustache.render(template, view);
  return a;
}
// 获取年龄
const getAge = (birthday: string) => {
  const birthdayArr = birthday.split('-');
  const birthdayYear = Number(birthdayArr[0]);
  const birthdayMonth = Number(birthdayArr[1]);
  const birthdayDay = Number(birthdayArr[2]);
  const today = new Date();
  const nowYear = today.getFullYear();
  const nowMonth = today.getMonth() + 1;
  const nowDay = today.getDate();
  // 同年 则为0岁
  if (nowYear === birthdayYear || !birthday) {
    return 0;
  }
  const ageDiff = nowYear - birthdayYear; // 年之差
  if (ageDiff > 0) {
    if (nowMonth === birthdayMonth) {
      const dayDiff = nowDay - birthdayDay; // 日之差
      return dayDiff < 0 ? ageDiff - 1 : ageDiff;
    }
    const monthDiff = nowMonth - birthdayMonth; // 月之差
    return monthDiff < 0 ? ageDiff - 1 : ageDiff;
  }
};
export default Parser;
