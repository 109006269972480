/**
 * 更新网页标题
 * @param { title } 标题名
 */
function UpdateTitle(title: string) {
  if (document.getElementById('title')) {
    document.getElementById('title')!.innerHTML = title;
  }
}

export default UpdateTitle;
