import { Input, Radio } from 'antd';
import { Picker } from 'antd-mobile';
import React, { Fragment, useEffect, useState } from 'react';
import WxDoctorApi, { IDoctorMsg, IPatientCase } from '../../../../../api/WxDoctorApi';
import VerifyPatientCodeModal from '../../../EditPatient/components/VerifyPatientCodeModal/VerifyPatientCodeModal';
import './PatientState.scss';
interface IPatientStateProps {
  patientMsg: IDoctorMsg;
  patientType: string;
  sampleSequence: string;
  visitNumber: string;
  patientCase?: IPatientCase;
  onChange: (type: string, value: string) => void;
  verifySend: () => void;
}
type CascaderOneValue = React.ReactText[] | undefined;
/*
 *
 *  问卷通知时 操作新老患者逻辑
 *
 * */
function PatientState(props: IPatientStateProps) {
  const { patientMsg, patientType, sampleSequence, onChange, visitNumber, verifySend } = props;
  // const [patient, setPatient] = useState();
  // const [sampleSequence, setSampleSequence] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [check, setCheck] = useState<CascaderOneValue>();
  const [patientCode, setPatientCode] = useState();
  const [docMsg, setDocMsg] = useState({} as IDoctorMsg);

  useEffect(() => {
    verifySend();
    getDoctorMsg();
  }, []);
  /**
   * 获取医生信息
   */
  async function getDoctorMsg() {
    const { result } = await WxDoctorApi.getUserMsg();
    if (result) {
      //
      setDocMsg(result);
    }
  }
  useEffect(() => {
    if (check) {
      onChange('visitNumber', check[0] + '');
    }
  }, [check]);
  // tslint:disable-next-line:no-any one-variable-per-declaration
  let visitingData: any[] = [0],
    maxVisitNumber: number = 0,
    initVisitNumber: number = 0;

  if (patientMsg.name) {
    maxVisitNumber = patientMsg.currentMaxVisitNumber;
    initVisitNumber = patientMsg.initVisitNumber;
  }
  visitingData = Array((maxVisitNumber || initVisitNumber) + 1);
  if (patientCode && patientCode.name) {
    maxVisitNumber = patientCode.maxVisitNumber;
    initVisitNumber = patientCode.initVisitNumber;
    // 根据就诊编号生成 访视阶段内容
    visitingData = Array((maxVisitNumber || initVisitNumber) + 1);
  }
  // 生成方式阶段，方式阶段，初始可 根据 initVisitNumber 设置
  visitingData = visitingData
    .fill(1)
    .map((value, index) => index)
    .slice(initVisitNumber)
    .map(value => ({ label: `访视${value}`, value }));
  if (maxVisitNumber !== patientMsg.maxVisitNumber) {
    visitingData.push({ label: '新增问卷', value: visitingData.length });
  }
  visitingData.reverse();

  /*
   *
   *   启动校验 患者编号
   * */
  // tslint:disable-next-line:no-any
  function actionVerify(reset?: boolean, data?: any) {
    if (!sampleSequence || patientMsg.sampleSequence === sampleSequence) {
      return;
    }
    setShowVerify(!showVerify);
    if (data) {
      setPatientCode(data);
    }
    if (reset) {
      onChange('sampleSequence', '');
    }
  }

  const checkText = check ? visitingData.find(({ value }) => value === check[0]) : '';
  return (
    <div id={'patientState'}>
      <h1>访视阶段</h1>
      <div className={'container'}>
        <div className={'state'}>
          <b>患者类型</b>
          <div className={'contentRight'}>
            <Radio.Group
              style={{ fontWeight: 'bold' }}
              disabled={!!patientMsg.type}
              value={patientType}
              onChange={e => onChange('patientType', e.target.value)}
            >
              <Radio value={1}>新患者</Radio>
              <Radio value={2}>老患者</Radio>
            </Radio.Group>
          </div>
        </div>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {patientType && (
          <Fragment>
            <p>就诊医院：{docMsg && docMsg.organizationName}</p>
            <p>问卷系统：{docMsg && docMsg.systemName}</p>
          </Fragment>
        )}
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {Number(patientType) === 2 && (
          <Fragment>
            <hr />
            <div className={'codeSampleSequence'}>
              <b>就诊编号</b>
              <span className={'contentRight'}>
                {/* tslint:disable-next-line:jsx-no-multiline-js */}
                {patientMsg.type !== 2 ? (
                  <Input
                    placeholder={'请输入就诊编号'}
                    className={'code'}
                    value={sampleSequence}
                    onChange={e => onChange('sampleSequence', e.target.value)}
                    onBlur={() => actionVerify()}
                  />
                ) : (
                  <span>{sampleSequence}</span>
                )}

                <img src={require('../../../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
                {/* tslint:disable-next-line:jsx-no-multiline-js */}
                {showVerify && (
                  <VerifyPatientCodeModal
                    onClose={actionVerify}
                    show={showVerify}
                    patientMsg={patientMsg}
                    sampleSequence={sampleSequence}
                  />
                )}
              </span>
            </div>
          </Fragment>
        )}
      </div>
      {/* tslint:disable-next-line:jsx-no-multiline-js */}
      {patientType && (
        <div className={'container'}>
          <Picker
            disabled={patientMsg.type === 1 || Number(patientType) === 1 || !sampleSequence}
            data={visitingData}
            cols={1}
            value={check}
            onChange={setCheck}
          >
            <div>
              <b>访视阶段</b>
              {//  tslint:disable-next-line:jsx-no-multiline-js
              Number(patientType) === 1 ? (
                <div className={'contentRight'}>{`访视${patientMsg && patientMsg.initVisitNumber}`}</div>
              ) : (
                <div className={'contentRight'}>{checkText ? `访视${checkText.value}` : `访视${visitNumber}`}</div>
              )}
            </div>
          </Picker>
          {Number(patientType) === 1 ? (
            <div className={'hint'}>新患者只能发送访视{patientMsg && patientMsg.initVisitNumber}（基本信息）</div>
          ) : (
            <div className={'hint'}>
              现有访视：
              {//  tslint:disable-next-line:jsx-no-multiline-js
              visitingData
                .slice(1)
                .map(value => value.label)
                .join('/')}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PatientState;
