import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch } from 'dva';
import React, { useEffect, useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormRadio.scss';
const isPc = window.screen.width > 500;
/**
 * 单选框 FormRadio
 */

function FromRadio(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  const [val, setVal] = useState(props.formObj.subjectData.defaultValue);
  const dispatch = useDispatch();
  useEffect(() => {
    if (val !== null) {
      dispatch({
        type: 'survey/changeForm',
        payload: {
          props,
          val,
        },
      });
    }
  }, [val]);
  function radioChange(str: RadioChangeEvent) {
    setVal(str.target.value);
  }
  // 其他输入
  function otherInput(str: React.ChangeEvent<HTMLInputElement>) {
    if (str.currentTarget.value.includes(':')) {
      alert('不能输入英文冒号');
      setVal('');
      return;
    }
    setVal(`${props.formObj.subjectData.otherInput}:${str.currentTarget.value}`);
  }

  function getDefaultValue() {
    if (val) {
      return val.toString().split(':')[1];
    }
    return '';
  }
  return (
    <div id="FromRadio">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.errMsg ? 'cl star' : 'star'}
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
            >
              *
            </div>
          </AuthCompount>
          <div>{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!!formObj.explain}>
        <div className="explain">（说明：{formObj.explain}）</div>
      </AuthCompount>
      <Radio.Group onChange={radioChange} value={val + ''} defaultValue={formObj.defaultValue + ''} className="rad">
        {// tslint:disable-next-line:jsx-no-multiline-js
        formObj.radios &&
          formObj.radios.map(item => (
            <Radio key={item.name} value={`${item.value}`}>
              {item.name || item.label}
            </Radio>
          ))}
      </Radio.Group>
      <AuthCompount
        // tslint:disable-next-line:jsx-no-multiline-js
        isShow={val && val.toString().includes(props.formObj.subjectData.otherInput)}
      >
        <input
          className="form_input"
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={getDefaultValue()}
          onChange={otherInput}
        />
      </AuthCompount>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FromRadio);
