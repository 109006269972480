import { Button, Icon } from 'antd';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import WeChatDoctor, { IGroup } from '../../../api/WeChatDoctor';
import { MobileAddBlockContent, MobileHeader, MobileNoDatas } from '../../../components/index';
import { MobilePagination, UpdateTitle } from '../../../utils/index';
import './DoctorGroup.scss';

interface IMyState {
  pageData: { pageSize: number; total: number; current: number };
  groupsData: IGroup[];
  delGroups: IDelGroups;
}
interface IDelGroups {
  isDel: boolean;
  delArr: number[];
}
// tslint:disable-next-line:no-empty-interface
interface IMyProps extends RouteComponentProps {}
class DoctorGroupClass extends Component<IMyProps, IMyState> {
  constructor(props: Readonly<IMyProps>) {
    super(props);
    this.state = {
      pageData: { pageSize: 10, total: 0, current: 1 },
      groupsData: [],
      delGroups: {
        isDel: false,
        delArr: [],
      },
    };
    this.scrollE = this.scrollE.bind(this);
  }

  public componentDidMount(): void {
    UpdateTitle('我的分组');
    this.getGroups();
    window.addEventListener('scroll', this.scrollE, true);
  }
  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.scrollE, true);
  }
  public scrollE() {
    const { pageData } = this.state;
    if (pageData.total > pageData.pageSize) {
      MobilePagination('Doctor-DoctorGroup', pageData.current, pageData.pageSize, pageData.total).then(pageNum => {
        if (pageNum) {
          this.setState((prevState: IMyState) => {
            return { pageData: { ...prevState.pageData, current: Number(pageNum) } };
          }, this.getGroups);
        }
      });
    }
  }

  // 获取我的分组
  public async getGroups(noMerge?: boolean) {
    const {
      pageData: { current: pageNum, pageSize: pageSize },
    } = this.state;
    const { result } = await WeChatDoctor.getMyGroups({ pageNum, pageSize });
    if (result) {
      this.setState((prevState: IMyState) => {
        // tslint:disable-next-line:no-any
        let state: any = {};
        if (prevState.groupsData.length && !noMerge) {
          state = { groupsData: [...prevState.groupsData, ...result.records] };
        } else {
          state = { groupsData: result.records };
        }
        state = { ...state, pageData: { current: result.current, total: result.total, pageSize: result.size } };
        return state;
      });
    }
  }
  // 删除分组
  public delGroups = async () => {
    const {
      delGroups: { isDel, delArr },
    } = this.state;
    if (isDel) {
      const result = await WeChatDoctor.deleteGroup({ data: { ids: delArr } });
      if (result.code === 0) {
        this.toggleDelGroups(false);
        this.setState({ pageData: { pageSize: 10, total: 0, current: 1 } }, () => {
          this.getGroups(true);
        });
      }
    }
  };
  // 跳转详情
  public clickGroup(id: number) {
    const {
      delGroups: { isDel, delArr },
    } = this.state;
    if (isDel) {
      if (delArr.includes(id)) {
        this.setState({ delGroups: { isDel, delArr: delArr.filter(value => value !== id) } });
      } else {
        this.setState({ delGroups: { isDel, delArr: [...delArr, id] } });
      }
    } else {
      this.props.history.push(`/Doctor/DoctorGroupDetail/${id}`);
    }
  }
  public toggleDelGroups(isDel: boolean) {
    this.setState({ delGroups: { isDel, delArr: [] } });
  }

  public render() {
    const { delGroups, groupsData } = this.state;

    return (
      <div id="Doctor-DoctorGroup">
        <div className={'groupHeader'}>
          <MobileHeader backUrl={'/Doctor/DoctorInfo'}>
            {// tslint:disable-next-line: jsx-no-multiline-js
            delGroups.isDel ? (
              <Fragment>
                <Button type={'link'} onClick={() => this.toggleDelGroups(false)}>
                  取消
                </Button>
                <Button
                  type={'link'}
                  disabled={delGroups.delArr.length === 0}
                  className="deleteSure"
                  onClick={this.delGroups}
                >
                  确定删除
                </Button>
              </Fragment>
            ) : (
              <Button type={'link'} disabled={groupsData.length === 0} onClick={() => this.toggleDelGroups(true)}>
                删除组
              </Button>
            )}
          </MobileHeader>
          <MobileAddBlockContent backUrl={`/Doctor/DoctorAddGroup/ADD/0`}>添加分组</MobileAddBlockContent>
        </div>
        <div className={'groupList'}>
          {//  tslint:disable-next-line:jsx-no-multiline-js
          groupsData.length ? (
            groupsData.map(({ id, name, description, hulkUserInfoList }) => (
              <div key={`groupItem-${id}`} className={'groupItem'} onClick={() => this.clickGroup(id)}>
                <div className={'groupItemHeader'}>
                  {//  tslint:disable-next-line:jsx-no-multiline-js
                  delGroups.isDel && delGroups.delArr.includes(id) && (
                    <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time_select.svg')} />
                  )}
                  {//  tslint:disable-next-line:jsx-no-multiline-js
                  delGroups.isDel && !delGroups.delArr.includes(id) && (
                    <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time.svg')} />
                  )}
                  <div className="itemTitle">{name}</div>
                  <div className={'itemLabel'}>
                    <div className="itemPhoto">
                      {//  tslint:disable-next-line:jsx-no-multiline-js
                      hulkUserInfoList.slice(0, 5).map((item, index, array) => {
                        return (
                          <div key={item.id} style={{ zIndex: array.length - 1 - index }}>
                            <img src={item.headimgurl} />
                          </div>
                        );
                      })}
                    </div>
                    <span className="itemCount">{`${hulkUserInfoList.length}人`}</span>
                    <Icon type="right" className="detailsArrow" />
                  </div>
                </div>
                <p>{description}</p>
              </div>
            ))
          ) : (
            <MobileNoDatas imgurl={'img_group_null.svg'} show={true} msg={'您还没有分组'} />
          )}
        </div>
      </div>
    );
  }
}

export default DoctorGroupClass;
