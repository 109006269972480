import axios from './RequestApi';
export default class WxDoctorApi {
  /**
   *
   * 获取我的病人列表
   */
  public static getMyPatients(data: IMyPatientReq) {
    return axios.get<IListResult<IPatientItem>, IMyPatientReq>(`/logined/hulkUserInfo/wechat/patient/page`, data);
  }

  /**
   *
   * 获取医生详情
   */
  public static getUserMsg() {
    return axios.get<IDoctorMsg, {}>(`/logined/hulkUserInfo/userInfo`);
  }
  /**
   *
   * 通过患者ID获取病例信息
   */
  public static getPatientCase(id: string) {
    return axios.get<IPatientCase, {}>(`/logined/wechatUserPatientRelationship/${id}`);
  }
  /**
   *
   * 获取病人详情
   */
  public static getPatientMsg(id: string) {
    return axios.get<IDoctorMsg, {}>(`/logined/hulkUserInfo/${id}/userInfo`);
  }
  /**
   * 医生认证
   */
  public static doctorCetification(data: IDoctorCetifReq) {
    return axios.post<string, IDoctorCetifReq>(`/logined/wechat/validationCode/authenticate`, data);
  }

  /**
   *
   * 获取病人通知列表
   */
  public static getPatientNotis(data: INotiListReq) {
    return axios.get<IListResult<INotiItem>, INotiListReq>(`/logined/wechatNotifyMsgTemplate/systemMsg/page`, data);
  }
  /**
   * 新增通知
   */
  public static addOneNotification(data: INewNotiReq) {
    return axios.post<INotiItem, INewNotiReq>(`/logined/wechatNotifyMsgTemplate/public/save`, data);
  }

  /**
   *
   * 获取通知信息
   */
  public static getNotificationMsg(id: string) {
    return axios.get<INotiItem, {}>(`/logined/wechatNotifyMsgTemplate/${id}`);
  }

  /**
   *
   * 获取分组信息
   */
  public static getPatientEditMsg(data: IPatientEditReq) {
    return axios.get<IPatientEditMsg, IPatientEditReq>(`/logined/wechatPatientRemark/get`, data);
  }

  /**
   * 编辑病人信息
   */
  public static updatePatientEditMsg(data: IUpdatePMsgReq) {
    return axios.post<string, IUpdatePMsgReq>(`/logined/wechatPatientRemark/save`, data);
  }
  /**
   * 获取病人通知数量
   */
  public static getNotiNumbers(data: INotiNumberReq) {
    return axios.get<number, INotiNumberReq>(`/logined/wechatNotifyMsgTemplate/count`, data);
  }
  /**
   * 病人信息,修改就诊状态
   */

  public static updateNotiTreateStatus(data: IUpdateNotiTreateReq) {
    return axios.post<boolean, IUpdateNotiTreateReq>(`/logined/wechatNotifyMsg/visitResult/deal`, data);
  }
}

export interface IUpdateNotiTreateReq {
  id: string;
  visitResult: number | string;
}
export interface INotiNumberReq {
  receiveUserId: string;
  sysMsgType: string; // come_back_msg:复诊通知,follow_up_msg:随访通知,follow_up_questionnaire:随访问卷
}

export interface IDoctorCetifReq {
  inviteCode: string;
  mobile: string;
  validationCode: string;
}

export interface IMyPatientReq {
  name?: string;
  pageNum: number;
  pageSize: number;
}

export interface IPatientItem {
  age: number;
  attentionTime: string;
  department: string;
  expertName: string;
  gender: number; // 性别
  groupNames: string[];
  headimgurl: string;
  id: number;
  morphUserUuid: string;
  msgCount: number;
  name: string;
  organizationName: string;
  sampleSequence: string;
  title: string;
  wechatUserInfoId: number;
}

export interface IListResult<T> {
  current: number;
  pages: number;
  records: T[];
  size: number;
  total: number;
}

export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
}
export interface IDoctorMsg {
  currentMaxVisitNumber: number;
  age: number;
  department: string;
  expertName: string;
  systemName: string;
  gender: number;
  initVisitNumber: number;
  maxVisitNumber: number;
  groupCount: number;
  id: number;
  morphUserUuid: number;
  name: string;
  organizationName: string;
  patientCount: number;
  qrCodeUrl: string;
  systemCode: string;
  title: string;
  wechatUserInfoId: number;
  sampleSequence: string;
  groupNames: string[];
  sex: number;
  type: number;
  headimgurl: string;
}

export interface IPatientCase {
  hospitalName?: string;
  initVisitNumber?: number;
  maxVisitNumber?: number;
  patientId?: number;
  sampleSequence?: string;
  systemName?: string;
  userId?: number;
}

export interface INotiListReq {
  receiveUserId: string;
  startTime?: number;
  endTime?: number;
  pageNum: number;
  pageSize: number;
  sysMsgType: string; // come_back_msg:复诊通知,follow_up_msg:随访通知,follow_up_questionnaire:随访问卷
}

export interface INotiItem {
  content: string;
  createdId: number;
  id: number;
  notifyMsgId: number;
  answerResult: number;
  notifyMsgTemplateVo: INotifyMsgTemplateVo;
  receiveUserId: number;
  receiveUserInfo: IReceiveUserInfo;
  result: number;
  sendTime: string;
  sendUserInfo: ISendUserInfo;
  status: number; // number 未推送 1 未读 2 已读 3 已处理
  sysMsgType: number;
  treatmentTime: string;
  type: number;
  title: string;
  typeStr: string;
  visitResult: number;
  msgId: string;
}

export interface INotifyMsgTemplateVo {
  content: string;
  createdId: number;
  dataSource: number;
  id: number;
  receiveUserId: number;
  receiveUserInfo: IReceiveUserInfo;
  result: number;
  sendTime: string;
  sendUserInfo: ISendUserInfo;
  status: number;
  sysMsgType: number;
  sysMsgTypeStr: string;
  title: string;
  treatmentTime: string;
  type: number;
}

export interface IReceiveUserInfo {
  age: number;
  department: string;
  expertName: string;
  gender: number;
  groupCount: number;
  id: number;
  morphUserUuid: number;
  name: string;
  organizationName: string;
  patientCount: number;
  qrCodeUrl: string;
  systemCode: string;
  title: string;
  wechatUserInfoId: number;
}
export interface ISendUserInfo {
  age: number;
  department: string;
  expertName: string;
  gender: number;
  groupCount: number;
  id: number;
  morphUserUuid: number;
  name: string;
  organizationName: string;
  patientCount: number;
  qrCodeUrl: string;
  systemCode: string;
  title: string;
  wechatUserInfoId: number;
}

export interface INewNotiReq {
  content?: string;
  dataSource?: number;
  nextFollowUpDays?: number;
  pushCycleId?: number;
  // tslint:disable-next-line:no-any
  receiveUserId?: any;
  sendTime?: string;
  sysMsgType?: number;
  title?: string;
  treatmentTime?: string;
  patientType?: string;
  visitNumber?: number;
  type?: number;
  patientRelationship?: {
    patientId: string;
    userId: string;
  };
}

export interface IPatientEditReq {
  patientId: string;
}

export interface IPatientEditMsg {
  groupInfoVo: IGroupItem[];
  patientId: number;
  remark: string;
  sampleSequence: string;
  type: string;
}

export interface IGroupItem {
  exist: boolean;
  id: string;
  name: string;
  description: string;
}

export interface IUpdatePMsgReq {
  groupIds: string[];
  patientId: string;
  remark: string;
  sampleSequence: string;
  type: 'new_patient' | 'old_patient';
}
