import { Input } from 'antd';
import { DatePicker } from 'antd-mobile';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import './NotifyContent.scss';

const { TextArea } = Input;

interface INotifyContentProps {
  type: string;
  notifyStartDate: string;
  notifyEndDate: string;
  notifyTitle: string;
  notifyContent: string;
  onChange: (type: string, value: string) => void;
  verifySend?: () => void;
}
/*
 *
 *  编辑通知内容
 *
 * */
function NotifyContent(props: INotifyContentProps) {
  const { type, notifyStartDate, notifyEndDate, notifyTitle, notifyContent, onChange, verifySend } = props;
  useEffect(() => {
    if (verifySend) {
      verifySend();
    }
  }, []);

  return (
    <div id={'notifyContent'}>
      <h1>通知详情</h1>
      <div className={'container'}>
        <h2>通知标题</h2>
        <TextArea
          value={notifyTitle}
          onChange={e => onChange('notifyTitle', e.target.value)}
          placeholder={'请输入标题...'}
        />
      </div>
      <div className={'container'}>
        <DatePicker
          mode="datetime"
          className={'timePicker450pxCenter'}
          minDate={
            //  tslint:disable-next-line:jsx-no-multiline-js
            moment()
              .add(1, 'h')
              .toDate()
          }
          value={moment(notifyStartDate).toDate()}
          onChange={date => onChange('notifyStartDate', moment(date).format('YYYY-MM-DD HH:mm:ss'))}
        >
          <div>
            <b>发送时间</b>
            <div className={'contentRight'}>
              {notifyStartDate ? moment(notifyStartDate).format('YYYY-MM-DD HH:mm') : <span>选择时间</span>}
              <img src={require('../../../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
            </div>
          </div>
        </DatePicker>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {(type === 'return' || type === 'follow') && (
          <Fragment>
            <hr />
            <DatePicker
              mode="datetime"
              className={'timePicker450pxCenter'}
              minDate={
                //  tslint:disable-next-line:jsx-no-multiline-js
                moment(notifyStartDate)
                  .add(1, 'm')
                  .toDate()
              }
              value={moment(notifyEndDate).toDate()}
              onChange={date => onChange('notifyEndDate', moment(date).format('YYYY-MM-DD HH:mm:ss'))}
            >
              <div>
                <b>{type === 'return' ? '复诊' : '随访'}时间</b>
                <div className={'contentRight'}>
                  {notifyEndDate ? moment(notifyEndDate).format('YYYY-MM-DD HH:mm') : <span>选择时间</span>}
                  <img src={require('../../../../../assets/moblieImg/icon_more2.svg')} className="moreIcon" />
                </div>
              </div>
            </DatePicker>
          </Fragment>
        )}
      </div>
      <div className={'container'}>
        <h2>通知内容</h2>
        <TextArea
          rows={6}
          value={notifyContent}
          onChange={e => onChange('notifyContent', e.target.value)}
          placeholder={'请输入内容...'}
        />
      </div>
    </div>
  );
}

export default NotifyContent;
