import { Empty, Form, Input, InputNumber, Pagination, Popover, Table, Tooltip } from 'antd';
import { connect } from 'dva';
import { Link } from 'dva/router';
import moment from 'moment';
import React from 'react';
import DiseaseManage, { IDiseaseItem } from '../../../api/DiseaseManage';
import downFileApi from '../../../api/Downfile';
import { Charts, IChartProps } from '../../../components/Charts/Charts';
// import { MobileFooter } from '../../../components/Foot/MobileFooter';
import { MobileEfollowQrModal } from '../../../components/QRModalCmp/QRModalCmp';
import Config from '../../../config/Config';
import { MobilePagination } from '../../../utils';
import LayoutComponent from './../../../layout/LayoutComponent';
import { IDisPreviewProps, IDisPreviewState, initState, searchMsg } from './CodeType';
import './DiseaseOverview.scss';
import { ItemCard } from './ItemCards';
const isPc = window.screen.width > 1024;
const isMobile = window.screen.width < 750;
const actionsUrl = (record: IDiseaseItem) => {
  // 操作状态1：正常 2：继续编辑3：锁定4:不显示
  if (record.id) {
    switch (record.status) {
      case 1: // 新增问卷
        return `/GaugeSurvey?writeType=surveyCreate&patientId=${record.id}`;
      case 2: // 继续填写
        return `/GaugeSurvey?writeType=surveyCreate&patientId=${record.id}`;
      default:
        // 锁定或者不显示不需要跳转
        return '#';
    }
  } else {
    // 匿名问卷直接到新增病例
    return `/GaugeSurvey?writeType=surveyCreate`;
  }
};

const listColumns = [
  {
    title: '病例编号',
    dataIndex: 'customCode',
    key: 'customCode',
    width: '20%',
    render: (text: string, record: IDiseaseItem) => {
      return (
        <div title={record.customCode} className="nowrap-elips">
          {record.customCode}
        </div>
      );
    },
  },
  {
    title: '就诊序号',
    dataIndex: 'sampleSequence',
    key: 'sampleSequence',
    width: '18%',
    render: (text: string, record: IDiseaseItem) => {
      return (
        <div className="nowrap-elips" title={record.sampleSequence}>
          {record.sampleSequence || '/'}
        </div>
      );
    },
  },
  {
    title: '病例名称',
    dataIndex: 'name',
    key: 'name',
    width: '14%',
    render: (text: string, record: IDiseaseItem) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div title={record.name} className="nowrap-elips">
            {record.name || '/'}
          </div>
          <Tooltip placement="bottomLeft" title="该患者需要填写问卷">
            <img
              src={require('./../../../assets/img/icon_tips_red.svg')}
              className="icon_tips"
              hidden={!record.isRemind}
            />
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: '访视次数',
    dataIndex: 'showVisitNumber',
    key: 'showVisitNumber',
    width: '13%',
    render: (text: string, record: IDiseaseItem) => {
      if (record.showVisitNumber && record.maxVisitNumber > 0) {
        return <div>{`${record.showVisitNumber}/${record.maxVisitNumber}`}</div>;
      }
      return <div>{`${record.showVisitNumber}`}</div>;
    },
  },
  {
    title: '最后访视时间',
    dataIndex: 'lastVisitTime',
    key: 'lastVisitTime',
    width: '25%',
    render: (text: string, record: IDiseaseItem) => {
      return record.lastVisitTime ? moment(record.lastVisitTime).format('YYYY-MM-DD HH:mm:ss') : '/';
    },
  },
  {
    title: '最后填写人',
    dataIndex: 'expertName',
    key: 'expertName',
    width: '10%',
  },
  {
    title: '操作',

    // tslint:disable-next-line:no-any
    render: (text: string, record: any) => {
      if (record.customCode !== '/') {
        // 操作状态1：正常2：继续编辑3：锁定4:不显示
        const link = `/SeeDiseaseOverview/${record.id}/${record.name}/${record.customCode}`;
        return (
          <div className="pclistAction " style={{ width: '180px' }}>
            <Link
              className={record.status === 3 ? 'disabledColor rowBtnLine' : 'rowBtnLine'}
              title={record.status === 3 ? '其他用户正在填写，无法操作' : ''}
              to={actionsUrl(record)}
              hidden={record.status === 4}
            >
              {record.status === 2 ? '继续填写' : '新增问卷'}
            </Link>
            <Link className="rowBtnLine" to={link}>
              查看病例问卷
            </Link>
          </div>
        );
      } else {
        return (
          <div>
            <Link className="rowBtnLine" to={`/GaugeSurvey?writeType=surveyCreate`}>
              继续填写
            </Link>
          </div>
        );
      }
    },
  },
];

// tslint:disable-next-line:no-any
class DiseasePreview extends React.Component<IDisPreviewProps, IDisPreviewState> {
  public state = initState as IDisPreviewState;
  public componentDidMount() {
    const { listType } = this.props.global;
    this.setState({ pagination: { ...this.state.pagination, pageSize: !isMobile && listType === 2 ? 18 : 10 } }, () => {
      this.getAnonymous();
    });
    this.getStatisticMsg();
    this.scrollE = this.scrollE.bind(this);
    window.addEventListener('scroll', this.scrollE, true);
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const { listType } = this.props.global;
    const marginLeft = isPc ? '10px' : '20px';
    const cardEmptyShow = this.state.pagination.total === 0;
    /**
     * 查询 输入UI
     */
    const searchJsx = searchMsg.map((item, index) => {
      const showFiter = index === searchMsg.length - 1 && isMobile;
      return (
        <div className="customInput" key={index}>
          {item.title}:
          <Form.Item style={{ marginLeft }}>
            {// tslint:disable-next-line:jsx-no-multiline-js
            getFieldDecorator(item.code)(
              item.type === 'Input' ? (
                <Input placeholder="请输入" allowClear={true} />
              ) : (
                <InputNumber min={0} max={1000} />
              )
            )}
          </Form.Item>
          <div className="fiterBtnContainer">
            <div hidden={!showFiter} className="mobileSearchBtn" onClick={this.searchSurvey}>
              筛选
            </div>
          </div>
        </div>
      );
    });

    /**
     * list Cards
     */
    const listItems = this.state.listItem.map(item => {
      return <ItemCard item={item} key={item.id} />;
    });

    const pcList = (
      <div hidden={cardEmptyShow} style={{ width: '100%' }}>
        <Table
          className="listTable"
          columns={listColumns}
          dataSource={this.state.listItem}
          pagination={false}
          rowKey={'id'}
        />
      </div>
    );

    const qrImgContent = (
      <div className="qrContainer">
        {/* <QRImge url={Config.activeConfig.wx_chat_public} width={160} /> */}
        <img src={Config.activeConfig.wx_chat_public} className="imgQr" />
        <div className="disp">扫一扫进入医患交流平台</div>
      </div>
    );
    const efollowContent = (
      <div className="effowContainer">
        医患交流平台
        <div onClick={this.linkWeChat} className="joinAction">
          点击进入
        </div>
      </div>
    );

    const paginationSHow = this.state.pagination.pages && this.state.pagination.pages > 1;
    return (
      <div className="DiseaseOverviewContainer" id="DiseaseScrollId" onScroll={this.scrollEvent}>
        <LayoutComponent isBack={true} name="病例总览">
          <div className="preViewHeader">
            <Charts charts={this.state.statisticMsg} />
            <div className="filterContainer">
              {searchJsx}
              <div onClick={this.searchSurvey} className={'customInput searchBtn'}>
                筛选
              </div>
            </div>
            <div hidden={isMobile} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className="listType">
                排列方式:
                <div
                  className={this.props.global.listType === 1 ? 'typeBtnSel' : 'typeBtn'}
                  onClick={() => this.listTypeClick(1)}
                >
                  <img
                    // tslint:disable-next-line:jsx-no-multiline-js
                    src={require(`../../../assets/img/${
                      listType === 1 ? 'icon_list_selected.svg' : 'icon_list_normal.svg'
                    }`)}
                    className="img"
                  />
                  列表式
                </div>
                <div className={listType === 2 ? 'typeBtnSel' : 'typeBtn'} onClick={() => this.listTypeClick(2)}>
                  <img
                    // tslint:disable-next-line:jsx-no-multiline-js
                    src={require(`../../../assets/img/${
                      listType === 2 ? 'icon_card_selected.svg' : 'icon_card_normal.svg'
                    }`)}
                    className="img"
                  />
                  卡片式
                </div>
              </div>

              <div className="addCaseContainer">
                <Link
                  // tslint:disable-next-line:jsx-no-multiline-js
                  to={`/GaugeSurvey?writeType=surveyCreate`}
                  className="addOneCase"
                >
                  新增病例
                </Link>
                <div
                  className="loadStatisticBtn"
                  onClick={this.uploadStatisticMsg}
                  hidden={!this.props.global.sysConfig.excelExportStatis}
                >
                  导出统计信息
                </div>
              </div>
            </div>
          </div>
          {/* <Spin className="mobileSpin" spinning={this.state.onloading} /> */}
          <div className="listContainer" id="listContainerId" onScroll={this.scrollEvent}>
            {listType === 1 ? pcList : listItems}

            {/* <MobileFooter url={this.props.global.companyInfo.logoUrl} name={this.props.global.companyInfo.name} /> */}
          </div>
          <div hidden={!cardEmptyShow} style={{ marginTop: '100px' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'暂无数据'} />
          </div>

          <div hidden={!paginationSHow} className="paginationContainer">
            <Pagination
              current={this.state.pagination.current}
              total={this.state.pagination.total}
              pageSize={this.state.pagination.pageSize}
              onChange={this.paginationChange}
            />
          </div>
        </LayoutComponent>
        <div className="eFellow">
          <Popover
            placement="left"
            content={efollowContent}
            trigger="click"
            overlayClassName="popQrEfollew"
            // tslint:disable-next-line:jsx-no-multiline-js
            onVisibleChange={visible => {
              this.setState({ efollowActive: visible });
            }}
          >
            <div
              className={this.state.efollowActive ? 'efellowIcon efellowIconActive' : 'efellowIcon'}
              hidden={!this.props.global.showWxPublicButton}
            />
          </Popover>
          <Popover
            placement="leftBottom"
            content={qrImgContent}
            trigger="click"
            overlayClassName="popQrEfollew"
            // tslint:disable-next-line:jsx-no-multiline-js
            onVisibleChange={visible => {
              this.setState({ qrIconActive: visible });
            }}
          >
            <div
              className={this.state.qrIconActive ? 'qrImgIcon qrImgIconActive' : 'qrImgIcon '}
              hidden={!this.props.global.showQrCodeButton}
              // tslint:disable-next-line:jsx-no-multiline-js
            />
          </Popover>
        </div>
        <div className="mobileAddCase">
          <Link to={`/GaugeSurvey?writeType=surveyCreate`}>新增病例</Link>
        </div>

        <div
          className="mobielQrIcon"
          onClick={() => this.setState({ mobileShowEfollow: true })}
          hidden={!this.props.global.showQrCodeButton}
        >
          <img src={require('./../../../assets/img/icon_qr_code.svg')} />
        </div>
        <MobileEfollowQrModal
          url={Config.activeConfig.wx_chat_public}
          visible={this.state.mobileShowEfollow}
          callback={() => this.setState({ mobileShowEfollow: false })}
          discription={'扫一扫进入医患交流平台E-Follow'}
        />
      </div>
    );
  }

  /**
   * 滚动
   */
  public scrollE = () => {
    if (!isMobile) {
      return;
    }
    const { pagination, onloading } = this.state;
    MobilePagination('DiseaseScrollId', pagination.current, pagination.pageSize, pagination.total).then(pageNum => {
      if (pageNum && !onloading) {
        this.setState(
          (prevState: IDisPreviewState) => {
            return { pagination: { ...prevState.pagination, current: Number(pageNum) }, onloading: true };
          },
          () => {
            this.scollGetItemsview();
          }
        );
      }
    });
  };

  private linkWeChat = () => {
    const payload = { ...this.props.global.hulkUserInfoBackLoginVo, token: this.props.global.token };
    const linkUrl = `${window.location.origin}/wechat/#/Doctor/DoctorInfo?type=pc`;
    this.props.dispatch({
      type: 'global/wxInfoSave',
      payload: {
        hulkUserInfoBackLoginVo: payload,
      },
      callback: () => {
        // this.props.history.push('#/Doctor/DoctorInfo');
        window.open(linkUrl);
      },
    });
  };

  /**
   * 导出统计信息
   */
  private uploadStatisticMsg = async () => {
    const { getFieldsValue } = this.props.form;
    const searchs = getFieldsValue(['code', 'customCode', 'visitNumber', 'name']);
    downFileApi.get(`/logined/excel/template/export/statis`, { params: { ...searchs } });
  };

  // 滚动加载下一页
  private scrollEvent = async () => {
    const { pagination } = this.state;
    const pageNum = await MobilePagination(
      'DiseaseScrollId',
      pagination.current,
      pagination.pageSize,
      pagination.total
    );
    // debugger;
    if (pageNum) {
      pagination.current = pageNum;
      this.setState({
        pagination,
      });
      this.scollGetItemsview();
    }
  };
  /**
   * 获取统计数据
   */

  private getStatisticMsg = async () => {
    const { result } = await DiseaseManage.getStatisticMsg();
    if (result) {
      const titileMap = new Map([
        ['register_statis', '登记人数'],
        ['visit_step_statis', '访视完成统计'],
        ['questionnaire_statis', '问卷完成情况'],
        ['patient_statis', '病例累计数量'],
      ]);
      const statistic = result.map(item => {
        const chartItem: IChartProps = {
          datas: item.statisItemVoList,
          type: item.type,
          color: [],
          title: titileMap.get(item.type) || '',
        };
        return chartItem;
      });

      this.setState({ statisticMsg: [...statistic] });
    }
  };

  /**
   *
   * 获取匿名问卷信息
   */
  private getAnonymous = async () => {
    const { result } = await DiseaseManage.getAnonymousInfo();

    this.setState({
      anonyResult: result.id ? result : undefined,
    });
    this.getDiseaseOverview();
  };

  /**
   * 搜索
   * 滚动获取
   */
  private scollGetItemsview = async () => {
    const { getFieldsValue } = this.props.form;
    const { pagination, anonyResult, listItem } = this.state;
    const { pageSize, current } = this.state.pagination;
    const searchs = getFieldsValue(['code', 'customCode', 'visitNumber', 'name']);
    const { result } = await DiseaseManage.getDiseaseOverview({ ...searchs, pageNum: current, pageSize });
    if (result) {
      // listMap.set(result.current, result.records); // 丢入 map 中
      pagination.current = result.current;
      pagination.total = result.total;
      pagination.pages = result.pages;
      const anonyItem =
        anonyResult &&
        ({
          customCode: '/',
          lastVisitTime: anonyResult.created,
          visitNumber: anonyResult.visitNumber || '/',
          id: anonyResult.id,
          status: 2,
        } as IDiseaseItem);

      this.setState({
        // listMap,
        listItem:
          result.current === 1 && anonyResult ? [anonyItem!, ...result.records] : [...listItem, ...result.records],
        pagination,
        onloading: false,
      });
    }
  };
  /**
   * 搜索
   * @param {val} 搜索参数
   */
  private getDiseaseOverview = async () => {
    const { getFieldsValue } = this.props.form;
    const { pagination, anonyResult } = this.state;
    const { listType } = this.props.global;
    const { current } = this.state.pagination;
    const pageSize = isMobile ? 10 : listType === 2 ? 18 : 10;

    const searchs = getFieldsValue(['code', 'customCode', 'visitNumber', 'name']);
    this.setState({ onloading: true });
    const { result } = await DiseaseManage.getDiseaseOverview({ ...searchs, pageNum: current, pageSize });
    if (result) {
      // listMap.set(result.current, result.records); // 丢入 map 中
      pagination.current = result.current;
      pagination.total = result.total;
      pagination.pages = result.pages;
      const anonyItem =
        anonyResult &&
        ({
          customCode: '/',
          lastVisitTime: anonyResult.created,
          visitNumber: anonyResult.visitNumber,
          showVisitNumber: anonyResult.visitNumber,
          id: anonyResult.patientId,
        } as IDiseaseItem);

      this.setState({
        // listMap,
        listItem: result.current === 1 && anonyResult ? [anonyItem!, ...result.records] : [...result.records],
        pagination,
      });
    }
    this.setState({ onloading: false });
  };
  /**
   * 病例编号搜索
   * @param {val} 搜索参数
   */
  private searchSurvey = () => {
    // 筛选 需要补充参数
    this.getDiseaseOverview();
  };
  /**
   * 页面 分页切换
   */
  private paginationChange = (page: number, pageSize?: number) => {
    const { pagination } = this.state;
    pagination.current = page;
    this.setState({
      pagination,
    });
    this.getDiseaseOverview();
  };
  /**
   *  列表类型点击事件
   */
  private listTypeClick = (type: number) => {
    // this.setState({
    //   listType: type,
    // });
    this.setState({
      listItem: [],
      pagination: { total: 0, pageSize: type === 1 ? 10 : 18, current: 1 },
    });
    this.getAnonymous();
    this.props.dispatch({
      type: 'global/wxInfoSave',
      payload: {
        listType: type,
      },
    });
  };
}

// tslint:disable-next-line:no-any
export default connect((global: any) => global)(Form.create()(DiseasePreview));
