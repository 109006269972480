import axios from './RequestApi';

export default class WeChatDoctor {
  // 校验患者编号
  public static verifyPatientCode(data: { sampleSequences: string }) {
    return axios.get<IVerifyPatient[], { sampleSequences: string }>(`/logined/patient/sampleSequence/info`, data);
  }
  // 分组列表
  public static getMyGroups(data: INormalReq) {
    return axios.get<IListResult<IGroup>, INormalReq>(`/logined/wechatGroup/page`, data);
  }
  // 添加分组
  public static addGroup<T, D>(data: D) {
    return axios.post<T, D>(`/logined/wechatGroup`, data);
  }
  // 编辑分组
  public static editGroup(data: IEditGroup) {
    return axios.put<string, IEditGroup>(`logined/wechatGroup/${data.id}`, data.dto);
  }
  // 删除分组成员
  public static deleteGroupMember<T, D>(data: { data: IDeleteMember }) {
    return axios.delete<T, D>(`logined/wechatGroup/${data.data.id}/${data.data.userId}`);
  }
  // 删除分组
  public static deleteGroup<T, D>(data: IDeleteNotify) {
    return axios.delete<T, D>(`/logined/wechatGroup/batch?ids=${data.data.ids}`);
  }
  // 校验就诊编号
  public static verifySampleSequence(data: string[]) {
    return axios.get<IVerifyResult[], string[]>(`/logined/patient/sampleSequence/info?sampleSequences=${data}`);
  }
  // 绑定就诊编号
  public static updateSampleSequence<T, D>(data: D) {
    return axios.post<T, D>(`/logined/wechatUserPatientRelationship`, data);
  }
  // 分组详情
  public static groupDetail(id: number) {
    return axios.get<IGroup, {}>(`logined/wechatGroup/${id}`);
  }
  // 患者列表
  public static getPatients<T, D>(data: D) {
    return axios.get<T, D>(`logined/hulkUserInfo/wechat/patient/page`, data);
  }
  // 编辑入组时间
  public static editGroupJoinTime<T, D>(data: IEditJoinTime) {
    return axios.put<T, IEditJoinTime>(
      `/logined/wechatGroupUserRelationship/group/${data.groupId}/relationshipUserId/${data.relationshipUserId}?joinTime=${data.joinTime}`,
      data
    );
  }
  // 周期列表
  public static getPeriods(data: INormalReq) {
    return axios.get<IListResult<IPeriod>, INormalReq>(`logined/wechatPushCycle/page`, data);
  }
  // 删除周期
  public static deletePeriods<T, D>(data: D) {
    return axios.delete<T, D>(`/logined/wechatPushCycle/batch`, data);
  }
  // 新增周期
  public static addPeriods<T, D>(data: IEditPushCycle) {
    return axios.post<T, IEditPushCycle>(`/logined/wechatPushCycle?groupId=${data.groupId}`, data.dto);
  }
  // 编辑周期
  public static editPeriods<T, D>(data: IEditPushCycle) {
    return axios.put<T, IEditPushCycle>(`/logined/wechatPushCycle/${data.dto.id}`, data.dto);
  }
  // 通知列表
  public static getNotifies(data: INormalReq) {
    return axios.get<IListResult<INotify>, INormalReq>(`/logined/wechatNotifyMsgTemplate/page`, data);
  }
  // 新建通知
  public static addNotify<T, D>(data: D) {
    return axios.post<T, D>(`/logined/wechatNotifyMsgTemplate/save`, data);
  }
  // 查看通知
  public static getNotify<T, D>(data: ID) {
    return axios.get<T, ID>(`/logined/wechatNotifyMsgTemplate/${data.id}`, data);
  }
  // 编辑通知
  public static editNotify<T, D>(data: IEditPushCycle) {
    return axios.put<T, IEditPushCycle>(`/logined/wechatNotifyMsgTemplate/${data.id}`, data.dto);
  }
  // 删除通知
  public static deleteNotify<T, D>(data: IDeleteNotify) {
    return axios.delete<T, IDeleteNotify>(
      `/logined/wechatNotifyMsgTemplate/batch?ids=${data.data.ids.toString()}&pushCycleId=${data.data.pushCycleId}`,
      data
    );
  }
}
export interface IVerifyPatient {
  age?: number;
  gender?: number;
  genderStr?: string;
  id?: number;
  isBind?: boolean;
  name?: string;
  sampleSequence?: string;
  userId?: number;
}
export interface INormalReq {
  name?: string;
  type?: string;
  pageNum: number;
  pageSize: number;
}
export interface IListResult<T> {
  current: number;
  pages: number;
  records: T[];
  size: number;
  total: number;
}
export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
}
export interface IGroup {
  id: number;
  name: string;
  description: string;
  hulkUserInfoList: IPatientItem[];
}
export interface IPatientItem {
  age: number;
  department: string;
  expertName: string;
  gender: number;
  groupCount: number;
  groupNames: string;
  headimgurl: string;
  id: string;
  joinTime: string;
  name: string;
  organizationName: string;
  patientCount: number;
  qrCodeUrl: string;
  sampleSequence: string;
  systemCode: string;
  title: string;
  wechatUserInfoId: number;
  editing?: boolean;
  isChoosed?: boolean;
  isDeleting?: boolean;
  isAddToGroup?: boolean;
  writeSampleSequence?: string;
  isHide?: boolean;
}
export interface IVerifyResult {
  age: number;
  gender: number;
  id: string;
  isBind: boolean;
  name: string;
  sampleSequence: string;
}
interface IDeleteNotify {
  data: IDS;
}
export interface IPeriod {
  id: number;
  days: number;
  number: string;
}
export interface INotify {
  id: number;
  title: string;
  sysMsgType: number;
  treatmentTime: string;
  sendTime: string;
  status: boolean;
  nextFollowUpDays: string;
}
export interface IUpdateSampleSequenceItem {
  patientId: string;
  userId: string;
}
interface IDeleteMember {
  id: number;
  userId: string;
}
interface IDS {
  ids: number[];
  pushCycleId?: number;
}
interface IEditPushCycle {
  id?: string;
  groupId?: string;
  // tslint:disable-next-line: no-any
  dto: any;
}

interface IEditJoinTime {
  groupId: number;
  relationshipUserId: string;
  joinTime: string;
}
interface ID {
  id: string;
}
interface IEditGroup {
  id: number;
  // tslint:disable-next-line: no-any
  dto: any;
}
// interface IGroup {
//   description: string;
//   name: string;
//   patientIds?: number[];
//   deleteUserIds?: string[];
//   joinUserIdList?:string[];
//   type?:number
// }
