import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'dva';
import { Link } from 'dva/router';
import React from 'react';
// import { MobileFooter } from '../../../components/Foot/MobileFooter';
import LayoutComponent from './../../../layout/LayoutComponent';
import { IGlobal } from './../../../Model/GlobalModel';
import './HomePage.scss';
interface IProps extends FormComponentProps {
  global: IGlobal;
}
interface IState {
  data: {
    user: string;
  };
}
class PageHome extends React.Component<IProps> {
  public state = {
    data: {},
  } as IState;

  public render() {
    return (
      <div className="homeContainer">
        <LayoutComponent
          isBack={false}
          // tslint:disable-next-line:jsx-no-multiline-js
          name={this.props.global.companyInfo.name}
        >
          {this.homeMenuRender()}
          {/* <MobileFooter url={this.props.global.companyInfo.logoUrl} name={this.props.global.companyInfo.name} /> */}
        </LayoutComponent>
      </div>
    );
  }

  /**
   * pc渲染首页选择菜单
   */
  private homeMenuRender = () => {
    const explainUrl = () => {
      switch (this.props.global.companyInfo.code) {
        case 'SHCZ20190001':
          return '/SurveyExplain/SHCZ20190001';
        case 'SHGH20190001':
          return '/SurveyExplain/SHGH20190001';
        case 'XAET20190001':
          return '/SurveyExplain/XAET20190001';
        case 'PTZX20190001':
          return '/SurveyExplain/PTZX20190001';
        case 'SHRJ20190001':
          return '/SurveyExplain/SHRJ20190001';
        case 'SHGFY20190001':
          return '/SurveyExplain/SHGFY20190001';
        case 'TEST001':
          return '/SurveyExplain/TEST001';
        default:
          break;
      }
    };
    return (
      <div className="men_box">
        <h2 className="main-title">{this.props.global.companyInfo.systemName}</h2>
        <div className="men_con">
          {// tslint:disable-next-line:jsx-no-multiline-js
          [
            {
              img: 'img_card_1.svg',
              name: '填写说明',
              url: explainUrl() + '',
            },
            {
              img: 'img_card_2.svg',
              name: '填写问卷',
              url: '/SearchGauge',
            },

            {
              img: 'img_card_3.svg',
              name: '病例总览',
              url: '/DiseasePreview',
            },
          ].map(item => (
            <Link to={item.url} className="menu_item" key={item.name}>
              <img src={require(`../../../assets/img/${item.img}`)} alt="" />
              <div className="menu_name">{item.name}</div>
            </Link>
          ))}
        </div>
      </div>
    );
  };
}
// tslint:disable-next-line:no-any
export default connect((global: any) => global)(PageHome);
