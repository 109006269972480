import { RouteComponentProps } from 'dva/router';
import React, { useEffect } from 'react';
import { MobileHeader } from '../../../components/index';
import UpdateTitle from '../../../utils/UpdateTitle';
import './AddNotifyType.scss';

interface IAddNotifyTypeProps extends RouteComponentProps<{ id: string }> {}
/*
 *
 *  添加通知类型
 *
 *
 * */
function AddNotifyType(props: IAddNotifyTypeProps) {
  const { history, match } = props;
  useEffect(() => {
    UpdateTitle('添加通知');
  }, []);

  function addNotifyType(type: string) {
    history.push(`/Doctor/AddNotifyType/${match.params.id}/${type}`);
  }
  return (
    <div id={'AddNotifyType'}>
      <MobileHeader backUrl={`/Doctor/PatientMsg/${match.params.id}`} />
      <div className={'AddNotifyTypeContent'}>
        <h1>通知类型</h1>
        <div className={'return'} onClick={() => addNotifyType('return')}>
          <h2>复诊通知</h2>
          <span>已初诊，需要复诊的患者</span>
        </div>
        <div className={'follow'} onClick={() => addNotifyType('follow')}>
          <h2>随访通知</h2>
          <span>需要随访的患者</span>
        </div>
        <div className={'questionnaire'} onClick={() => addNotifyType('questionnaire')}>
          <h2>随访问卷</h2>
          <span>新老患者都可以发送随访问卷</span>
        </div>
      </div>
    </div>
  );
}

export default AddNotifyType;
