import { Icon, Tag } from 'antd';
import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { AuthCompount, Foot } from './../../../components';
import './HatVirus.scss';
interface IProps
  extends RouteComponentProps<{
    code: string;
    name: string;
    sampleSequence: string;
  }> {}

function HatVirus(props: IProps) {
  /**
   * 获取 url 参数
   */
  const getQueryVariable = (urlKey: string) => {
    const url = window.location.href;
    const reg = /[?&][^?&]+=[^?&]+/g;
    const arr = url.match(reg);
    const obj = new Map();
    if (arr) {
      arr.forEach(item => {
        const tempArr = item.substring(1).split('=');
        const key = decodeURIComponent(tempArr[0]);
        const val = decodeURIComponent(tempArr[1]);
        obj.set(key, val);
      });
    }
    return obj.get(urlKey);
  };
  const [Q01816, setQ01816] = useState<string[]>([]);
  const [Q01817, setQ01817] = useState<string[]>([]);
  const [Q01818] = useState(getQueryVariable('Q01818'));
  const [Q01819, setQ01819] = useState<string[]>([]);
  const split = (val: string) => {
    if (val) {
      return val.split(',');
    }
    return [];
  };
  useEffect(() => {
    setQ01816(split(getQueryVariable('Q01816')));
    const region = split(getQueryVariable('Q01817'));
    region.sort((a, b) => {
      const top = a.split('-')[1];
      const bottom = b.split('-')[1];
      return Number(top) - Number(bottom);
    });
    setQ01817(region);
    setQ01819(split(getQueryVariable('Q01819')));
  }, []);

  function titleRender(title: string, img: string, isShow: boolean) {
    return (
      <div className="title_box">
        <img className="" src={require(`../../../assets/img/${img}.svg`)} />
        <div>{title}</div>
        <AuthCompount isShow={isShow}>
          <Icon className="icon_sy" type="exclamation-circle" />
        </AuthCompount>
      </div>
    );
  }
  /**
   * 判断是否有* 如果有则删掉*
   */
  const splitWord = (word: string) => {
    if (word.includes('*')) {
      return word.split('*')[0];
    }
    return word;
  };
  const danger1 = getQueryVariable('Q01816') ? getQueryVariable('Q01816').includes('*') : false;
  const danger2 = getQueryVariable('Q01817') ? getQueryVariable('Q01817').includes('-1') : false;
  const dangerBor = Q01816.filter(item => !item.includes('*'));
  return (
    <div id="HatVirus">
      <div className="heig_box">
        <div className="hintTitle">
          <Icon type="warning" />
          <div className="hintFon">请找医生获得预约单后再关闭该页面</div>
        </div>
        {titleRender('您的症状', 'doctor', danger1)}
        <AuthCompount isShow={!!Q01816.length}>
          <div className="tag_box">
            {// tslint:disable-next-line:jsx-no-multiline-js
            Q01816.map(item => (
              <AuthCompount key={item} isShow={item.includes('*')}>
                <Tag className="tag_itam" color="red">
                  {splitWord(item)}
                </Tag>
              </AuthCompount>
            ))}
          </div>

          <AuthCompount isShow={danger1 && !!dangerBor.length}>
            <div className="bor" />
          </AuthCompount>

          <div className="tag_box">
            {// tslint:disable-next-line:jsx-no-multiline-js
            Q01816.map((item, i) => (
              <AuthCompount key={item} isShow={!item.includes('*')}>
                <Tag className="tag_itam" key={i} color="orange">
                  {splitWord(item)}
                </Tag>
              </AuthCompount>
            ))}
          </div>
        </AuthCompount>
        <AuthCompount isShow={!Q01816.length}>
          <Tag className="lef">无</Tag>
        </AuthCompount>
        <div className="top">{titleRender('前两周您居住、出差或旅行的省份', 'fan', danger2)}</div>
        <div className="tag_box">
          {// tslint:disable-next-line:jsx-no-multiline-js
          Q01817.map((item, i) => (
            <div key={i}>
              <AuthCompount isShow={item.split('-')[1] === '1'}>
                <Tag className="tag_itam" color="red">
                  {item.split('-')[0]}
                </Tag>
              </AuthCompount>
              <AuthCompount isShow={item.split('-')[1] === '2'}>
                <Tag className="tag_itam" color="orange">
                  {item.split('-')[0]}
                </Tag>
              </AuthCompount>
              <AuthCompount isShow={item.split('-')[1] === '3'}>
                <Tag className="tag_itam" color="blue">
                  {item.split('-')[0]}
                </Tag>
              </AuthCompount>
            </div>
          ))}
          <AuthCompount isShow={!Q01817.length}>
            <Tag className="lef">无</Tag>
          </AuthCompount>
        </div>
        <div className="top">
          {titleRender(`您前两周内${Q01818 === '是' ? '' : '未'}乘坐过高危交通工具`, 'Train', Q01818 === '是')}
        </div>
        <div className="top">{titleRender(`您两周内曾接触`, 'hand', !!Q01819.length)}</div>
        <div className="antmo">
          {// tslint:disable-next-line:jsx-no-multiline-js
          Q01819.map(item => (
            <div key={item}>{item}</div>
          ))}
          <AuthCompount isShow={!Q01819.length}>
            <Tag className="lef">无</Tag>
          </AuthCompount>
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default HatVirus;
