import DataSet from '@antv/data-set';
import { Axis, Chart, Coord, Geom, Legend } from 'bizcharts';
import React from 'react';
import { useWindowUpdateWidth } from '../../utils/UtilHook';
import './Charts.scss';
/**
 * register_statis（登记人数统计）、visit_step_statis（访视阶段统计）、questionnaire_statis（问卷统计）及patient_statis（病例统计
 */
export enum ChartType {
  register_statis = 'register_statis', //  登记统计
  visit_step_statis = 'visit_step_statis', // 访视阶段统计
  questionnaire_statis = 'questionnaire_statis', // 问卷统计
  patient_statis = 'patient_statis', // 病例统计
}
const colorArr = [
  '#92C8FF',
  '#5AADFF',
  '#0096EA',
  '#00A3C9',
  '#25BAA4',
  '#25CC80',
  '#93DD45',
  '#F7DF00',
  '#FFBE0D',
  '#FF8B00',
];
const { DataView } = DataSet;
const isPc = window.screen.width > 1024;
const isMobile = window.screen.width < 750;
const cols = {
  percent: {
    formatter: (val: number) => {
      const valStr = val * 100 + '%';
      return valStr;
    },
  },
};
export interface IChartProps {
  type: string;
  datas: IChartDataItem[];
  title: string;
  color: string[]; // 主色调 点的颜色
  uint?: string; // 单位
}
export interface IChartDataItem {
  item: string;
  count: number;
}
/**
 *
 * 用数组创建图
 */
export function Charts(props: { charts?: IChartProps[] }) {
  if (!props.charts) {
    return null;
  }

  const charts = props.charts.map((item, index) => {
    switch (item.type) {
      case ChartType.register_statis: {
        item.color = ['#8DB545', '#c5e290'];
        item.uint = '人';
        return <ChartPie {...item} key={index} />;
      }
      case ChartType.questionnaire_statis: {
        item.color = ['#3069b4', '#a2c8fa'];
        item.uint = '份';
        return <ChartPie {...item} key={index} />;
      }
      case ChartType.visit_step_statis: {
        return <ChartHistogram {...item} key={index} />;
      }
      case ChartType.patient_statis: {
        return <ChartCurved {...item} key={index} />;
      }
    }
  });
  return <div className="chartCardsContainer"> {charts} </div>;
}
/**
 *
 * 饼状图
 */
function ChartPie(props: IChartProps) {
  const dv = new DataView();
  dv.source(props.datas).transform({
    type: 'percent',
    field: 'count',
    dimension: 'item',
    as: 'percent',
  });

  let height = useWindowUpdateWidth(140);
  if (isPc) {
    height = useWindowUpdateWidth(140);
  }
  if (isMobile) {
    height = useWindowUpdateWidth(200);
  }

  return (
    <div className="chartCard" key={props.title}>
      <div className="title">{props.title} </div>
      <div className="chartContent">
        <div className="chartContainer">
          <Chart height={height} padding={[0, 0, 0, 0]} data={dv} scale={cols} forceFit={true} width={height}>
            <Coord type={'theta'} radius={0.9} innerRadius={0.6} />
            <Geom
              type="intervalStack"
              position="percent"
              color={['item', props.color]}
              // tslint:disable-next-line:jsx-no-multiline-js
              tooltip={[
                'item*percent',
                (item, percent) => {
                  percent = percent * 100 + '%';
                  return {
                    name: item,
                    value: percent,
                  };
                },
              ]}
              // tslint:disable-next-line:jsx-no-multiline-js
              style={{
                lineWidth: 1,
                stroke: '#fff',
              }}
            />
          </Chart>
        </div>
        <div className="chartMsg">
          <div className="statisticsMsg">
            <div className="msgContainer">
              <div className="complete">
                <div className="dot" style={{ backgroundColor: props.color[0] }} /> 已完成
              </div>
              <div className="statisticsContent">{props.datas[0].count}</div>
              <div> {props.uint || '个'} </div>
            </div>
          </div>
          <div className="statisticsMsg">
            <div className="msgContainer">
              <div className="complete">
                <div className="dot" style={{ backgroundColor: props.color[1] }} />
                未完成
              </div>
              <div className="statisticsContent">{props.datas[1].count}</div>
              <div> {props.uint || '个'} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
/**
 *
 * @param  直方图
 */
export function ChartHistogram(props: IChartProps) {
  const dv = new DataView();
  dv.source(props.datas).transform({
    type: 'sort',
  });
  let height = useWindowUpdateWidth(230);
  let left = useWindowUpdateWidth(80);
  if (isPc) {
    height = useWindowUpdateWidth(300);
    left = useWindowUpdateWidth(130);
  } else if (isMobile) {
    height = 160;
    left = useWindowUpdateWidth(130);
  }
  const top = useWindowUpdateWidth(24);
  const right = useWindowUpdateWidth(90);
  const bottom = useWindowUpdateWidth(34);
  return (
    <div className="chartCardHig" key={props.title}>
      <div className="title">{props.title} </div>
      <div>
        <Chart height={height} data={dv} forceFit={true} padding={[top, right, bottom, left]}>
          <Coord transpose={true} />
          <Axis name="item" label={{ offset: 5 }} />
          <Axis name="count" />
          <Geom
            type="interval"
            position="item*count"
            // tslint:disable-next-line:jsx-no-multiline-js
            color={[
              'item',
              item => {
                const visNumber = item.split('-')[1];
                const index: number = Number(visNumber) % 10;
                return colorArr[index];
              },
            ]}
          />
        </Chart>
      </div>
    </div>
  );
}

/**
 *
 * @param  折线图
 */
export function ChartCurved(props: IChartProps) {
  let height = useWindowUpdateWidth(230);
  if (isPc) {
    height = useWindowUpdateWidth(300);
  } else if (isMobile) {
    height = 180;
  }
  const top = 24;
  const left = useWindowUpdateWidth(100);
  const right = useWindowUpdateWidth(100);
  const bottom = 45;
  // tslint:disable-next-line:no-shadowed-variable
  const cols = {
    value: {
      min: 0,
    },
    item: {
      range: [0, 1],
    },
  };
  return (
    <div className="chartCardHig" key={props.title}>
      <div className="title">{props.title} </div>
      <div>
        <Chart
          height={height}
          data={props.datas}
          forceFit={true}
          width={height}
          padding={[top, right, bottom, left]}
          scale={cols}
        >
          <Legend />
          <Axis name="item" label={{ formatter: val => `${val.substring(2, val.length)}` }} />
          <Axis
            name="count"
            // tslint:disable-next-line:jsx-no-multiline-js
            label={{
              formatter: val => `${val}`,
            }}
          />
          <Geom type="line" position="item*count" size={2} />
          <Geom
            type="point"
            position="item*count"
            size={4}
            shape={'circle'}
            // tslint:disable-next-line:jsx-no-multiline-js
            style={{
              stroke: '#fff',
              lineWidth: 1,
            }}
          />
        </Chart>
      </div>
    </div>
  );
}
