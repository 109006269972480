// import { NavBar } from 'antd-mobile';
import { Button } from 'antd';
import { SubscriptionAPI } from 'dva';
import { RouteComponentProps } from 'dva/router';
import React, { Fragment, useEffect, useState } from 'react';
import WeChatDoctor, { IPeriod } from '../../../api/WeChatDoctor';
import { MobileAddBlockContent, MobileHeader, MobileNoDatas } from '../../../components/index';
import { MobilePagination, UpdateTitle } from '../../../utils';
import './DoctorSetPeriod.scss';
export interface IProps
  extends SubscriptionAPI,
    RouteComponentProps<{
      groupId: string;
    }> {}
export interface IDeleteState {
  isDeleting: boolean;
  deleteArr: number[];
}

export default (props: IProps) => {
  // tslint:disable-next-line: radix
  const groupId = parseInt(props.match.params.groupId);
  const [periods, setPeriods] = useState<IPeriod[]>([]);
  const [pagnation, setPagnation] = useState({ pageSize: 10, total: 0, pageNum: 1 });
  const [deleteState, setDeleteState] = useState<IDeleteState>({ isDeleting: false, deleteArr: [] });
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isN, setIsN] = useState(0);

  useEffect(() => {
    UpdateTitle('设置推送周期');
    getPeriods();
  }, []);
  useEffect(() => {
    if (deleteSuccess) {
      getPeriods();
      setDeleteSuccess(false);
    }
  }, [deleteSuccess]);
  // 滚动加载下一页
  async function scrollEvent() {
    const pageNum = await MobilePagination('periodList', pagnation.pageNum, pagnation.pageSize, pagnation.total);
    if (pageNum) {
      pagnation.pageNum = pageNum;
      getPeriods();
    }
  }
  let list;
  async function getPeriods() {
    const searchRequest = {
      pageNum: pagnation.pageNum,
      pageSize: pagnation.pageSize,
      groupId,
    };
    const { result } = await WeChatDoctor.getPeriods(searchRequest);
    setIsN(Number(result.records.some(value => Number(value.number) === -1)));
    setPeriods([...periods, ...result.records]);
    setPagnation({ ...pagnation, total: result.total });
  }

  function itemClick(id: number) {
    if (deleteState.isDeleting) {
      let deleteArr = deleteState.deleteArr;
      if (deleteArr.lastIndexOf(id) !== -1) {
        deleteArr = deleteArr.filter(v => {
          return v !== id;
        });
      } else {
        deleteArr.push(id);
      }
      setDeleteState({ ...deleteState, deleteArr });
    } else {
      props.history.push(`/Doctor/DoctorNotifyList/${id}/${groupId}`);
    }
  }
  function editPeriod(item: IPeriod, isLast: boolean) {
    props.history.push(
      `/Doctor/DoctorAddPeriod/${groupId}/${item.id}/EDIT/${item.number}/${item.days}/${isLast ? 0 : 1}`
    );
  }
  async function deleteSure() {
    if (deleteState.deleteArr.length > 0) {
      const result = await WeChatDoctor.deletePeriods({ data: { pushCycleIdList: deleteState.deleteArr, groupId } });
      if (result.code === 0) {
        setDeleteState({ isDeleting: false, deleteArr: [] });
        pagnation.pageNum = 1;
        setPeriods([]);
        setDeleteSuccess(true);
      }
    } else {
      setDeleteState({ isDeleting: false, deleteArr: [] });
    }
  }
  if (periods.length <= 0) {
    list = <MobileNoDatas imgurl={'img_time_null.svg'} show={true} msg={'您还没有设置推送周期'} />;
  } else {
    list = periods.map((v, index, array) => {
      return (
        <div key={v.id} className="periodItem" onClick={() => itemClick(v.id)}>
          <div className="titlePeriod">
            {// tslint:disable-next-line: jsx-no-multiline-js
            deleteState.isDeleting ? (
              deleteState.deleteArr.includes(v.id) ? (
                <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time_select.svg')} />
              ) : (
                <img className="deleteIcon" src={require('../../../assets/img/icon_delete_time.svg')} />
              )
            ) : (
              <span>{index + 1}</span>
            )}
            <strong>{`${v.days}天 *${v.number.toString() === '-1' ? 'N' : v.number}次`}</strong>
            <img
              className="editIcon"
              style={{ opacity: deleteState.isDeleting ? '0.5' : '1' }}
              src={require('../../../assets/img/icon_edit_new.svg')}
              // tslint:disable-next-line: jsx-no-multiline-js
              onClick={e => {
                e.stopPropagation();
                // tslint:disable-next-line:no-unused-expression
                !deleteState.isDeleting && editPeriod(v, array.length - 1 === index);
              }}
            />
          </div>
          <div className="tips">
            {v.days}天后推送，推送{v.number.toString() === '-1' ? '无限' : v.number}次
            <img className="detailsArrow" src={require('../../../assets/img/icon_more.svg')} />
          </div>
        </div>
      );
    });
  }
  return (
    <div id="Doctor-DoctorSetPeriod">
      <div className={'doctorSetPeriodHeader'}>
        <MobileHeader backUrl={`/Doctor/DoctorGroupDetail/${groupId}`}>
          {// tslint:disable-next-line: jsx-no-multiline-js
          periods.length > 0 && !deleteState.isDeleting && (
            <Button type={'link'} onClick={() => setDeleteState({ ...deleteState, isDeleting: true })}>
              删除周期
            </Button>
          )}
          {// tslint:disable-next-line: jsx-no-multiline-js
          deleteState.isDeleting && (
            <Fragment>
              <Button type={'link'} onClick={() => setDeleteState({ ...deleteState, isDeleting: false })}>
                取消
              </Button>
              <Button
                type={'link'}
                disabled={deleteState.deleteArr.length === 0}
                className="deleteSure"
                onClick={deleteSure}
              >
                确定删除
              </Button>
            </Fragment>
          )}
        </MobileHeader>
        <MobileAddBlockContent backUrl={`/Doctor/DoctorAddPeriod/${groupId}/0/ADD/0/0/${isN}`} disabled={isN === 1}>
          {isN === 1 ? '最后一个为N次，则无法继续添加周期' : '添加周期'}
        </MobileAddBlockContent>

        {// tslint:disable-next-line: jsx-no-multiline-js
        !!periods.length && (
          <div className="tips">
            按照<span>1</span>+<span>2</span>+<span>N</span>顺序推送通知
          </div>
        )}
      </div>
      <div className="periodList" id="periodList" onScroll={scrollEvent}>
        {list}
      </div>
    </div>
  );
};
