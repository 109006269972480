import { TimePicker } from 'antd';
import { useDispatch } from 'dva';
import moment from 'moment';
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormTime.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 时间选择
 * 在subjectData里面type为FormTime
 * 具体用法参照 ant design
 */
function FormTime(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  const dispatch = useDispatch();
  const [val, setVal] = useState(formObj.defaultValue);
  function inputChange(date: moment.Moment | null, dateString: string) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: dateString,
      },
    });
    setVal(dateString);
  }
  return (
    <div id="FormTime">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
              className={formObj.errMsg ? 'cl star' : 'star'}
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <TimePicker
          className={`form_input`}
          disabled={formObj.disable}
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={moment(formObj.defaultValue ? formObj.defaultValue : '00:00:00', 'HH:mm:ss')}
          value={moment(val ? val : '00:00:00', 'HH:mm:ss')}
          onChange={inputChange}
          getPopupContainer={() => document.getElementById('scrl_box')!}
        />
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormTime);
