import dva from 'dva';
import router from './../Router';
import Storage from './../utils/Storage';
import GlobalModel from './GlobalModel';
import GroupModel from './GroupModel';
import LoadModel from './LoadModel';
import SurveyModel from './SurveyModel';
export interface ICacheParam {
  answerId?: string;
  patientId?: string;
  answerCacheJson: string;
  questionId: string;
}
export interface ICacheResult {
  visitNumber?: number;
  userId?: number;
  patientId?: number;
  id?: number;
  answerCacheJson?: string | null;
  expertName?: string;
  channel?: string;
  questionId?: string;
  patient_sex?: string | null;
  answerJson?: string;
}
const storage = new Storage();
export const app = dva({
  initialState: {
    global: JSON.parse(storage.getStore('global')),
    groupInfo: JSON.parse(storage.getStore('groupInfo')),
  },
  // tslint:disable-next-line:no-any
  onStateChange: (state?: any) => {
    if (state) {
      storage.setStore('global', JSON.stringify(state.global));
      storage.setStore('groupInfo', JSON.stringify(state.groupInfo));
    }
  },
});
// tslint:disable-next-line:no-var-requires
// app.use(require('dva-immer').default());
app.model(GlobalModel);
app.model(SurveyModel);
app.model(GroupModel);
app.model(LoadModel);
app.router(router);
// app.router(() => <App />);
// 启动应用
app.start('#root');
