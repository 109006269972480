import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'dva';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LayoutComponent from './../../../layout/LayoutComponent';
import PTZX20190001 from './PTZX20190001';
import SHCZ20190001 from './SHCZ20190001';
import SHGFY20190001 from './SHGFY20190001';
import SHGH20190001 from './SHGH20190001';
import SHRJ20190001 from './SHRJ20190001';
import './SurveyExplain.scss';
import TEST001 from './TEST001';
import XAET20190001 from './XAET20190001';
interface IProps extends FormComponentProps, RouteComponentProps<{ companyCode: string }> {}
// const filePath = window.screen.width > 500 ? "img" : "moblie-img";
// tslint:disable-next-line:no-any

class SurveyExplain extends React.Component<IProps> {
  public render() {
    return (
      <div id="SurveyExplain">
        <LayoutComponent isBack={true} name="填写说明">
          {this.content()}
        </LayoutComponent>
      </div>
    );
  }
  private content = () => {
    switch (this.props.match.params.companyCode) {
      case 'SHCZ20190001':
        return <SHCZ20190001 />;
      case 'SHGH20190001':
        return <SHGH20190001 />;
      case 'XAET20190001':
        return <XAET20190001 />;
      case 'PTZX20190001':
        return <PTZX20190001 />;
      case 'SHRJ20190001':
        return <SHRJ20190001 />;
      case 'SHGFY20190001':
        return <SHGFY20190001 />;
      case 'TEST001':
        return <TEST001 />;
      default:
        return '';
    }
  };
}
export default connect()(SurveyExplain);
