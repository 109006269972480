import { Button, Col, Row, Spin } from 'antd';
import { Modal } from 'antd-mobile';
import React, { Fragment, useEffect, useState } from 'react';
import WeChatDoctor, { IVerifyPatient } from '../../../../../api/WeChatDoctor';
import { IDoctorMsg } from '../../../../../api/WxDoctorApi';
import SimpleContent from './SimpleContent';
import VerifyPatientCodeContent from './VerifyPatientCodeContent';
import './VerifyPatientCodeModal.scss';
interface IVerifyPatientCodeModalProps {
  patientMsg: IDoctorMsg;
  sampleSequence: string;
  show: boolean;
  onClose: (reset?: boolean, data?: IVerifyPatient) => void;
}
interface IVerifyPatientState extends IVerifyPatient {
  unlike: boolean; // 有差别
  loading: boolean;
}
/*
 *
 *  校验 患者编号弹出
 *
 * */
function VerifyPatientCodeModal(props: IVerifyPatientCodeModalProps) {
  const { patientMsg, sampleSequence, show, onClose } = props;
  const [verifySampleSequence, setVerifySampleSequence] = useState<IVerifyPatientState>({
    unlike: false,
    loading: false,
  });
  useEffect(() => {
    showVerify();
  }, []);

  /*
   * 当加载的时候 根据患者编号获取患者信息
   *
   * */
  function showVerify() {
    setVerifySampleSequence({
      ...verifySampleSequence,
      loading: true,
    });
    WeChatDoctor.verifyPatientCode({ sampleSequences: sampleSequence }).then(({ result: [data] }) => {
      if (data && patientMsg.name === data.name && patientMsg.gender === data.gender && patientMsg.age === data.age) {
        setVerifySampleSequence({ ...verifySampleSequence, ...data, unlike: false });
      } else {
        setVerifySampleSequence({ ...verifySampleSequence, ...data, unlike: true });
      }
    });
  }
  function close(reset?: boolean) {
    const { unlike, loading, ...data } = verifySampleSequence;
    onClose(reset, data);
  }

  return (
    <Modal visible={show} className={'editPatientModal'} transparent={true} maskClosable={false}>
      <div className={'codeModalContent'}>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {!verifySampleSequence.loading ? (
          <Fragment>
            {//  tslint:disable-next-line:jsx-no-multiline-js
            verifySampleSequence.isBind || !verifySampleSequence.name ? (
              <SimpleContent sampleSequence={sampleSequence} isBind={verifySampleSequence.isBind} />
            ) : (
              <VerifyPatientCodeContent
                patientMsg={patientMsg}
                sampleSequence={sampleSequence}
                verifySampleSequence={verifySampleSequence}
              />
            )}
            {//  tslint:disable-next-line:jsx-no-multiline-js
            verifySampleSequence.unlike && verifySampleSequence.name && !verifySampleSequence.isBind ? (
              <Row>
                <Col span={12}>
                  <Button onClick={() => close()}> 仍要继续</Button>
                </Col>
                <Col span={12}>
                  <Button type={'primary'} onClick={() => close(true)}>
                    重新填写
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  {/* tslint:disable-next-line:jsx-no-multiline-js */}
                  {verifySampleSequence.name && !verifySampleSequence.isBind ? (
                    <Button type={'primary'} onClick={() => close()}>
                      我知道了
                    </Button>
                  ) : (
                    <Button type={'primary'} onClick={() => close(true)}>
                      重新填写
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </Fragment>
        ) : (
          <Spin />
        )}
      </div>
    </Modal>
  );
}

export default VerifyPatientCodeModal;
