import { useCallback, useEffect, useState } from 'react';
import { IPagination } from '../api/WxDoctorApi';
import { ISubjectV } from '../assets/js/DataJson';
import { ISurvey } from '../Model/SurveyModel';
export interface IDeviceType {
  device: string;
}
export interface IInputProps {
  // tslint:disable-next-line:no-any
  dispatch?: any;
  formObj: ISubjectV;
  survey: ISurvey;
}

/**
 * @returns visible setVisible 控制显示状态
 * @returns colseModal 关闭模态框
 * @returns openModal 打开模态框
 */
export const useModalVisible = (vis: boolean) => {
  const [visible, setVisible] = useState(vis);
  const colseModal = useCallback(() => setVisible(false), [visible]);
  const openModal = useCallback(() => setVisible(true), [visible]);
  return { visible, setVisible, colseModal, openModal };
};

/**
 * 检测屏幕可视范围的变化
 */
export const useWindowVisibleSize = () => {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  return size;
};
/**
 *
 * 根据屏幕变化转换出取出对应的宽度
 */
export const useWindowUpdateWidth = (oldWidth: number) => {
  const isPc = window.screen.width > 1024;
  const isMobile = window.screen.width < 500;
  const size = useWindowVisibleSize();
  const [width, setWidth] = useState(oldWidth);
  useEffect(() => {
    isPc
      ? setWidth((size.width / 1920) * oldWidth)
      : isMobile
      ? setWidth((size.width / 750) * oldWidth)
      : setWidth((size.width / 1024) * oldWidth);
  }, [size]);
  return width;
};

/**
 * 文件流转换成下载文件
 * @param data 数据流
 * @param fileName  文件名 需要补充后缀
 */
// tslint:disable-next-line:no-any
export function exportFile(data: any, fileName: string) {
  const blobUrl = window.URL.createObjectURL(data);
  const filename = fileName;
  const aElement = document.createElement('a');
  document.body.appendChild(aElement);
  aElement.style.display = 'none';
  aElement.href = blobUrl;
  aElement.download = filename;
  aElement.click();
  document.body.removeChild(aElement);
}

/**
 * 滑动分页
 */
export const useMobliePagination = (oldPagination: IPagination, eId?: string) => {
  const [pagination, setPagination] = useState(oldPagination);
  const [total, setTotal] = useState(oldPagination.total);
  const [endPage, setEndPage] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScrollAction);
    return () => {
      window.removeEventListener('scroll', handleScrollAction);
    };
  }, []);
  /**
   * 判断是否滑动到底部,并返响应的 函数
   */
  function handleScrollAction() {
    const clientHeight = ((eId && document.getElementById(eId)) || document.body).clientHeight; // 可见区域高度
    const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop; // 滚动条到顶部的距离
    const scrollHeight = document.body.clientHeight; // 整个页面高度
    const curItems = pagination.current * pagination.pageSize; // 当前已经拿到的数据总量
    if (clientHeight - scrollTop < scrollHeight + 100 && curItems < total) {
      // 滑动到了底部 切后面还有数据可以进行分页
      setPagination({ ...pagination, current: pagination.current + 1 });
    }
    // 如果分页到底
    if (curItems >= total && clientHeight - scrollTop < scrollHeight + 100) {
      // reject 需要传入Error对象
      setEndPage(true);
    }
  }
  return { pagination, setTotal, endPage };
};
