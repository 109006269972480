import { RouteComponentProps } from 'dva/router';
import React, { useEffect, useState } from 'react';
import { IconFont } from '../../../config/IconConfig';
import LayoutComponent from '../../../layout/LayoutComponent';
import { Storage } from './../../../utils';
import './PsdUpdateSuc.scss';
let lastsecond = 5;
const isMobile = window.screen.width < 500;
interface IUpdatePsdProps extends RouteComponentProps<{ name: string; code: string }> {}
function PsdUpdateSuc(props: IUpdatePsdProps) {
  const [discSecon, setDiscSecon] = useState(lastsecond);
  useEffect(() => {
    const storage = new Storage();
    storage.clearStore();
    const siv = setInterval(() => {
      lastsecond--;
      setDiscSecon(lastsecond);
      if (lastsecond === 0) {
        props.history.push(`/SignIn/${props.match.params.code}`);
        lastsecond = 5;
        clearInterval(siv);
      }
      return () => {
        lastsecond = 5;
        clearInterval(siv);
      };
    }, 1000);
  }, []);
  if (isMobile) {
    return (
      <div className="UpdatePsdSuccess">
        <IconFont className="successIcon" type="iconpc_icon_finish" />
        <div className="suctitle"> 密码修改成功 </div>
        <div className="switchDis"> {`${discSecon}s后自动返回「登录」页面，请重新登录…`}</div>
      </div>
    );
  }
  return (
    <div className="psdssContainer">
      <LayoutComponent isBack={true} name="修改密码">
        <div className="UpdatePsdSuccess ">
          <IconFont className="successIcon" type="iconpc_icon_finish" />
          <div className="suctitle"> 密码修改成功 </div>
          <div className="switchDis"> {`${discSecon}s后自动返回「登录」页面，请重新登录…`}</div>
        </div>
      </LayoutComponent>
    </div>
  );
}

export default PsdUpdateSuc;
