import { Modal } from 'antd';
import { useDispatch, useSelector } from 'dva';
import React from 'react';
import { useEffect, useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import { ISurvey } from './../../../Model/SurveyModel';
import SurveyModelUtil from './../../../utils/Survey/SurveyModelUtil';
import FormRender from './../FormRender/FormRender';
import './FormGroup.scss';
const isPc = window.screen.width > 500;
let startId = 100;
/**
 * 复合组件
 * 在subjectData里面type为FormGroup
 */
function FormGroup(props: ISurveyHook) {
  // props = JSON.parse(JSON.stringify(props));
  if (!props.formObj.subjectData.defaultValue) {
    return <div />;
  }
  const gaugeData = useSelector(({ survey }: { survey: ISurvey }) => survey.gaugeData);
  const menuIndex = useSelector(({ survey }: { survey: ISurvey }) => survey.menuIndex);
  const [defaultValue, setDefaultValue] = useState(props.formObj.subjectData.defaultValue);
  const [deleteInfo, setDeleteInfo] = useState({ showModal: false, groupIndex: -1 });
  const [formObj, setFormObj] = useState(props.formObj);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: defaultValue,
      },
    });
  }, [defaultValue]);
  // 删除组合组件
  async function delGroup(groupIndex: number) {
    const surveyModelUtil = new SurveyModelUtil();
    props.formObj.subjectData.defaultValue.splice(groupIndex, 1);
    // 准备渲染的数组也要一起删除
    props.formObj.subjectData.groupChildrens!.splice(groupIndex, 1);
    const data = surveyModelUtil.getGroupPosition(
      gaugeData[menuIndex].position[props.formObj.subjectData.field],
      props.formObj
    );
    setFormObj({ ...data });
    setDeleteInfo({ showModal: false, groupIndex: -1 });
    setDefaultValue([...props.formObj.subjectData.defaultValue]);
  }
  // 添加组件
  async function createGroup() {
    const surveyModelUtil = new SurveyModelUtil();
    const groupItem = surveyModelUtil.formGroupInit(props.formObj);
    props.formObj.subjectData.defaultValue = [...defaultValue, groupItem];
    // 重新定位元素
    const data = surveyModelUtil.getGroupPosition(
      gaugeData[menuIndex].position[props.formObj.subjectData.field],
      props.formObj
    );
    setFormObj(data);
    setDefaultValue([...formObj.subjectData.defaultValue]);
  }
  return (
    <div id="FormGroup">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={formObj.subjectData.errMsg ? 'cl star' : 'star'}
              // tslint:disable-next-line:jsx-no-multiline-js
              id={
                formObj.subjectData.parentPosition &&
                `errLogo_${formObj.subjectData.field}_${formObj.subjectData.parentPosition.groupIndex}`
              }
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{formObj.subjectData.subjectName}</div>
        </div>
        <AuthCompount isShow={!!formObj.subjectData.explain}>
          <div className="explain">（说明：{formObj.subjectData.explain}）</div>
        </AuthCompount>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition &&
              `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
            }
          >
            {formObj.subjectData.errMsg}
          </div>
        </AuthCompount>
      </div>
      {// tslint:disable-next-line:no-any
      formObj.subjectData.defaultValue.map((group: any, index: number) => {
        return (
          <div key={index}>
            <div className="group_box">
              <div className="g_ra">组{index + 1}</div>
              <div className="g_oper">
                <AuthCompount isShow={formObj.subjectData.defaultValue.length > 1}>
                  <div onClick={() => setDeleteInfo({ showModal: true, groupIndex: index })}>删除组</div>
                </AuthCompount>
                <AuthCompount isShow={index === formObj.subjectData.defaultValue.length - 1}>
                  <div className="group_add" onClick={createGroup}>
                    添加组
                  </div>
                </AuthCompount>
              </div>
            </div>
            {// tslint:disable-next-line:jsx-no-multiline-js 这里默认是和groupChildrens顺序是一样的 懒得改了
            formObj.subjectData.groupChildrens &&
              formObj.subjectData.groupChildrens[index] &&
              formObj.subjectData.groupChildrens[index]!.map((groups, i) => {
                startId++;
                return (
                  <div key={startId + groups.subjectData.field}>
                    <FormRender formObj={groups} />
                  </div>
                );
              })}
          </div>
        );
      })}
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          // tslint:disable-next-line:jsx-no-multiline-js
          id={
            props.formObj.subjectData.parentPosition &&
            `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
          }
        >
          {props.formObj.subjectData.errMsg}
        </div>
      </AuthCompount>
      <Modal
        visible={deleteInfo.showModal}
        className="deleteGroup"
        footer={null}
        onCancel={() => setDeleteInfo({ ...deleteInfo, showModal: false })}
        centered={true}
      >
        <div className="content">
          <div className="firstLine">确定删除该组吗？</div>
        </div>
        <div className="buttonContainer">
          <span className="sure" onClick={() => delGroup(deleteInfo.groupIndex)}>
            确定删除
          </span>
          <span className="cancel" onClick={() => setDeleteInfo({ ...deleteInfo, showModal: false })}>
            我再想想
          </span>
        </div>
      </Modal>
    </div>
  );
}
export default React.memo(FormGroup);
