import { GaugeSurvey, QRActionApi } from '../../api';
import { IGetSurvey } from '../../api/GaugeSurvey';
import {
  IAllGauge,
  IGauge,
  IParentPosition,
  IPosititonInfo,
  ISubject,
  ISubjectShow,
  ISubjectV,
} from '../../assets/js/DataJson';
import { ICacheResult } from './../../Model/Model';
import { ISurvey } from './../../Model/SurveyModel';
import Parser from './../Parser';
import { ISurveyHook } from './SurveyHook';
interface ISubmitSurvey {
  answerJson: string;
  questionAnswerJson: string;
  writeType: string;
  id?: string;
  visitNumber?: number;
  userId?: string;
  patientId?: string;
  patient_sex?: number | null;
  questionId: string;
  code?: string;
}
/**
 * 量表私有方法类
 */
class SurveyModelUtil {
  public state = {
    pageCatchTypes: ['surveyUpdate', 'surveyCreate', 'surveyCreateVisit', 'surveyQrcodeUpdate'], // 需要缓存的进入方式
    defaultValueJson: ['FormSelect', 'FormCheckbox', 'FormSectionsDate', 'FormGroup'], // 需要默认值需要 json.parse的 题目
    handleCountData: {
      countData: ['FormSelect', 'FormRadio', 'FormCheckbox'], // 需要处理算分的组件
      countArr: ['FormSelect', 'FormCheckbox'], // 处理组件里面需要算分的
      countArrKey: {
        // 要处理的具体数据
        FormCheckbox: 'checkboxs',
        // tslint:disable-next-line:no-any
      } as any,
    },
    // tslint:disable-next-line:no-any
    timer: null as any,
  };

  /**
   * 从云端获取初始化对应的数据
   */
  // tslint:disable-next-line:no-any
  public getSurveyData = async (payload: any) => {
    // tslint:disable-next-line:no-any
    let surveyInfo = {} as ICacheResult;
    let gaugeData = {} as IGetSurvey;
    switch (payload.writeType) {
      // 编辑问卷
      case 'surveyUpdate':
        surveyInfo = await this.getCacheGauge(payload.patientId, payload.answerId);
        if (!surveyInfo.answerCacheJson) {
          surveyInfo = await this.getGaugeDetail(payload.answerId);
        }
        break;
      // 查看详情
      case 'surveyDetail':
        surveyInfo = await this.getGaugeDetail(payload.answerId);
        break;
      // 新建病例或者新增问卷
      case 'surveyCreate':
        if (payload.patientId) {
          surveyInfo = await this.getCacheGauge(payload.patientId, payload.answerId);
        } else {
          surveyInfo = await this.getUnknownCacheGauge();
        }
        break;
      // 扫码进入,不缓存
      case 'surveyQrcodeCreate':
        const surveyQrcodeCreate = await this.getQRCodeMsg(payload.code);
        gaugeData = surveyQrcodeCreate.gaugeData;
        surveyInfo = surveyQrcodeCreate.surveyInfo;
        return {
          gaugeData,
          surveyInfo,
        };
      // 扫码进入编辑,需要缓存
      case 'surveyQrcodeUpdate':
        const surveyQrcodeUpdate = await this.getQRCodeMsg(payload.code);
        gaugeData = surveyQrcodeUpdate.gaugeData;
        surveyInfo = surveyQrcodeUpdate.surveyInfo;
        if (surveyInfo.channel === 'PARIENT') {
          delete surveyInfo.answerJson;
        }

        const qrCodeAnswerOrCache = await this.getQrCodeAnswerOrCache(payload.code);
        surveyInfo.answerCacheJson = qrCodeAnswerOrCache.answerCacheJson;
        return {
          gaugeData,
          surveyInfo,
        };
    }
    gaugeData = await this.getSurveyOriginal({ questionId: surveyInfo.questionId });
    return {
      gaugeData,
      surveyInfo,
    };
  };

  /**
   * 初始化量表model的数据
   * @param gaugeData 初始化获取的json
   * @param roleType 患者还是医生
   * @param isInit 是第一次初始化还是单个初始化一个模块
   */

  public surveyInit = (
    gaugeData: IAllGauge[],
    // tslint:disable-next-line:no-any
    surveyAnswer: any,
    roleType: 'patient' | 'doctor',
    // tslint:disable-next-line:no-any
    payload: any,
    isLastSurvey?: boolean
  ) => {
    const answer = JSON.parse(JSON.stringify(surveyAnswer));
    // tslint:disable-next-line:no-any
    let schema: { [key: string]: any } = {};
    // 算分条件隐藏的对象
    // tslint:disable-next-line:no-any
    const countData = {} as { [key: string]: any };
    gaugeData.forEach((item, menuIndex) => {
      item.isError = [];
      item.position = {};
      let itemNum = 0;
      item.moduleChildrens.forEach((sub: IGauge, menuIChild: number) => {
        sub.subjectShowStatus = {} as ISubjectShow;
        sub.isShow = true;
        let subNum = 0;
        sub.subjects.forEach((sje, subjectIndex) => {
          // 当前填写人是否有权限查看对应的题目
          const isDelete =
            roleType === 'patient'
              ? this.patientVisitIf(sje, payload.visitNumber)
              : this.doctorVisitIf(sje, payload.visitNumber);

          sje.roleShowStatus = isDelete;
          // 以字段作为key记录题目的位置,当前小模块里面的题目显示状态,默认是true
          sub.subjectShowStatus![sje.subjectData.field] = isDelete;
          sje.subjectData.showStatus = isDelete;
          if (isLastSurvey || answer[sje.subjectData.field]) {
            sje.subjectData.defaultValue = answer[sje.subjectData.field];
          } else {
            if (sje.subjectData.defaultValue && this.state.defaultValueJson.includes(sje.subjectData.subjectType)) {
              sje.subjectData.defaultValue = JSON.parse(sje.subjectData.defaultValue);
            }

            if (sje.subjectData.subjectType === 'FormNumInput' && sje.subjectData.defaultValue) {
              sje.subjectData.defaultValue = Number(sje.subjectData.defaultValue);
            }
          }

          // 设置一个控制更新的key
          sje.subjectData.updateKey = sje.subjectData.updateKey ? ++sje.subjectData.updateKey : 1;
          // 判断是否需要根据权限重新给校验条件赋值
          if (roleType === 'patient' && sje.subjectData.patientRequired === 'true') {
            sje.validatorData.required = sje.subjectData.patientRequired;
          }

          // 这里先暂时单独处理一下
          if (sje.validatorData.pattern) {
            sje.validatorData.pattern = new RegExp(sje.validatorData.pattern);
          }

          if (isDelete) {
            // 以字段作为key记录题目的位置，这里是大模块下全部的题目，以及位置
            item.position[sje.subjectData.field] = {
              menuIndex, // 记录大模块位置
              menuIChild, // 记录小模块位置
              subjectIndex, // 记录题目位置
              errMsg: '', // 错误清空
            };
            // 如果条件隐藏的，有能从缓存答案里面例获取出来值，则默认显示
            if (!sje.subjectData.visibleIf || (sje.subjectData.visibleIf && sje.subjectData.defaultValue)) {
              answer[sje.subjectData.field] = sje.subjectData.defaultValue || null; // 回显
              schema[sje.subjectData.field] = sje.validatorData;
            } else {
              sje.subjectData.showStatus = false;
            }
            // 如果是一个复合组件，且没有默认值的情况下，则初始化他的默认值
            if (sje.subjectData.subjectType === 'FormGroup') {
              if (!sje.subjectData.defaultValue) {
                sje.subjectData.defaultValue = [this.formGroupInit(sje)];
                // 如果为空这里初始化一个复合组件的答案对象
                answer[sje.subjectData.field] = sje.subjectData.defaultValue;
              }
              // 获取对应的位置以及组合渲染数组
              sje = this.getGroupPosition(item.position[sje.subjectData.field], sje);
              // 获取复合组件的校验信息
              const subjectData = this.updateGroupSchema(sje, schema, roleType);
              sje = subjectData.subject;
              schema = subjectData.schema;
            }
          } else {
            subNum++;
          }
          // 符合组件的算分对象单独初始化
          if (sje.subjectData.subjectType === 'FormGroup') {
            countData[sje.subjectData.field] = this.getGroupCountData(JSON.parse(JSON.stringify(sje.subjectData)));
          } else {
            // 普通题目
            countData[sje.subjectData.field] = this.getCountData(JSON.parse(JSON.stringify(sje.subjectData)));
          }
        });
        sub.isShow = subNum !== sub.subjects.length;
        if (!sub.isShow) {
          itemNum++;
        }
      });
      item.isShow = itemNum !== item.moduleChildrens.length;
    });
    return {
      answer,
      schema,
      gaugeData,
      menuIndex: this.single(gaugeData),
      writeType: payload.writeType,
      currentCatchIf: this.state.pageCatchTypes.includes(payload.writeType),
      countData,
    };
  };

  /**
   * 判断该问卷为什么类型的提交，并提交到对应接口
   * @params params 要提交的参数
   */
  public submitSurvey = async (params: ISubmitSurvey) => {
    switch (params.writeType) {
      // 编辑问卷
      case 'surveyUpdate':
        await GaugeSurvey.updateGauge({
          answerJson: params.answerJson,
          id: params.id,
          questionId: params.questionId,
        });
        return {};
      // 新增病例
      case 'surveyCreate':
        await GaugeSurvey.createGauge({
          answerJson: params.answerJson,
          visitNumber: params.visitNumber,
          patientId: params.patientId,
          questionId: params.questionId,
        });
        return {};
      // 扫码进入不需要缓存 新建
      case 'surveyQrcodeCreate':
        const surveyQrcodeCreate = await GaugeSurvey.createQrcode({
          answerJson: params.answerJson,
          code: params.code,
        });
        return surveyQrcodeCreate.result;
      // 扫码进入需要缓存 编辑
      case 'surveyQrcodeUpdate':
        const surveyQrcodeUpdate = await GaugeSurvey.updateQrcode({
          answerJson: params.answerJson,
          code: params.code,
        });
        return surveyQrcodeUpdate.result;
    }
  };

  /**
   * 处理Validator的报错
   * @param gaugeData 题目数组
   * @param errors 报错字段以及信息
   */
  // tslint:disable-next-line:no-any
  public catchErrValidator = (gaugeData: IAllGauge[], errors?: Array<{ field: string; message: string }>) => {
    if (!errors) {
      return gaugeData;
    }
    // 找到报错位置
    const positions = [] as IPosititonInfo[];
    gaugeData.forEach((gaugeItem: IAllGauge, menuIndex: number) => {
      gaugeItem.isError = [];
      errors.forEach((item: { field: string; message: string }) => {
        // 这里由于拿的是所有的报错数据进行循环，所以可能里面会搀着其非当前循环模块东西
        let groupInfo = [] as string[];
        const isGroup = item.field.includes('.');
        let obj = {} as IPosititonInfo;
        // 如果是复合组件则获取里面的内容， 0 复合组件字段 1 小组件下标 2 小组件字段
        if (isGroup) {
          groupInfo = item.field.split('.');
          obj = gaugeItem.position[groupInfo[0]];
          if (obj) {
            obj.fieldChildren = groupInfo[2];
            obj.childrenIndex = Number(groupInfo[1]);
          }
        } else {
          obj = gaugeItem.position[item.field];
        }
        if (obj) {
          obj.errMsg = item.message;
          // 搜集报错信息，这里可以颗粒化到记录出每个模块有多少个报错，目前没有要求暂时不实现
          gaugeItem.isError.push({
            field: isGroup ? groupInfo[2] : item.field,
            menuIndex,
            menuIChild: obj.menuIChild,
          });
          // 🤔 我重新let一个obj 并没有覆盖之前，所以引用的地址一直没变
          positions.push(JSON.parse(JSON.stringify(obj)));
        }
      });
    });

    positions.forEach(item => {
      const subjectData =
        gaugeData[item.menuIndex].moduleChildrens[item.menuIChild].subjects[item.subjectIndex].subjectData;
      // 如果是复合组件, 这里可以改成直接用key取值，留作优化

      if (item.fieldChildren) {
        subjectData.groupChildrens![item.childrenIndex!]!.forEach(group => {
          if (group.subjectData.field === item.fieldChildren) {
            group.subjectData.errMsg = item.errMsg;
            subjectData.errMsg =
              gaugeData[item.menuIndex].moduleChildrens[item.menuIChild].subjects[
                item.subjectIndex
              ].validatorData.message;
          }
        });
      } else {
        subjectData.errMsg = item.errMsg;
      }
    });
    return gaugeData;
  };

  /**
   * 每次答案有改变时就调用
   */
  // tslint:disable-next-line:no-any
  public updateSurveyData = (sje: ISubjectV, schema: any, answer: any, countData: any) => {
    // 显示的时候 我要拿到值和校验信息
    if (sje.subjectData.showStatus) {
      schema[sje.subjectData.field] = sje.validatorData;
    } else {
      answer[sje.subjectData.field] = null;
      delete schema[sje.subjectData.field];
      delete countData[sje.subjectData.field];
    }
    return {
      answer,
      schema,
      countData,
    };
  };

  /**
   * 题目change时调用
   */
  // tslint:disable-next-line:no-any
  public changeSurvey = (payload: { props: ISurveyHook; val: any }, state: ISurvey) => {
    const field = payload.props.formObj.subjectData.field;
    state.answer[field] = payload.val;
    const position = state.gaugeData[state.menuIndex].position[field];
    if (!position) {
      return null;
    }

    const subject =
      state.gaugeData[state.menuIndex].moduleChildrens[position.menuIChild].subjects[position.subjectIndex];
    subject.subjectData.errMsg = '';
    subject.subjectData.defaultValue = payload.val;
    // 这里单独做一层 算分的处理，这里注意，一定要等到subject.subjectData.defaultValue 赋值完在进行操作😋
    state.countData[payload.props.formObj.subjectData.field] = this.getCountData(payload.props.formObj.subjectData);

    // 取消报错
    state.gaugeData[state.menuIndex].isError = this.cancelBigModule(state, field);
    if (subject.subjectData.initGroupChildrens) {
      return this.visibleIfGroupChange(state.gaugeData, state.answer, state.schema, state.countData, true);
    }
    return this.visibleIfChange(state.gaugeData, state.countData, state.answer, state.schema);
  };

  /**
   * 复合组件里面的组件change事件
   */
  // tslint:disable-next-line:no-any
  public changeGroupSurvey = (payload: { props: ISurveyHook; val: any }, state: ISurvey) => {
    const parentPosition = payload.props.formObj.subjectData.parentPosition!;
    if (!parentPosition) {
      return null;
    }
    const subject =
      state.gaugeData[state.menuIndex].moduleChildrens[parentPosition.menuIChild].subjects[parentPosition.subjectIndex];
    const group = subject.subjectData.groupChildrens!;
    group[parentPosition.groupIndex!]![parentPosition.groupChildrenIndex!].subjectData.defaultValue = payload.val;
    const dom = document.getElementById(`${payload.props.formObj.subjectData.field}_${parentPosition.groupIndex}`);
    group[parentPosition.groupIndex!]![parentPosition.groupChildrenIndex!].subjectData.errMsg = '';
    subject.subjectData.errMsg = '';
    const domErr = document.getElementById(
      `errLogo_${payload.props.formObj.subjectData.field}_${parentPosition.groupIndex}`
    );

    if (dom) {
      dom.innerHTML = '';
    }
    if (domErr) {
      domErr.className = 'star';
    }
    // 取消报错
    state.gaugeData[state.menuIndex].isError = this.cancelBigModule(state, payload.props.formObj.subjectData.field);
    // 这里直接改变复合组件那道题目和复合组件的默认是，这样就不需要再循环取值
    subject.subjectData.defaultValue[parentPosition.groupIndex!] = subject.subjectData.defaultValue[
      parentPosition.groupIndex!
    ]
      ? subject.subjectData.defaultValue[parentPosition.groupIndex!]
      : {};

    subject.subjectData.defaultValue[parentPosition.groupIndex!][payload.props.formObj.subjectData.field] = payload.val;

    state.countData[subject.subjectData.field] = this.getGroupCountData(subject.subjectData);
    // 这里单独做一层 当做传值和回显用
    state.answer[subject.subjectData.field] = subject.subjectData.defaultValue;

    return this.visibleIfGroupChange(state.gaugeData, state.answer, state.schema, state.countData);
  };

  /**
   * 导航栏块报红
   */
  public errStyle = (data: IAllGauge, index: number, iChild?: number) => {
    if (data.isError) {
      if (iChild || iChild === 0) {
        // tslint:disable-next-line:no-any
        const isErr = data.isError.some((item: any) => {
          return item.menuIndex === index && item.menuIChild === iChild;
        });
        return isErr ? 'err' : '';
      } else {
        // tslint:disable-next-line:no-any
        const isErr = data.isError.some((item: any) => {
          return item.menuIndex === index;
        });
        return isErr ? 'err' : '';
      }
    }
    return '';
  };

  /**
   * 读取上次方式
   */
  public lastSaveSurvey = async (
    visitNumber: number,
    patientId: string,
    // tslint:disable-next-line:no-any
    gaugeData: any,
    // tslint:disable-next-line:no-any
    answer: any,
    // tslint:disable-next-line:no-any
    payload: any
  ) => {
    const { result } = await GaugeSurvey.answerLastInfo({ visitNumber: visitNumber - 1, patientId });
    const modules = gaugeData[payload.props.positions[0]].moduleChildrens[payload.props.positions[1]];
    // tslint:disable-next-line:no-any
    const answerJson: any = JSON.parse(result.answerJson);
    modules.subjects.forEach((sje: ISubjectV) => {
      const isVal = answerJson[sje.subjectData.field] || typeof answerJson[sje.subjectData.field] === 'number';
      answer[sje.subjectData.field] = payload.checked ? answerJson[sje.subjectData.field] : null;
      sje.subjectData.defaultValue = payload.checked ? answerJson[sje.subjectData.field] : null;
      sje.subjectData.errMsg = '';
      if (payload.checked && (!isVal || JSON.stringify(answerJson[sje.subjectData.field]) === '[]')) {
        // 如果上次方式没有对应的答案则显示 未找到上次内容
        sje.subjectData.errMsg = '未找到上次内容';
      }
    });
    answer.beforeModuleNames = answer.beforeModuleNames || [];
    if (payload.checked) {
      answer.beforeModuleNames.push(payload.props.data.moduleName);
    } else {
      answer.beforeModuleNames = answer.beforeModuleNames.filter((item: string) => item !== modules.moduleName);
    }
    return {
      lastGaugeData: gaugeData,
      lastAnswer: answer,
    };
  };

  /**
   * 重置dva的state
   */
  public restState = () => {
    return {
      schema: {},
      gaugeData: [], // 所有量表数据
      answer: {}, // 答案
      doms: [],
      menuIndex: -1, // 1级菜单下标
      menuIChild: -1, // 2级菜单下标
      visitNumber: 0, // 访视次数
      patientId: null, // 患者id
      id: '', // 问卷id
      isDetail: '',
      userId: undefined,
      channel: 0,
      writeType: '',
      currentCatchIf: false,
      questionId: '', // 新建问卷id
      code: '',
    };
  };

  /**
   * 更新复合组件校验条件,以及对应的Schema的信息
   */
  // tslint:disable-next-line:no-any
  public updateGroupSchema = (subject: ISubjectV, schema: any, roleType?: string) => {
    // 判断是否需要根据权限重新给校验条件赋值
    if (roleType === 'patient' && subject.subjectData.patientRequired === 'true') {
      subject.validatorData.required = subject.subjectData.patientRequired;
    }
    subject.validatorData.type = 'array';
    subject.validatorData.fields = {};

    subject.subjectData.groupChildrens!.forEach((groupChildrens, groupIndex) => {
      // tslint:disable-next-line:no-any
      const fields = {} as { [key: string]: any };
      groupChildrens!.forEach(item => {
        if (item.subjectData.showStatus) {
          // 组合验证信息
          fields[item.subjectData.field] = item.validatorData;
          subject.validatorData.fields[groupIndex] = {
            type: 'object',
            required: subject.validatorData.required,
            fields,
          };
        }
      });
    });
    schema[subject.subjectData.field] = subject.validatorData;
    return { subject, schema };
  };

  /**
   * 定位复合组件 组 的位置
   * @param props 当前题目的信息
   * @param position 当前位置信息
   */
  public getGroupPosition = (position: IParentPosition, props: ISubjectV) => {
    props.subjectData.groupChildrens = [];
    // tslint:disable-next-line:no-any
    props.validatorData.fields = {} as { [key: number]: any };
    props.validatorData.type = 'array';
    // tslint:disable-next-line:no-any
    props.subjectData.defaultValue.forEach((defauVal: { [key: string]: any }, groupIndex: number) => {
      const initGroupChildrens = JSON.parse(JSON.stringify(props.subjectData.initGroupChildrens));
      initGroupChildrens.forEach((ori: ISubjectV, groupChildrenIndex: number) => {
        ori.subjectData.defaultValue = defauVal[ori.subjectData.field];
        if (!ori.subjectData.visibleIf) {
          ori.subjectData.showStatus = true;
        }
        // 这里临时判断条件隐藏
        ori.subjectData.isGroupChildren = true;
        ori.subjectData.parentPosition = {
          ...position,
          groupChildrenIndex,
          groupIndex,
        };
      });
      props.subjectData.groupChildrens!.push(initGroupChildrens);
    });
    return props;
  };

  /**
   * 扫码进入获取相关信息
   * @param code
   */
  public getQRCodeMsg = async (code: string) => {
    const { result } = await QRActionApi.getQRCodeMsg({ code });
    const gaugeData = {
      jsonDesc: result.questionVo.jsonDesc,
    };
    const surveyInfo = {
      originCode: result.organizationVo.code,
      visitNumber: result.visitNumber,
      answerJson: result.answerInfoVo.answerJson,
      gender: result.answerInfoVo.gender,
      channel: result.channel,
    };
    return { gaugeData, surveyInfo, result };
  };

  /**
   * 条件隐藏，每次答案变化的时候都会触发
   * @param countData 专属算分的对象
   */
  // tslint:disable-next-line:no-any
  public visibleIfChange = (
    gaugeData: IAllGauge[],
    // tslint:disable-next-line:no-any
    countData: any,
    // tslint:disable-next-line:no-any
    answer: any,
    // tslint:disable-next-line:no-any
    schema: any
  ) => {
    // tslint:disable-next-line:no-any
    let validatorData = {} as any;
    gaugeData.forEach(gauge => {
      gauge.moduleChildrens.forEach(sub => {
        sub.subjects.forEach(sje => {
          // 判断角色权限以及是否是复合组件的子组件
          if (sje.roleShowStatus && !sje.subjectData.parentPosition) {
            if (sje.subjectData.visibleIf) {
              try {
                const visibleIf = Parser(sje.subjectData.visibleIf, countData, 'visibleIf');
                // tslint:disable-next-line:no-eval
                sje.subjectData.showStatus = !!eval(visibleIf);
                if (!sje.subjectData.showStatus) {
                  if (sje.subjectData.subjectType === 'FormGroup') {
                    sje.subjectData.defaultValue = [this.formGroupInit(sje)];
                    sje.subjectData.groupChildrens = this.getGroupPosition(
                      gauge.position[sje.subjectData.field],
                      sje
                    ).subjectData.groupChildrens;
                  } else {
                    sje.subjectData.defaultValue = null;
                  }
                }
                // 记录当前模块状态
                sub.subjectShowStatus![sje.subjectData.field] = !!sje.subjectData.showStatus;
                validatorData = this.updateSurveyData(sje, schema, answer, countData);
              } catch (err) {
                // 隐藏的时候并不会去重新渲染对应的组件而是在render 组件那里直接取消render 所以需要手动重置默认值
                sje.subjectData.showStatus = false;

                // 记录当前模块状态
                sub.subjectShowStatus![sje.subjectData.field] = false;
                if (sje.subjectData.subjectType === 'FormGroup') {
                  sje.subjectData.defaultValue = [this.formGroupInit(sje)];
                  sje.subjectData.groupChildrens = this.getGroupPosition(
                    gauge.position[sje.subjectData.field],
                    sje
                  ).subjectData.groupChildrens;
                } else {
                  sje.subjectData.defaultValue = null;
                }
              }
            }
            if (sje.subjectData.formula) {
              const formula = Parser(sje.subjectData.formula!, countData);

              try {
                // tslint:disable-next-line:no-eval
                sje.subjectData.defaultValue = eval(formula);
                sje.subjectData.defaultValue = this.numFormatting(sje.subjectData);
              } catch (err) {
                sje.subjectData.defaultValue = 0;
              }

              // 这里直接答案进行赋值，也就是给后端传的
              answer[sje.subjectData.field] = sje.subjectData.defaultValue;
              countData[sje.subjectData.field] = sje.subjectData.defaultValue;
            }
          }
        });
      });
    });
    validatorData = validatorData.answer ? validatorData : { answer, schema };
    gaugeData = this.moduleShowStatus(gaugeData);
    return { gaugeData, countData, ...validatorData };
  };

  /**
   * 节流定时
   */
  // tslint:disable-next-line:no-any
  public delay = (timeout: any) => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      this.state.timer = null;
    }
    return new Promise(resolve => {
      this.state.timer = setTimeout(resolve, timeout);
    });
  };
  /**
   * 页面自动定位
   */
  public scrollToWindow = (state: ISurvey) => {
    const isPc = window.screen.width > 500;
    let range = 0;
    for (let i = 0; i < state.menuIChild; i++) {
      const dom = document.getElementById('gauge_content' + state.menuIndex + '_' + i) as HTMLElement;
      const pdom = dom ? (dom.parentNode as HTMLElement) : null;
      if (pdom) {
        range = range + pdom.scrollHeight + 16;
      }
    }
    if (document.getElementById('scrl_box')) {
      if (!!document.getElementById('scrl_box')!.scrollTo) {
        (isPc ? document.getElementById('scrl_box')! : window).scrollTo({ behavior: 'smooth', top: range });
        return;
      }
      (isPc ? document.getElementById('scrl_box')! : window).scrollTo({ behavior: 'smooth', top: range });
    }
  };

  /**
   * 复合组件的条件隐藏和算分
   */

  public visibleIfGroupChange = (
    gaugeData: IAllGauge[],
    // tslint:disable-next-line:no-any
    answer: any,
    // tslint:disable-next-line:no-any
    schema: any,
    // tslint:disable-next-line:no-any
    countData: any,
    isGroup?: boolean
  ) => {
    // 获取当前符合组件对应的算分对象
    gaugeData.forEach(gauge => {
      gauge.moduleChildrens.forEach(sub => {
        sub.subjects.forEach((sje, i) => {
          // 复合权限的在判断 权限 只会在初始化的时候判断一次
          if (sje.roleShowStatus && sje.subjectData.groupChildrens) {
            // 用每组的下标为key
            sje.subjectData.countData = {};
            sje.subjectData.groupChildrens.forEach((groups, groupIndex) => {
              sje.subjectData.countData![groupIndex] = {};
              groups!.forEach(item => {
                sje.subjectData.countData![groupIndex][item.subjectData.field] = this.getCountData(item.subjectData);
              });
            });
          }
        });
      });
    });
    // 进行条件隐藏和算分
    gaugeData.forEach(gauge => {
      gauge.moduleChildrens.forEach(sub => {
        sub.subjects.forEach((sje, i) => {
          // 复合权限的在判断 权限 只会在初始化的时候判断一次
          if (sje.roleShowStatus && sje.subjectData.groupChildrens) {
            sje.subjectData.groupChildrens.forEach((groups, groupIndex) => {
              groups!.forEach(item => {
                // 条件隐藏
                if (item.subjectData.visibleIf) {
                  // 如果复合组件需要外部的条件，则在 sje.subjectData.countData![groupIndex]里拼接上算分对象😋
                  const visibleIf = Parser(
                    item.subjectData.visibleIf,
                    sje.subjectData.countData![groupIndex],
                    'visibleIf'
                  );
                  try {
                    // tslint:disable-next-line:no-eval
                    const isShow = !!eval(visibleIf);
                    // 如果显示状态发生改变则更新整个复合组件、算分对象、答案、schema
                    if (item.subjectData.showStatus !== isShow) {
                      sje.subjectData.updateKey += 1;
                    }
                    item.subjectData.showStatus = isShow;
                  } catch (err) {
                    // 如果他本是是复合组件调发生增加或者删除发生的change，则不需要更新
                    if (item.subjectData.showStatus !== false && isGroup) {
                      sje.subjectData.updateKey += 1;
                    }
                    item.subjectData.showStatus = false;
                  }
                  // 如果隐藏则重置默认值
                  if (!item.subjectData.showStatus) {
                    item.subjectData.defaultValue = null;
                    sje.subjectData.defaultValue[groupIndex][item.subjectData.field] = null;
                    item.subjectData.errMsg = '';
                    if (answer[sje.subjectData.field]) {
                      delete answer[sje.subjectData.field][groupIndex][item.subjectData.field];
                      delete countData[sje.subjectData.field][groupIndex][item.subjectData.field];
                    }
                  }
                }

                // 算分
                if (item.subjectData.formula) {
                  const formula = Parser(item.subjectData.formula, sje.subjectData.countData![groupIndex]);
                  try {
                    // tslint:disable-next-line:no-eval
                    item.subjectData.defaultValue = eval(formula);
                  } catch (err) {
                    item.subjectData.defaultValue = 0;
                  }
                  // 在普通组件里面算分是只要有改动就会刷新，但是在复合组件中里面的题目如果想要刷新的必须要刷新整个符合组件
                  // 所以在这里 直接用dom操作的方式
                  const dom = document.getElementById(
                    `${item.subjectData.field}_${item.subjectData.parentPosition!.groupIndex}`
                  );
                  if (dom) {
                    dom.innerHTML = this.numFormatting(item.subjectData).toString();
                  }
                  answer[sje.subjectData.field][item.subjectData.field] = sje.subjectData.defaultValue;
                  countData[sje.subjectData.field][item.subjectData.field] = sje.subjectData.defaultValue;
                }
              });
            });

            // 获取复合组件的校验信息
            const subjectData = this.updateGroupSchema(sje, schema);
            sje = subjectData.subject;
            schema = subjectData.schema;
          }
        });
      });
    });
    return { gaugeData, countData, answer, schema };
  };
  /**
   * 初始化复合组件
   */
  public formGroupInit = (data: ISubjectV) => {
    // tslint:disable-next-line:no-any
    const defaultValue = {} as any;
    data.subjectData.initGroupChildrens!.forEach(item => {
      // 复合组件至少有一个所以这里直接去值
      defaultValue[item.subjectData.field] = null;
    });
    return defaultValue;
  };
  /**
   * 分数格式化
   */
  private numFormatting = (subjectData: ISubject) => {
    if (subjectData.defaultValue) {
      return subjectData.decimalNum
        ? Number(subjectData.defaultValue).toFixed(subjectData.decimalNum)
        : Number(subjectData.defaultValue).toFixed(3);
    }
    return 0;
  };

  /**
   * 判断小模块是否允许显示
   */
  private moduleShowStatus = (gaugeData: IAllGauge[]) => {
    gaugeData.forEach(gauge => {
      gauge.moduleChildrens.forEach(sub => {
        const vals = [];
        // tslint:disable-next-line:forin
        for (const key in sub.subjectShowStatus!) {
          vals.push(sub.subjectShowStatus![key]);
        }
        const isShow = vals.some(item => item);
        sub.isShow = isShow;
      });
    });
    return gaugeData;
  };

  /**
   * 传值和回显
   * 目前 handleCountData 里面的类型是需要特殊的,其他的正常处理
   * @param subjectData 当前题目
   * @param isInit 是否是初始化
   */
  // tslint:disable-next-line:no-any
  private getCountData = (subjectData: ISubject) => {
    const handleCountData = this.state.handleCountData;
    const data = subjectData.defaultValue;
    // 所谓的特殊处理 就是从对应的对象里面 通过传入的值 进行取分数😝
    if (data) {
      // 需要算分的组件
      if (handleCountData.countData.includes(subjectData.subjectType)) {
        if (handleCountData.countArr.includes(subjectData.subjectType)) {
          // tslint:disable-next-line:no-any
          const arr = data.map((item: any) => (subjectData.countNumData ? subjectData.countNumData[item] : item));
          return arr;
        }
        return subjectData.countNumData ? subjectData.countNumData[subjectData.defaultValue] : subjectData.defaultValue;
      }
    }
    return subjectData.defaultValue;
  };

  /**
   * 符合组件算分对应组合
   */
  private getGroupCountData = (subjectData: ISubject) => {
    // tslint:disable-next-line:no-any
    const countVal = [] as any;
    if (subjectData.groupChildrens) {
      // tslint:disable-next-line:no-any
      subjectData.groupChildrens!.map((groupChildrens: any, i: number) => {
        countVal[i] = {};
        groupChildrens.forEach((item: ISubjectV) => {
          countVal[i][item.subjectData.field] = this.getCountData(item.subjectData);
        });
      });
    }
    return countVal;
  };

  /**
   * 取消大模块报红
   */
  private cancelBigModule = (state: ISurvey, field: string) => {
    return state.gaugeData[state.menuIndex].isError.filter(
      // tslint:disable-next-line:no-any
      (item: any) => item.field !== field
    );
  };
  /**
   * 判断医生有没有资格填写
   * @param sje
   * @param visitNumber
   * @return boolean
   */
  private doctorVisitIf = (sje: ISubjectV, visitNumber: number) => {
    // 则visitNumbers里面有-1 则里面的代表排除的 如果没有 则代表包含的
    if (!sje.roleTypes || sje.roleTypes.includes('1')) {
      if (!sje.visitNumbers) {
        return true;
      }
      return sje.visitNumbers.includes('-1')
        ? !sje.visitNumbers.includes(visitNumber.toString())
        : sje.visitNumbers.includes(visitNumber.toString());
    }
    return false;
  };
  /**
   * 判断患者有没有资格填写
   * @param sje
   * @param visitNumber
   * @return boolean
   */
  private patientVisitIf = (sje: ISubjectV, visitNumber: number) => {
    // 则visitNumbers里面有-1 则里面的代表排除的 如果没有 则代表包含的
    if (!sje.roleTypes || sje.roleTypes.includes('0')) {
      if (!sje.visitNumbers) {
        return true;
      }
      return sje.visitNumbers.includes('-1')
        ? !sje.visitNumbers.includes(visitNumber.toString())
        : sje.visitNumbers.includes(visitNumber.toString());
    }

    return false;
  };

  /**
   * 返回第一个符合条件的下标,此方法只能适用于IAllGauge[];这个数据结构
   * @param arr 题目大模块
   */
  private single(arr: IAllGauge[]) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isShow) {
        return i;
      }
    }
  }

  /**
   * 获取问卷详情
   * @param id 问卷id
   */
  private getGaugeDetail = async (id: string) => {
    const { result } = await GaugeSurvey.getGaugeDetail({ id });
    return result;
  };

  /**
   * 获取编辑问卷缓存
   *
   */
  private getCacheGauge = async (patientId: string, answerId: string) => {
    const { result } = await GaugeSurvey.getCacheGauge({ patientId, answerId });
    return result;
  };
  /**
   * 获取匿名问卷缓存
   */

  private getUnknownCacheGauge = async () => {
    const { result } = await GaugeSurvey.getUnknownCacheGauge();
    return result;
  };

  /**
   * 获取原题目
   */
  private getSurveyOriginal = async (data: { questionId?: string }) => {
    const { result } = await GaugeSurvey.getSurvey(data);
    return result;
  };
  /**
   * 获取扫码编辑问卷的信息，包括缓存信息
   */
  private getQrCodeAnswerOrCache = async (code: string) => {
    const { result } = await GaugeSurvey.getQrCodeAnswerOrCache({ code });
    return result;
  };
}
export default SurveyModelUtil;
