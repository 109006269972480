// import { Input } from "antd";
import { DatePicker } from 'antd';
import { useDispatch } from 'dva';
import moment from 'moment';
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import AuthCompount from '../../AuthCompount/AuthCompount';
import './FormDateTime.scss';
const isPc = window.screen.width > 500;
// tslint:disable-next-line:no-any
declare var window: any;
/**
 * 日期时间选择
 * 在subjectData里面type为FormDateTime
 * 具体用法参照 ant design
 */
function FormDateTime(props: ISurveyHook) {
  const [val, setVal] = useState(props.formObj.subjectData.defaultValue);
  const dispatch = useDispatch();
  function inputChange(date: moment.Moment | null, dateString: string) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: dateString,
      },
    });
    setVal(dateString);
  }

  return (
    <div id="FormDateTime">
      <div className="form_box">
        <div className="tit_box">
          <AuthCompount isShow={!!props.formObj.validatorData.required}>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              className={props.formObj.subjectData.errMsg ? 'cl star' : 'star'}
              // tslint:disable-next-line:jsx-no-multiline-js
              id={
                props.formObj.subjectData.parentPosition &&
                `errLogo_${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
              }
            >
              *
            </div>
          </AuthCompount>
          <div className="cln">{props.formObj.subjectData.subjectName}</div>
          <AuthCompount isShow={!!props.formObj.subjectData.explain}>
            <div className="explain">（说明：{props.formObj.subjectData.explain}）</div>
          </AuthCompount>
        </div>
        <DatePicker
          showTime={true}
          className={`form_input`}
          disabled={props.formObj.subjectData.disable}
          // tslint:disable-next-line:jsx-no-multiline-js
          defaultValue={
            props.formObj.subjectData.defaultValue ? moment(props.formObj.subjectData.defaultValue) : undefined
          }
          value={val ? moment(val) : undefined}
          onChange={inputChange}
          getCalendarContainer={() => document.getElementById('scrl_box')!}
        />
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            // tslint:disable-next-line:jsx-no-multiline-js
            id={
              props.formObj.subjectData.parentPosition &&
              `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
            }
          >
            {props.formObj.subjectData.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          // tslint:disable-next-line:jsx-no-multiline-js
          id={
            props.formObj.subjectData.parentPosition &&
            `${props.formObj.subjectData.field}_${props.formObj.subjectData.parentPosition.groupIndex}`
          }
        >
          {props.formObj.subjectData.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormDateTime);
