import { Form } from 'antd';
import { DatePicker } from 'antd-mobile';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { RouteComponentProps } from 'dva/router';
import moment from 'moment';
import React, { useState } from 'react';
import { WxDoctorApi } from '../../../api';
import { INewNotiReq } from '../../../api/WxDoctorApi';
import './NewNotis.scss';
interface INewNotisProps extends FormComponentProps, RouteComponentProps<{ id: string }> {}
function NewNotisPage(props: INewNotisProps) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { getFieldDecorator, validateFields } = props.form;
  const [sysMsgType, setSysMsgType] = useState(1); //  1 复诊通知 2 随访通知 3 随访问卷
  /**
   * 发送一个通知信息
   */
  async function addOneNoti() {
    validateFields(async (err, values) => {
      if (!err) {
        const receiveUserId = props.match.params.id;
        const notiReq: INewNotiReq = {
          type: 1,
          sysMsgType,
          dataSource: 1,
          title: values.notiTitle,
          content: values.notiContentMsg,
          sendTime: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          treatmentTime: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          receiveUserId,
        };
        const { result } = await WxDoctorApi.addOneNotification(notiReq);
        if (result) {
          props.history.goBack();
        }
      }
    });
  }
  /**
   * 类型选择
   */
  function notiTypeClick(type: number) {
    // 1 复诊通知 2 随访通知 3 随访问卷
    setSysMsgType(type);
  }
  return (
    <div className="NewNotis">
      <div className="navContainer">
        <div className="returnContiner" onClick={props.history.goBack}>
          <img src={require('../../../assets/moblieImg/btn_back.svg')} className="iconBack" />
          返回
        </div>
        <div onClick={addOneNoti} className="sendNotif">
          发送
        </div>
      </div>
      <div className="notiTitle"> 通知标题 </div>
      <Form.Item colon={false}>
        {/* tslint:disable-next-line:jsx-no-multiline-js*/}
        {getFieldDecorator('notiTitle', {
          rules: [{ required: true, message: '点击输入' }],
        })(<TextArea placeholder="标题" className="notiTitleTextInput" />)}
      </Form.Item>
      <div className="notiTypeTitle">通知类型 </div>
      <div className="notiTypeContainer">
        <div>
          <div
            className="notiReVisiting"
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              notiTypeClick(1);
            }}
          >
            复诊通知
          </div>
          <img
            src={require('../../../assets/moblieImg/icon_group_checkbox_select.svg')}
            className="imgIcon"
            hidden={sysMsgType !== 1}
          />
        </div>
        <div>
          <div
            className="notiVisit"
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              notiTypeClick(2);
            }}
          >
            随访通知
          </div>
          <img
            src={require('../../../assets/moblieImg/icon_group_checkbox_select.svg')}
            className="imgIcon"
            hidden={sysMsgType !== 2}
          />
        </div>
        <div>
          <div
            className="notiQuestionnaire"
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              notiTypeClick(3);
            }}
          >
            随访问卷
          </div>
          <img
            src={require('../../../assets/moblieImg/icon_group_checkbox_select.svg')}
            className="imgIcon"
            hidden={sysMsgType !== 3}
          />
        </div>
      </div>
      <div>
        <DatePicker
          mode="date"
          value={startDate}
          // tslint:disable-next-line:jsx-no-multiline-js
          onChange={date => {
            setStartDate(date);
          }}
        >
          <div className="notiSendTimeContainer">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <div>发送时间</div> <img src={require('../../../assets/moblieImg/icon_calendar.svg')} className="img" />
            </div>
            <div> {(startDate && moment(startDate).format('YYYY-MM-DD')) || ''} </div>
          </div>
        </DatePicker>
      </div>
      <div>
        <DatePicker
          mode="datetime"
          value={endDate}
          // tslint:disable-next-line:jsx-no-multiline-js
          onChange={date => {
            setEndDate(date);
          }}
        >
          <div className="notiSendTimeContainer">
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <div>就诊时间</div> <img src={require('../../../assets/moblieImg/icon_calendar.svg')} className="img" />
            </div>
            <div> {(endDate && moment(endDate).format('YYYY-MM-DD HH:mm')) || ''} </div>
          </div>
        </DatePicker>
      </div>
      <div className="notiContentMsgTitle">通知内容 </div>
      <Form.Item colon={false}>
        {/* tslint:disable-next-line:jsx-no-multiline-js*/}
        {getFieldDecorator('notiContentMsg', {
          rules: [{ required: true, message: '点击输入' }],
        })(<TextArea placeholder="通知内容" className="notiContentMsgInput" />)}
      </Form.Item>
    </div>
  );
}
const NewNotis = Form.create()(NewNotisPage);
export default NewNotis;
