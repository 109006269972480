import React, { useEffect, useState } from 'react';
import './MobileNoDatas.scss';
interface INoDataProps {
  show: boolean;
  msg?: string;
  imgurl?: string;
}
export default function MobileNoDatas(props: INoDataProps) {
  if (!props.show) {
    return null;
  }
  const [imgUrl, setImgUrl] = useState(props.imgurl || 'img_patient_null.svg');
  /*
   * 如果单独设置了图片 显示设置的图片
   */
  useEffect(() => {
    if (props.imgurl) {
      setImgUrl(props.imgurl);
    }
  }, [props.imgurl]);
  return (
    <div className="noDatasContainer">
      <img src={require(`../../assets/moblieImg/${imgUrl}`)} className="img" />
      <div className="msg"> {props.msg || '暂无通知'} </div>
    </div>
  );
}
