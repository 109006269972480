import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import Config from '../config/Config';
import { exportFile } from '../utils/UtilHook';
import Storage from './../utils/Storage';
const downFileApi = axios.create({
  baseURL: Config.activeConfig.REACT_APP_DOMAIN,
  // baseURL: 'http://192.168.1.146:8082',
  responseType: 'blob',
  /*  baseURL: 'http://192.168.1.75:8082', */
});
// 上电文拦截器
downFileApi.interceptors.request.use(
  config => {
    const global = JSON.parse(new Storage().getStore('global'));
    if (global.token) {
      config.headers.Authorization = 'Bearer ' + global.token;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 下电文拦截器
downFileApi.interceptors.response.use(
  // tslint:disable-next-line:no-any
  (response: any): any => {
    const responseType = response.data.type;
    if (responseType === 'application/json') {
      // 下载 responseType === application/json 肯定是报错
      const reader = new FileReader();
      const blob = new Blob([response.data]);
      reader.addEventListener('loadend', () => {
        const msg = JSON.parse(String(reader.result));
        message.error(msg.message);
      });
      reader.readAsText(blob, 'utf-8');
    } else {
      // 返回的数据不是 json 直接按照文件流处理
      const fileName = decodeURI(response.headers['content-disposition'].split(';')[1].split('fileName=')[1]);
      const blob = new Blob([response.data]);
      exportFile(blob, fileName);
      return response;
    }
  },
  (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message.info('发出的请求有错误，服务器没有进行新建或修改数据的操作。');
          break;
        case 401: {
          message.info('用户没有权限（令牌、用户名、密码错误）。');
          window.location.href = '/';
          break;
        }

        case 403:
          message.info('用户得到授权，但是访问是被禁止的。');
          break;
        case 404:
          message.info('发出的请求针对的是不存在的记录，服务器没有进行操作。');
          break;
        case 406:
          message.info('请求的格式不可得。');
          break;
        case 410:
          message.info('请求的资源被永久删除，且不会再得到的。');
          break;
        case 422:
          message.info('当创建一个对象时，发生一个验证错误。');
          break;
        case 500:
          message.info('服务器发生错误，请检查服务器。');
          break;
        case 502:
          message.info('网关错误。');
          break;
        case 503:
          message.info('服务不可用，服务器暂时过载或维护。');
          break;
        case 504:
          message.info('网关超时。');
          break;
        default:
          message.info('未知错误');
      }
    }
  }
);
// tslint:disable-next-line:no-any
export const down = (data: any, fromName: string) => {
  const blob = new Blob([data.data], {
    type: 'application/vnd.ms-excel;charset=UTF-8',
  });
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fromName);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};
export default downFileApi;
