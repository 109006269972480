import { connect } from 'dva';
import React from 'react';
class SHGFY20190001 extends React.Component<{}> {
  public render() {
    return (
      <div id="SHGFY20190001">
        <div className="explain_box">
          <h1 className="firstCode">一、 项目介绍</h1>
          <h4> 1. 项目简介</h4>
          <div className="thirdCode">
            该项目由上海国妇婴医院发起。通过本量表系统记录患者辅助生殖方法和妊娠信息，以备后续科研项目进行需要的数据收集工作。我们将继续收集上述患者信息直至项目研究结束或患者永久性退出。
          </div>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">二、填写指南：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span>本量表系统内所记录的信息主要包括：辅助生殖方法和妊娠。</span>
            </li>
            <li>
              <span>
                病例在系统内首次创建后，系统将为患者进行编号，该编号为患者的唯一识别信息。根据此编号可在系统内查询患者病例信息。
              </span>
            </li>
            <li>
              <span>
                请注意准确填写问卷信息，仔细查看问题内容及说明。问题涉及数值单位时，请根据问题标注的单位填写。
              </span>
            </li>
            <li>
              <span> 问卷医生填写部分，需要由专业医生进行收集填写。</span>
            </li>
          </ul>
        </div>
        <div className="explain_box">
          <h1 className="firstCode">三、 注意事项：</h1>
          <ul className="pointLi thirdCode">
            <li>
              <span> 我们会严格保守患者任何个人信息秘密。</span>
            </li>
            <li>
              <span>医生必须确保填写内容正确无误后提交。</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default connect()(SHGFY20190001);
