import DiseasePreview from './Main/DiseaseOverview/DiseasePreview';
import GaugeSurvey from './Main/GaugeSurvey/GaugeSurvey';
import HatVirus from './Main/HatVirus/HatVirus';
import HJ90520200001 from './Main/HatVirus/HJ90520200001';
import JNL20200001 from './Main/HatVirus/JNL20200001'
import HomePage from './Main/HomePage/HomePage';
import LandingPage from './Main/LandingPage/LandingPage'
import PsdUpdateSuc from './Main/Password/PsdUpdateSuc';
import UpdatePsd from './Main/Password/UpdatePsd';
import PdfResult from './Main/PdfResult/PdfResult'
import QRLogin from './Main/QRActions/QRLogin';
import SearchGauge from './Main/SearchGauge/SearchGauge';
import SeeDiseaseOverview from './Main/SeeDiseaseOverview/SeeDiseaseOverview';
import SubmitCase from './Main/SubmitCase/SubmitCase';
import SurveyExplain from './Main/SurveyExplain/SurveyExplain';

import SignIn from './SignIn/SignIn';
import Test from './Test/Test';

export interface IRouteConfigItem {
  path: string;
  name: string;
  // tslint:disable-next-line:no-any
  component?: React.ComponentType | any;
  icon?: string;
  status?: string;
  routes?: IRouteConfigItem[];
}

const Routes: IRouteConfigItem[] = [
  {
    path: '/',
    name: '首页',
    component: HomePage,
  },
  {
    path: '/GaugeSurvey',
    name: '量表',
    component: GaugeSurvey,
  },
  {
    path: '/PdfResult',
    name: '量表结果',
    component: PdfResult,
  },
  {
    path: '/SearchGauge',
    name: '量表搜索页',
    component: SearchGauge,
  },
  {
    path: '/DiseasePreview',
    name: '病例总览',
    component: DiseasePreview,
  },
  {
    path: '/SeeDiseaseOverview/:patientId/:patientName/:customCode',
    name: '查看病例总览',
    component: SeeDiseaseOverview,
  },
  {
    path: '/SurveyExplain/:companyCode',
    name: '调查说明',
    component: SurveyExplain,
  },
  {
    path: '/SignIn/:code',
    name: '登录',
    component: SignIn,
  },
  {
    path: '/QRLogin',
    name: '二维码',
    component: QRLogin,
  },

  {
    path: '/SubmitCase/:code/:name/:sampleSequence',
    name: '提交成功',
    component: SubmitCase,
  },
  {
    path: '/Test',
    name: '测试',
    component: Test,
  },
  {
    path: '/UpdatePsd/:name/:code',
    name: '修改密码',
    component: UpdatePsd,
  },

  {
    path: '/PsdUpdateSuc/:code',
    name: '修改成功',
    component: PsdUpdateSuc,
  },
  {
    path: '/HatVirus',
    name: '冠状病毒结果页',
    component: HatVirus,
  },
  {
    path: '/HJ90520200001',
    name: '海军第905医院',
    component: HJ90520200001,
  },
  {
    path: '/JNL20200001',
    name: '基诺链冠状病毒自测',
    component: JNL20200001,
  },
  {
    path: '/LandingPage',
    name: '流行病登记说明',
    component: LandingPage,
  },
];

export default Routes;
