import { Button, Dropdown, Input, Menu, message } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { connect } from 'dva';
import React from 'react';
import DiseaseManage from '../../../api/DiseaseManage';
import downFileApi from '../../../api/Downfile';
import GaugeSurvey from '../../../api/GaugeSurvey';
import { ScanImgModalCmp } from '../../../components/QRModalCmp/QRModalCmp';
import { IconFont } from '../../../config/IconConfig';
import LayoutComponent from './../../../layout/LayoutComponent';
import { IParam, IPatientInfo, IProps, IState, MobileActions, PcaseMsg } from './CodeType';
import { PCaseCard } from './Component/PCaseCard';
import './SearchGauge.scss';
const { Search } = Input;
const isPc = window.screen.width > 500;

class SearchGauge extends React.Component<IProps, IState> {
  public state = {
    data: {},
    point: '请输入病例编号',
    status: 0,
    QRUrl: '',
    QRImgBase64: '',
  } as IState;
  // tslint:disable-next-line:no-any
  public fileInput: any;
  public componentDidMount() {
    window.addEventListener('click', () => {
      this.setState({
        QRStatus: false,
      });
    });
  }
  public componentWillUnmount() {
    window.removeEventListener('click', () => {
      this.setState({
        QRStatus: false,
      });
    });
  }

  public render() {
    const btn = (
      <div>
        <IconFont type="iconicon_search1" className="web_iconicon_search" />
        <span>搜索</span>
      </div>
    );

    const btnToForm = () => {
      if (this.state.status) {
        // 操作状态1：正常2：继续编辑3：锁定4:不显示 5 有匿名问卷 6 没有匿名问卷
        const titles = [' 新增问卷', '继续填写', '新增问卷', ' ', ' 继续填写病例', ' 新增病例'];
        const status = [false, false, true, false, false];
        if (this.state.status === 3) {
          return <div className="enable">其他用户正在填写，无法操作</div>;
        } else {
          return (
            <Button
              type="primary"
              onClick={this.linkGaugeSurvey}
              className="ser_btn"
              disabled={status[this.state.status - 1]}
              hidden={this.state.data && this.state.data.status === 4}
            >
              <img src={require('./../../../assets/img/icon_edit_white.svg')} />
              {titles[this.state.status - 1]}
            </Button>
          );
        }
      }
    };
    const menusItems: JSX.Element[] = [];

    MobileActions.map((item, index) => {
      let push = true;
      if (index === 0 && !this.props.global.sysConfig.qrCodeAdd) {
        // 第一个 ,如果没有二维码权限隐藏
        push = false;
      }
      if (push) {
        menusItems.push(
          <Menu.Item onClick={e => this.mobileAddCase(e, index)} key={index}>
            <div>
              <img
                src={require(`../../../assets/img/${item.img}`)}
                alt=""
                className="moblieQRimgIcon"
                style={{ marginRight: '10px' }}
              />
              {item.title}
            </div>
          </Menu.Item>
        );
      }
    });
    const isShowExcelDown = this.props.global.sysConfig.excelImport;
    return (
      <div className="SearchGauge">
        <LayoutComponent isBack={true} name="填写问卷">
          <div className="SearchGaugeContainer">
            <div className="header-content" hidden={isPc}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Dropdown overlay={<Menu>{menusItems} </Menu>} trigger={['click']} placement="bottomRight">
                  <div className="addCaseBtn">
                    <img src={require('../../../assets/img/icon_add_patient.svg')} />
                    新增病例
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="search_content">
              <Search
                // tslint:disable-next-line: jsx-alignment
                placeholder={this.state.point}
                enterButton={btn}
                // size="large"
                onSearch={value => this.searchSurvey(value)}
              />
              <div className="searchType">
                <span> 搜索指定内容</span>
                <div className="cornerContainer">
                  <div
                    className={this.state.searchType ? 'cornerBtn' : 'cornerBtnSelected'}
                    onClick={() => this.setState({ searchType: 0, point: '请输入病例编号' })}
                  >
                    病例编号
                  </div>
                  <div
                    className={this.state.searchType ? 'cornerBtnSelected' : 'cornerBtn'}
                    onClick={() => this.setState({ searchType: 1, point: '请输入就诊序号' })}
                  >
                    就诊序号
                  </div>
                </div>
              </div>
              {/* tslint:disable-next-line:jsx-no-multiline-js */}
              {this.state.data && this.state.data.id ? (
                <div className="msg_box">
                  <div className="msg_name">
                    <div className="na">姓名：</div>
                    <div className="eclips">{this.state.data.name}</div>
                  </div>
                  <div className="msg_name">
                    <div className="na">编号：</div>
                    <div className="eclips">{this.state.data.customCode ? this.state.data.customCode : '暂无'}</div>
                  </div>
                  <div className="msg_name">
                    <div className="na">
                      {this.props.global.sysConfig.maxVisitNumber === -1 ? '访视次数：' : '访视次数/总访视数：'}
                    </div>
                    <div>
                      {// tslint:disable-next-line:jsx-no-multiline-js
                      this.props.global.sysConfig.maxVisitNumber === -1
                        ? `${this.state.data.visitNumber}次`
                        : `${this.state.data.visitNumber}/${this.state.data.maxVisitNumber} 次`}
                    </div>
                  </div>
                  <div className="msg_name">
                    <div className="na">最后填写人：</div>
                    <div className="eclips">{this.state.data.expertName}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* tslint:disable-next-line:jsx-no-multiline-js */}
              {this.state.status === 5 || this.state.status === 2 ? (
                <div className="msg_us">您最后一次访视还未填写结束</div>
              ) : (
                ''
              )}

              {btnToForm()}
            </div>
            <div className="header-content" hidden={!isPc}>
              <div>
                <div className="pCardsTitle"> 添加病例 </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div hidden={!this.props.global.sysConfig.qrCodeAdd} className="addNewCase">
                    <PCaseCard
                      title={PcaseMsg[0].title}
                      discription={PcaseMsg[0].discription}
                      img={PcaseMsg[0].img}
                      showQR={true}
                    />
                  </div>
                  <div>
                    <PCaseCard
                      title={PcaseMsg[1].title}
                      discription={PcaseMsg[1].discription}
                      img={PcaseMsg[1].img}
                      onClick={this.linkGaugeSurvey}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '40px' }} hidden={!isShowExcelDown}>
                <div className="pCardsTitle"> EXCEL导入患者 </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <PCaseCard
                      title={PcaseMsg[2].title}
                      discription={PcaseMsg[2].discription}
                      img={PcaseMsg[2].img}
                      onClick={this.getExcelTemplate}
                    />
                  </div>
                  <div onClick={this.upload}>
                    <input
                      type="file"
                      multiple={false}
                      name="file"
                      id="file"
                      ref={el => (this.fileInput = el)}
                      onChange={this.inputChange}
                      hidden={true}
                    />
                    <PCaseCard title={PcaseMsg[3].title} discription={PcaseMsg[3].discription} img={PcaseMsg[3].img} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutComponent>
        <ScanImgModalCmp visible={this.state.visible} colseModal={() => this.setState({ visible: false })} />
      </div>
    );
  }

  /**
   *
   * 上传导入模板触发上传事件
   */

  private upload = () => {
    this.fileInput.click();
  };

  private inputChange = async (info: React.ChangeEvent<HTMLInputElement>) => {
    if (info.currentTarget.files) {
      const file = info.currentTarget.files[0];
      if (file) {
        const formdata = new FormData();
        formdata.append('file', file);
        const { result } = await GaugeSurvey.uploadExcelTemplate(formdata);
        if (result) {
          message.success('上传成功');
        } else {
          message.success('重新选择上传模板');
        }
      } else {
        message.success('选择上传模板');
      }
    }
  };

  /**
   * 手机端新增病例
   */
  private mobileAddCase = (e: ClickParam, index: number) => {
    if (index === 0) {
      // 显示二维码
      this.setState({ visible: true });
    } else {
      // 新增病例
      this.props.history.push(`/GaugeSurvey?writeType=surveyCreate`);
    }
    e.domEvent.stopPropagation();
  };

  /**
   * 跳转到量表页
   */

  private linkGaugeSurvey = () => {
    if (this.state.data && this.state.data.id) {
      this.props.history.push(`/GaugeSurvey?writeType=surveyCreate&patientId=${this.state.data.id}`);
    } else {
      // 新增病例
      this.props.history.push(`/GaugeSurvey?writeType=surveyCreate`);
    }
  };

  private getExcelTemplate = async () => {
    downFileApi.get(`/logined/excel/template/downLoad`);
  };

  /**
   * 搜索如果能搜到 直接显示 否则查看是否有匿名问卷
   */
  private searchSurvey = async (val: string) => {
    if (val) {
      const req: IParam = this.state.searchType ? { code: val } : { customCode: val };
      const { result } = await DiseaseManage.getPatientByCode<IPatientInfo, IParam>(req);
      if (result) {
        this.setState({ status: result.status, data: result });
      } else {
        this.getAnonymousInfo();
      }
    }
  };

  /**
   * 获取匿名问卷
   */
  private getAnonymousInfo = async () => {
    const { result } = await DiseaseManage.getAnonymousInfo();

    if (result.id) {
      this.setState({ status: 5, data: undefined });
    } else {
      this.setState({ status: 6, point: '没有找到患者信息', data: undefined });
    }
  };
}
export default connect(global => global)(SearchGauge);
