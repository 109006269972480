import { Tooltip } from 'antd';
import { Toast } from 'antd-mobile';
import { Link } from 'dva/router';
import moment from 'moment';
import React from 'react';
import { IDiseaseItem } from '../../../api/DiseaseManage';
import './ItemCards.scss';
const isPc = window.screen.width > 800;
const isMobile = window.screen.width < 500;
interface IItemCardProps {
  item: IDiseaseItem;
}
const actionsUrl = (record: IDiseaseItem) => {
  // 操作状态1：正常 2：继续编辑3：锁定4:不显示
  if (record.id) {
    switch (record.status) {
      case 1: // 新增问卷
        return `/GaugeSurvey?writeType=surveyCreate&patientId=${record.id}`;
      case 2: // 继续填写
        return `/GaugeSurvey?writeType=surveyCreate&patientId=${record.id}`;
      default:
        // 锁定或者不显示不需要跳转
        return '#';
    }
  } else {
    // 匿名问卷直接到新增病例

    return `/GaugeSurvey?writeType=surveyCreate`;
  }
};
export function ItemCard(props: IItemCardProps) {
  const { item } = props;
  const link = `/SeeDiseaseOverview/${item.id}/${item.name}/${item.customCode}`;
  if (item.customCode === '/') {
    return (
      <div className="listItemCard">
        <div className="itemHeader">
          <div className="timer noMsgTitle">暂无创建日期</div>
          <div className="name noMsgTitle" title={item.name}>
            暂无创建人
          </div>
        </div>
        <div className="itemCardContainer">
          <div className="itemContentContainer">
            <div className="itemContent">
              <div>
                <img src={require('../../../assets/img/img_head_null.svg')} className="img" />
              </div>
              <div className="contentMsg">
                <div className="name noMsgTitle">
                  <div className="nameTitle noMsgTitle">{'暂无病例名称'}</div>
                  <div hidden={isMobile}>
                    <Tooltip placement="bottomLeft" title="小警告">
                      <img
                        src={require('./../../../assets/img/icon_tips_red.svg')}
                        className="icon_tips"
                        hidden={!item.isRemind}
                      />
                    </Tooltip>
                  </div>
                  <img
                    src={require('./../../../assets/img/icon_tips_red.svg')}
                    className="icon_tips"
                    hidden={isPc || !item.isRemind}
                    // tslint:disable-next-line:jsx-no-multiline-js
                    onClick={() => {
                      Toast.loading('该患者需要填写问卷', 2);
                    }}
                  />
                </div>
                <div className="tradeCode noMsgTitle">暂无病例编号： {item.customCode} </div>
                <div className="patientCode noMsgTitle">暂无就诊序号： {item.sampleSequence} </div>
                <div className="visitNumber">
                  <span className="newTitle"> 访视次数：</span>
                  {item.visitNumber}
                </div>
                <div className="visitNumber" hidden={isPc}>
                  <span className="newTitle">最后访视日期：</span>
                  {`${moment(item.lastVisitTime).format('YYYY.MM.DD HH:mm')}`}
                </div>
                <div className="visitNumber noMsgTitle" title={item.name} hidden={isPc}>
                  暂无最后编辑人
                </div>
              </div>
            </div>
            <div className="pcCardFooter">
              <div className="visitNumber">
                <span className="timer">最后访视日期：</span>
                {`${moment(item.lastVisitTime).format('YYYY.MM.DD HH：mm')}`}
              </div>
              <div className="name" title={item.name}>
                暂无最后编辑人
              </div>
            </div>
          </div>
        </div>
        <div className="itemfooter">
          <Link className="action" to={`/GaugeSurvey?writeType=surveyCreate`}>
            继续填写
          </Link>
        </div>
      </div>
    );
  }
  const lineClas = item.status === 3 ? 'action  rightLine disabledColor' : 'action rightLine';
  return (
    <div className="listItemCard">
      <div className="itemHeader">
        <div className="timer">
          {// tslint:disable-next-line:jsx-no-multiline-js
          item.created ? `创建日期:${moment(item.created).format('YYYY.MM.DD HH:mm')}` : '暂无创建日期'}
        </div>
        <div className="name" title={item.createdName}>
          <div className="nameWrap"> {item.createdName ? `创建人:${item.createdName}` : '暂无创建人'} </div>
        </div>
      </div>
      <div className="itemCardContainer">
        <div className="itemContentContainer">
          <div className="itemContent">
            <div>
              <img
                // tslint:disable-next-line:jsx-no-multiline-js
                src={require(`../../../assets/img/${
                  item.gender ? (item.gender === '男' ? 'img_male' : 'img_female') : 'img_head_null'
                }.svg`)}
                className="img"
              />
            </div>
            <div className="contentMsg">
              <div className="name">
                <div className="nameTitle">{item.name ? item.name : '匿名'}</div>
                <div hidden={isMobile}>
                  <Tooltip placement="bottomLeft" title="该患者需要填写问卷">
                    <img
                      src={require('./../../../assets/img/icon_tips_red.svg')}
                      className="icon_tips"
                      hidden={!item.isRemind}
                    />
                  </Tooltip>
                </div>
                <img
                  src={require('./../../../assets/img/icon_tips_red.svg')}
                  className="icon_tips"
                  hidden={!isMobile || !item.isRemind}
                  // tslint:disable-next-line:jsx-no-multiline-js
                  onClick={() => {
                    Toast.info('建议在此问卷收集患者信息', 1);
                  }}
                />
              </div>
              <div className="tradeCode">
                <span className="newTitle">病例编号： </span>
                <div className="CodewrapMsg">{item.customCode}</div>
              </div>
              <div className="patientCode">
                <span className="newTitle">就诊序号： </span>
                <div className="CodewrapMsg">{item.sampleSequence}</div>
              </div>
              <div className="visitNumber">
                <span className="newTitle"> 访视次数： </span>

                {// tslint:disable-next-line:jsx-no-multiline-js
                item.maxVisitNumber > 0
                  ? `${item.showVisitNumber} / ${item.maxVisitNumber ? item.maxVisitNumber : 1}`
                  : `${item.showVisitNumber} `}
              </div>
              <div className="visitNumber" hidden={isPc}>
                <span className="newTitle">最后访视日期：</span>
                {`${moment(item.lastVisitTime).format('YYYY.MM.DD HH:mm')}`}
              </div>
              <div className="visitNumber" title={item.name} hidden={isPc}>
                <span className="newTitle">最后编辑人：</span>
                {`${item.expertName}`}
              </div>
            </div>
          </div>
          <div className="pcCardFooter">
            <div className="visitNumber">
              <span className="timer">最后访视日期：</span>
              {`${moment(item.lastVisitTime).format('YYYY.MM.DD HH:mm')}`}
            </div>
            <div className="name" title={item.name}>
              <span className="newTitle">最后编辑人：</span>
              {`${item.expertName}`}
            </div>
          </div>
        </div>
      </div>
      <div className="itemfooter">
        <Link
          className={lineClas}
          title={item.status === 3 ? '其他用户正在填写，无法操作' : ''}
          to={actionsUrl(item)}
          hidden={item.status === 4}
        >
          {item.status === 2 ? '继续填写' : '新增问卷'}
        </Link>
        <Link className="action" to={link}>
          查看病例问卷
        </Link>
      </div>
    </div>
  );
}
