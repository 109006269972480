import { useDispatch } from 'dva';
import React, { useState } from 'react';
import { ISurveyHook } from '../../../utils/Survey/SurveyHook';
import { IconFont } from './../../../config/IconConfig';
import AuthCompount from './../../AuthCompount/AuthCompount';
import './FormNumInput.scss';
const isPc = window.screen.width > 500;
/**
 * 数字输入框
 * 在subjectData里面type为inputNumber
 * 根据sections 判断是否有 有箭头
 */
function FormNumInput(props: ISurveyHook) {
  const formObj = props.formObj.subjectData;
  const [inputVal, setInputVal] = useState(props.formObj.subjectData.defaultValue);
  const dispatch = useDispatch();
  function inputChange(str: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: 'survey/changeForm',
      payload: {
        props,
        val: str.currentTarget.value === '' ? '' : Number(str.currentTarget.value),
      },
    });
    setInputVal(str.currentTarget.value);
  }
  return (
    <div id="FormNumInput">
      <div className="form_box">
        <div className="form_box_content">
          <div className="tit_box">
            <AuthCompount
              // tslint:disable-next-line:jsx-no-multiline-js
              isShow={!!props.formObj.validatorData.required}
            >
              <div
                id={formObj.parentPosition && `errLogo_${formObj.field}_${formObj.parentPosition.groupIndex}`}
                className={formObj.errMsg ? 'cl star' : 'star'}
              >
                *
              </div>
            </AuthCompount>
            <div>{formObj.subjectName}</div>
          </div>

          <div className="ipt_box">
            <input
              className={`${formObj.size} form_input`}
              // tslint:disable-next-line:jsx-no-multiline-js
              defaultValue={!formObj.defaultValue ? '' : String(formObj.defaultValue)}
              onChange={inputChange}
              id={props.formObj.subjectData.field}
              type="number"
              placeholder={formObj.placeholder}
            />
            <div className="scale">{formObj.unit}</div>
            <AuthCompount
              // tslint:disable-next-line:jsx-no-multiline-js
              isShow={!!formObj.threshold && Number(formObj.threshold![0]) > inputVal}
            >
              <IconFont type="iconpc_icon_descend" />
            </AuthCompount>
            <AuthCompount
              // tslint:disable-next-line:jsx-no-multiline-js
              isShow={!!formObj.threshold && Number(formObj.threshold![1]) < inputVal}
            >
              <IconFont type="iconpc_icon_rise" />
            </AuthCompount>
          </div>
        </div>
        <AuthCompount isShow={!!formObj.explain}>
          <div className="explain">（说明：{formObj.explain}）</div>
        </AuthCompount>
        <AuthCompount isShow={isPc}>
          <div
            className="errMsg"
            id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
          >
            {formObj.errMsg}
          </div>
        </AuthCompount>
      </div>
      <AuthCompount isShow={!isPc}>
        <div
          className="m_errMsg"
          id={formObj.parentPosition && `${formObj.field}_${formObj.parentPosition.groupIndex}`}
        >
          {formObj.errMsg}
        </div>
      </AuthCompount>
    </div>
  );
}
export default React.memo(FormNumInput);
